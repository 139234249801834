import {
  MENU_NAV_ITEMS,
  PUBLIC_WEBSITE_URL,
  UTILITY_NAV_ITEMS,
} from "consts/public-website";
import React from "react";
import Logo from "static/images/full-logo.png";

export default class PublicWebsiteFooter extends React.Component {
  render() {
    return (
      <footer className="public-website__footer">
        <div className="public-website__footer__wrapper">
          <div className="public-website__footer__content">
            <a
              href={PUBLIC_WEBSITE_URL}
              className="public-website__footer__logo"
            >
              <img src={Logo} alt="logo" />
            </a>
            <nav className="public-website__footer__nav">
              <div>
                <h2>Menu</h2>
                <ul>
                  {MENU_NAV_ITEMS.map((item) => (
                    <li key={item.label}>
                      <a href={item.url}>{item.label}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h2>Utility Pages</h2>
                <ul>
                  {UTILITY_NAV_ITEMS.map((item) => (
                    <li key={item.label}>
                      <a href={item.url}>{item.label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
          <small>Copyright &copy; Onesight</small>
        </div>
      </footer>
    );
  }
}
