/**
 * @author Karim Shalapy
 * @date 2022-09-21
 * @description page for a specific category details
 * @filename categories-details.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import {
  exist,
  getDurationRange,
  getNumberReadableValue,
  getPerviousDate,
  showDefaultImage,
} from "utilities/common";
import defaultImg from "static/images/default.png";
import type { Direction, SalesValues, SortOption } from "interfaces/overview";
import { CategoryChildrenSalesData } from "interfaces/category";
import { toast } from "react-toastify";
import { getCategoriesSalesChildren } from "utilities/categories";
import { mapChartData } from "utilities/charts";
import TableView from "../../common/table-view";
import SectionLoader from "../../common/section-loader";
import CollectionCard from "../../common/collection-card";
import Pagination from "../../common/Pagination";
import SortSearch from "../../common/sort-search";
import PercentageChange from "../../common/percentage-change";

interface CategoryDetailsProps {
  categoryName: string;
  updateCategoryName: (parentName: string) => void;
  exportRef?: React.RefObject<HTMLDivElement>;
}

interface CategoryDetailsState {
  id: string;
  categorySummary: Omit<CategoryChildrenSalesData, "subcategories"> | null;
  comparedCategorySummary: Omit<
    CategoryChildrenSalesData,
    "subcategories"
  > | null;
  previousCategorySummary?: Omit<
    CategoryChildrenSalesData,
    "subcategories"
  > | null;
  loadingCategorySummary: boolean;
  loadingComparedCategorySummary: boolean;
  loadingCategoryChildren: boolean;
  loadingComparedCategoryChildren: boolean;
  categoryChildrenSales: SalesValues[] | null;
  comparedCategoryChildrenSales: SalesValues[] | null;
  currentPage: number;
  comparedCurrentPage: number;
  totalPages: number;
  comparedTotalPages: number;
  searchQuery: string;
  sortBy?: SortOption;
  sortDirection?: Direction;
}

class CategoryDetails extends React.Component<
  CategoryDetailsProps & WithTranslation,
  CategoryDetailsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: CategoryDetailsProps & WithTranslation) {
    super(props);

    this.state = {
      loadingCategorySummary: true,
      loadingComparedCategorySummary: true,
      loadingCategoryChildren: true,
      loadingComparedCategoryChildren: true,
      id: Date.now().toString(),
      categoryChildrenSales: null,
      comparedCategoryChildrenSales: null,
      categorySummary: null,
      previousCategorySummary: null,
      comparedCategorySummary: null,
      currentPage: 1,
      comparedCurrentPage: 1,
      totalPages: 1,
      comparedTotalPages: 1,
      searchQuery: "",
    };

    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.loadAllData = this.loadAllData.bind(this);
    this.updateCurrentPage = this.updateCurrentPage.bind(this);
    this.updateComparedCurrentPage = this.updateComparedCurrentPage.bind(this);
    this.loadCategoryChildren = this.loadCategoryChildren.bind(this);
    this.loadPreviousCategorySummary =
      this.loadPreviousCategorySummary.bind(this);
    this.loadComparedCategoryChildren =
      this.loadComparedCategoryChildren.bind(this);
  }

  componentDidMount() {
    this.loadAllData();
    this.context.addUpdatesListener(this.state.id, this.loadAllData);
  }

  componentDidUpdate(
    prevProps: Readonly<CategoryDetailsProps & WithTranslation>,
    prevState: Readonly<CategoryDetailsState>
  ) {
    if (this.props.categoryName !== prevProps.categoryName) {
      this.loadAllData();
    } else {
      const sortChanged =
        prevState.sortBy !== this.state.sortBy ||
        prevState.sortDirection !== this.state.sortDirection;
      const searchChanged = prevState.searchQuery !== this.state.searchQuery;
      if (
        prevState.currentPage !== this.state.currentPage ||
        sortChanged ||
        searchChanged
      ) {
        this.loadCategoryChildren()
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            this.setState({
              loadingCategoryChildren: false,
            });
          });
      }

      if (
        prevState.comparedCurrentPage !== this.state.comparedCurrentPage ||
        sortChanged ||
        searchChanged
      ) {
        this.loadComparedCategoryChildren()
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            this.setState({
              loadingComparedCategoryChildren: false,
            });
          });
      }
    }
  }

  updateSearchQuery(searchQuery: string) {
    this.setState({
      searchQuery,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateSort(sortBy?: SortOption, sortDirection?: Direction) {
    if (!sortBy || !sortDirection) {
      this.setState({
        sortBy: undefined,
        sortDirection: undefined,
        currentPage: 1,
        comparedCurrentPage: 1,
      });
      return;
    }
    this.setState({
      sortBy,
      sortDirection,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateCurrentPage(currentPage: number) {
    this.setState({
      currentPage,
    });
  }

  updateComparedCurrentPage(comparedCurrentPage: number) {
    this.setState({
      comparedCurrentPage,
    });
  }

  loadCategoryChildren() {
    this.setState({
      loadingCategoryChildren: true,
    });
    let categoryChildrenSales = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      categoryChildrenSales = getCategoriesSalesChildren({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        parentName: this.props.categoryName,
        currentPage: this.state.currentPage,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          categoryChildrenSales: res.data.subcategories,
          categorySummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            subcategory_count: res.data.subcategory_count,
          },
          loadingCategoryChildren: false,
          totalPages: res.pagination.totalPageCount || 1,
        });
      });
    }
    return categoryChildrenSales;
  }

  loadPreviousCategorySummary() {
    this.setState({
      loadingCategorySummary: true,
    });
    let previousCategorySummaryPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      previousCategorySummaryPromise = getCategoriesSalesChildren({
        project: this.context.project,
        from: getPerviousDate(this.context.currentPeriod.from).from,
        to: getPerviousDate(this.context.currentPeriod.to).to,
        parentName: this.props.categoryName,
        currentPage: this.state.currentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          previousCategorySummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            subcategory_count: res.data.subcategory_count,
          },
          loadingCategorySummary: false,
        });
      });
    }
    return previousCategorySummaryPromise;
  }

  loadComparedCategoryChildren() {
    this.setState({
      loadingComparedCategoryChildren: true,
    });

    let comparedCategoryChildrenSales = Promise.resolve();
    if (this.context.comparedPeriod) {
      comparedCategoryChildrenSales = getCategoriesSalesChildren({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        parentName: this.props.categoryName,
        currentPage: this.state.comparedCurrentPage,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          comparedCategoryChildrenSales: res.data.subcategories,
          comparedCategorySummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            subcategory_count: res.data.subcategory_count,
          },
          loadingComparedCategorySummary: false,
          loadingComparedCategoryChildren: false,
          comparedTotalPages: res.pagination.totalPageCount || 1,
        });
      });
    }
    return comparedCategoryChildrenSales;
  }

  loadAllData() {
    const categoryChildrenSales = this.loadCategoryChildren();
    const previousCategorySummary = this.loadPreviousCategorySummary();
    const comparedCategoryChildrenSales = this.loadComparedCategoryChildren();
    Promise.all([
      categoryChildrenSales,
      previousCategorySummary,
      comparedCategoryChildrenSales,
    ])
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        this.setState({
          loadingCategoryChildren: false,
          loadingCategorySummary: false,
          loadingComparedCategorySummary: false,
        });
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const allCategoriesData = mapChartData(this.state.categoryChildrenSales);
    const allComparedCategoriesData = mapChartData(
      this.state.comparedCategoryChildrenSales
    );
    const tabs = {
      [t(this.props.categoryName)]: {
        title: `${t(this.props.categoryName)}`,
        items: allCategoriesData,
      },
    };
    const comparedTabs = {
      [t(this.props.categoryName)]: {
        title: `${t(this.props.categoryName)}`,
        items: allComparedCategoriesData,
      },
    };

    return (
      <div
        className="product-analytics"
        key={this.state.id}
        ref={this.props.exportRef}
      >
        <div className="card-container">
          {this.state.loadingCategorySummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="card product-data">
              <div className="information-card">
                <div className="product-info">
                  {this.state.categorySummary?.image_url ? (
                    <img
                      src={this.state.categorySummary?.image_url}
                      alt={this.state.categorySummary?.name}
                      onError={(e) => {
                        showDefaultImage(e);
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImg}
                      alt={this.state.categorySummary?.name}
                    />
                  )}
                  <div className="product-name">
                    <span className="title">
                      {this.state.categorySummary?.name}
                    </span>
                    <span className="tooltip">
                      {this.state.categorySummary?.name}
                    </span>
                  </div>
                  <div className="product-count">
                    {getNumberReadableValue(
                      this.state.categorySummary?.subcategory_count
                    )}{" "}
                    <span>{t("subcategories")}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*
            Next line is needed to push the stats to the next line when there's a comparison period
          */}
          {this.context.comparedPeriod && <div />}

          {this.state.loadingCategorySummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="product-sales">
              {this.context.comparedPeriod && (
                <h2 className="card--title">
                  {t(this.context.currentPeriod.alias, { ns: "common" })} (
                  {new Date(this.context.currentPeriod.to).getFullYear()})
                </h2>
              )}
              <div className="card product-sales transparent-card">
                <div className="stats-card">
                  <div className="stat">
                    <div className="label">{t("unitsSold")}</div>
                    <div className="title">
                      {getNumberReadableValue(
                        this.state.categorySummary?.number_sold
                      )}
                    </div>
                    {!this.context.comparedPeriod && (
                      <PercentageChange
                        current={this.state.categorySummary?.number_sold}
                        previous={
                          this.state.previousCategorySummary?.number_sold
                        }
                      />
                    )}
                  </div>
                  <div className="stat">
                    <div className="label">{t("totalSalesValue")}</div>
                    <div className="title">
                      {`${getNumberReadableValue(
                        this.state.categorySummary?.sales_value
                      )} ${this.context.settings.currency}`}
                    </div>
                    {!this.context.comparedPeriod && (
                      <PercentageChange
                        current={this.state.categorySummary?.sales_value}
                        previous={
                          this.state.previousCategorySummary?.sales_value
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.context.comparedPeriod &&
            (this.state.loadingComparedCategorySummary ? (
              <div className="card product-data">
                <SectionLoader />
              </div>
            ) : (
              <div className="product-sales">
                <h2 className="card--title">
                  {t(this.context.comparedPeriod.alias, { ns: "common" })} (
                  {new Date(this.context.comparedPeriod.to).getFullYear()})
                </h2>
                <div className="card product-sales transparent-card">
                  <div className="stats-card">
                    <div className="stat">
                      <div className="label">
                        {t("unitsSold", { ns: "product" })}
                      </div>
                      <div className="title">
                        {getNumberReadableValue(
                          this.state.comparedCategorySummary?.number_sold
                        )}
                      </div>
                    </div>
                    <div className="stat">
                      <div className="label">
                        {t("totalSalesValue", { ns: "product" })}
                      </div>
                      <div className="title">
                        {`${getNumberReadableValue(
                          this.state.comparedCategorySummary?.sales_value
                        )} ${this.context.settings.currency}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="span-2">
            <SortSearch
              searchHandler={this.updateSearchQuery}
              sortHandler={this.updateSort}
              sortBy={this.state.sortBy}
              direction={this.state.sortDirection}
              mode="minimal"
            />
          </div>
          <div className={this.context.comparedPeriod ? "" : "span-2 centered"}>
            <div className="card">
              <h2 className="card--title">
                <div>
                  {t("subcategoriesSales")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </div>
              </h2>
              <div className="sales-table categories-sales-table big">
                <TableView
                  products={this.state.categoryChildrenSales || []}
                  loading={this.state.loadingCategoryChildren}
                  type="categories-children-sales-list"
                />
              </div>
              {!this.state.loadingCategoryChildren &&
                this.state.searchQuery &&
                !this.state.categoryChildrenSales.length && (
                  <p>{t("noSearchResult", { ns: "common" })}</p>
                )}
            </div>
            {this.state.totalPages > 1 && (
              <Pagination
                totalPageCount={this.state.totalPages}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.updateCurrentPage}
              />
            )}
          </div>
          {this.context.comparedPeriod && (
            <div>
              <div className="card">
                <h2 className="card--title">
                  <div>
                    {t("subcategoriesSales")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod.type,
                        this.context.comparedPeriod?.from,
                        this.context.comparedPeriod?.to
                      )}
                      )
                    </span>
                  </div>
                </h2>
                <div className="sales-table categories-sales-table big">
                  <TableView
                    products={this.state.comparedCategoryChildrenSales || []}
                    loading={this.state.loadingComparedCategoryChildren}
                    type="categories-children-sales-list"
                  />
                </div>
                {!this.state.loadingComparedCategoryChildren &&
                  this.state.searchQuery &&
                  !this.state.comparedCategoryChildrenSales.length && (
                    <p>{t("noSearchResult", { ns: "common" })}</p>
                  )}
              </div>
              {this.state.comparedTotalPages > 1 && (
                <Pagination
                  totalPageCount={this.state.comparedTotalPages}
                  currentPage={this.state.comparedCurrentPage}
                  pageNeighbors={1}
                  changePageHandler={this.updateComparedCurrentPage}
                />
              )}
            </div>
          )}
          {this.state.categoryChildrenSales && (
            <div
              className={`card ${
                this.context.comparedPeriod ? "" : "span-2 centered"
              }`}
            >
              <CollectionCard tabs={tabs} title={t(this.props.categoryName)} />
            </div>
          )}
          {this.state.comparedCategoryChildrenSales && (
            <div className="card">
              <CollectionCard
                tabs={comparedTabs}
                title={t(this.props.categoryName)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

CategoryDetails.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["categories", "common"])(CategoryDetails);
