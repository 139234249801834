/**
 * @author Ahmed Serag
 * @date 2021-12-05
 * @description settings nav-bar
 * @filename settings-nav-bar.tsx
 */
import React from "react";

interface SettingsNavBarProps {
  tabs: {
    icon: string;
    label: string;
    description: string;
    active: boolean;
    onSelectTab: () => void;
  }[];
}

export default class SettingsNavBar extends React.Component<SettingsNavBarProps> {
  render() {
    return (
      <div className="settings-nav-bar">
        {this.props.tabs.map((tab, index) => (
          <button
            key={index}
            className={`settings-nav-bar--item ${tab.active ? "active" : ""}`}
            onClick={tab.onSelectTab}
            type="button"
          >
            {tab.icon}
            <div className="settings-nav-bar--item__content">
              <h3 className="settings-nav-bar--item__label">{tab.label}</h3>
              <p className="settings-nav-bar--item__description">
                {tab.description}
              </p>
            </div>
          </button>
        ))}
      </div>
    );
  }
}
