/**
 * @author Ahmed Serag
 * @date 2019-12-09
 * @description support layout
 * @filename support.tsx
 */
import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import {
  CONTACT_US_SCHEMA,
  sendSupportMessage as sendSupportMessageUtility,
} from "utilities/settings";
import { withTranslation, WithTranslation } from "react-i18next";
import { SupportCategories } from "interfaces/settings";
import ArrowDownIcon from "static/images/down-arrow.svg";
import Header from "../../common/header";

/**
 * React Component that renders Not-found Layout.
 *
 * @export
 * @class NotFound
 * @extends {React.Component}
 */
class SupportClass extends React.Component<
  RouteComponentProps & WithTranslation,
  {
    loading: boolean;
    categoryMenu: boolean;
    selectedCategory: SupportCategories;
  }
> {
  static sendSupportMessage(
    values: {
      title: string;
      message: string;
      category: SupportCategories;
    },
    formikHelpers: FormikHelpers<{
      title: string;
      message: string;
      category: SupportCategories;
    }>
  ): void {
    sendSupportMessageUtility(values.title, values.message, values.category)
      .then(() => {
        toast.success("Your message has been sent successfully");
        formikHelpers.resetForm();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {
      loading: false,
      categoryMenu: false,
      selectedCategory: "I found a bug",
    };
  }

  componentDidMount() {
    // set page title
    document.title = "Support";
  }

  categoryChangeHandler(e, setFieldValue: (field, value) => void) {
    this.setState({
      selectedCategory: e.target.innerText,
      categoryMenu: false,
    });
    // this is the aria-label of the selected category to be sent to backend
    setFieldValue("category", e.target.attributes["aria-label"].value);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="Support">
        <Header title={t("support")} showPeriod={false} />
        <div className="card sales-card">
          <h2 className="card--title">{t("sendMessage")}</h2>
          <Formik
            validationSchema={CONTACT_US_SCHEMA}
            initialValues={{
              title: "",
              message: "",
              category: this.state.selectedCategory,
            }}
            isInitialValid={false}
            onSubmit={SupportClass.sendSupportMessage}
          >
            {(formikBag) => (
              <Form className="card--section">
                <Field name="title">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="title">{t("title")}</label>
                      <input
                        id="title"
                        placeholder={t("titlePlaceholder")}
                        name="title"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="category" setFieldValue>
                  {({ meta }) => (
                    <div className="form-field">
                      <label htmlFor="category">{t("category")}</label>
                      <div
                        className="dropdown-container"
                        role="listbox"
                        aria-expanded={this.state.categoryMenu}
                        aria-label={t("category")}
                        tabIndex={0}
                        onClick={() => {
                          this.setState({
                            categoryMenu: !this.state.categoryMenu,
                          });
                        }}
                      >
                        <span className="dropdown-container__sort">
                          {this.state.selectedCategory}
                        </span>
                        <ArrowDownIcon className="dropdown-icon" />
                        {this.state.categoryMenu && (
                          <ul className="dropdown-menu" role="listbox">
                            <li
                              role="option"
                              aria-selected={!this.state.selectedCategory}
                              aria-label="I found a bug"
                              className="item"
                              onClick={(e) => {
                                this.categoryChangeHandler(
                                  e,
                                  formikBag.setFieldValue
                                );
                              }}
                            >
                              {t("iFoundABug")}
                            </li>
                            <li
                              role="option"
                              aria-selected={!this.state.selectedCategory}
                              aria-label="My data isn't getting updated"
                              className="item"
                              onClick={(e) => {
                                this.categoryChangeHandler(
                                  e,
                                  formikBag.setFieldValue
                                );
                              }}
                            >
                              {t("myDataIsntGettingUpdated")}
                            </li>
                            <li
                              role="option"
                              aria-selected={!this.state.selectedCategory}
                              aria-label="Refund Request"
                              className="item"
                              onClick={(e) => {
                                this.categoryChangeHandler(
                                  e,
                                  formikBag.setFieldValue
                                );
                              }}
                            >
                              {t("refundRequest")}
                            </li>
                            <li
                              role="option"
                              aria-selected={!this.state.selectedCategory}
                              aria-label="Feature suggestion"
                              className="item"
                              onClick={(e) => {
                                this.categoryChangeHandler(
                                  e,
                                  formikBag.setFieldValue
                                );
                              }}
                            >
                              {t("featureSuggestion")}
                            </li>
                            <li
                              role="option"
                              aria-selected={!this.state.selectedCategory}
                              aria-label="General/other"
                              className="item"
                              onClick={(e) => {
                                this.categoryChangeHandler(
                                  e,
                                  formikBag.setFieldValue
                                );
                              }}
                            >
                              {t("general/other")}
                            </li>
                          </ul>
                        )}
                      </div>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="message">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="message">{t("message")}</label>
                      <textarea
                        id="message"
                        name="message"
                        placeholder={t("messagePlaceholder")}
                        type="text"
                        rows="8"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <button type="submit" disabled={formikBag.isSubmitting}>
                  {t("send")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
export const Support = withTranslation("support")(SupportClass);
