/**
 * @author Ahmed Serag
 * @date 2021-06-09
 * @description Stock tab in the app.
 * @filename stock.tsx
 */
import {
  ItemsSales,
  SortByDirection,
  SortByValue,
  StockResponse,
} from "interfaces/overview";
import React from "react";
import { getItemsSales, getItemsStock, getSearchItems } from "utilities/stock";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  deBounce,
  exist,
  getDurationRange,
  getNumberReadableValue,
} from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";
import TableView from "../../common/table-view";
import Header from "../../common/header";
import DoughnutCard from "../../common/doughnut-card";
import Loader from "../../common/Loader";
import { TableContent } from "../../common/table-content";
import SearchIcon from "../../../../static/images/search.svg";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";
import ArrowDownIcon from "../../../../static/images/down-arrow.svg";
import Product from "./product";
import Pagination from "../../common/Pagination";
import ErrorBoundary from "../../common/error-boundary";

interface StockState {
  activeTab: "top_selling" | "product_stock" | "products_analytics" | string;
  loading: boolean;
  itemsSales: ItemsSales[];
  comparedItemsSales?: ItemsSales[];
  id: string;
  itemsStock: StockResponse;
  comparedItemsStock?: StockResponse;
  totalSales: number;
  comparedItemsTotalSales?: number;
  totalStock: number;
  totalComparisonStock: number;
  productSku: string;
  isSearching: boolean;
  searchInput: string;
  currentPage?: number;
  totalPageCount?: number;
  searchItems?: ItemsSales[];
  productSortingMenu: boolean;
  sortedByTextValue: string;
  sort: string;
  direction: string;
}

class StockClass extends React.Component<WithTranslation, StockState> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportTopProductsRef: React.RefObject<HTMLDivElement>;

  exportProductsAnalyticsRef: React.RefObject<HTMLDivElement>;

  exportProductsStockRef: React.RefObject<HTMLDivElement>;

  exportProductPageRef: React.RefObject<HTMLDivElement>;

  debouncedSearchResults = deBounce(this.getSearchResults.bind(this));

  constructor(props: WithTranslation) {
    super(props);

    this.exportTopProductsRef = React.createRef();
    this.exportProductsAnalyticsRef = React.createRef();
    this.exportProductsStockRef = React.createRef();
    this.exportProductPageRef = React.createRef();

    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.state = {
      activeTab: params.get("activeTab") ?? "top_selling",
      itemsSales: [],
      itemsStock: {
        inStock: 0,
        lowStock: 0,
        outOfStock: 0,
      },
      loading: true,
      id: Date.now().toString(),
      totalSales: 0,
      comparedItemsTotalSales: 0,
      totalStock: 0,
      totalComparisonStock: 0,
      productSku: "",
      isSearching: false,
      searchInput: "",
      productSortingMenu: false,
      sortedByTextValue: "",
      sort: "",
      direction: "",
    };
    this.loadData = this.loadData.bind(this);
    this.updateProductSku = this.updateProductSku.bind(this);
    this.changePageHandler = this.changePageHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams(this.state.activeTab);
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Stock";
  }

  componentDidUpdate(_prevProps, prevState: StockState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(this.state.activeTab);
      // set page title according to the active tab
      switch (this.state.activeTab) {
        case "top_selling":
          document.title = "Top Selling Products";
          break;
        case "product_stock":
          document.title = "Products Stock";
          break;
        case "products_analytics":
          document.title = "Products Analytics";
          break;
        default:
          document.title = "Stock";
      }
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  getSearchResults(searchQuery, searchPage?, sort?, direction?) {
    let searchItemsPromise = Promise.resolve();
    searchItemsPromise = getSearchItems({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      search: searchQuery,
      currentPage: searchPage,
      sort,
      direction,
      filters: this.context.appliedFilters,
    }).then((searchResults) => {
      this.setState({
        searchItems: searchResults.data,
        totalPageCount: searchResults.pagination.totalPageCount,
        currentPage: searchResults.pagination.currentPage,
      });
    });
    Promise.all([searchItemsPromise]);
  }

  changePageHandler(currentPage) {
    this.setState({
      currentPage,
    });
    this.getSearchResults(
      this.state.searchInput,
      currentPage,
      this.state.sort,
      this.state.direction
    );
  }

  /**
   * send search request for the input every 3 characters or every 0.5 seconds
   * of not typing
   * @param searchQuery
   */
  searchHandler(searchQuery) {
    this.setState({
      isSearching: true,
      searchInput: searchQuery,
    });
    if (!searchQuery) {
      // if search input is empty then show all products
      this.setState({ isSearching: false });
    }
    if (searchQuery.length % 3 === 0) {
      // show search results after 3 seconds

      this.getSearchResults(
        searchQuery,
        "",
        this.state.sort,
        this.state.direction
      );
    } else {
      // show search results after not typing for 0.5 seconds
      this.debouncedSearchResults(
        searchQuery,
        "",
        this.state.sort,
        this.state.direction
      );
    }
  }

  loadData() {
    this.setState({ loading: true });

    let itemsSalesPromise = Promise.resolve();
    let itemsStockPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      itemsSalesPromise = getItemsSales({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((itemsSales) => {
        this.setState({
          itemsSales: itemsSales.data,
          totalSales: itemsSales.pagination.totalAmount,
        });
      });
      itemsStockPromise = getItemsStock({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        threshold: this.context.settings?.threshold,
        filters: this.context.appliedFilters,
      }).then((itemsStock) => {
        const totalStock =
          itemsStock.inStock + itemsStock.lowStock + itemsStock.outOfStock;
        this.setState({
          itemsStock,
          totalStock,
        });
      });
    }

    let comparedItemsSalesPromise = Promise.resolve();
    let comparedItemsStockPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedItemsSalesPromise = getItemsSales({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      }).then((comparedItemsSales) => {
        this.setState({
          comparedItemsSales: comparedItemsSales.data,
          comparedItemsTotalSales: comparedItemsSales.pagination.totalAmount,
        });
      });
      // fetching compared items stock after timeout
      // to avoid the backend from blocking concurrent requests
      setTimeout(() => {
        comparedItemsStockPromise = getItemsStock({
          project: this.context.project,
          from: this.context.comparedPeriod.from,
          to: this.context.comparedPeriod.to,
          threshold: this.context.settings?.threshold,
          filters: this.context.appliedFilters,
        }).then((comparedItemsStock) => {
          const totalComparisonStock =
            comparedItemsStock.inStock +
            comparedItemsStock.lowStock +
            comparedItemsStock.outOfStock;
          this.setState({
            comparedItemsStock,
            totalComparisonStock,
          });
        });
      }, 2500);
    } else {
      this.setState({
        comparedItemsSales: undefined,
      });
    }

    this.getSearchResults(
      this.state.searchInput,
      this.state.currentPage,
      this.state.sort,
      this.state.direction
    );

    Promise.all([
      itemsSalesPromise,
      comparedItemsSalesPromise,
      itemsStockPromise,
      comparedItemsStockPromise,
    ])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  updateProductSku(productSku: string) {
    this.setState({
      productSku,
    });
  }

  sortProducts(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    sort?: SortByValue,
    direction?: SortByDirection
  ) {
    const input = e.target as HTMLElement;
    this.setState({
      sortedByTextValue: input.innerText,
      sort,
      direction,
    });
    this.getSearchResults("", "", sort, direction);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="stock">
        <Header
          title={t("productStock")}
          exportRef={
            this.state.activeTab === "top_selling"
              ? this.exportTopProductsRef
              : this.state.activeTab === "products_analytics" &&
                this.state.productSku
              ? this.exportProductPageRef
              : this.state.activeTab === "products_analytics"
              ? this.exportProductsAnalyticsRef
              : this.exportProductsStockRef
          }
        />
        <div className="tabs">
          <div
            className={`tab ${
              this.state.activeTab === "top_selling" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "top_selling") {
                this.setState({ activeTab: "top_selling" });
              }
            }}
          >
            {t("topSellingProducts")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "products_analytics" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "products_analytics") {
                this.setState({ activeTab: "products_analytics" });
                this.getSearchResults(
                  this.state.searchInput,
                  "",
                  this.state.sort,
                  this.state.direction
                );
              }
            }}
          >
            {t("productsAnalytics")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "product_stock" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "product_stock") {
                this.setState({ activeTab: "product_stock" });
              }
            }}
          >
            {t("productStock")}
          </div>
        </div>
        {this.state.activeTab === "top_selling" && (
          <div ref={this.exportTopProductsRef}>
            <div
              className={
                this.context.comparedPeriod
                  ? "card-container"
                  : "flex justify-center"
              }
            >
              <div className="card stock--table">
                <div className="stock--body">
                  <h2 className="card--title">
                    <div>
                      {t("topSellingProducts")}
                      <span className="duration">
                        (
                        {getDurationRange(
                          this.context.currentPeriod?.type,
                          this.context.currentPeriod?.from,
                          this.context.currentPeriod?.to
                        )}
                        )
                      </span>
                    </div>
                    {this.state.itemsSales.length ? (
                      <CSVLink
                        data={this.state.itemsSales}
                        filename={`items_sales breakdown from:${this.context.currentPeriod?.from}-to:${this.context.currentPeriod?.to}.csv`}
                        className="export-list-button"
                        target="_blank"
                      >
                        {t("exportList")}
                      </CSVLink>
                    ) : null}
                  </h2>
                  <ul>
                    {this.state.itemsSales.map((item, index) => (
                      <TableContent
                        key={index}
                        type="stock"
                        rowItem={{
                          image_url: item.image_url,
                          number_of_items: item.number_sold,
                          name: item.name,
                          sku: item.sku,
                        }}
                        salesValue={getNumberReadableValue(item.sales_value)}
                        stock={getNumberReadableValue(item.stock)}
                        itemIndex={index}
                        currency={this.context.settings.currency}
                        percentageValue={Math.round(
                          (item.sales_value / this.state.totalSales) * 100
                        )}
                        isCompare={!!this.context.comparedPeriod}
                      />
                    ))}
                  </ul>
                </div>
              </div>
              {this.context.comparedPeriod && this.state.comparedItemsSales && (
                <div className="card stock--table">
                  <div className="stock--body">
                    <h2 className="card--title">
                      <div>
                        {t("topSellingProducts")}
                        <span className="duration">
                          (
                          {getDurationRange(
                            this.context.comparedPeriod?.type,
                            this.context.comparedPeriod?.from,
                            this.context.comparedPeriod?.to
                          )}
                          )
                        </span>
                      </div>
                      {this.state.comparedItemsSales.length ? (
                        <CSVLink
                          data={this.state.comparedItemsSales}
                          filename={`items_sales breakdown from:${this.context.comparedPeriod?.from}-to:${this.context.comparedPeriod?.to}.csv`}
                          className="export-list-button"
                          target="_blank"
                        >
                          {t("exportList")}
                        </CSVLink>
                      ) : null}
                    </h2>
                    <ul>
                      {this.state.comparedItemsSales?.map((item, index) => {
                        return (
                          <TableContent
                            key={index}
                            type="stock"
                            rowItem={{
                              image_url: item.image_url,
                              number_of_items: item.number_sold,
                              name: item.name,
                              sku: item.sku,
                            }}
                            salesValue={getNumberReadableValue(
                              item.sales_value
                            )}
                            stock={getNumberReadableValue(item.stock)}
                            currency={this.context.settings.currency}
                            itemIndex={index}
                            percentageValue={Math.round(
                              (item.sales_value /
                                this.state.comparedItemsTotalSales) *
                                100
                            )}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.activeTab === "products_analytics" &&
          (this.state.productSku ? (
            <>
              <button
                className="products-button--back"
                type="button"
                onClick={() => {
                  this.setState({
                    productSku: "",
                  });
                }}
              >
                <BackArrowIcon />
                {t("backToList")}
              </button>
              <ErrorBoundary>
                <Product
                  productSku={this.state.productSku}
                  updateProductSku={this.updateProductSku}
                  exportRef={this.exportProductPageRef}
                />
              </ErrorBoundary>
            </>
          ) : (
            <div ref={this.exportProductsAnalyticsRef}>
              <form className="products-search">
                <SearchIcon className="search-icon" />
                <input
                  type="text"
                  className="search-input"
                  placeholder={t("searchPlaceholder")}
                  value={this.state.searchInput}
                  onChange={(e) => {
                    this.searchHandler(e.target.value);
                  }}
                />
                {this.state.isSearching && (
                  <input
                    type="reset"
                    className="clear-icon"
                    value="+"
                    onClick={() => {
                      this.setState({ isSearching: false, searchInput: "" });
                      this.getSearchResults("");
                    }}
                  />
                )}
                <div
                  className="dropdown-container"
                  onClick={() => {
                    this.setState({
                      productSortingMenu: !this.state.productSortingMenu,
                    });
                  }}
                >
                  Sort by
                  <span className="dropdown-container__sort">
                    {this.state.sortedByTextValue}
                  </span>
                  <ArrowDownIcon className="dropdown-icon" />
                  {this.state.productSortingMenu && (
                    <ul className="dropdown-menu">
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(e);
                        }}
                      >
                        default
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.Name,
                            SortByDirection.Asc
                          );
                        }}
                      >
                        product name (A-Z)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.Name,
                            SortByDirection.Desc
                          );
                        }}
                      >
                        product name (Z-A)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.NumberSold,
                            SortByDirection.Asc
                          );
                        }}
                      >
                        quantity sold (low to high)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.NumberSold,
                            SortByDirection.Desc
                          );
                        }}
                      >
                        quantity sold (high to low)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.SalesValue,
                            SortByDirection.Asc
                          );
                        }}
                      >
                        sales value (low to high)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.SalesValue,
                            SortByDirection.Desc
                          );
                        }}
                      >
                        sales value (high to low)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.Stock,
                            SortByDirection.Asc
                          );
                        }}
                      >
                        current stock (low to high)
                      </li>
                      <li
                        className="item"
                        onClick={(e) => {
                          this.sortProducts(
                            e,
                            SortByValue.Stock,
                            SortByDirection.Desc
                          );
                        }}
                      >
                        current stock (high to low)
                      </li>
                    </ul>
                  )}
                </div>
              </form>
              <div className="card product-analytics">
                <h2 className="card--title">
                  {t("productsAnalytics")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod?.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </h2>

                <div className="products-analytics-table">
                  {this.state.searchItems ? (
                    this.state.searchItems.length ? (
                      <TableView
                        products={this.state.searchItems}
                        updateProductSku={this.updateProductSku}
                        type="products-analytics"
                      />
                    ) : (
                      <div className="not-found">
                        {`${t("noSearchResultsAreYouSureYouHaveThisProduct")}`}
                      </div>
                    )
                  ) : (
                    <>
                      {this.getSearchResults(
                        this.state.searchInput,
                        "",
                        this.state.sort,
                        this.state.direction
                      )}
                      <Loader />
                    </>
                  )}
                </div>
              </div>
              <div className="page-navigator">
                <Pagination
                  totalPageCount={this.state.totalPageCount}
                  currentPage={this.state.currentPage}
                  pageNeighbors={1}
                  changePageHandler={this.changePageHandler}
                />
              </div>
            </div>
          ))}
        {this.state.activeTab === "product_stock" && (
          <div
            ref={this.exportProductsStockRef}
            className={
              this.context.comparedPeriod
                ? "card-container"
                : "flex justify-center"
            }
          >
            <div className="pie-chart-only-page">
              <DoughnutCard
                title={t("productStock")}
                items={[
                  {
                    name: [
                      `${t("inStock")}`,
                      `${this.state.itemsStock?.inStock} ${t(
                        "items"
                      )} . ${Math.round(
                        (this.state.itemsStock?.inStock /
                          this.state.totalStock) *
                          100
                      )}%`,
                    ],
                    count: `${this.state.itemsStock?.inStock}`,
                  },
                  {
                    name: [
                      `${t("outOfStock")}`,
                      `${this.state.itemsStock?.outOfStock} ${t(
                        "items"
                      )} . ${Math.round(
                        (this.state.itemsStock?.outOfStock /
                          this.state.totalStock) *
                          100
                      )}%`,
                    ],
                    count: `${this.state.itemsStock?.outOfStock}`,
                  },
                  {
                    name: [
                      `${t("lowStock")}`,
                      `${this.state.itemsStock?.lowStock} ${t(
                        "items"
                      )} . ${Math.round(
                        (this.state.itemsStock?.lowStock /
                          this.state.totalStock) *
                          100
                      )}%`,
                    ],
                    count: `${this.state.itemsStock?.lowStock}`,
                  },
                ]}
                currentPeriod={this.context.currentPeriod}
                blackThemed
              />
            </div>
            {this.context.comparedPeriod && (
              <div className="pie-chart-only-page">
                <DoughnutCard
                  title={t("productStock")}
                  items={[
                    {
                      name: [
                        `${t("inStock")}`,
                        `${this.state.comparedItemsStock?.inStock} ${t(
                          "items"
                        )} . ${Math.round(
                          (this.state.comparedItemsStock?.inStock /
                            this.state.totalComparisonStock) *
                            100
                        )}%`,
                      ],
                      count: `${this.state.comparedItemsStock?.inStock}`,
                    },
                    {
                      name: [
                        `${t("outOfStock")}`,
                        `${this.state.comparedItemsStock?.outOfStock} ${t(
                          "items"
                        )} . ${Math.round(
                          (this.state.comparedItemsStock?.outOfStock /
                            this.state.totalComparisonStock) *
                            100
                        )}%`,
                      ],
                      count: `${this.state.comparedItemsStock?.outOfStock}`,
                    },
                    {
                      name: [
                        `${t("lowStock")}`,
                        `${this.state.comparedItemsStock?.lowStock} ${t(
                          "items"
                        )} . ${Math.round(
                          (this.state.comparedItemsStock?.lowStock /
                            this.state.totalComparisonStock) *
                            100
                        )}%`,
                      ],
                      count: `${this.state.comparedItemsStock?.lowStock}`,
                    },
                  ]}
                  currentPeriod={this.context.comparedPeriod}
                  blackThemed
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

StockClass.contextType = ANALYTICS_CONTEXT;
export const Stock = withTranslation("stock")(StockClass);
