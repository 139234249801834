/**
 * @author Salma Hefnawy
 * @date 2021-04-03
 * @description add user component
 * @filename add-user.tsx
 */
import { Formik, Form, Field } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { string as YUPString, object as YUPObject } from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { AddUserInput } from "interfaces/user-management";
import { addUser } from "utilities/user-management";
import Loader from "../../../common/Loader";
import ArrowDownIcon from "../../../../../static/images/down-arrow.svg";

interface AddUserState {
  rolesMenu: boolean;
  loading: boolean;
}
interface AddUserProps {
  onFinish: () => void;
  onSubmit: () => void;
  roles: string[];
}
class AddUser extends React.Component<
  WithTranslation & AddUserProps,
  AddUserState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: WithTranslation & AddUserProps) {
    super(props);

    this.state = {
      rolesMenu: false,
      loading: false,
    };
    this.handleAddUser = this.handleAddUser.bind(this);
  }

  handleAddUser(values: AddUserInput) {
    addUser(values.name, values.email, values.role)
      .then((result) => {
        toast.success(result.message);
        this.props.onSubmit();
        this.props.onFinish();
      })
      .catch((errors) => {
        errors.forEach((error: string) => toast.error(error));
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return (
        <div className="settings settings--user-management">
          <Loader />
        </div>
      );
    }

    return (
      <div className="settings settings--user-management">
        <div className="card">
          <h2 className="card--title">{t("addUser")}</h2>
          <Formik
            onSubmit={(values) => this.handleAddUser(values)}
            initialValues={{
              name: "",
              email: "",
              role: "",
            }}
            validationSchema={YUPObject().shape({
              name: YUPString()
                .required(t("requiredName"))
                .min(3, t("min3Characters")),
              email: YUPString()
                .email(t("validEmail"))
                .required(t("requiredEmail")),
              role: YUPString().required(),
            })}
          >
            {(formikBag) => (
              <Form className="card--section">
                <Field name="name">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="name">{t("userName")}</label>
                      <input
                        placeholder={t("enterUserName")}
                        name="name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="email">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="email">{t("email")}</label>
                      <input
                        placeholder={t("enterNewEmail")}
                        name="email"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="role">
                  {({ meta }) => (
                    <div className="form-field">
                      <label htmlFor="role">{t("role")}</label>
                      <div className="form-field">
                        <div
                          className="dropdown-container"
                          onClick={() => {
                            this.setState({
                              rolesMenu: !this.state.rolesMenu,
                            });
                          }}
                        >
                          {formikBag.values.role.length
                            ? formikBag.values.role
                            : "select"}
                          <ArrowDownIcon className="dropdown-icon" />
                          {this.state.rolesMenu && (
                            <ul className="dropdown-menu">
                              {this.props.roles?.map((role, index) => (
                                <li
                                  key={index}
                                  className="item"
                                  onClick={() => {
                                    formikBag.setFieldValue("role", role);
                                  }}
                                >
                                  {role}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <div className="action-buttons">
                  <button
                    type="submit"
                    className="button save"
                    disabled={!(formikBag.isValid && formikBag.dirty)}
                  >
                    {t("saveChanges")}
                  </button>
                  <button
                    className="button-outline"
                    type="button"
                    onClick={() => this.props.onFinish()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

AddUser.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(AddUser);
