/**
 * @author Youssef Tarek
 * @date 2022-01-09
 * @description Implementation of Brands related utilities.
 * @filename brands.ts
 */

import { BrandRequestPayload } from "interfaces/brand";
import { Brand } from "../api/brand";
import { GenericRequestPayload } from "../definitions/interfaces/overview";

import { getPaginatedPayloadData, handleError } from "./common";

export function getBrands(payload: GenericRequestPayload) {
  return Brand.brandsTopSelling(payload)
    .then((overviewPayload) => {
      return getPaginatedPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getBrandsItems(payload: BrandRequestPayload) {
  return Brand.brandsItemsSales(payload)
    .then((overviewPayload) => {
      return getPaginatedPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
