import { MENU_NAV_ITEMS, PUBLIC_WEBSITE_URL } from "consts/public-website";
import React from "react";
import Logo from "static/images/full-logo.png";

export default class PublicWebsiteHeader extends React.Component {
  render() {
    return (
      <header className="public-website__header">
        <a href={PUBLIC_WEBSITE_URL} className="public-website__header__logo">
          <img src={Logo} alt="logo" />
        </a>
        <nav className="public-website__header__nav">
          <ul>
            {MENU_NAV_ITEMS.map((item) => (
              <li key={item.label}>
                <a href={item.url}>{item.label}</a>
              </li>
            ))}
          </ul>
        </nav>
        <a
          href={`${PUBLIC_WEBSITE_URL}/pricing`}
          className="public-website__header__button"
        >
          Get Started For Free
        </a>
      </header>
    );
  }
}
