/**
 * @author Ahmed Serag
 * @date 2021-07-01
 * @description declaration of overview interface.
 * @filename overview.ts
 */

import { AdvancedFilters } from "./advanced-filters";

export interface Overview {
  total_revenues: number; // GROSS Sales
  total_orders: number;
  customer_count: number;
  avg_categories_per_order: number;
  avg_items_per_order: number;
  coupons: number;
  returns: number;
  shipping: number;
  taxes: number;
  top_performing_product: {
    name: string;
    image_url: string;
    sku: string;
  };
  top_performing_category: {
    name: string;
    image_url: string;
  };
  top_used_payment_method: string;
}

export interface Counts {
  name: string | string[];
  count: number | string;
  sales_value?: number;
}

export interface AreaCounts {
  area: string;
  count: string;
}

export interface CityCounts {
  city: string;
  count: string;
}

export interface CountryCounts {
  country: string;
  count: string;
}

export interface ItemsSales {
  name: string;
  number_sold: number;
  sales_value: number;
  unique_products_sold?: number;
  percentage?: number;
  image_url?: string;
  stock?: number;
  sku?: string;
}

export interface SalesValues {
  name: string;
  sales_value: number;
  number_sold: number;
}

export interface ExportedSalesValues extends SalesValues {
  sales_quantity: number;
}
export interface StockResponse {
  inStock: number;
  lowStock: number;
  outOfStock: number;
}

export interface BrandsValues {
  brand_image_url: string;
  brand_name: string;
  sales_value: number;
  number_sold: number;
}

export interface BrandsItemsValues {
  name: string;
  sales_value: number;
}

export enum SortByValueMinimal {
  SalesValue = "sales_value",
  NumberSold = "number_sold",
}

export enum SortByValue {
  Name = "name",
  SalesValue = "sales_value",
  NumberSold = "number_sold",
  Stock = "stock",
}

export enum SortByDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Direction = `${SortByDirection}`;
export type SortOption = `${SortByValue}`;

export interface PercentageChange {
  percentage: number;
  sign: string;
  text?: string;
}

export interface GenericRequestPayload {
  project: string;
  from: string;
  to: string;
  sort?: string;
  direction?: string;
  search?: string;
  currentPage?: number;
  pageSize?: number;
  filters?: AdvancedFilters;
  exported?: number;
  with_list?: number;
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
