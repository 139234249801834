/**
 * @author Youssef Tarek
 * @date 2022-03-28
 * @description Implementation of Abandoned Carts related utilities
 * @filename abandoned-carts.ts
 */

import { AbandonedCarts } from "api/abandoned-carts";
import {
  AbandonedCartRequestPayload,
  AbandonedCartsDetails,
} from "interfaces/abandoned-carts";
import { getPayloadData, handleError } from "./common";

/**
 * handles fetching the abandoned carts details
 * @param payload
 */
export function getAbandonedCarts(
  payload: AbandonedCartRequestPayload
): Promise<AbandonedCartsDetails> {
  return AbandonedCarts.getAbandonedCarts(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
