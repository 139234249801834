/**
 * @author Ahmed Serag
 * @date 2021-06-09
 * @description Categories tab in the app.
 * @filename categories.tsx
 */
import { ExportedSalesValues, ItemsSales } from "interfaces/overview";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CSVLink } from "react-csv";
import { getCategories, getCategoriesSalesExport } from "utilities/categories";
import {
  exist,
  getDurationRange,
  getNumberReadableValue,
} from "utilities/common";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { mapTabsToQueryString } from "utilities/urls";
import BackArrowIcon from "static/images/left-arrow.svg";
import Header from "../../common/header";
import CategoriesSales from "./categories-sales";
import Loader from "../../common/Loader";
import { TableContent } from "../../common/table-content";
import CategoryDetails from "./categories-details";

interface CategoriesState {
  activeTab: "top_selling_categories" | "categories_sales" | string;
  categories: ItemsSales[];
  categoriesSalesExport: ExportedSalesValues[];
  comparedCategories?: ItemsSales[];
  comparedCategoriesSalesExport?: ExportedSalesValues[];
  loading: boolean;
  id: string;
  selectedCategory: string;
}

class Categories extends React.Component<
  RouteComponentProps & WithTranslation,
  CategoriesState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportTopCategoriesRef: React.RefObject<HTMLDivElement>;

  exportCategoriesSalesRef: React.RefObject<HTMLDivElement>;

  exportCategoryDetailsSalesRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportTopCategoriesRef = React.createRef();
    this.exportCategoriesSalesRef = React.createRef();
    this.exportCategoryDetailsSalesRef = React.createRef();

    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.state = {
      activeTab: params.get("activeTab") ?? "top_selling_categories",
      loading: true,
      categories: [],
      categoriesSalesExport: [],
      id: Date.now().toString(),
      selectedCategory: "",
    };
    this.loadData = this.loadData.bind(this);
    this.updateSelectedCategory = this.updateSelectedCategory.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams(
      this.state.activeTab,
      this.context.listBy
    );
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Categories";
  }

  componentDidUpdate(_prevProps, prevState: CategoriesState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(
        this.state.activeTab,
        this.context.listBy
      );
      // set page title
      switch (this.state.activeTab) {
        case "top_selling_categories":
          document.title = "Categories";
          break;
        case "categories_sales":
          document.title = "Categories Sales";
          break;
        default:
          document.title = "Categories";
          break;
      }
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  updateSelectedCategory(categoryName: string) {
    this.setState({
      selectedCategory: categoryName,
    });
  }

  loadData() {
    this.setState({ loading: true });

    let categoriesPromise = Promise.resolve();
    let categoriesSalesExportPromise = Promise.resolve();
    if (exist(this.context, ["project", "currentPeriod"])) {
      categoriesPromise = getCategories({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sort: this.context.listBy,
        filters: this.context.appliedFilters,
      }).then((categories) => {
        this.setState({
          categories,
        });
      });

      categoriesSalesExportPromise = getCategoriesSalesExport({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sort: this.context.listBy,
        filters: this.context.appliedFilters,
      }).then((categoriesSalesExport) => {
        this.setState({
          categoriesSalesExport,
        });
      });
    }

    let comparedCategoriesPromise = Promise.resolve();
    let comparedCategoriesSalesExportPromise = Promise.resolve();
    if (this.context.comparedPeriod) {
      comparedCategoriesPromise = getCategories({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        sort: this.context.listBy,
        filters: this.context.appliedFilters,
      }).then((comparedCategories) => {
        this.setState({
          comparedCategories,
        });
      });

      comparedCategoriesSalesExportPromise = getCategoriesSalesExport({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        sort: this.context.listBy,
        filters: this.context.appliedFilters,
      }).then((comparedCategoriesSalesExport) => {
        this.setState({
          comparedCategoriesSalesExport,
        });
      });
    } else {
      this.setState({
        comparedCategories: undefined,
        comparedCategoriesSalesExport: undefined,
      });
    }

    Promise.all([
      categoriesPromise,
      comparedCategoriesPromise,
      categoriesSalesExportPromise,
      comparedCategoriesSalesExportPromise,
    ])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
        this.setState({
          loading: false,
        });
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="categories">
        <Header
          title={t("categories")}
          exportRef={
            this.state.activeTab === "top_selling_categories"
              ? this.exportTopCategoriesRef
              : this.exportCategoriesSalesRef
          }
        />
        <div className="tabs">
          <div
            className={`tab ${
              this.state.activeTab === "top_selling_categories" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "top_selling_categories") {
                this.setState({ activeTab: "top_selling_categories" });
                mapTabsToQueryString(this.state.activeTab, this.context.listBy);
              }
            }}
          >
            {t("topSellingCategories")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "categories_sales" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "categories_sales") {
                this.setState({ activeTab: "categories_sales" });
                mapTabsToQueryString(this.state.activeTab, this.context.listBy);
              }
            }}
          >
            {t("categoriesSales")}
          </div>
        </div>

        {this.state.activeTab === "top_selling_categories" && (
          <>
            {/* display listing options whether by sales value or number sold,
        and ability to choose from them */}
            <div className="toggler">
              <div
                className={`toggle ${
                  this.context.listBy === "sales_value" ? "active" : ""
                }`}
                onClick={() => {
                  // update to the new listing type in the context and the new data will be fetched
                  if (this.context.listBy !== "sales_value") {
                    this.context.updateListingType(
                      this.state.activeTab,
                      "sales_value"
                    );
                  }
                }}
              >
                {t("salesValue")}
              </div>
              <div
                className={`toggle ${
                  this.context.listBy === "number_sold" ? "active" : ""
                }`}
                onClick={() => {
                  if (this.context.listBy !== "number_sold") {
                    this.context.updateListingType(
                      this.state.activeTab,
                      "number_sold"
                    );
                  }
                }}
              >
                {t("numberSold")}
              </div>
            </div>
            <div ref={this.exportTopCategoriesRef}>
              <div
                className={
                  this.context.comparedPeriod
                    ? "card-container"
                    : "flex justify-center"
                }
              >
                <div className="card categories--table">
                  <div className="categories--body">
                    <div className="card--title">
                      <div>
                        {t("topSellingCategories")}
                        <span className="duration">
                          (
                          {getDurationRange(
                            this.context.currentPeriod.type,
                            this.context.currentPeriod?.from,
                            this.context.currentPeriod?.to
                          )}
                          )
                        </span>
                      </div>
                      {this.state.categoriesSalesExport?.length ? (
                        <CSVLink
                          data={this.state.categoriesSalesExport}
                          filename={`categories_sales breakdown from:${this.context.currentPeriod?.from}-to:${this.context.currentPeriod?.to}.csv`}
                          className="export-list-button"
                          target="_blank"
                        >
                          {t("exportList")}
                        </CSVLink>
                      ) : null}
                    </div>
                    {this.state.categories?.map((category, index) => {
                      return (
                        <TableContent
                          key={index}
                          rowItem={category}
                          type="categories"
                          itemIndex={index}
                          salesValue={getNumberReadableValue(
                            category.sales_value
                          )}
                          currency={this.context.settings.currency}
                          percentageValue={category.percentage}
                          isCompare={!!this.context.comparedPeriod}
                          listBy={this.context.listBy}
                        />
                      );
                    })}
                  </div>
                </div>
                {this.context.comparedPeriod && (
                  <div className="card categories--table">
                    <div className="categories--body">
                      <div className="card--title">
                        <div>
                          {t("topSellingCategories")}
                          <span className="duration">
                            (
                            {getDurationRange(
                              this.context.comparedPeriod.type,
                              this.context.comparedPeriod?.from,
                              this.context.comparedPeriod?.to
                            )}
                            )
                          </span>
                        </div>
                        {this.state.comparedCategoriesSalesExport?.length ? (
                          <CSVLink
                            data={this.state.comparedCategoriesSalesExport}
                            filename={`categories_sales breakdown from:${this.context.comparedPeriod?.from}-to:${this.context.comparedPeriod?.to}.csv`}
                            className="export-list-button"
                            target="_blank"
                          >
                            {t("exportList")}
                          </CSVLink>
                        ) : null}
                      </div>
                      <ul>
                        {this.state.comparedCategories?.map(
                          (category, index) => {
                            return (
                              <TableContent
                                key={index}
                                type="categories"
                                rowItem={category}
                                salesValue={getNumberReadableValue(
                                  category.sales_value
                                )}
                                currency={this.context.settings.currency}
                                itemIndex={index}
                                percentageValue={category.percentage}
                                listBy={this.context.listBy}
                              />
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {this.state.activeTab === "categories_sales" &&
          (this.state.selectedCategory ? (
            <>
              <button
                className="products-button--back"
                type="button"
                onClick={() => {
                  this.setState({
                    selectedCategory: "",
                  });
                }}
              >
                <BackArrowIcon />
                {t("backToList")}
              </button>
              <CategoryDetails
                categoryName={this.state.selectedCategory}
                updateCategoryName={this.updateSelectedCategory}
                exportRef={this.exportCategoryDetailsSalesRef}
              />
            </>
          ) : (
            <CategoriesSales
              updateCategoryName={this.updateSelectedCategory}
              exportRef={this.exportCategoriesSalesRef}
            />
          ))}
      </div>
    );
  }
}

Categories.contextType = ANALYTICS_CONTEXT;
export default withTranslation("categories")(Categories);
