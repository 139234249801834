import {
  AreaCounts,
  CityCounts,
  CountryCounts,
  GenericRequestPayload,
} from "interfaces/overview";
import React from "react";
import { CSVLink } from "react-csv";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  getCustomersPerArea,
  getCustomersPerCity,
  getCustomersPerCountry,
} from "utilities/orders";
import { getDurationRange } from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";
import { PaginationPayload } from "interfaces/payload";
import SectionLoader from "../../common/section-loader";
import Pagination from "../../common/Pagination";
import TableView from "../../common/table-view";

type ShippingLocationsState = {
  comparedCity?: CityCounts[];
  comparedCountry?: CountryCounts[];
  comparedArea?: AreaCounts[];
  loading: boolean;
  sectionLoader: boolean;
  comparedSectionLoader: boolean;
  comparedLoading: boolean;
  totalPages: number;
  currentPage: number;
  comparedTotalPages: number;
  comparedCurrentPage: number;
  activeOption: "byCountry" | "byCity" | "byArea";
  comparedActiveOption: "byCountry" | "byCity" | "byArea";
  displayedData?: CityCounts[] | CountryCounts[] | AreaCounts[];
  ListData?: CityCounts[] | CountryCounts[] | AreaCounts[];
  comparedListData?: CityCounts[] | CountryCounts[] | AreaCounts[];
  comparedDisplayedData?: CityCounts[] | CountryCounts[] | AreaCounts[];
  id: string;
};

interface ShippingLocationsProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class ShippingLocations extends React.Component<
  ShippingLocationsProps & WithTranslation,
  ShippingLocationsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: ShippingLocationsProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      sectionLoader: true,
      comparedSectionLoader: true,
      comparedLoading: true,
      activeOption: "byCountry",
      comparedActiveOption: "byCountry",
      currentPage: 1,
      totalPages: 1,
      comparedCurrentPage: 1,
      comparedTotalPages: 1,
      id: Date.now().toString(),
    };
    this.loadData = this.loadData.bind(this);
    this.updatedCurrentPage = this.updatedCurrentPage.bind(this);
    this.updateComparedCurrentPage = this.updateComparedCurrentPage.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentDidUpdate(
    prevProps: Readonly<ShippingLocationsProps & WithTranslation>,
    prevState: Readonly<ShippingLocationsState>
  ) {
    // when the tab is changed e.g from city to area, let the current page be 1
    if (prevState.activeOption !== this.state.activeOption) {
      this.setState({ currentPage: 1, loading: true });
    }
    // when the page number is changed send a request with the requested page
    if (prevState.currentPage !== this.state.currentPage) {
      this.handleChange(this.state.activeOption);
    }
    // when the tab for comparison card is changed, let the current page be 1
    if (prevState.comparedActiveOption !== this.state.comparedActiveOption) {
      this.setState({ comparedCurrentPage: 1, comparedLoading: true });
    }
    // when the page number is changed send a request with the requested page
    if (prevState.comparedCurrentPage !== this.state.comparedCurrentPage) {
      this.handleComparedChange(this.state.comparedActiveOption);
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  handleChange(metric: string) {
    this.setState({ loading: true, sectionLoader: true });
    if (metric === "byCountry") {
      this.getCustomersPerCountryHandler();
    } else if (metric === "byCity") {
      this.getCustomersPerCityHandler();
    } else {
      this.getCustomersPerAreaHandler();
    }
  }

  handleComparedChange(metric: string) {
    this.setState({ comparedLoading: true, comparedSectionLoader: true });
    if (metric === "byCountry") {
      this.getComparedCustomersPerCountryHandler();
    } else if (metric === "byCity") {
      this.getComparedCustomersPerCityHandler();
    } else {
      this.getComparedCustomersPerAreaHandler();
    }
  }

  getCustomersPerCityHandler() {
    const customersPerCityPromise = getCustomersPerCity({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      currentPage: this.state.currentPage,
    }).then((city) => {
      this.setState({
        displayedData: city.data,
        totalPages: city.pagination.totalPageCount || 1,
        loading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerCity,
      this.context.currentPeriod.from,
      this.context.currentPeriod.to
    );
    Promise.resolve(customersPerCityPromise);
  }

  getCustomersPerCountryHandler() {
    const customersPerCountryPromise = getCustomersPerCountry({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      currentPage: this.state.currentPage,
    }).then((country) => {
      this.setState({
        displayedData: country.data,
        totalPages: country.pagination.totalPageCount || 1,
        loading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerCountry,
      this.context.currentPeriod.from,
      this.context.currentPeriod.to
    );
    Promise.resolve(customersPerCountryPromise);
  }

  getCustomersPerAreaHandler() {
    const customersPerAreaPromise = getCustomersPerArea({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      currentPage: this.state.currentPage,
    }).then((area) => {
      this.setState({
        displayedData: area.data,
        totalPages: area.pagination.totalPageCount || 1,
        loading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerArea,
      this.context.currentPeriod.from,
      this.context.currentPeriod.to
    );
    Promise.resolve(customersPerAreaPromise);
  }

  getComparedCustomersPerCityHandler() {
    const customersPerCityPromise = getCustomersPerCity({
      project: this.context.project,
      from: this.context.comparedPeriod.from,
      to: this.context.comparedPeriod.to,
      currentPage: this.state.comparedCurrentPage,
    }).then((city) => {
      this.setState({
        comparedDisplayedData: city.data,
        comparedTotalPages: city.pagination.totalPageCount || 1,
        comparedLoading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerCity,
      this.context.comparedPeriod.from,
      this.context.comparedPeriod.to,
      true
    );
    Promise.resolve(customersPerCityPromise);
  }

  getComparedCustomersPerCountryHandler() {
    const customersPerCountryPromise = getCustomersPerCountry({
      project: this.context.project,
      from: this.context.comparedPeriod.from,
      to: this.context.comparedPeriod.to,
      currentPage: this.state.comparedCurrentPage,
    }).then((country) => {
      this.setState({
        comparedDisplayedData: country.data,
        comparedTotalPages: country.pagination.totalPageCount || 1,
        comparedLoading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerCountry,
      this.context.comparedPeriod.from,
      this.context.comparedPeriod.to,
      true
    );
    Promise.resolve(customersPerCountryPromise);
  }

  getComparedCustomersPerAreaHandler() {
    const customersPerAreaPromise = getCustomersPerArea({
      project: this.context.project,
      from: this.context.comparedPeriod.from,
      to: this.context.comparedPeriod.to,
      currentPage: this.state.comparedCurrentPage,
    }).then((area) => {
      this.setState({
        comparedDisplayedData: area.data,
        comparedTotalPages: area.pagination.totalPageCount || 1,
        comparedLoading: false,
      });
    });
    this.getCustomersListHandler(
      getCustomersPerArea,
      this.context.comparedPeriod.from,
      this.context.comparedPeriod.to,
      true
    );
    Promise.resolve(customersPerAreaPromise);
  }

  getCustomersListHandler(
    getCustomersList: (
      payload: GenericRequestPayload
    ) => Promise<
      PaginationPayload<CityCounts[] | CountryCounts[] | AreaCounts[]>
    >,
    from: string,
    to: string,
    isComparingPeriod?: boolean
  ) {
    const customersListPromise = getCustomersList({
      project: this.context.project,
      from,
      to,
      exported: 1,
    }).then((response) => {
      if (isComparingPeriod) {
        this.setState({
          comparedListData: response.data,
          comparedSectionLoader: false,
        });
      } else {
        this.setState({
          ListData: response.data,
          sectionLoader: false,
        });
      }
      Promise.resolve(customersListPromise);
    });
  }

  updatedCurrentPage(currentPage: number) {
    this.setState({
      currentPage,
    });
  }

  updateComparedCurrentPage(comparedCurrentPage: number) {
    this.setState({
      comparedCurrentPage,
    });
  }

  loadData() {
    this.setState({ loading: true, comparedLoading: true });

    this.getCustomersPerCountryHandler();

    if (this.context.comparedPeriod) {
      this.getComparedCustomersPerCountryHandler();
    }
  }

  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div ref={this.props.exportRef}>
        <div
          className={
            this.context.comparedPeriod
              ? "card-container"
              : "flex justify-center"
          }
        >
          <div className="card">
            <h2 className="card--title">
              <div>
                {t("locationBreakdown")}
                <span className="duration">
                  {getDurationRange(
                    this.context.currentPeriod.type,
                    this.context.currentPeriod.from,
                    this.context.currentPeriod.to
                  )}
                </span>
              </div>
              {this.state.sectionLoader ? (
                <SectionLoader width={40} height={40} />
              ) : (
                <CSVLink
                  data={this.state.ListData}
                  filename={`location-breakdown-from:${this.context.currentPeriod.from}-to:${this.context.currentPeriod.to}.csv`}
                  className="export-list-button"
                  target="_blank"
                >
                  {t("exportList")}
                </CSVLink>
              )}
            </h2>
            <div className="card--options">
              <div
                className={`option ${
                  this.state.activeOption === "byCountry" ? "active" : ""
                }`}
                title="byCountry"
                onClick={(e) => {
                  this.setState({
                    activeOption: e.target.title,
                  });
                  this.handleChange(e.target.title);
                }}
              >
                {t("byCountry")}
              </div>
              <div
                className={`option ${
                  this.state.activeOption === "byCity" ? "active" : ""
                }`}
                title="byCity"
                onClick={(e) => {
                  this.setState({
                    activeOption: e.target.title,
                  });
                  this.handleChange(e.target.title);
                }}
              >
                {t("byCity")}
              </div>
              <div
                className={`option ${
                  this.state.activeOption === "byArea" ? "active" : ""
                }`}
                title="byArea"
                onClick={(e) => {
                  this.setState({
                    activeOption: e.target.title,
                  });
                  this.handleChange(e.target.title);
                }}
              >
                {t("byArea")}
              </div>
            </div>
            <div className="locations-table">
              <TableView
                loading={this.state.loading}
                type={this.state.activeOption}
                products={this.state.displayedData}
              />
            </div>
            {this.state.totalPages > 1 && (
              <Pagination
                totalPageCount={this.state.totalPages}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.updatedCurrentPage}
              />
            )}
          </div>
          {this.context.comparedPeriod && (
            <div className="card">
              <h2 className="card--title">
                <div>
                  {t("locationBreakdown")}
                  <span className="duration">
                    {getDurationRange(
                      this.context.comparedPeriod.type,
                      this.context.comparedPeriod.from,
                      this.context.comparedPeriod.to
                    )}
                  </span>
                </div>
                {this.state.comparedSectionLoader ? (
                  <SectionLoader width={40} height={40} />
                ) : (
                  <CSVLink
                    data={this.state.comparedListData}
                    filename={`location-breakdown-from:${this.context.comparedPeriod.from}-to:${this.context.comparedPeriod.to}.csv`}
                    className="export-list-button"
                    target="_blank"
                  >
                    {t("exportList")}
                  </CSVLink>
                )}
              </h2>
              <div className="card--options">
                <div
                  className={`option ${
                    this.state.comparedActiveOption === "byCountry"
                      ? "active"
                      : ""
                  }`}
                  title="byCountry"
                  onClick={(e) => {
                    this.setState({ comparedActiveOption: e.target.title });
                    this.handleComparedChange(e.target.title);
                  }}
                >
                  {t("byCountry")}
                </div>
                <div
                  className={`option ${
                    this.state.comparedActiveOption === "byCity" ? "active" : ""
                  }`}
                  title="byCity"
                  onClick={(e) => {
                    this.setState({ comparedActiveOption: e.target.title });
                    this.handleComparedChange(e.target.title);
                  }}
                >
                  {t("byCity")}
                </div>
                <div
                  className={`option ${
                    this.state.comparedActiveOption === "byArea" ? "active" : ""
                  }`}
                  title="byArea"
                  onClick={(e) => {
                    this.setState({ comparedActiveOption: e.target.title });
                    this.handleComparedChange(e.target.title);
                  }}
                >
                  {t("byArea")}
                </div>
              </div>
              <div className="locations-table">
                <TableView
                  loading={this.state.comparedLoading}
                  type={this.state.comparedActiveOption}
                  products={this.state.comparedDisplayedData}
                />
              </div>
              {this.state.comparedTotalPages > 1 && (
                <Pagination
                  totalPageCount={this.state.comparedTotalPages}
                  currentPage={this.state.comparedCurrentPage}
                  pageNeighbors={1}
                  changePageHandler={this.updateComparedCurrentPage}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ShippingLocations.contextType = ANALYTICS_CONTEXT;
export default withTranslation("orders")(ShippingLocations);
