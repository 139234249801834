/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description implementation of User behavior related utilities.
 * @filename user-behavior.ts
 */
import { UserBehavior } from "api/user-behavior";
import { GenericRequestPayload } from "interfaces/overview";
import { PaginationPayload } from "interfaces/payload";
import {
  MostSearchedTerms,
  LoyaltyPoints,
  ExportedLoyaltyPoints,
  PromoCodes,
  ExportedPromoCodesList,
  GA4PagesSummary,
  SpecificPageSummary,
  PageSummaryRequestPayload,
} from "interfaces/user-behavior";
import { getPaginatedPayloadData, getPayloadData, handleError } from "./common";

/**
 * handles fetching most searched terms for a given project to be displayed in the user behavior tab
 * @param project
 * @returns MostSearchedTerms
 */
export function getMostSearchedTerms(
  project: string
): Promise<MostSearchedTerms> {
  return UserBehavior.mostSearchedTerms(project)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching loyalty points for a given project to be displayed in the user behavior tab
 * @param payload
 * @returns LoyaltyPoints
 */
export function getLoyaltyPoints(
  payload: GenericRequestPayload
): Promise<LoyaltyPoints> {
  return UserBehavior.getLoyaltyPoints(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching loyalty points for a given project per day
 * @param payload
 * @returns LoyaltyPoints
 */
export function getLoyaltyPointsPerDay(
  payload: GenericRequestPayload
): Promise<number[]> {
  return UserBehavior.getLoyaltyPointsPerDay(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching loyalty points to be exported
 * @param project
 * @param percentage
 * @returns LoyaltyPoints
 */
export function exportLoyalPoints(
  project: string,
  percentage: string
): Promise<ExportedLoyaltyPoints> {
  return UserBehavior.exportLoyalPoints(project, percentage)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching promo codes summary for a given project to be displayed in the user behavior tab
 * @param payload
 * @returns PromoCodes
 */
export function getPromoCodes(
  payload: GenericRequestPayload
): Promise<PromoCodes> {
  return UserBehavior.getPromoCodes(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching promo codes exported list for a given project in the user behavior tab
 * @param payload
 * @returns ExportedPromoCodes[]
 */
export function exportPromoCodes(
  payload: GenericRequestPayload
): Promise<ExportedPromoCodesList[]> {
  return UserBehavior.exportPromoCodes(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching promo codes exported list for a given project in the user behavior tab
 * @param payload
 * @returns ExportedPromoCodes[]
 */
export function exportPromoCodesPaginated(
  payload: GenericRequestPayload
): Promise<PaginationPayload<ExportedPromoCodesList[]>> {
  return UserBehavior.exportPromoCodesPaginated(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching pages summary data
 * @param payload
 * @returns GA4PagesSummary
 */
export function getAllGA4PagesSummary(
  payload: GenericRequestPayload
): Promise<PaginationPayload<GA4PagesSummary>> {
  return UserBehavior.getAllGA4PagesSummary(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching specific page summary data
 * @param payload
 * @returns SpecificPageSummary
 */
export function getSpecificPageSummary(
  payload: PageSummaryRequestPayload
): Promise<SpecificPageSummary> {
  return UserBehavior.getSpecificPageSummary(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
