/**
 * @author Ahmed Serag
 * @Date: 2019-6-13
 * @description Implementation of Common utilities.
 * @filename common.ts
 */
import { ERRORS } from "consts/errors";
import {
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  endOfQuarter,
  sub,
  subMonths,
  subYears,
  endOfYear,
} from "date-fns";
import i18next from "i18next";
import { PercentageChange } from "interfaces/overview";
import { PaginationPayload, Payload } from "interfaces/payload";
import { UserPlatform, UserRole } from "interfaces/user";
import numeral from "numeral";
import defaultImg from "static/images/default.png";

export function exist(value: unknown, keys: string[] = undefined): boolean {
  // first missing key if found
  let missingKey: string;
  let keyExist = true;

  if (value === undefined || value === null) {
    keyExist = false;
  }

  if (keyExist && keys !== undefined && keys !== null) {
    missingKey = keys.find(
      (key: string) => value[key] === null || value[key] === undefined
    );
  }

  return keyExist && !missingKey;
}

/**
 * check if the given value is empty.
 *
 * @param {*} value any type of object.
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(value: any) {
  return value === undefined || value === null || value.length === 0;
}

/**
 * get data from payload.
 *
 * @export
 * @template T Type of the payload.
 * @param {Payload<T>} payload Payload to extract data from.
 * @returns {Promise<T>} Promise to return the data from the payload.
 */
export function getPayloadData<T extends unknown = unknown>(
  payload: Payload<T>,
  key: "data" | "message" = "data"
): Promise<T> {
  if (!exist(payload)) {
    return Promise.reject();
  }
  // handle the case where the response message is on the message key
  if (!exist(payload[key])) {
    return Promise.reject(payload);
  }

  return Promise.resolve(payload[key] as T);
}

/**
 * get data and paginration data from payload.
 *
 * @export
 * @template T Type of the payload.
 * @param {Payload<PaginationPayload<T>>} payload Payload to extract pagination data and data from.
 * @returns {Promise<PaginationPayload<T>>} Promise to return the data from the payload.
 */
export function getPaginatedPayloadData<T extends unknown = unknown>(
  payload: PaginationPayload<T>
): Promise<PaginationPayload<T>> {
  if (!exist(payload)) {
    return Promise.reject();
  }

  if (!exist(payload.data)) {
    return Promise.reject(payload);
  }

  return Promise.resolve({
    data: payload.data,
    pagination: payload.pagination,
  });
}

/**
 * handle errors occurred in the system before
 * displaying them to the user.
 *
 * @param {*} error an error happened in the process.
 * @returns {Array<string>} array of error messages to be displayed.
 */
export function handleError(error: unknown): string[] {
  let errors: string[] = [];

  switch (typeof error) {
    case "string":
      errors.push(error);
      break;
    case "object":
      for (const key in error) {
        errors = [...errors, ...handleError(error[key])];
      }
      break;
    default:
      errors.push(ERRORS.unexpected);
  }

  return errors;
}

export function getNumberReadableValue(number: number): string {
  if (!exist(number)) {
    return undefined;
  }
  return numeral(number).format("0.[00]a");
}

export function getTwoDecimalNumber(number: number): string {
  if (!exist(number)) {
    return undefined;
  }
  return numeral(number).format("00");
}

export function generateRandomColor(
  ignore: string[] = [],
  colors = [
    "#f94144",
    "#f3722c",
    "#f8961e",
    "#f9844a",
    "#f9c74f",
    "#90be6d",
    "#f9c74f",
    "#90be6d",
    "#43aa8b",
    "#4d908e",
    "#577590",
    "#277da1",
  ]
): string {
  const colorsToUse = colors.filter((color) => !ignore.includes(color));

  return colorsToUse[Math.floor(Math.random() * colorsToUse.length)];
}

/**
 * format a title of a given date range
 *
 * @export
 * @param {Date} from start date
 * @param {Date} to end date
 * @return {*}  {string} a meaningful string of the date range
 */
export function getCustomDatesRangeTitle(from: Date, to: Date): string {
  if (!exist(from) || !exist(to)) {
    return undefined;
  }

  return `${new Date(from).toLocaleDateString(
    i18next.language === "en" ? "en-US" : "ar-EG",
    { day: "numeric", month: "numeric", year: "numeric" }
  )} - ${new Date(to).toLocaleDateString(
    i18next.language === "en" ? "en-US" : "ar-EG",
    { day: "numeric", month: "numeric", year: "numeric" }
  )}`;
}

/**
 * handles generating the duration based on the user selection
 * @param type string (year, month , week)
 * @returns formatted duration
 * @example getDuration(year) => "Jan - Dec 2021"
 */
export function getDurationRange(type: string, from: string, to: string) {
  switch (type) {
    case "week":
      return `${i18next.t("common:sundaySaturday")} ${new Date(
        from
      ).toLocaleDateString(i18next.language === "en" ? "en-US" : "ar-EG", {
        day: "numeric",
        month: "numeric",
      })} - ${new Date(to).toLocaleDateString(
        i18next.language === "en" ? "en-US" : "ar-EG",
        { day: "numeric", month: "numeric" }
      )}`;
    case "month":
      return ` ${new Date(from).toLocaleDateString(
        i18next.language === "en" ? "en-US" : "ar-EG",
        {
          month: "short",
          year: "numeric",
        }
      )}`;
    case "year": {
      return `${i18next.t("common:janDec")} ${new Date(from).toLocaleDateString(
        i18next.language === "en" ? "en-US" : "ar-EG",
        { year: "numeric" }
      )}`;
    }
    case "previousMonth": {
      return `${subMonths(new Date(), 1).toLocaleDateString(
        i18next.language === "en" ? "en-US" : "ar-EG",
        { month: "long" }
      )}`;
    }
    case "previousYear": {
      return `${new Date().getFullYear() - 1}`;
    }
    default:
      return `${new Date(from).toLocaleDateString(
        i18next.language === "en" ? "en-US" : "ar-EG",
        { day: "numeric", month: "numeric", year: "numeric" }
      )}`;
  }
}

/**
 * gets a pervious date based on user selection
 * @param type string (year,month,week)
 * @returns object with comparison dates of the pervious date
 * @example getPerviousDate(year) => {from: "2020-01-01", to: "2020-12-28"}
 */
export function getPerviousDate(type?: string) {
  const today = new Date();
  switch (type) {
    case "day": {
      const from = format(startOfYesterday(), "yyyy-LL-dd");
      const to = format(startOfYesterday(), "yyyy-LL-dd");
      return { from, to };
    }
    case "week": {
      const from = format(
        startOfWeek(
          sub(today, {
            weeks: 1,
          })
        ),
        "yyyy-LL-dd"
      );
      const to = format(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
        "yyyy-LL-dd"
      );
      return { from, to };
    }
    case "month": {
      const from = format(
        startOfMonth(
          sub(today, {
            months: 1,
          })
        ),
        "yyyy-LL-dd"
      );
      const to = format(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
        "yyyy-LL-dd"
      );
      return { from, to };
    }
    case "previousMonth": {
      const from = format(startOfMonth(subMonths(today, 2)), "yyyy-LL-dd");
      const to = format(startOfMonth(subMonths(today, 1)), "yyyy-LL-dd");
      return { from, to };
    }
    case "previousYear": {
      const from = format(startOfYear(subYears(today, 2)), "yyyy-LL-dd");
      const to = format(endOfYear(subYears(today, 2)), "yyyy-LL-dd");
      return { from, to };
    }
    case "past7Days": {
      const from = format(sub(today, { days: 14 }), "yyyy-LL-dd");
      const to = format(sub(today, { days: 7 }), "yyyy-LL-dd");
      return { from, to };
    }
    case "past30Days": {
      const from = format(sub(today, { days: 60 }), "yyyy-LL-dd");
      const to = format(sub(today, { days: 30 }), "yyyy-LL-dd");
      return { from, to };
    }
    case "past90Days": {
      const from = format(sub(today, { days: 180 }), "yyyy-LL-dd");
      const to = format(sub(today, { days: 90 }), "yyyy-LL-dd");
      return { from, to };
    }
    case "quarter": {
      const from = format(
        startOfQuarter(sub(today, { months: 3 })),
        "yyyy-LL-dd"
      );
      const to = format(endOfQuarter(sub(today, { months: 3 })), "yyyy-LL-dd");
      return { from, to };
    }
    default:
    case "year": {
      const from = format(
        startOfYear(
          sub(today, {
            years: 1,
          })
        ),
        "yyyy-LL-dd"
      );
      const to = format(
        new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
        "yyyy-LL-dd"
      );
      return { from, to };
    }
  }
}

/**
 * gets percentage change between durations
 * @param current number recent stat
 * @param previous number current stat
 * @returns object with percentage and its sign
 * @example getPercentageChange(30, 50) => {percentage: -40, sign: "decrease"}
 */
export function getPercentageChange(
  current: number,
  previous: number
): PercentageChange {
  const percentage = Math.round(((current - previous) / previous) * 100);
  return {
    percentage,
    sign: percentage > 0 ? "increase" : "decrease",
    text:
      percentage > 0
        ? `${i18next.t("common:increase")}`
        : `${i18next.t("common:decrease")}`,
  };
}

export function getNumberFormatWithSeparators(number) {
  if (!exist(number)) {
    return undefined;
  }
  return new Intl.NumberFormat().format(number);
}

/**
 * trigger a function after a period of time
 *
 * @exports
 * @param func function to be triggered
 * @param timeout period to trigger the function
 * @returns function that gets executed after timeout
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deBounce<T extends (...params: any[]) => any>(
  func: T,
  timeout = 500
  // eslint-disable-next-line function-paren-newline
) {
  let timer: ReturnType<typeof setTimeout>;

  const debouncedFn: {
    (...args: Parameters<T>): void;
    cancel?: () => void;
  } = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
  debouncedFn.cancel = () => clearTimeout(timer);
  return debouncedFn;
}

/**
 * Indicates whether a user has at least ONE of a list of required roles to
 * access an asset. Admin permission supersedes all and is considered part of
 * the list regardless of the list's value.
 */
export function hasPermission(
  allowedRoles: UserRole[],
  userRoles: UserRole[]
): boolean {
  const isPermitted =
    userRoles.some((role) => allowedRoles.includes(role)) ||
    allowedRoles.length === 0 ||
    userRoles.length === 0;

  return isPermitted;
}

export function hasFeature(
  allowedPlatforms: UserPlatform[],
  userPlatform: UserPlatform
): boolean {
  const isPermitted =
    allowedPlatforms.includes(userPlatform) || allowedPlatforms.length === 0;

  return isPermitted;
}

export function setCookie(
  name: string,
  value: string,
  days: number,
  path = "/"
) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path}`;
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
  return undefined;
}

export function showDefaultImage(e) {
  e.currentTarget.src = defaultImg;
}

/**
 * function that takes two dates and combine the date of the first with the time of the second
 *
 * @exports
 * @param date1 first date determining returned date
 * @param date2 second date determining returned time
 * @returns combined date
 */
export function combineDates(date1, date2) {
  const year = date1.getFullYear();
  const month = date1.getMonth();
  const day = date1.getDate();

  const hours = new Date(date2).getHours();
  const minutes = new Date(date2).getMinutes();
  const seconds = new Date(date2).getSeconds();

  return new Date(year, month, day, hours, minutes, seconds);
}
