/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description search term tab of the App.
 * @filename search-term.tsx
 */
import React from "react";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { getDurationRange, exist } from "utilities/common";
import { getMostSearchedTerms } from "utilities/user-behavior";
import { MostSearchedTerms } from "interfaces/user-behavior";
import Loader from "../../common/Loader";

type SearchTermsState = {
  id: string;
  loading: boolean;
  searchedTerms: MostSearchedTerms;
};

interface SearchTermsProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class SearchTerms extends React.Component<
  SearchTermsProps & WithTranslation,
  SearchTermsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: SearchTermsProps & WithTranslation) {
    super(props);

    this.state = {
      id: Date.now().toString(),
      loading: false,
      searchedTerms: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({ loading: true });

    let searchedTermsPromise = Promise.resolve();

    if (exist(this.context, ["project"])) {
      searchedTermsPromise = getMostSearchedTerms(this.context.project).then(
        (searchedTerms) => {
          this.setState({
            searchedTerms,
          });
        }
      );
    }

    Promise.all([searchedTermsPromise])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  renderedSearchItems() {
    return this.state.searchedTerms?.most_searched_terms?.map(
      (mostSearchedTerm, index) => {
        return (
          <div key={index} className="table__body-row">
            <p>{mostSearchedTerm.search_term}</p>
            <p>{mostSearchedTerm.frequency}</p>
          </div>
        );
      }
    );
  }

  renderedNoResultsSearchItems() {
    return this.state.searchedTerms?.most_searched_no_result_terms?.map(
      (mostSearchedTerm, index) => {
        return (
          <div key={index} className="table__body-row">
            <p>{mostSearchedTerm.search_term}</p>
            <p>{mostSearchedTerm.frequency}</p>
          </div>
        );
      }
    );
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <>
        {Object.keys(this.context.appliedFilters).length > 0 && (
          <div className="disclaimer">
            <span>{t("searchTermsAppliedFiltersDisclaimer")}</span>
          </div>
        )}
        <div className="card-container search-terms" ref={this.props.exportRef}>
          <div className="card">
            <h1 className="card--title">
              {t("mostSearchedTerms")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </h1>
            <div className="table table--two-columns">
              <div className="table__head">
                <h2>{t("terms")}</h2>
                <h2>{t("frequency")}</h2>
              </div>
              <div className="table__body">{this.renderedSearchItems()}</div>
            </div>
          </div>
          <div className="card">
            <h1 className="card--title">
              {t("noResultsTitle")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </h1>
            <div className="search-terms__no-results">
              <p className="search-terms__no-results-count">
                {this.state.searchedTerms?.no_result_search_count}
              </p>
              <p className="search-terms__no-results-text">{t("noResults")}</p>
            </div>
          </div>
          <div className="card">
            <h1 className="card--title">
              {t("mostSearchedTermsNoResults")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </h1>
            <div className="table table--two-columns">
              <div className="table__head">
                <h2>{t("terms")}</h2>
                <h2>{t("frequency")}</h2>
              </div>
              <div className="table__body">
                {this.renderedNoResultsSearchItems()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SearchTerms.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(SearchTerms);
