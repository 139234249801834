/**
 * @author Youssef Tarek
 * @date 2023-02-14
 * @description dropdown to show filters segment in the header
 * @filename filters-segments-dropdown.tsx
 */

import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import ArrowDownIcon from "static/images/down-arrow.svg";
import {
  deleteFiltersSegment,
  showFiltersPreview,
} from "utilities/advanced-filters";
import DeleteIcon from "static/images/delete-icon.svg";
import EditIcon from "static/images/edit.svg";
import { toast } from "react-toastify";
import SectionLoader from "../section-loader";

interface FiltersSegmentsDropdownProps {
  clickHandler?: (menu, e?) => void;
  isActive?: boolean;
  disableAdvancedFilters?: boolean;
}

interface FiltersSegmentsDropdownState {
  id: string;
}

class FiltersSegmentsDropdown extends React.Component<
  FiltersSegmentsDropdownProps & WithTranslation,
  FiltersSegmentsDropdownState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      id: Date.now().toString(),
    };
  }

  deleteSegment(id: number) {
    deleteFiltersSegment(this.context.project, id)
      .then(() => {
        this.context.updateFiltersSegments(
          this.context.availableFiltersSegments.filter(
            (segment) => segment.id !== id
          )
        );
        toast.success(this.props.t("segmentDeletedSuccessfully"));
      })
      .catch(() => {
        toast.error(this.props.t("errorDeletingSegment"));
      });
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className={`dropdown-container ${
          this.props.disableAdvancedFilters && "disabled"
        }`}
        onClick={(e) => {
          this.props.clickHandler("filtersSegmentsMenu", e);
        }}
      >
        {this.context.appliedFiltersSegment
          ? this.context.appliedFiltersSegment.title
          : t("segments")}
        <ArrowDownIcon className="dropdown-icon" />
        {this.props.isActive && (
          <div
            className="dropdown-menu width-max-content"
            onClick={(e) => e.stopPropagation()}
          >
            {this.context.loadingFilters ? (
              <SectionLoader />
            ) : (
              <>
                <div className="filters-segments">
                  {this.context.availableFiltersSegments.map((segment) => {
                    return (
                      <div
                        className="filters-segment"
                        key={segment.id}
                        onClick={() => {
                          this.context.updateAppliedFilters(segment.filters);
                          this.context.updateAppliedFiltersSegment(segment);
                          this.props.clickHandler("filtersSegmentsMenu");
                        }}
                      >
                        <div className="filters-segment__info">
                          <span className="filters-segment__title">
                            {segment.title}
                          </span>
                          <span className="filters-segment__details">
                            {showFiltersPreview(segment.filters)}
                          </span>
                        </div>
                        <div className="filters-segment__actions">
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => {
                              this.deleteSegment(segment.id);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                          <button
                            className="action-button"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.context.toggleCreateANewSegment(segment);
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="segment-button">
                  <span className="plus-icon">+</span>
                  <button
                    className="button-underlined black"
                    type="button"
                    onClick={() => this.context.toggleCreateANewSegment()}
                  >
                    {t("createANewSegment")}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

FiltersSegmentsDropdown.contextType = ANALYTICS_CONTEXT;
export default withTranslation("filters")(FiltersSegmentsDropdown);
