/**
 * @author Ahmed Serag
 * @date 2021-07-03
 * @description header component
 * @filename header.tsx
 */
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getQuarter,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  sub,
  endOfQuarter,
  subMonths,
} from "date-fns";
import React from "react";
import { listProjects } from "utilities/orders";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Project } from "consts/project";
import { PeriodType } from "interfaces/period";
import { getCustomDatesRangeTitle } from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
// import { exportAsImage } from "utilities/export-as-image";
import MoreIcon from "static/images/more.svg";
import PrivacyIcon from "static/images/privacy.svg";
import TermsIcon from "static/images/terms.svg";
import SettingsIcon from "static/images/settings.svg";
import SupportIcon from "static/images/contact.svg";
import LogoutIcon from "static/images/logout.svg";
import { Authenticator } from "utilities/authenticator";
import { ROUTES } from "consts/routes";
import { NavLink } from "react-router-dom";
import { flattenFilters } from "utilities/advanced-filters";
import ArrowDownIcon from "../../../static/images/down-arrow.svg";
import AdvancedFilters from "./advanced-filters";
import AppliedFilters from "./applied-filters";
import FiltersSegmentsDropdown from "./advanced-filters/filters-segments-dropdown";

interface HeaderState {
  projectsMenu: boolean;
  currentPeriodMenu: boolean;
  comparedPeriodMenu: boolean;
  settingsMenu: boolean;
  advancedFiltersMenu: boolean;
  activeMenu: string;
  projects: Project[];
  customStartDate?: Date;
  customEndDate?: Date;
  showCustomDate?: boolean;
  customComparedStartDate?: Date;
  customComparedEndDate?: Date;
  showComparedCustomDate?: boolean;
}

interface HeaderProps {
  title: string;
  showPeriod?: boolean;
  disableComparing?: boolean;
  disableDateFilters?: boolean;
  disableAdvancedFilters?: boolean;
  exportRef?: React.RefObject<HTMLDivElement>;
}

class Header extends React.Component<
  HeaderProps & WithTranslation,
  HeaderState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  static defaultProps = {
    showPeriod: true,
  };

  constructor(props: HeaderProps & WithTranslation) {
    super(props);
    this.state = {
      projectsMenu: false,
      currentPeriodMenu: false,
      comparedPeriodMenu: false,
      settingsMenu: false,
      advancedFiltersMenu: false,
      activeMenu: "",
      projects: [],
      customStartDate: new Date(),
      customComparedStartDate: new Date(),
    };
    this.onSelectComparedPeriod = this.onSelectComparedPeriod.bind(this);
    this.onSelectPeriod = this.onSelectPeriod.bind(this);
  }

  componentDidMount() {
    listProjects().then((projects) => {
      this.setState({ projects });
    });
    // close the menu when clicking outside
    window.addEventListener("click", () => this.setActiveMenu(""));
  }

  componentWillUnmount() {
    window.removeEventListener("click", () => this.setActiveMenu(""));
  }

  onSelectPeriod(alias: string, type: PeriodType, from: Date, to: Date) {
    if (from && to) {
      this.context.updateCurrentPeriod({
        alias,
        type,
        from: format(from, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      });
    }
    this.setState({
      showCustomDate: from && to ? false : this.state.showCustomDate,
      currentPeriodMenu: from && to ? false : this.state.currentPeriodMenu,
      customStartDate: from,
      customEndDate: to,
    });
  }

  onSelectComparedPeriod(
    alias: string,
    type: PeriodType,
    from: Date,
    to: Date
  ) {
    if (from && to) {
      this.context.updateComparedPeriod({
        alias,
        type,
        from: format(from, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      });
    }
    if (!from && !to) {
      this.context.updateComparedPeriod(null);
    }
    this.setState({
      showComparedCustomDate:
        from && to ? false : this.state.showComparedCustomDate,
      comparedPeriodMenu: from && to ? false : this.state.comparedPeriodMenu,
      customComparedStartDate: from,
      customComparedEndDate: to,
    });
  }

  /**
   * get the initials of the user
   * check if they have a last name and consequently get its initial
   * @returns users Initials
   */
  getUserInitials() {
    return `${this.context?.user?.name[0]}${
      this.context?.user?.name.indexOf(" ") !== -1
        ? this.context?.user?.name[this.context?.user?.name.indexOf(" ") + 1]
        : ""
    }`;
  }

  /**
   * set active menu for header component
   * menus are: projectsMenu, currentPeriodMenu, comparedPeriodMenu,
   * settingsMenu, and advancedFiltersMenu
   * @param menu menu to be active
   */
  setActiveMenu(menu: string, e?: React.MouseEvent) {
    if (e) {
      e.stopPropagation();
    }
    if (menu === this.state.activeMenu) {
      this.setState({ activeMenu: "" });
    } else {
      this.setState({ activeMenu: menu });
    }
  }

  /**
   * check if menu is active
   * @param selectedMenu menu to be checked
   */
  isActiveMenu(selectedMenu: string) {
    return this.state.activeMenu === selectedMenu;
  }

  /**
   * check if the current period is active
   * @param period period to be checked
   */
  isActivePeriod(period: string) {
    return this.context.currentPeriod.alias === period;
  }

  render(): React.ReactNode {
    const { t } = this.props;
    const date = new Date();
    const currentYearQuery = date.getFullYear();
    const currentYear = date.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );
    const previousYearQuery = currentYearQuery - 1;
    date.setFullYear(date.getFullYear() - 1);
    const previousYear = date.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );

    return (
      <div className="page-header">
        <div className="page-header-container">
          <div className="title">
            <span>
              {new Date().toLocaleDateString(
                i18next.language === "en" ? "en-US" : "ar-EG",
                {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }
              )}
            </span>
            {this.props.title}
          </div>
          {/* {this.props.exportRef && (
            <button
              type="button"
              className="button--main"
              onClick={
                () =>
                  exportAsImage(this.props.exportRef.current, this.props.title)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              export page
            </button>
          )} */}
          <div className="page-header--profile">
            <div
              className="dropdown-container--profile"
              onClick={(e) => {
                this.setActiveMenu("settingsMenu", e);
              }}
            >
              {this.context?.settings?.logo && (
                <div className="dropdown-logo__container">
                  <img
                    src={`${process.env.BACKEND_ENDPOINT}${this.context?.settings?.logo}`}
                    alt="logo"
                    className="dropdown-logo__image"
                  />
                </div>
              )}
              <div className="dropdown-profile__image">
                {this.getUserInitials()}
              </div>
              <p className="dropdown-profile__name">
                {this.context?.user?.name}
              </p>
              <MoreIcon className="dropdown-icon" />

              {this.isActiveMenu("settingsMenu") && (
                <div className="dropdown-menu">
                  <div className="dropdown-item">
                    <NavLink
                      to={ROUTES.settings.path}
                      className="dropdown-item-link"
                      onClick={() => {
                        this.context.updateSideBarOpened(false);
                      }}
                    >
                      <SettingsIcon className="settings-icon" />
                      {t("settings")}
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink
                      to={ROUTES.support.path}
                      className="dropdown-item-link"
                      onClick={() => {
                        this.context.updateSideBarOpened(false);
                      }}
                    >
                      <SupportIcon className="support-icon" />
                      {t("support")}
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink
                      to={ROUTES.Login.path}
                      className="dropdown-item-link logout"
                      onClick={() => {
                        Authenticator.logout();
                        this.context.updateUser(null);
                        this.context.updateSideBarOpened(false);
                      }}
                    >
                      <LogoutIcon />
                      {t("logout")}
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <a
                      className="dropdown-item-link logout"
                      href={`${process.env.BACKEND_ENDPOINT}uploads/privacy-${i18next.language}.html`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PrivacyIcon />
                      {t("privacyPolicy")}
                    </a>
                  </div>
                  <div className="dropdown-item">
                    <a
                      className="dropdown-item-link logout"
                      href={`${process.env.BACKEND_ENDPOINT}uploads/terms-${i18next.language}.html`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TermsIcon />
                      {t("termsAndConditions")}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {this.props.showPeriod && (
          <>
            <div className="operations">
              {this.state.projects.length > 1 && (
                <div
                  className="dropdown-container"
                  onClick={(e) => {
                    this.setActiveMenu("projectsMenu", e);
                  }}
                >
                  {this.context.project ?? "Select project"}
                  <ArrowDownIcon className="dropdown-icon" />
                  {this.isActiveMenu("projectsMenu") && this.state.projects && (
                    <div className="dropdown-menu">
                      {this.state.projects.map((project) => {
                        return (
                          <div
                            key={project.code}
                            className="item"
                            onClick={(e) => {
                              e.preventDefault();
                              this.context.setProject(project.code);
                            }}
                          >
                            {project.code}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              <AdvancedFilters
                clickHandler={(menu, e?) => this.setActiveMenu(menu, e)}
                isActive={this.isActiveMenu("advancedFiltersMenu")}
                disableAdvancedFilters={this.props.disableAdvancedFilters}
              />
              <FiltersSegmentsDropdown
                clickHandler={(menu, e?) => this.setActiveMenu(menu, e)}
                isActive={this.isActiveMenu("filtersSegmentsMenu")}
                disableSegments={this.props.disableAdvancedFilters}
              />
              <div
                className={`dropdown-container ${
                  this.props.disableDateFilters && "disabled"
                }`}
              >
                {t(this.context.currentPeriod?.alias)}
                <ArrowDownIcon
                  className="dropdown-icon"
                  enableBackground="new 0 0 330 330"
                  onClick={(e) => {
                    this.setActiveMenu("currentPeriodMenu", e);
                  }}
                />
                {this.isActiveMenu("currentPeriodMenu") && (
                  <div className="dropdown-menu">
                    <div
                      className={`item ${
                        this.isActivePeriod("day") ? "active" : ""
                      }`}
                      // make selected period today with alias "today"
                      onClick={() => {
                        this.onSelectPeriod(
                          "today",
                          "day",
                          startOfToday(),
                          startOfToday()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("today")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("week") ? "active" : ""
                      }`}
                      /* make selected period this week with alias "thisWeek"
                       the week period is calculated from the start of the week
                       to the current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "thisWeek",
                          "week",
                          startOfWeek(new Date()),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("thisWeek")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("month") ? "active" : ""
                      }`}
                      /* make selected period this month with alias "thisMonth"
                        the month period is calculated from the start of the month
                        to the current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "thisMonth",
                          "month",
                          startOfMonth(new Date()),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("thisMonth")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("year") ? "active" : ""
                      }`}
                      /* make selected period this year with alias "thisYear"
                        the year period is calculated from the start of the year
                        to the current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "thisYear",
                          "year",
                          startOfYear(new Date()),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("thisYear")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("previousMonth") ? "active" : ""
                      }`}
                      /* make selected period previous month with alias "previousMonth"
                        the previous month period is calculated from the start of the previous month
                        to the end of the previous month */
                      onClick={() => {
                        const startOfPreviousMonth = startOfMonth(
                          sub(new Date(), {
                            months: 1,
                          })
                        );
                        const endOfPreviousMonth = endOfMonth(
                          sub(new Date(), {
                            months: 1,
                          })
                        );
                        this.onSelectPeriod(
                          "previousMonth",
                          "previousMonth",
                          startOfPreviousMonth,
                          endOfPreviousMonth
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {`${t("previousMonth")}
                      (${subMonths(new Date(), 1).toLocaleDateString(
                        i18next.language === "en" ? "en-US" : "ar-EG",
                        { month: "long" }
                      )})`}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("previousYear") ? "active" : ""
                      }`}
                      /* make selected period previous year with alias "previousYear"
                        the previous year period is calculated from the start of the previous year
                        to the end of the previous year */
                      onClick={() => {
                        const startOfPreviousYear = startOfYear(
                          sub(new Date(), {
                            years: 1,
                          })
                        );
                        const endOfPreviousYear = endOfYear(
                          sub(new Date(), {
                            years: 1,
                          })
                        );
                        this.onSelectPeriod(
                          "previousYear",
                          "previousYear",
                          startOfPreviousYear,
                          endOfPreviousYear
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {`${t("previousYear")} (${new Date().getFullYear() - 1})`}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("past7Days") ? "active" : ""
                      }`}
                      /* make selected period the past 7 days with alias "past7Days"
                        the past 7 days period is calculated from 7 days before current date
                        till current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "past7Days",
                          "past7Days",
                          sub(new Date(), { days: 7 }),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("past7Days")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("past30Days") ? "active" : ""
                      }`}
                      /* make selected period the past 30 days with alias "past30Days"
                        the past 30 days period is calculated from 30 days before current date
                        till current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "past30Days",
                          "past30Days",
                          sub(new Date(), { days: 30 }),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("past30Days")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("past90Days") ? "active" : ""
                      }`}
                      /* make selected period the past 90 days with alias "past90Days"
                        the past 90 days period is calculated from 90 days before current date
                        till current date */
                      onClick={() => {
                        this.onSelectPeriod(
                          "past90Days",
                          "past90Days",
                          sub(new Date(), { days: 90 }),
                          new Date()
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("past90Days")}
                    </div>
                    <div
                      className={`item ${
                        this.isActivePeriod("firstQuarter") ? "active" : ""
                      }`}
                      /* make selected period the first quarter with alias "firstQuarter"
                        the first quarter period is the first 3 months of the year */
                      onClick={() => {
                        const firstQuarter = new Date(
                          new Date().getFullYear(),
                          0,
                          1
                        );
                        this.onSelectPeriod(
                          "firstQuarter",
                          "quarter",
                          startOfQuarter(firstQuarter),
                          endOfQuarter(firstQuarter)
                        );
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("firstQuarter")}
                    </div>
                    {getQuarter(new Date()) >= 2 && (
                      <div
                        className={`item ${
                          this.isActivePeriod("secondQuarter") ? "active" : ""
                        }`}
                        /* make selected period the second quarter with alias "secondQuarter"
                        the second quarter period is the second 3 months (April, May, June)
                         of the year */
                        onClick={() => {
                          const secondQuarter = new Date(
                            new Date().getFullYear(),
                            3,
                            1
                          );
                          this.onSelectPeriod(
                            "secondQuarter",
                            "quarter",
                            startOfQuarter(secondQuarter),
                            endOfQuarter(secondQuarter)
                          );
                          this.onSelectComparedPeriod(null, null, null, null);
                        }}
                      >
                        {t("secondQuarter")}
                      </div>
                    )}
                    {getQuarter(new Date()) >= 3 && (
                      <div
                        className={`item ${
                          this.isActivePeriod("thirdQuarter") ? "active" : ""
                        }`}
                        /* make selected period the third quarter with alias "thirdQuarter"
                        the third quarter period is the third 3 months (July, August, September)
                        of the year */
                        onClick={() => {
                          const thirdQuarter = new Date(
                            new Date().getFullYear(),
                            6,
                            1
                          );
                          this.onSelectPeriod(
                            "thirdQuarter",
                            "quarter",
                            startOfQuarter(thirdQuarter),
                            endOfQuarter(thirdQuarter)
                          );
                          this.onSelectComparedPeriod(null, null, null, null);
                        }}
                      >
                        {t("thirdQuarter")}
                      </div>
                    )}
                    {getQuarter(new Date()) >= 4 && (
                      <div
                        className={`item ${
                          this.isActivePeriod("fourthQuarter") ? "active" : ""
                        }`}
                        /* make selected period the fourth quarter with alias "fourthQuarter"
                        the fourth quarter period is the fourth 3 months
                        (October, November, December) of the year */
                        onClick={() => {
                          const fourthQuarter = new Date(
                            new Date().getFullYear(),
                            9,
                            1
                          );
                          this.onSelectPeriod(
                            "fourthQuarter",
                            "quarter",
                            startOfQuarter(fourthQuarter),
                            endOfQuarter(fourthQuarter)
                          );
                          this.onSelectComparedPeriod(null, null, null, null);
                        }}
                      >
                        {t("fourthQuarter")}
                      </div>
                    )}
                    <div
                      className={`item ${
                        this.isActivePeriod("custom") ? "active" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.setState({
                          showCustomDate: true,
                        });
                      }}
                    >
                      <div className="custom">
                        {t("custom")}
                        <svg
                          className={`custom-icon ${
                            this.state.showCustomDate ? "open-icon" : ""
                          }`}
                          enableBackground="new 0 0 330 330"
                          viewBox="0 0 330 330"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m325.607 79.393c-5.857-5.857-15.355-5.858-21.213.001l-139.39 139.393-139.397-139.394c-5.857-5.857-15.355-5.858-21.213.001-5.858 5.858-5.858 15.355 0 21.213l150.004 150c2.813 2.813 6.628 4.393 10.606 4.393s7.794-1.581 10.606-4.394l149.996-150c5.859-5.857 5.859-15.355.001-21.213z" />
                        </svg>
                      </div>
                      {this.state.showCustomDate && (
                        <DatePicker
                          onChange={(dates) => {
                            const [start, end] = dates as [Date, Date];
                            this.onSelectPeriod(
                              getCustomDatesRangeTitle(start, end),
                              "custom",
                              start,
                              end
                            );
                            this.onSelectComparedPeriod(null, null, null, null);
                          }}
                          selected={this.state.customStartDate ?? undefined}
                          startDate={this.state.customStartDate ?? undefined}
                          endDate={this.state.customEndDate ?? undefined}
                          selectsRange
                          inline
                          closeOnScroll
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`dropdown-container ${
                  (this.props.disableComparing ||
                    this.props.disableDateFilters) &&
                  "disabled"
                }`}
              >
                <span className="secondary">{t("comparedTo")}</span>{" "}
                {
                  // show the corresponding quarter to the compared quarter alias
                  // the alias is in the form of "previous[First|..]Quarter"
                  // so we transform it to the form "[first|..]Quarter"
                  this.context.comparedPeriod
                    ? this.context.comparedPeriod?.type === "quarter"
                      ? this.context.comparedPeriod?.alias.startsWith(
                          "previous"
                        )
                        ? `${t(
                            `${this.context.comparedPeriod?.alias
                              .match(/First|Second|Third|Fourth/)[0]
                              .toLowerCase()}Quarter`
                          )} ${previousYear}`
                        : `${t(
                            this.context.comparedPeriod.alias
                          )} ${currentYear}`
                      : t(this.context.comparedPeriod.alias)
                    : t("none")
                }
                <ArrowDownIcon
                  className="dropdown-icon"
                  enableBackground="new 0 0 330 330"
                  onClick={(e) => {
                    this.setActiveMenu("comparedPeriodMenu", e);
                  }}
                />
                {this.isActiveMenu("comparedPeriodMenu") && (
                  <div className="dropdown-menu">
                    {this.context.currentPeriod.type === "day" && (
                      <>
                        {/* Yesterday */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias === "yesterday"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "yesterday",
                              "day",
                              startOfYesterday(),
                              startOfYesterday()
                            );
                          }}
                        >
                          {t("yesterday")}
                        </div>
                        {/* Same day in the previous week */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "sameDayPreviousWeek"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const sameDayPreviousWeek = sub(new Date(), {
                              weeks: 1,
                            });
                            this.onSelectComparedPeriod(
                              "sameDayPreviousWeek",
                              "day",
                              sameDayPreviousWeek,
                              sameDayPreviousWeek
                            );
                          }}
                        >
                          {t("sameDayPreviousWeek")}
                        </div>
                        {/* Same day in the previous month */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "sameDayPreviousMonth"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const sameDayPreviousMonth = sub(new Date(), {
                              months: 1,
                            });
                            this.onSelectComparedPeriod(
                              "sameDayPreviousMonth",
                              "day",
                              sameDayPreviousMonth,
                              sameDayPreviousMonth
                            );
                          }}
                        >
                          {t("sameDayPreviousMonth")}
                        </div>
                        {/* Same day in the previous year */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "sameDayPreviousYear"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const sameDayPreviousYear = sub(new Date(), {
                              years: 1,
                            });
                            this.onSelectComparedPeriod(
                              "sameDayPreviousYear",
                              "day",
                              sameDayPreviousYear,
                              sameDayPreviousYear
                            );
                          }}
                        >
                          {t("sameDayPreviousYear")}
                        </div>
                      </>
                    )}
                    {this.context.currentPeriod.type === "week" && (
                      <>
                        {/* Previous Week */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousWeek"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "previousWeek",
                              "week",
                              startOfWeek(
                                sub(new Date(), {
                                  weeks: 1,
                                })
                              ),
                              endOfWeek(
                                sub(new Date(), {
                                  weeks: 1,
                                })
                              )
                            );
                          }}
                        >
                          {t("previousWeek")}
                        </div>
                        {/* Same week in the previous month */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "sameWeekPreviousMonth"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "sameWeekPreviousMonth",
                              "week",
                              startOfWeek(
                                sub(new Date(), {
                                  months: 1,
                                })
                              ),
                              endOfWeek(
                                sub(new Date(), {
                                  months: 1,
                                })
                              )
                            );
                          }}
                        >
                          {t("sameWeekPreviousMonth")}
                        </div>
                        {/* Same week in the previous year */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "sameWeekPreviousYear"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "sameWeekPreviousYear",
                              "week",
                              startOfWeek(
                                sub(new Date(), {
                                  years: 1,
                                })
                              ),
                              endOfWeek(
                                sub(new Date(), {
                                  years: 1,
                                })
                              )
                            );
                          }}
                        >
                          {t("sameWeekPreviousYear")}
                        </div>
                      </>
                    )}
                    {this.context?.currentPeriod?.type === "month" && (
                      <>
                        {/* Previous Month */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousMonth"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "previousMonth",
                              "month",
                              startOfMonth(
                                sub(new Date(), {
                                  months: 1,
                                })
                              ),
                              endOfMonth(
                                sub(new Date(), {
                                  months: 1,
                                })
                              )
                            );
                          }}
                        >
                          {t("previousMonth")}
                        </div>
                        {/* Same period in the pervious month */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "samePeriodPreviousMonth"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "samePeriodPreviousMonth",
                              "month",
                              startOfMonth(
                                sub(new Date(), {
                                  months: 1,
                                })
                              ),
                              sub(new Date(), {
                                months: 1,
                              })
                            );
                          }}
                        >
                          {t("samePeriodPreviousMonth")}
                        </div>
                        {/* Same period in the pervious year */}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "samePeriodPreviousYear"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.onSelectComparedPeriod(
                              "samePeriodPreviousYear",
                              "month",
                              startOfMonth(
                                sub(new Date(), {
                                  years: 1,
                                })
                              ),
                              sub(new Date(), {
                                years: 1,
                              })
                            );
                          }}
                        >
                          {t("samePeriodPreviousYear")}
                        </div>
                      </>
                    )}
                    {this.context?.currentPeriod?.type === "year" && (
                      <div
                        className={`item ${
                          this.context.comparedPeriod?.type === "year"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          this.onSelectComparedPeriod(
                            "previousYear",
                            "year",
                            startOfYear(
                              sub(new Date(), {
                                years: 1,
                              })
                            ),
                            endOfYear(
                              sub(new Date(), {
                                years: 1,
                              })
                            )
                          );
                        }}
                      >
                        {t("previousYear")}
                      </div>
                    )}
                    {this.context?.currentPeriod?.type === "quarter" && (
                      <>
                        {getQuarter(new Date()) > 1 && (
                          <div
                            className={`item ${
                              this.context.comparedPeriod?.alias ===
                              "firstQuarter"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              const firstQuarter = new Date(
                                currentYearQuery,
                                0,
                                1
                              );
                              this.onSelectComparedPeriod(
                                "firstQuarter",
                                "quarter",
                                startOfQuarter(firstQuarter),
                                endOfQuarter(firstQuarter)
                              );
                            }}
                          >
                            {`${t("firstQuarter")} ${currentYear}`}
                          </div>
                        )}
                        {getQuarter(new Date()) > 2 && (
                          <div
                            className={`item ${
                              this.context.comparedPeriod?.alias ===
                              "secondQuarter"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              const secondQuarter = new Date(
                                currentYearQuery,
                                3,
                                1
                              );
                              this.onSelectComparedPeriod(
                                "secondQuarter",
                                "quarter",
                                startOfQuarter(secondQuarter),
                                endOfQuarter(secondQuarter)
                              );
                            }}
                          >
                            {`${t("secondQuarter")} ${currentYear}`}
                          </div>
                        )}
                        {getQuarter(new Date()) > 3 && (
                          <div
                            className={`item ${
                              this.context.comparedPeriod?.alias ===
                              "thirdQuarter"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              const thirdQuarter = new Date(
                                currentYearQuery,
                                6,
                                1
                              );
                              this.onSelectComparedPeriod(
                                "thirdQuarter",
                                "quarter",
                                startOfQuarter(thirdQuarter),
                                endOfQuarter(thirdQuarter)
                              );
                            }}
                          >
                            {`${t("thirdQuarter")} ${currentYear}`}
                          </div>
                        )}
                        {getQuarter(new Date()) > 4 && (
                          <div
                            className={`item ${
                              this.context.comparedPeriod?.alias ===
                              "fourthQuarter"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              const fourthQuarter = new Date(
                                currentYearQuery,
                                9,
                                1
                              );
                              this.onSelectComparedPeriod(
                                "fourthQuarter",
                                "quarter",
                                startOfQuarter(fourthQuarter),
                                endOfQuarter(fourthQuarter)
                              );
                            }}
                          >
                            {`${t("fourthQuarter")} ${currentYear}`}
                          </div>
                        )}
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousFirstQuarter"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const previousFirstQuarter = new Date(
                              previousYearQuery,
                              0,
                              1
                            );
                            this.onSelectComparedPeriod(
                              "previousFirstQuarter",
                              "quarter",
                              startOfQuarter(previousFirstQuarter),
                              endOfQuarter(previousFirstQuarter)
                            );
                          }}
                        >
                          {`${t("firstQuarter")} ${previousYear}`}
                        </div>
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousSecondQuarter"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const previousSecondQuarter = new Date(
                              previousYearQuery,
                              3,
                              1
                            );
                            this.onSelectComparedPeriod(
                              "previousSecondQuarter",
                              "quarter",
                              startOfQuarter(previousSecondQuarter),
                              endOfQuarter(previousSecondQuarter)
                            );
                          }}
                        >
                          {`${t("secondQuarter")} ${previousYear}`}
                        </div>
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousThirdQuarter"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const previousThirdQuarter = new Date(
                              previousYearQuery,
                              6,
                              1
                            );
                            this.onSelectComparedPeriod(
                              "previousThirdQuarter",
                              "quarter",
                              startOfQuarter(previousThirdQuarter),
                              endOfQuarter(previousThirdQuarter)
                            );
                          }}
                        >
                          {`${t("thirdQuarter")} ${previousYear}`}
                        </div>
                        <div
                          className={`item ${
                            this.context.comparedPeriod?.alias ===
                            "previousFourthQuarter"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const previousFourthQuarter = new Date(
                              previousYearQuery,
                              9,
                              1
                            );
                            this.onSelectComparedPeriod(
                              "previousFourthQuarter",
                              "quarter",
                              startOfQuarter(previousFourthQuarter),
                              endOfQuarter(previousFourthQuarter)
                            );
                          }}
                        >
                          {`${t("fourthQuarter")} ${previousYear}`}
                        </div>
                      </>
                    )}
                    {this.context?.currentPeriod?.type === "custom" && (
                      <div
                        className={`item ${
                          this.context.comparedPeriod?.type === "custom"
                            ? "active"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({
                            showComparedCustomDate: true,
                          });
                        }}
                      >
                        <div className="custom">
                          {t("custom")}
                          <svg
                            className={`custom-icon ${
                              this.state.showComparedCustomDate
                                ? "open-icon"
                                : ""
                            }`}
                            enableBackground="new 0 0 330 330"
                            viewBox="0 0 330 330"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m325.607 79.393c-5.857-5.857-15.355-5.858-21.213.001l-139.39 139.393-139.397-139.394c-5.857-5.857-15.355-5.858-21.213.001-5.858 5.858-5.858 15.355 0 21.213l150.004 150c2.813 2.813 6.628 4.393 10.606 4.393s7.794-1.581 10.606-4.394l149.996-150c5.859-5.857 5.859-15.355.001-21.213z" />
                          </svg>
                        </div>
                        {this.state.showComparedCustomDate && (
                          <DatePicker
                            onChange={(dates) => {
                              const [start, end] = dates as [Date, Date];
                              this.onSelectComparedPeriod(
                                getCustomDatesRangeTitle(start, end),
                                "custom",
                                start,
                                end
                              );
                            }}
                            selected={
                              this.state.customComparedStartDate ?? undefined
                            }
                            startDate={
                              this.state.customComparedStartDate ?? undefined
                            }
                            endDate={
                              this.state.customComparedEndDate ?? undefined
                            }
                            selectsRange
                            inline
                            closeOnScroll
                          />
                        )}
                      </div>
                    )}
                    <div
                      className="item"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.onSelectComparedPeriod(null, null, null, null);
                      }}
                    >
                      {t("none")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <AppliedFilters
              appliedFilters={flattenFilters(this.context?.appliedFilters)}
            />
          </>
        )}
      </div>
    );
  }
}
Header.contextType = ANALYTICS_CONTEXT;
export default withTranslation("common")(Header);
