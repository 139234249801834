/**
 * @author Salma Hefnawy
 * @date 20222-06-05
 * @description declaration of overview recommendations interface.
 * @filename recommendations.ts
 */

import { ItemsSales } from "./overview";

export interface OverviewStockRecommendation extends ItemsSales {
  run_out_date: string;
}

export interface OverviewBundleRecommendation extends ItemsSales {
  bundle_product_name: string;
  bundle_product_sku: string;
}

export enum RecommendationsType {
  STOCK = "stock",
  BUNDLE = "bundle",
  PROMOTION = "promotion",
}
