import React from "react";

export const CalendarIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m452 40h-24v-40h-40v40h-264v-40h-40v40h-24c-33.084 0-60 26.916-60 60v352c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60v-352c0-33.084-26.916-60-60-60zm20 412c0 11.028-8.972 20-20 20h-392c-11.028 0-20-8.972-20-20v-264h432zm0-304h-432v-48c0-11.028 8.972-20 20-20h24v40h40v-40h264v40h40v-40h24c11.028 0 20 8.972 20 20z" />
      <path d="m76 230h40v40h-40z" />
      <path d="m156 230h40v40h-40z" />
      <path d="m236 230h40v40h-40z" />
      <path d="m316 230h40v40h-40z" />
      <path d="m396 230h40v40h-40z" />
      <path d="m76 310h40v40h-40z" />
      <path d="m156 310h40v40h-40z" />
      <path d="m236 310h40v40h-40z" />
      <path d="m316 310h40v40h-40z" />
      <path d="m76 390h40v40h-40z" />
      <path d="m156 390h40v40h-40z" />
      <path d="m236 390h40v40h-40z" />
      <path d="m316 390h40v40h-40z" />
      <path d="m396 310h40v40h-40z" />
    </svg>
  );
};
export default CalendarIcon;
