/**
 * @author Youssef Tarek
 * @date 2022-01-02
 * @description implementation of brand related API calls.
 * @filename brand.ts
 */

import {
  BrandsValues,
  GenericRequestPayload,
  SortByDirection,
} from "interfaces/overview";
import { PaginationPayload } from "interfaces/payload";
import { BrandRequestPayload, BrandSalesData } from "interfaces/brand";
import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Brand {
  public static brandsTopSelling({
    project,
    from,
    to,
    search = "",
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
  }: GenericRequestPayload): Promise<PaginationPayload<BrandsValues[]>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.brands.path}`);
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("search", search);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.brands.method,
    });
  }

  public static brandsItemsSales({
    project,
    from,
    to,
    brand,
    search = "",
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
  }: BrandRequestPayload): Promise<PaginationPayload<BrandSalesData>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.brandsItemsSales.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("brand", brand);
    url.searchParams.append("search", search);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.brandsItemsSales.method,
    });
  }
}
