/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description Overview tab of the App.
 * @filename overview.tsx
 */
import { ROUTES } from "consts/routes";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { getOverView, getOrdersRevenueBreakdown } from "utilities/orders";
import {
  Overview as OverviewInterface,
  PercentageChange as IPercentageChange,
} from "interfaces/overview";
import { toast } from "react-toastify";
import {
  exist,
  getNumberReadableValue,
  getDurationRange,
  getPerviousDate,
  showDefaultImage,
} from "utilities/common";

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import defaultImg from "static/images/default.png";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import paymentMethodIcon from "static/images/payment-method.png";
import RevenueIcon from "static/images/revenue.svg";
import OrdersIcon from "static/images/orders.svg";
import CustomersIcon from "static/images/customers.svg";
import GoalAchievementCard from "./goal-achievement-card";
import Loader from "../../common/Loader";
import BreakdownChart from "../../common/breakdown-chart";
import Header from "../../common/header";
import PercentageChange from "../../common/percentage-change";
import OverviewRecommendations from "./overview-recommendations";

interface OverviewState {
  overview: OverviewInterface;
  previousOverview: OverviewInterface;
  comparedOverview?: OverviewInterface;
  loading: boolean;
  id: string;
}

class Overview extends React.Component<
  RouteComponentProps & WithTranslation,
  OverviewState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportRef = React.createRef();

    this.state = {
      overview: null,
      previousOverview: null,
      loading: true,
      id: Date.now().toString(),
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Overview";
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  /**
   * show > 1000% if percentage is that high
   * @param percentageChange
   * @returns what to be shown in percentage change stat
   */
  // eslint-disable-next-line class-methods-use-this
  getPercentageChangeNumber(percentageChange: IPercentageChange) {
    if (Math.abs(percentageChange.percentage) >= 1000) {
      return "> 1000";
    }
    return Math.abs(percentageChange.percentage);
  }

  /**  initializes overview for current period,
  pervious overview for the pervious period,
  compared overview for compared period
  */
  loadData() {
    let overviewPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      overviewPromise = getOverView({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((overview) => {
        this.setState({ overview });
      });
    }

    let previousOverviewPromise = Promise.resolve();

    previousOverviewPromise = getOverView({
      project: this.context.project,
      from: getPerviousDate(this.context.currentPeriod.type).from,
      to: getPerviousDate(this.context.currentPeriod.type).to,
      filters: this.context.appliedFilters,
    }).then((previousOverview) => {
      this.setState({ previousOverview });
    });

    let comparedOverviewPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedOverviewPromise = getOverView({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      }).then((comparedOverview) => {
        this.setState({
          comparedOverview,
        });
      });
    } else {
      this.setState({
        comparedOverview: null,
      });
    }

    Promise.all([
      overviewPromise,
      previousOverviewPromise,
      comparedOverviewPromise,
    ])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;
    const currDate = new Date();
    const prevDate = new Date(
      new Date().setFullYear(currDate.getFullYear() - 1)
    );
    const currentYear = currDate.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );
    const previousYear = prevDate.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );

    if (this.state.loading) {
      return <Loader />;
    }

    // TODO Provide the rest of the metrics
    // let topSales = 0;
    // let grossSales = 0;
    // let netSales = 0;
    // if (this.state.overview) {
    //   topSales =
    //     this.state.overview?.total_revenues +
    //     Math.abs(this.state.overview?.coupons);
    //   grossSales =
    //     topSales -
    //     Math.abs(this.state.overview?.taxes) -
    //     Math.abs(this.state.overview?.shipping);
    //   netSales =
    //     grossSales -
    //     Math.abs(this.state.overview?.returns) -
    //     Math.abs(this.state.overview?.coupons);
    // }

    return (
      <div className="overview" ref={this.exportRef}>
        <Header title={t("dashboardOverview")} exportRef={this.exportRef} />
        <div className="overview-header">
          <div className="card overview-card transparent-card">
            <div className="overview-items gap-30">
              <div className="overview-item">
                <div className="item-data-with-icon">
                  <span className="tooltip">{t("totalRevenueDisclaimer")}</span>
                  <RevenueIcon />
                  <div className="item-data">
                    <span className="title">{t("totalRevenue")}</span>
                    <span className="header">
                      {` ${getNumberReadableValue(
                        this.state.overview?.total_revenues
                      )} 
                      ${this.context.settings.currency}`}
                    </span>
                  </div>
                </div>
                <PercentageChange
                  current={this.state.overview?.total_revenues}
                  previous={this.state.previousOverview?.total_revenues}
                />
              </div>
              <Link to={ROUTES.orders.path} className="overview-item">
                <div className="item-data-with-icon">
                  <span className="tooltip">{t("totalOrdersDisclaimer")}</span>
                  <OrdersIcon />
                  <div className="item-data">
                    <span className="title">{t("totalOrders")}</span>
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.overview?.total_orders
                      )}
                    </span>
                  </div>
                </div>
                <PercentageChange
                  current={this.state.overview?.total_orders}
                  previous={this.state.previousOverview?.total_orders}
                />
              </Link>
              <Link to={ROUTES.customers.path} className="overview-item">
                <div className="item-data-with-icon">
                  <span className="tooltip">
                    {t("totalCustomersDisclaimer")}
                  </span>
                  <CustomersIcon />
                  <div className="item-data">
                    <span className="title">{t("totalCustomers")}</span>
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.overview?.customer_count
                      )}
                    </span>
                  </div>
                </div>
                <PercentageChange
                  current={this.state.overview?.customer_count}
                  previous={this.state.previousOverview?.customer_count}
                />
              </Link>
              <GoalAchievementCard
                totalRevenue={this.state.overview?.total_revenues}
              />
            </div>
            {this.state.comparedOverview && (
              <>
                <h2 className="card--title mt-40">
                  {this.context.comparedPeriod?.type === "quarter"
                    ? this.context.comparedPeriod?.alias.startsWith("previous")
                      ? `${t(
                          `${this.context.comparedPeriod?.alias
                            .match(/First|Second|Third|Fourth/)[0]
                            .toLowerCase()}Quarter`
                        )} ${previousYear}`
                      : `${t(
                          this.context.comparedPeriod?.alias
                        )} ${currentYear}`
                    : t(this.context.comparedPeriod?.alias)}
                </h2>
                <div className="overview-items gap-30">
                  <div className="overview-item light-blue">
                    <div className="item-data-with-icon">
                      <RevenueIcon />
                      <div className="item-data">
                        <span className="header">
                          {`${getNumberReadableValue(
                            this.state.comparedOverview?.total_revenues
                          )} ${this.context.settings.currency}`}
                        </span>
                        <span className="title">{t("totalRevenue")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="overview-item light-violet">
                    <div className="item-data-with-icon">
                      <OrdersIcon />
                      <div className="item-data">
                        <span className="header">
                          {getNumberReadableValue(
                            this.state.comparedOverview?.total_orders
                          )}
                        </span>
                        <span className="title">{t("totalOrders")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="overview-item light-yellow">
                    <div className="item-data-with-icon">
                      <CustomersIcon />
                      <div className="item-data">
                        <span className="header">
                          {getNumberReadableValue(
                            this.state.comparedOverview?.customer_count
                          )}
                        </span>
                        <span className="title">{t("newCustomers")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <OverviewRecommendations />
        <div className="cards-row">
          <div className="card features-card">
            <h2 className="card--title">
              <span>{t("featured")}</span>
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </h2>
            <div className="feature">
              <div className="feature--subtitle">
                {t("topPerformingProduct")}
              </div>
              <div className="feature--data">
                {this.state.overview?.top_performing_product?.image_url ? (
                  <img
                    src={this.state.overview?.top_performing_product?.image_url}
                    alt={this.state.overview?.top_performing_product?.name}
                    onError={(e) => {
                      showDefaultImage(e);
                    }}
                  />
                ) : (
                  <img
                    src={defaultImg}
                    alt={this.state.overview?.top_performing_product?.name}
                  />
                )}
                <div className="feature--name">
                  <span>
                    {this.state.overview?.top_performing_product?.name}
                  </span>
                  <span className="feature--sku">
                    {`sku: ${this.state.overview?.top_performing_product?.sku}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="feature">
              <div className="feature--subtitle">
                {t("topPerformingCategory")}
              </div>
              <div className="feature--data">
                {this.state.overview?.top_performing_category?.image_url ? (
                  <img
                    src={
                      this.state.overview?.top_performing_category?.image_url
                    }
                    alt={this.state.overview?.top_performing_category?.name}
                    onError={(e) => {
                      showDefaultImage(e);
                    }}
                  />
                ) : (
                  <img
                    src={defaultImg}
                    alt={this.state.overview?.top_performing_category?.name}
                  />
                )}
                <div className="feature--name">
                  {this.state.overview?.top_performing_category?.name}
                </div>
              </div>
            </div>
            <div className="feature">
              <div className="feature--subtitle">
                {t("topUsedPaymentMethod")}
              </div>
              <div className="feature--data">
                <img src={paymentMethodIcon} alt="paymentMethodIcon" />
                <div className="feature--name">
                  {this.state.overview?.top_used_payment_method}
                </div>
              </div>
            </div>
          </div>
          <div className="cards-row--orders-card">
            <BreakdownChart
              getDataPerDay={getOrdersRevenueBreakdown}
              chartLabel={t("common:revenue")}
              chartTitle={t("common:totalRevenue")}
            />
          </div>
        </div>
      </div>
    );
  }
}

Overview.contextType = ANALYTICS_CONTEXT;
export default withTranslation("overview")(Overview);
