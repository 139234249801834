/* eslint-disable react/jsx-curly-newline */
/**
 * @author Youssef Tarek
 * @date 2022-03-15
 * @description Abandoned carts screen of the App
 * @filename abandoned-carts.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import i18next from "i18next";
import { Form, Formik } from "formik";
import { format } from "date-fns";
import { combineDates, exist, getNumberReadableValue } from "utilities/common";
import { AbandonedCartsDetails } from "interfaces/abandoned-carts";
import { getAbandonedCarts } from "utilities/abandoned-carts";
import DatePickerField from "common/date-picker-field";
import CalendarIcon from "common/calendar-icon";
import { toast } from "react-toastify";
import Product from "layouts/stock/product";
import { CSVLink } from "react-csv";
import DoughnutCard from "../../common/doughnut-card";
import TableView from "../../common/table-view";
import Loader from "../../common/Loader";
import Header from "../../common/header";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";
import ClockIcon from "../../../../static/images/clock.svg";

interface AbandonedCartsState {
  loading: boolean;
  id: string;
  productSku: string;
  formError: string;
  durationRange: string;
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  abandonedCarts?: AbandonedCartsDetails;
}

class AbandonedCarts extends React.Component<
  RouteComponentProps & WithTranslation,
  AbandonedCartsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportAbandonedCartsRef: React.RefObject<HTMLDivElement>;

  exportAbandonedCartsProductRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportAbandonedCartsRef = React.createRef();
    this.exportAbandonedCartsProductRef = React.createRef();

    this.state = {
      loading: false,
      id: Date.now().toString(),
      productSku: "",
      formError: "",
      durationRange: "",
      startDate: new Date(),
      startTime: new Date(),
      endDate: new Date(),
      endTime: new Date(),
    };
    this.loadData = this.loadData.bind(this);
    this.updateProductSku = this.updateProductSku.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Abandoned Carts";
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  handleSubmit(
    values: {
      startDate: Date;
      startTime: Date;
      endDate: Date;
      endTime: Date;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const startDate = combineDates(values.startDate, values.startTime);
    const endDate = combineDates(values.endDate, values.endTime);

    if (startDate >= endDate) {
      this.setState({ formError: i18next.t("abandoned-carts:formError") });
      setSubmitting(false);
    } else if (startDate >= new Date() || endDate >= new Date()) {
      this.setState({
        formError: i18next.t("abandoned-carts:futureDateError"),
      });
      setSubmitting(false);
    } else {
      this.setState({ loading: true });
      this.setState({ formError: "" });
      let abandonedCartsPromise = Promise.resolve();

      if (exist(this.context, ["project"])) {
        abandonedCartsPromise = getAbandonedCarts({
          project: this.context.project,
          from: format(startDate, "yyyy-MM-dd hh:mm:ss"),
          to: format(endDate, "yyyy-MM-dd hh:mm:ss"),
        }).then((abandonedCarts) => {
          this.setState({
            abandonedCarts,
          });
        });
        this.setState({
          durationRange: `${i18next.t("abandoned-carts:from")}: ${format(
            startDate,
            "yyyy-MM-dd hh:mm:ss"
          )} ${i18next.t("abandoned-carts:to")}: ${format(
            endDate,
            "yyyy-MM-dd hh:mm:ss"
          )}`,
        });
      }

      Promise.all([abandonedCartsPromise])
        .then(() => {
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          toast.error(error);
        });
    }
  }

  // get abandoned carts from a week ago
  loadData() {
    this.setState({ loading: true });
    const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let abandonedCartsPromise = Promise.resolve();
    if (exist(this.context, ["project"])) {
      abandonedCartsPromise = getAbandonedCarts({
        project: this.context.project,
        from: format(lastWeek, "yyyy-MM-dd hh:mm:ss"),
        to: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
      }).then((abandonedCarts) => {
        this.setState({
          abandonedCarts,
        });
      });
    }
    Promise.all([abandonedCartsPromise])
      .then(() => {
        this.setState({ loading: false });
        this.setState({
          durationRange: `${i18next.t("abandoned-carts:from")}: ${format(
            lastWeek,
            "yyyy-MM-dd hh:mm:ss"
          )} ${i18next.t("abandoned-carts:to")}: ${format(
            new Date(),
            "yyyy-MM-dd hh:mm:ss"
          )}`,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  updateProductSku(productSku: string) {
    this.setState({
      productSku,
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="abandoned-carts">
        <Header
          title={t("abandonedCarts")}
          disableAdvancedFilters
          disableComparing
          exportRef={
            this.state.productSku
              ? this.exportAbandonedCartsProductRef
              : this.exportAbandonedCartsRef
          }
        />
        {this.state.productSku ? (
          <>
            <button
              className="products-button--back"
              type="button"
              onClick={() => {
                this.setState({
                  productSku: "",
                });
              }}
            >
              <BackArrowIcon />
              {t("backToList")}
            </button>
            <Product
              productSku={this.state.productSku}
              updateProductSku={this.updateProductSku}
              exportRef={this.exportAbandonedCartsProductRef}
            />
          </>
        ) : (
          <div ref={this.exportAbandonedCartsRef}>
            <div className="grid">
              <div className="card abandoned-carts--card">
                {Object.keys(this.context.appliedFilters).length > 0 && (
                  <div className="disclaimer">
                    <span>{t("abandonedCartsAppliedFiltersDisclaimer")}</span>
                  </div>
                )}
                <h2 className="card--title flex align-center">
                  {t("abandonmentDuration")}{" "}
                  <span className="duration">{this.state.durationRange}</span>
                </h2>
                <Formik
                  initialValues={{
                    startDate: new Date(),
                    startTime: new Date(),
                    endDate: new Date(),
                    endTime: new Date(),
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.handleSubmit(values, setSubmitting);
                  }}
                >
                  {(props) => {
                    const { values, isSubmitting } = props;
                    return (
                      <Form className="abandoned-carts__form">
                        <div>
                          <label className="abandoned-carts__form-label">
                            {t("from")}
                          </label>
                          <div className="abandoned-carts__form-fields">
                            <div className="abandoned-carts__form-field">
                              <label htmlFor="">{t("date")}</label>
                              <div className="abandoned-carts__form-field-input">
                                <DatePickerField
                                  name="startDate"
                                  dateFormat="d MMMM yyyy"
                                  startDate={values.startDate}
                                  endDate={values.endDate}
                                  maxDate={new Date()}
                                  onChange={(date) =>
                                    this.setState({ startDate: date })
                                  }
                                />

                                <CalendarIcon />
                                <BackArrowIcon className="input-arrow" />
                              </div>
                            </div>
                            <div className="abandoned-carts__form-field">
                              <label htmlFor="">{t("time")}</label>
                              <div className="abandoned-carts__form-field-input">
                                <DatePickerField
                                  name="startTime"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  dateFormat="h:mm aa"
                                  className="time-input"
                                  onChange={(date) =>
                                    this.setState({ startTime: date })
                                  }
                                />
                                <ClockIcon />
                                <BackArrowIcon className="input-arrow" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="abandoned-carts__form-label">
                            {t("to")}
                          </label>
                          <div className="abandoned-carts__form-fields">
                            <div className="abandoned-carts__form-field">
                              <label htmlFor="">{t("date")}</label>
                              <div className="abandoned-carts__form-field-input">
                                <DatePickerField
                                  name="endDate"
                                  dateFormat="d MMMM yyyy"
                                  startDate={values.startDate}
                                  endDate={values.endDate}
                                  minDate={values.startDate}
                                  maxDate={new Date()}
                                  onChange={(date) =>
                                    this.setState({ endDate: date })
                                  }
                                />
                                <CalendarIcon />
                                <BackArrowIcon className="input-arrow" />
                              </div>
                            </div>
                            <div className="abandoned-carts__form-field">
                              <label htmlFor="">{t("time")}</label>
                              <div className="abandoned-carts__form-field-input">
                                <DatePickerField
                                  name="endTime"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  dateFormat="h:mm aa"
                                  className="time-input"
                                  onChange={(date) =>
                                    this.setState({ endTime: date })
                                  }
                                />
                                <ClockIcon />
                                <BackArrowIcon className="input-arrow" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="abandoned-carts__form-error">
                          {this.state.formError}
                        </p>
                        <button
                          className="button--main black"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {t("apply")}
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
                {this.state.abandonedCarts ? (
                  <div className="abandoned-carts--card-info">
                    <div>
                      <label htmlFor="">{t("totalAbandonedCarts")}</label>
                      <p>
                        {this.state.abandonedCarts.guest_cart_count +
                          this.state.abandonedCarts.user_cart_count}
                      </p>
                    </div>
                    <div>
                      <label htmlFor="">{t("userCarts")}</label>
                      <p>{this.state.abandonedCarts.user_cart_count}</p>
                    </div>
                    <div>
                      {this.state.abandonedCarts?.user_list && (
                        <CSVLink
                          data={this.state.abandonedCarts?.user_list}
                          filename="abandoned-carts-users-list.csv"
                          className="export-list-button ml-auto"
                          target="_blank"
                        >
                          {t("exportList")}
                        </CSVLink>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              {this.state.abandonedCarts ? (
                <>
                  <div className="card overview-card">
                    <h2 className="card--title flex align-center">
                      {t("Users Abandoned Carts")}{" "}
                      <span className="duration">
                        {this.state.durationRange}
                      </span>
                    </h2>
                    <div className="overview-items">
                      <div className="item">
                        <span className="header">
                          {getNumberReadableValue(
                            this.state.abandonedCarts?.user_cart_count
                          )}
                        </span>
                        <span className="title">{t("abandonedCarts")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card abandoned-carts--products">
                    <h2 className="card--title">
                      {t("productsList")}{" "}
                      <span className="duration">
                        {this.state.durationRange}
                      </span>
                    </h2>
                    <div className="abandoned-carts-products-table">
                      {this.state.abandonedCarts?.product_list && (
                        <TableView
                          products={this.state.abandonedCarts?.product_list}
                          type="abandoned-carts"
                          updateProductSku={this.updateProductSku}
                        />
                      )}
                    </div>
                  </div>
                  <div className="abandoned-carts--doughnut-card">
                    <DoughnutCard
                      title={t("abandonedCartsStatistics")}
                      items={[
                        {
                          name: [
                            `${t("guests")}`,
                            `${Math.round(
                              (this.state.abandonedCarts?.guest_cart_count /
                                (this.state.abandonedCarts?.guest_cart_count +
                                  this.state.abandonedCarts?.user_cart_count)) *
                                100
                            )}% . ${
                              this.state.abandonedCarts?.guest_cart_count
                            } ${t("abandonedCart")}`,
                          ],
                          count:
                            `${this.state.abandonedCarts?.guest_cart_count}` ??
                            "0",
                        },
                        {
                          name: [
                            `${t("users")}`,
                            `${Math.round(
                              (this.state.abandonedCarts?.user_cart_count /
                                (this.state.abandonedCarts?.guest_cart_count +
                                  this.state.abandonedCarts?.user_cart_count)) *
                                100
                            )}% . ${
                              this.state.abandonedCarts?.user_cart_count
                            } ${t("abandonedCart")}`,
                          ],
                          count:
                            `${this.state.abandonedCarts?.user_cart_count}` ??
                            "0",
                        },
                      ]}
                      currentPeriod={this.context.currentPeriod}
                      radius="100"
                      blackThemed
                      legendPosition="bottom"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

AbandonedCarts.contextType = ANALYTICS_CONTEXT;
export default withTranslation("abandoned-carts")(AbandonedCarts);
