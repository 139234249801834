/**
 * @author Youssef Tarek
 * @date 2021-12-04
 * @description Account information of the user
 * @filename account-information.tsx
 */
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Formik, Form, FormikHelpers, Field } from "formik";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { string as YUPString, object as YUPObject } from "yup";

interface AccountInformationState {
  editingEmail: boolean;
  editingPassword: boolean;
}

export class AccountInformation extends React.Component<
  RouteComponentProps,
  AccountInformationState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      editingEmail: false,
      editingPassword: false,
    };
    this.updateAccountName = this.updateAccountName.bind(this);
  }

  componentDidMount() {
    // set page title
    document.title = "Account Settings";
  }

  updateAccountName(
    _values: {
      name: string;
    },
    formikHelpers: FormikHelpers<{
      name: string;
    }>
  ) {
    if (this.context.user?.name === _values.name) {
      return;
    }
    // TODO: Update account name
    formikHelpers.setSubmitting(false);
  }

  render(): React.ReactNode {
    return (
      <div className="account-information">
        <div className="card">
          <h2 className="card--title">Account Settings</h2>

          <Formik
            onSubmit={this.updateAccountName}
            initialValues={{
              name: this.context.user?.name,
            }}
            validationSchema={YUPObject().shape({
              name: YUPString().max(200).required("Name is required"),
            })}
          >
            {(formikBag) => (
              <Form className="card--section">
                <Field name="name">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="name">Full Name</label>
                      <input
                        id="topic"
                        placeholder="enter message topic"
                        name="name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <button
                  type="submit"
                  className="button save"
                  disabled={formikBag.isSubmitting}
                >
                  Update Name
                </button>
              </Form>
            )}
          </Formik>
          <div className="card--section">
            <label className="label" htmlFor="email">
              Email
            </label>
            <div className="flex account-information--email justify-between">
              <div className="title">{this.context.user.email}</div>
              <Link to="/changeEmail" className="link">
                Change Email
              </Link>
            </div>
            <label className="label my-10" htmlFor="password">
              Password
            </label>
            <div className="flex account-information--password justify-between">
              <div className="title">********</div>
              <Link to="/changePassword" className="link">
                Change Password
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountInformation.contextType = ANALYTICS_CONTEXT;
