/**
 * @author Karim Shalapy
 * @date 2022-09-25
 * @description page for a specific brand item sales details
 * @filename brands-details.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  exist,
  getDurationRange,
  getNumberReadableValue,
  getPerviousDate,
  showDefaultImage,
} from "utilities/common";
import defaultImg from "static/images/default.png";
import type { Direction, SalesValues, SortOption } from "interfaces/overview";
import { BrandSalesData } from "interfaces/brand";
import { getBrandsItems } from "utilities/brands";
import { mapChartData } from "utilities/charts";
import TableView from "../../common/table-view";
import SectionLoader from "../../common/section-loader";
import CollectionCard from "../../common/collection-card";
import Pagination from "../../common/Pagination";
import SortSearch from "../../common/sort-search";
import PercentageChange from "../../common/percentage-change";

interface BrandDetailsProps {
  brandName: string;
  updateBrandName: (parentName: string) => void;
  exportRef?: React.RefObject<HTMLDivElement>;
}

interface BrandDetailsState {
  id: string;
  brandSummary: Omit<BrandSalesData, "products"> | null;
  comparedBrandSummary: Omit<BrandSalesData, "products"> | null;
  loadingBrandSummary: boolean;
  loadingBrandItems: boolean;
  loadingComparedBrandSummary: boolean;
  loadingComparedBrandItems: boolean;
  previousBrandSummary?: Omit<BrandSalesData, "products"> | null;
  brandItemsSales: SalesValues[] | null;
  comparedBrandItemsSales: SalesValues[] | null;
  currentPage: number;
  comparedCurrentPage: number;
  totalPages: number;
  comparedTotalPages: number;
  searchQuery: string;
  sortBy?: SortOption;
  sortDirection?: Direction;
}

class BrandDetails extends React.Component<
  BrandDetailsProps & WithTranslation,
  BrandDetailsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: BrandDetailsProps & WithTranslation) {
    super(props);

    this.state = {
      loadingBrandSummary: true,
      loadingComparedBrandSummary: true,
      loadingBrandItems: true,
      loadingComparedBrandItems: true,
      id: Date.now().toString(),
      brandItemsSales: null,
      comparedBrandItemsSales: null,
      brandSummary: null,
      comparedBrandSummary: null,
      previousBrandSummary: null,
      currentPage: 1,
      totalPages: 1,
      comparedCurrentPage: 1,
      comparedTotalPages: 1,
      searchQuery: "",
    };

    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.loadAllData = this.loadAllData.bind(this);
    this.loadBrandItems = this.loadBrandItems.bind(this);
    this.loadComparedBrandItems = this.loadComparedBrandItems.bind(this);
    this.loadPreviousBrandSummary = this.loadPreviousBrandSummary.bind(this);
    this.updateCurrentPage = this.updateCurrentPage.bind(this);
    this.updateComparedCurrentPage = this.updateComparedCurrentPage.bind(this);
  }

  componentDidMount() {
    this.loadAllData();
    this.context.addUpdatesListener(this.state.id, this.loadAllData);
  }

  componentDidUpdate(
    prevProps: Readonly<BrandDetailsProps & WithTranslation>,
    prevState: Readonly<BrandDetailsState>
  ) {
    if (this.props.brandName !== prevProps.brandName) {
      this.loadAllData();
    } else {
      const sortChanged =
        prevState.sortBy !== this.state.sortBy ||
        prevState.sortDirection !== this.state.sortDirection;
      const searchChanged = prevState.searchQuery !== this.state.searchQuery;
      if (
        prevState.currentPage !== this.state.currentPage ||
        sortChanged ||
        searchChanged
      ) {
        this.loadBrandItems()
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            this.setState({
              loadingBrandItems: false,
            });
          });
      }

      if (
        prevState.comparedCurrentPage !== this.state.comparedCurrentPage ||
        sortChanged ||
        searchChanged
      ) {
        this.loadComparedBrandItems()
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            this.setState({
              loadingComparedBrandItems: false,
            });
          });
      }
    }
  }

  updateSearchQuery(searchQuery: string) {
    this.setState({
      searchQuery,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateSort(sortBy?: SortOption, sortDirection?: Direction) {
    if (!sortBy || !sortDirection) {
      this.setState({
        sortBy: undefined,
        sortDirection: undefined,
        currentPage: 1,
        comparedCurrentPage: 1,
      });
      return;
    }
    this.setState({
      sortBy,
      sortDirection,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateCurrentPage(currentPage: number) {
    this.setState({
      currentPage,
    });
  }

  updateComparedCurrentPage(comparedCurrentPage: number) {
    this.setState({
      comparedCurrentPage,
    });
  }

  loadBrandItems() {
    this.setState({
      loadingBrandItems: true,
    });

    let brandItemsSales = Promise.resolve();
    if (exist(this.context, ["project", "currentPeriod"])) {
      brandItemsSales = getBrandsItems({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        brand: this.props.brandName,
        currentPage: this.state.currentPage,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          brandItemsSales: res.data.products,
          brandSummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            products_count: res.data.products_count,
          },
          loadingBrandItems: false,
          totalPages: res.pagination.totalPageCount || 1,
        });
      });
    }
    return brandItemsSales;
  }

  loadComparedBrandItems() {
    this.setState({
      loadingComparedBrandItems: true,
    });

    let comparedBrandItemsSales = Promise.resolve();
    if (this.context.comparedPeriod) {
      comparedBrandItemsSales = getBrandsItems({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        brand: this.props.brandName,
        currentPage: this.state.comparedCurrentPage,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          comparedBrandItemsSales: res.data.products,
          comparedBrandSummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            products_count: res.data.products_count,
          },
          loadingComparedBrandItems: false,
          loadingComparedBrandSummary: false,
          comparedTotalPages: res.pagination.totalPageCount || 1,
        });
      });
    }
    return comparedBrandItemsSales;
  }

  loadPreviousBrandSummary() {
    this.setState({
      loadingBrandSummary: true,
    });

    let previousBrandSummaryPromise = Promise.resolve();
    if (exist(this.context, ["project", "currentPeriod"])) {
      previousBrandSummaryPromise = getBrandsItems({
        project: this.context.project,
        from: getPerviousDate(this.context.currentPeriod.type).from,
        to: getPerviousDate(this.context.currentPeriod.type).to,
        brand: this.props.brandName,
        currentPage: this.state.currentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          previousBrandSummary: {
            name: res.data.name,
            image_url: res.data.image_url,
            sales_value: res.data.sales_value,
            number_sold: res.data.number_sold,
            products_count: res.data.products_count,
          },
          loadingBrandSummary: false,
        });
      });
    }
    return previousBrandSummaryPromise;
  }

  loadAllData() {
    const brandItemsSalesPromise = this.loadBrandItems();
    const comparedBrandItemsSalesPromise = this.loadComparedBrandItems();
    const previousBrandSummaryPromise = this.loadPreviousBrandSummary();
    Promise.all([
      brandItemsSalesPromise,
      previousBrandSummaryPromise,
      comparedBrandItemsSalesPromise,
    ])
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        this.setState({
          loadingBrandItems: false,
          loadingComparedBrandItems: false,
          loadingBrandSummary: false,
          loadingComparedBrandSummary: false,
        });
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const allBrandsData = mapChartData(this.state.brandItemsSales);
    const allComparedBrandsData = mapChartData(
      this.state.comparedBrandItemsSales
    );
    const tabs = {
      [t(this.props.brandName)]: {
        title: `${t(this.props.brandName)}`,
        items: allBrandsData,
      },
    };
    const comparedTabs = {
      [t(this.props.brandName)]: {
        title: `${t(this.props.brandName)}`,
        items: allComparedBrandsData,
      },
    };

    return (
      <div
        className="product-analytics"
        key={this.state.id}
        ref={this.props.exportRef}
      >
        <div className="card-container">
          {this.state.loadingBrandSummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="card product-data">
              <div className="information-card">
                <div className="product-info">
                  {this.state.brandSummary?.image_url ? (
                    <img
                      src={this.state.brandSummary?.image_url}
                      alt={this.state.brandSummary?.name}
                      onError={(e) => {
                        showDefaultImage(e);
                      }}
                    />
                  ) : (
                    <img src={defaultImg} alt={this.state.brandSummary?.name} />
                  )}
                  <div className="product-name">
                    <span className="title">
                      {this.state.brandSummary?.name}
                    </span>
                    <span className="tooltip">
                      {this.state.brandSummary?.name}
                    </span>
                  </div>
                  <div className="product-count">
                    {getNumberReadableValue(
                      this.state.brandSummary?.products_count
                    )}{" "}
                    <span>{t("products", { ns: "product" })}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*
            Next line is needed to push the stats to the next line when there's a comparison period
          */}
          {this.context.comparedPeriod && <div />}

          {this.state.loadingBrandSummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="product-sales">
              {this.context.comparedPeriod && (
                <h2 className="card--title">
                  {t(this.context.currentPeriod.alias, { ns: "common" })} (
                  {new Date(this.context.currentPeriod.to).getFullYear()})
                </h2>
              )}
              <div className="card product-sales transparent-card">
                <div className="stats-card">
                  <div className="stat">
                    <div className="label">
                      {t("unitsSold", { ns: "product" })}
                    </div>
                    <div className="title">
                      {getNumberReadableValue(
                        this.state.brandSummary?.number_sold
                      )}
                    </div>
                    {!this.context.comparedPeriod && (
                      <PercentageChange
                        current={this.state.brandSummary?.number_sold}
                        previous={this.state.previousBrandSummary?.number_sold}
                      />
                    )}
                  </div>
                  <div className="stat">
                    <div className="label">
                      {t("totalSalesValue", { ns: "product" })}
                    </div>
                    <div className="title">
                      {`${getNumberReadableValue(
                        this.state.brandSummary?.sales_value
                      )} ${this.context.settings.currency}`}
                    </div>
                    {!this.context.comparedPeriod && (
                      <PercentageChange
                        current={this.state.brandSummary?.sales_value}
                        previous={this.state.previousBrandSummary?.sales_value}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.context.comparedPeriod &&
            (this.state.loadingComparedBrandSummary ? (
              <div className="card product-data">
                <SectionLoader />
              </div>
            ) : (
              <div className="product-sales">
                <h2 className="card--title">
                  {t(this.context.comparedPeriod.alias, { ns: "common" })} (
                  {new Date(this.context.comparedPeriod.to).getFullYear()})
                </h2>
                <div className="card product-sales transparent-card">
                  <div className="stats-card">
                    <div className="stat">
                      <div className="label">
                        {t("unitsSold", { ns: "product" })}
                      </div>
                      <div className="title">
                        {getNumberReadableValue(
                          this.state.comparedBrandSummary?.number_sold
                        )}
                      </div>
                    </div>
                    <div className="stat">
                      <div className="label">
                        {t("totalSalesValue", { ns: "product" })}
                      </div>
                      <div className="title">
                        {`${getNumberReadableValue(
                          this.state.comparedBrandSummary?.sales_value
                        )} ${this.context.settings.currency}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="span-2">
            <SortSearch
              searchHandler={this.updateSearchQuery}
              sortHandler={this.updateSort}
              sortBy={this.state.sortBy}
              direction={this.state.sortDirection}
              mode="minimal"
            />
          </div>
          <div className={this.context.comparedPeriod ? "" : "span-2 centered"}>
            <div className="card">
              <h2 className="card--title">
                <div>
                  {t("brandItemsSales")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </div>
              </h2>
              <div className="sales-table categories-sales-table big">
                <TableView
                  products={this.state.brandItemsSales || []}
                  type="brands-items-sales-list"
                  loading={this.state.loadingBrandItems}
                />
              </div>
              {!this.state.loadingBrandItems &&
                this.state.searchQuery &&
                !this.state.brandItemsSales.length && (
                  <p>{t("noSearchResult", { ns: "common" })}</p>
                )}
            </div>
            {this.state.totalPages > 1 && (
              <Pagination
                totalPageCount={this.state.totalPages}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.updateCurrentPage}
              />
            )}
          </div>

          {this.context.comparedPeriod && (
            <div>
              <div className="card">
                <h2 className="card--title">
                  <div>
                    {t("brandItemsSales")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod.type,
                        this.context.comparedPeriod?.from,
                        this.context.comparedPeriod?.to
                      )}
                      )
                    </span>
                  </div>
                </h2>
                <div className="sales-table categories-sales-table big">
                  <TableView
                    products={this.state.comparedBrandItemsSales || []}
                    type="brands-items-sales-list"
                    loading={this.state.loadingComparedBrandItems}
                  />
                </div>
                {!this.state.loadingComparedBrandItems &&
                  this.state.searchQuery &&
                  !this.state.brandItemsSales.length && (
                    <p>{t("noSearchResult", { ns: "common" })}</p>
                  )}
              </div>
              {this.state.comparedTotalPages > 1 && (
                <Pagination
                  totalPageCount={this.state.comparedTotalPages}
                  currentPage={this.state.comparedCurrentPage}
                  pageNeighbors={1}
                  changePageHandler={this.updateComparedCurrentPage}
                />
              )}
            </div>
          )}
          {this.state.brandItemsSales && (
            <div
              className={`card ${
                this.context.comparedPeriod ? "" : "span-2 centered"
              }`}
            >
              <CollectionCard tabs={tabs} title={t(this.props.brandName)} />
            </div>
          )}
          {this.state.comparedBrandItemsSales && (
            <div className="card">
              <CollectionCard
                tabs={comparedTabs}
                title={t(this.props.brandName)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

BrandDetails.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["brands", "product", "common"])(BrandDetails);
