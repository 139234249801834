import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { User as UserInterface } from "interfaces/user-management";
import {
  getAllUsers,
  getAllRoles,
  deleteUser,
  editUserRole,
} from "utilities/user-management";
import Loader from "../../../common/Loader";
import ArrowDownIcon from "../../../../../static/images/down-arrow.svg";
import DeleteIcon from "../../../../../static/images/delete-icon.svg";
import PlusIcon from "../../../../../static/images/plus.svg";
import AddUser from "./add-user";
import { Modal } from "../../../common/modal";

interface UserManagementState {
  loading: boolean;
  id: string;
  rolesMenu: boolean;
  addUser: boolean;
  showModal: boolean;
  users: UserInterface[];
  roles: string[];
  userId: string;
}

class UserManagement extends React.Component<
  RouteComponentProps & WithTranslation,
  UserManagementState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.state = {
      loading: false,
      id: Date.now().toString(),
      rolesMenu: false,
      addUser: false,
      showModal: false,
      users: [],
      roles: [],
      userId: "",
    };
    this.loadData = this.loadData.bind(this);

    this.handleEditUserRole = this.handleEditUserRole.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleEditUserRole(id: string, role: string) {
    this.setState({ loading: true });

    editUserRole(id, role)
      .then((result) => {
        toast.success(result.message);
        this.loadData();
      })
      .catch((err) => {
        toast.error(err);
        this.setState({ loading: false });
      });
  }

  handleDeleteUser(id: string) {
    this.setState({ loading: true });

    deleteUser(id)
      .then((result) => {
        toast.success(result.message);
        this.loadData();
      })
      .catch((err) => {
        toast.error(err);
        this.setState({ loading: false });
      })
      .finally(() => {
        this.hideModal();
      });
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  loadData() {
    this.setState({ loading: true });

    let getUsersPromise = Promise.resolve();
    let getRolesPromise = Promise.resolve();

    getUsersPromise = getAllUsers().then((users) => {
      this.setState({
        users,
      });
    });

    getRolesPromise = getAllRoles().then((roles) => {
      this.setState({
        roles,
      });
    });

    Promise.all([getUsersPromise, getRolesPromise])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return (
        <div className="settings settings--user-management">
          <Loader />
        </div>
      );
    }

    if (this.state.addUser) {
      return (
        <AddUser
          onFinish={() => this.setState({ addUser: false })}
          onSubmit={() => this.loadData()}
          roles={this.state.roles}
        />
      );
    }
    return (
      <div className="settings settings--user-management">
        <div className="card">
          <div className="card__heading">
            <div>
              <h2 className="card--title">{t("userManagement")}</h2>
              <p className="note">{t("manageUsersAndRoles")}</p>
            </div>
            <button
              type="button"
              className="button--main"
              onClick={() => this.setState({ addUser: true })}
            >
              <PlusIcon />
              {t("addUser")}
            </button>
          </div>
          <div className="table table--four-columns">
            <div className="table__head">
              <h2>{t("userName")}</h2>
              <h2>{t("email")}</h2>
              <h2>{t("role")}</h2>
            </div>
            <div className="table__body">
              {this.state.users?.map((user) => (
                <dl className="table__body-row" key={user.id}>
                  <div className="table__head--responsive">
                    <dt>{t("userName")}</dt>
                    <dd>{user.name}</dd>
                  </div>
                  <div className="table__head--responsive">
                    <dt>{t("email")}</dt>
                    <dd className="email">{user.email}</dd>
                  </div>
                  {/* Todo: this should be toggled by the userId */}
                  <div className="table__head--responsive">
                    <dt>{t("role")}</dt>
                    <dd
                      className="dropdown-container"
                      onClick={() => {
                        this.setState({
                          rolesMenu: !this.state.rolesMenu,
                          userId: user.id,
                        });
                      }}
                    >
                      {user.roles[0]}
                      <ArrowDownIcon className="dropdown-icon" />
                      {this.state.userId === user.id &&
                        this.state.rolesMenu && (
                          <ul className="dropdown-menu">
                            {this.state.roles?.map((role, index) => (
                              <li
                                key={index}
                                className="item"
                                onClick={() => {
                                  this.handleEditUserRole(user.id, role);
                                }}
                              >
                                {role}
                              </li>
                            ))}
                          </ul>
                        )}
                    </dd>
                  </div>
                  <button
                    type="button"
                    className="button--clear button__delete"
                    onClick={() => {
                      this.showModal();
                      this.setState({
                        userId: user.id,
                      });
                    }}
                  >
                    <DeleteIcon />
                    {t("deleteUser")}
                  </button>
                </dl>
              ))}
            </div>
            {/* Todo: this will be discussed if needed or not */}
            {/* <div className="table__actions">
              <button type="submit">Save Changes</button>
              <button type="button">cancel</button>
            </div> */}
          </div>
        </div>
        <Modal showModal={this.state.showModal} handleClose={this.hideModal}>
          <div className="settings__modal">
            <h1 className="settings__modal-title">
              {t("deleteConfirmationTitle")}
            </h1>
            <div className="settings__modal-actions">
              <button
                type="button"
                className="button--main button--delete"
                onClick={() => {
                  this.handleDeleteUser(this.state.userId);
                }}
              >
                {t("delete")}
              </button>
              <button
                type="button"
                className="button-outline"
                onClick={this.hideModal}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

UserManagement.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(UserManagement);
