/**
 * @author Ahmed Serag
 * @date 2020-07-20
 * @description implementation of Order related API calls.
 * @filename order.ts
 */
import { Project } from "consts/project";
import {
  AreaCounts,
  CityCounts,
  CountryCounts,
  Counts,
  GenericRequestPayload,
  ItemsSales,
  Overview,
  SortByDirection,
} from "interfaces/overview";
import { Payload } from "interfaces/payload";
import {
  OrdersOverview,
  OrdersHourlyBreakdown,
  CancelledOrdersPercentage,
  Product,
  ByPaymentMethodPercentage,
  ByOrderSourcePercentage,
  ByFilterSegmentPercentage,
} from "interfaces/orders-overview";
import { appendFiltersToSearchParam } from "utilities/urls";
import { ENDPOINTS } from "./endpoints";
import { Network } from "./network";

/**
 * implement Order related calls.
 */
export class Order {
  public static overview({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<Overview>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.overview.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.overview.method,
    });
  }

  public static ordersDetails({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<OrdersOverview>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.orders.path}`);

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.orders.method,
    });
  }

  public static paymentMethods({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<Counts[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.payments.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.payments.method,
    });
  }

  public static numberOfOrdersPerDay({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<number[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.ordersPerDay.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.ordersPerDay.method,
    });
  }

  public static listProjects(): Promise<Payload<Project[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.projects.path}`
    );
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.projects.method,
    });
  }

  public static ordersHourlyBreakdown({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<OrdersHourlyBreakdown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.orderHourlyBreakdown.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.orderHourlyBreakdown.method,
    });
  }

  public static ordersRevenueBreakdown({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<number[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.ordersRevenueBreakdown.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.ordersRevenueBreakdown.method,
    });
  }

  public static customersPerCity({
    project,
    from,
    to,
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
    exported = 0,
  }: GenericRequestPayload): Promise<Payload<CityCounts[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersCity.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    url.searchParams.append("export", exported.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersCity.method,
    });
  }

  public static customersPerCountry({
    project,
    from,
    to,
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
    exported = 0,
  }: GenericRequestPayload): Promise<Payload<CountryCounts[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersCountry.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    url.searchParams.append("export", exported.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersCountry.method,
    });
  }

  public static customersPerArea({
    project,
    from,
    to,
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
    exported = 0,
  }: GenericRequestPayload): Promise<Payload<AreaCounts[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersArea.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    url.searchParams.append("export", exported.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersArea.method,
    });
  }

  public static cancelledOrdersPercentage({
    project,
    from,
    to,
  }: GenericRequestPayload): Promise<Payload<CancelledOrdersPercentage>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCancelledOrdersPercentage.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCancelledOrdersPercentage.method,
    });
  }

  public static topProductsInCancelledOrders({
    project,
    from,
    to,
  }): Promise<Payload<Product[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getTopProductsInCancelledOrders.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getTopProductsInCancelledOrders.method,
    });
  }

  public static topCategoriesInCancelledOrders({
    project,
    from,
    to,
  }): Promise<Payload<Partial<ItemsSales[]>>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getTopCategoriesInCancelledOrders.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getTopCategoriesInCancelledOrders.method,
    });
  }

  public static topBrandsInCancelledOrders({
    project,
    from,
    to,
  }): Promise<Payload<Partial<ItemsSales[]>>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getTopBrandsInCancelledOrders.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getTopBrandsInCancelledOrders.method,
    });
  }

  public static cancelledOrdersByPaymentMethodPercentage({
    project,
    from,
    to,
  }): Promise<Payload<ByPaymentMethodPercentage[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCancelledOrdersByPaymentMethodPercentage.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCancelledOrdersByPaymentMethodPercentage.method,
    });
  }

  public static cancelledOrdersByOrderSourcePercentage({
    project,
    from,
    to,
  }): Promise<Payload<ByOrderSourcePercentage[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCancelledOrdersByOrderSourcePercentage.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCancelledOrdersByOrderSourcePercentage.method,
    });
  }

  public static cancelledOrdersByFilterSegmentsPercentage({
    project,
    from,
    to,
  }): Promise<Payload<ByFilterSegmentPercentage[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCancelledOrdersByFilterSegmentPercentage.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCancelledOrdersByFilterSegmentPercentage.method,
    });
  }

  public static cancelledOrdersBreakdown({
    project,
    from,
    to,
  }): Promise<Payload<number[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCancelledOrdersBreakdown.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCancelledOrdersBreakdown.method,
    });
  }
}
