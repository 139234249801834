/**
 * @author Youssef Tarek
 * @date 2022-03-05
 * @description show tables with headers in the app
 * @filename table-view.tsx
 */

import {
  AreaCounts,
  CityCounts,
  CountryCounts,
  ItemsSales,
  SalesValues,
} from "interfaces/overview";
import React from "react";
import { getNumberReadableValue, showDefaultImage } from "utilities/common";
import defaultImg from "static/images/default.png";
import { withTranslation, WithTranslation } from "react-i18next";
import Arrow from "static/images/down-arrow.svg";
import {
  ExportedPromoCodesList,
  GA4PagesReport,
  SessionSourceMediumList,
} from "interfaces/user-behavior";
import i18next from "i18next";
import {
  OverviewBundleRecommendation,
  OverviewStockRecommendation,
} from "interfaces/recommendations";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  ByFilterSegmentPercentage,
  ByOrderSourcePercentage,
  ByPaymentMethodPercentage,
} from "interfaces/orders-overview";
import SectionLoader from "./section-loader";

interface TableViewProps {
  products?:
    | ItemsSales[]
    | Partial<ItemsSales>[]
    | OverviewStockRecommendation[]
    | OverviewBundleRecommendation[]
    | SalesValues[]
    | CountryCounts[]
    | CityCounts[]
    | AreaCounts[]
    | ByPaymentMethodPercentage[]
    | ByOrderSourcePercentage[]
    | ByFilterSegmentPercentage[];
  promocodes?: ExportedPromoCodesList[];
  pagesReports?: GA4PagesReport[];
  sessionSourceMediumList?: SessionSourceMediumList[];
  type:
    | "abandoned-carts"
    | "products-analytics"
    | "promocodes"
    | "allPromoCodes"
    | "pages-reports"
    | "bundle-recommendation"
    | "promotions-recommendation"
    | "session-list"
    | "categories-sales-list"
    | "brands-items-sales-list"
    | "categories-children-sales-list"
    | "brands-sales-list"
    | "byCountry"
    | "byCity"
    | "byArea"
    | "byPaymentMethod"
    | "byOrderSource"
    | "byFilterSegments"
    | "top-products-cancelled-orders"
    | "top-categories-cancelled-orders"
    | "top-brands-cancelled-orders";
  updateProductSku?: (productSku: string) => void;
  updatePageTitle?: (pageTitle: string) => void;
  withNavigation?: boolean;
  loading?: boolean;
  expanded?: boolean;
}
class TableView extends React.Component<
  TableViewProps & WithTranslation,
  never
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  getTableHeaders() {
    let headers = [];
    switch (this.props.type) {
      case "products-analytics":
      case "promotions-recommendation":
      case "bundle-recommendation":
        headers = ["product", "unitsSold", "totalSalesValue"];
        if (this.props.type === "bundle-recommendation") {
          headers.push("bundleWith");
        } else if (this.props.type === "products-analytics") {
          headers.push("currentStock");
        }
        break;
      case "abandoned-carts":
        headers = ["product", "abandonedCarts"];
        break;
      case "promocodes":
      case "allPromoCodes":
        headers = ["name", "launchDate", "expiryDate", "numberOfTimesUsed"];
        if (this.props.type === "allPromoCodes") {
          headers.push("totalDiscount", "totalRevenue");
        }
        break;
      case "pages-reports":
        headers = ["page", "pageLink", "averageEngagement", "totalPageViews"];
        break;
      case "session-list":
        headers = [
          "source/meduium",
          "landingPage",
          "averageEngagement",
          "totalPageViews",
        ];
        break;
      case "categories-sales-list":
      case "brands-items-sales-list":
      case "categories-children-sales-list":
      case "brands-sales-list":
      case "byCountry":
      case "byCity":
      case "byArea":
        headers = ["totalSalesValue", "unitsSold"];
        if (this.props.type === "brands-sales-list") {
          headers.unshift("brand");
        } else if (this.props.type === "categories-sales-list") {
          headers.unshift("category");
        } else if (this.props.type === "categories-children-sales-list") {
          headers.unshift("subCategory");
          headers.push("products");
        } else if (this.props.type === "brands-items-sales-list") {
          headers.unshift("product");
          headers.push("stock");
        } else if (this.props.type === "byCountry") {
          headers.unshift("country");
        } else if (this.props.type === "byCity") {
          headers.unshift("city");
        } else if (this.props.type === "byArea") {
          headers.unshift("area");
        }
        break;

      case "byPaymentMethod":
      case "byOrderSource":
      case "byFilterSegments":
        headers = ["cancellationPercentage"];
        if (this.props.type === "byPaymentMethod") {
          headers.unshift("paymentMethod");
        } else if (this.props.type === "byOrderSource") {
          headers.unshift("orderSource");
        } else if (this.props.type === "byFilterSegments") {
          headers.unshift("filterSegment");
        }
        if (this.props.expanded) {
          headers.push("numberOfOrders");
        }
        break;

      case "top-products-cancelled-orders":
      case "top-categories-cancelled-orders":
      case "top-brands-cancelled-orders":
        headers = ["nrOfCancelledOrders"];
        if (this.props.type === "top-products-cancelled-orders") {
          headers.unshift("product");
        } else if (this.props.type === "top-categories-cancelled-orders") {
          headers.unshift("category");
        } else if (this.props.type === "top-brands-cancelled-orders") {
          headers.unshift("brand");
        }
        break;

      default:
        headers = [];
    }
    return headers;
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div
        role="table"
        className={`table ${this.props.loading ? "table--loading" : ""}`}
      >
        <div
          role="row"
          className={`table-headers ${
            this.props.withNavigation ? "with-navigation" : ""
          }`}
        >
          {this.getTableHeaders().map((header, index) => (
            <div role="columnheader" className="table-head" key={index}>
              {t(header)}
            </div>
          ))}
        </div>
        <div role="rowgroup" className="table-body">
          <ul>
            {(this.props.type === "abandoned-carts" ||
              this.props.type === "products-analytics" ||
              this.props.type === "bundle-recommendation" ||
              this.props.type === "promotions-recommendation") &&
              this.props.products.map((item) => {
                return (
                  <li className="table-row" key={item.name}>
                    <div role="cell" className="item-image">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt={item.name}
                          onError={(e) => {
                            showDefaultImage(e);
                          }}
                        />
                      ) : (
                        <img src={defaultImg} alt={item.name} />
                      )}
                      <div role="cell" className="item-info">
                        <span className="item-name">
                          {item.name || t("unnamed")}
                        </span>
                        {item.sku && (
                          <span className="item-sku">{`sku: ${item.sku}`}</span>
                        )}
                        <span className="tooltip">
                          {item.name || t("unnamed")}
                        </span>
                      </div>
                    </div>
                    <div className="item-data">
                      {(this.props.type === "products-analytics" ||
                        this.props.type === "bundle-recommendation" ||
                        this.props.type === "promotions-recommendation") && (
                        <>
                          <span role="cell" className="item-sold">
                            {`${getNumberReadableValue(item.number_sold)} ${t(
                              "sold"
                            )}`}
                          </span>
                          <span role="cell" className="item-sales-value">
                            {`${getNumberReadableValue(item.sales_value)} ${
                              this.context.settings.currency
                            }`}
                          </span>
                        </>
                      )}
                      {this.props.type === "products-analytics" && (
                        <>
                          <span role="cell" className="item-current-stock">
                            {`${getNumberReadableValue(item.stock)} ${t(
                              "inStock"
                            )}`}
                          </span>
                        </>
                      )}
                      {this.props.type === "abandoned-carts" && (
                        <>
                          <span role="cell" className="abandoned-carts-number">
                            {/* TODO add abandoned carts number */}
                            {`${getNumberReadableValue(item.frequency)}`}
                          </span>
                        </>
                      )}
                      {this.props.type === "bundle-recommendation" && (
                        <span
                          role="cell"
                          className="item-more-details item-bundle"
                        >
                          <button
                            type="submit"
                            onClick={
                              () =>
                                this.props.updateProductSku(
                                  item.bundle_product_sku
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            {item.bundle_product_name}
                            {/* <Arrow className="right-arrow" /> */}
                          </button>
                          <span className="tooltip">
                            {item.bundle_product_name}
                          </span>
                        </span>
                      )}
                    </div>
                    <span role="cell" className="item-more-details">
                      <button
                        type="submit"
                        onClick={() => this.props.updateProductSku(item.sku)}
                      >
                        {t("viewProductDetails")}
                        <Arrow className="right-arrow" />
                      </button>
                    </span>
                  </li>
                );
              })}

            {this.props.type === "promocodes" &&
              this.props.promocodes.map((promocode) => {
                return (
                  <li className="table-row" key={promocode.coupon_code}>
                    <div role="cell" className="promocode-name">
                      <p>{promocode.coupon_code}</p>
                      <span className="tooltip">{promocode.coupon_code}</span>
                      <div
                        className={`active-status ${
                          promocode.is_active || promocode.is_active === null
                            ? "active"
                            : "inactive"
                        }`}
                      >
                        {promocode.is_active || promocode.is_active === null
                          ? t("active")
                          : t("inactive")}
                      </div>
                    </div>
                    <div role="cell" className="launch-date">
                      {promocode.from_date
                        ? new Date(promocode.from_date).toLocaleDateString(
                            i18next.language === "en" ? "en-US" : "ar-EG",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </div>
                    <div role="cell" className="expiry-date">
                      {promocode.to_date
                        ? new Date(promocode.to_date).toLocaleDateString(
                            i18next.language === "en" ? "en-US" : "ar-EG",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </div>
                    <div role="cell" className="number-used">
                      {`${getNumberReadableValue(promocode.number_used)} ${t(
                        "times"
                      )}`}
                    </div>
                  </li>
                );
              })}

            {this.props.type === "allPromoCodes" &&
              this.props.promocodes.map((promocode) => {
                return (
                  <li className="table-row" key={promocode.coupon_code}>
                    <div role="cell" className="promocode-name">
                      <p>{promocode.coupon_code}</p>
                      <span className="tooltip">{promocode.coupon_code}</span>
                    </div>
                    <div role="cell" className="launch-date">
                      {promocode.from_date
                        ? new Date(promocode.from_date).toLocaleDateString(
                            i18next.language === "en" ? "en-US" : "ar-EG",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </div>
                    <div role="cell" className="expiry-date">
                      {promocode.to_date
                        ? new Date(promocode.to_date).toLocaleDateString(
                            i18next.language === "en" ? "en-US" : "ar-EG",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </div>
                    <div role="cell" className="number-used">
                      {`${getNumberReadableValue(promocode.number_used)} ${t(
                        "times"
                      )}`}
                    </div>
                    <div role="cell" className="number-used">
                      {`${getNumberReadableValue(promocode.discount)} ${
                        this.context.settings.currency
                      }`}
                    </div>
                    <div role="cell" className="number-used">
                      {`${getNumberReadableValue(promocode.sales_value)} ${
                        this.context.settings.currency
                      }`}
                    </div>
                  </li>
                );
              })}

            {this.props.type === "pages-reports" &&
              this.props.pagesReports.map((pagesReport, index) => {
                return (
                  <li className="table-row" key={index}>
                    <div role="cell" className="item">
                      <p>{pagesReport.pageName}</p>
                    </div>
                    <div role="cell" className="item item-link item-name">
                      <a href={pagesReport.pagePath}>{pagesReport.pagePath}</a>
                      {/* <span className="tooltip">{item.name}</span> */}
                    </div>
                    <div role="cell" className="item">
                      <p>{pagesReport.averageEngagementTime}</p>
                    </div>
                    <div role="cell" className="item">
                      <span role="cell" className="item-sold">
                        {`${getNumberReadableValue(pagesReport.pageViews)} ${t(
                          "views"
                        )}`}
                      </span>
                    </div>

                    <span role="cell" className="item-more-details">
                      <button
                        type="submit"
                        onClick={
                          () => this.props.updatePageTitle(pagesReport.pageName)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        {t("viewPage")}
                        <Arrow className="right-arrow" />
                      </button>
                    </span>
                  </li>
                );
              })}

            {this.props.type === "session-list" &&
              this.props.sessionSourceMediumList.map((session, index) => {
                return (
                  <li className="table-row" key={index}>
                    <div role="cell" className="item">
                      <p>{session.sessionSourceMedium}</p>
                    </div>
                    <div role="cell" className="item item-name item-link">
                      <a href={session.landingPage}>{session.landingPage}</a>
                    </div>
                    <div role="cell" className="item">
                      <p>{session.averageEngagementTime}</p>
                    </div>
                    <div role="cell" className="item">
                      <span role="cell" className="item-sold">
                        {`${getNumberReadableValue(session.pageViews)} ${t(
                          "views"
                        )}`}
                      </span>
                    </div>
                  </li>
                );
              })}
            {(this.props.type === "categories-sales-list" ||
              this.props.type === "brands-items-sales-list" ||
              this.props.type === "categories-children-sales-list" ||
              this.props.type === "brands-sales-list") &&
              this.props.products.map((item) => {
                return (
                  <li
                    className={`table-row ${
                      this.props.withNavigation ? "with-navigation" : ""
                    }`}
                    key={item.name || t("unnamed")}
                  >
                    <div role="cell" className="item-image">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt={item.name || t("unnamed")}
                          onError={(e) => {
                            showDefaultImage(e);
                          }}
                        />
                      ) : (
                        <img src={defaultImg} alt={item.name || t("unnamed")} />
                      )}
                      <div role="cell" className="item-info">
                        <span className="item-name">
                          {item.name || t("unnamed")}
                        </span>
                        {item.sku && (
                          <span className="item-sku">{`sku: ${item.sku}`}</span>
                        )}
                        <span className="tooltip">
                          {item.name || t("unnamed")}
                        </span>
                      </div>
                    </div>
                    <span role="cell" className="item-sales-value">
                      {`${getNumberReadableValue(item.sales_value)} ${
                        this.context.settings.currency
                      }`}
                    </span>
                    <span role="cell" className="item-sold">
                      {`${getNumberReadableValue(item.number_sold)} ${t(
                        "sold"
                      )}`}
                    </span>
                    {this.props.type === "categories-children-sales-list" && (
                      <span role="cell" className="item-sold">
                        {`${getNumberReadableValue(
                          item.unique_products_sold
                        )} ${t("items", { ns: "stock" })}`}
                      </span>
                    )}
                    {this.props.type === "brands-items-sales-list" && (
                      <span role="cell" className="item-sold">
                        {item.stock
                          ? `${getNumberReadableValue(item.stock)} ${t(
                              "units"
                            )}`
                          : t("outOfStock", { ns: "stock" })}
                      </span>
                    )}
                    {this.props.withNavigation && (
                      <span role="cell" className="item-more-details">
                        <button
                          type="submit"
                          onClick={() => {
                            return this.props.updateProductSku(item.name);
                          }}
                        >
                          <Arrow className="right-arrow" />
                        </button>
                      </span>
                    )}
                  </li>
                );
              })}

            {(this.props.type === "byCountry" ||
              this.props.type === "byCity" ||
              this.props.type === "byArea") &&
              this.props.products?.map((item, index) => {
                return (
                  <li className="table-row" key={index}>
                    <div role="cell" className="item">
                      {this.props.type === "byCountry"
                        ? item.country
                        : this.props.type === "byCity"
                        ? item.city
                        : item.area}
                    </div>
                    <div role="cell" className="item-sales-value">
                      {`${getNumberReadableValue(item.sales_value)} ${
                        this.context.settings.currency
                      }`}
                    </div>
                    <div role="cell" className="item-sold">
                      {`${getNumberReadableValue(item.count)} ${t("sold")}`}
                    </div>
                  </li>
                );
              })}

            {["byPaymentMethod", "byOrderSource", "byFilterSegments"].includes(
              this.props.type
            ) &&
              this.props.products?.map((item, index) => {
                return (
                  <li className="table-row" key={index}>
                    <div role="cell" className="item">
                      {this.props.type === "byPaymentMethod"
                        ? item.payment_method
                        : this.props.type === "byOrderSource"
                        ? item.order_source
                        : item.title}
                    </div>
                    <div role="cell" className="cancellation-percentage">
                      <span>{`${item.cancelation_percentage || "0"} %`}</span>
                    </div>
                    {this.props.expanded && (
                      <div role="cell" className="number-of-orders">
                        <span>
                          {`${
                            getNumberReadableValue(
                              item.canceled_orders_count
                            ) || "0"
                          }`}
                        </span>
                      </div>
                    )}
                  </li>
                );
              })}

            {[
              "top-products-cancelled-orders",
              "top-brands-cancelled-orders",
              "top-categories-cancelled-orders",
            ].includes(this.props.type) &&
              this.props.products?.map((item, index) => {
                return (
                  <li className="table-row" key={index}>
                    <div role="cell" className="item-image">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt={item.name || t("unnamed")}
                          onError={(e) => {
                            showDefaultImage(e);
                          }}
                        />
                      ) : (
                        <img src={defaultImg} alt={item.name || t("unnamed")} />
                      )}
                      <div role="cell" className="item-info">
                        <span className="item-name">
                          {item.name || t("unnamed")}
                        </span>
                        <span className="tooltip">
                          {item.name || t("unnamed")}
                        </span>
                      </div>
                    </div>
                    <div role="cell" className="cancellation-percentage">
                      <span>{`${item.canceled_orders_count}`}</span>
                    </div>
                    <span role="cell" className="item-more-details">
                      <button
                        type="submit"
                        onClick={() => {
                          if (
                            this.props.type === "top-products-cancelled-orders"
                          ) {
                            return this.props.updateProductSku(item.sku);
                          }
                          return this.props.updateProductSku(item.name);
                        }}
                      >
                        <Arrow className="right-arrow" />
                      </button>
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
        {this.props.loading && <SectionLoader />}
      </div>
    );
  }
}

TableView.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["common", "product", "stock", "orders"])(
  TableView
);
