/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description implementation of category related API calls.
 * @filename category.ts
 */
import { PaginationPayload, Payload } from "interfaces/payload";
import {
  ChildrenRequestPayload,
  CategoryChildrenSalesData,
} from "interfaces/category";
import {
  ExportedSalesValues,
  GenericRequestPayload,
  ItemsSales,
  PartialBy,
  SortByDirection,
} from "interfaces/overview";
import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Category {
  public static CategoriesPercentages({
    project,
    from,
    to,
    sort = "sales_value",
    filters = {},
  }: PartialBy<GenericRequestPayload, "currentPage">): Promise<
    PaginationPayload<ItemsSales[]>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.categories.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sort", sort);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.categories.method,
    });
  }

  public static categoriesSales({
    project,
    from,
    to,
    search = "",
    sort = "sales_value",
    direction = SortByDirection.Desc,
    currentPage = 1,
    pageSize = 10,
    filters = {},
  }: GenericRequestPayload): Promise<PaginationPayload<ItemsSales[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.categoriesSales.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("search", search);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.categoriesSales.method,
    });
  }

  public static categoriesSalesChildren({
    project,
    from,
    to,
    parentName,
    sort = "sales_value",
    direction = SortByDirection.Desc,
    search = "",
    currentPage = 1,
    pageSize = 10,
    filters = {},
  }: ChildrenRequestPayload): Promise<
    PaginationPayload<CategoryChildrenSalesData>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.categoriesSalesChildren.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("parentName", parentName);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("search", search);
    url.searchParams.append("sort", sort);
    url.searchParams.append("direction", direction);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.categoriesSalesChildren.method,
    });
  }

  public static categoriesSalesExport({
    project,
    from,
    to,
    sort = "sales_value",
    filters = {},
  }: PartialBy<GenericRequestPayload, "currentPage">): Promise<
    Payload<ExportedSalesValues[]>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.categoriesSalesExport.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sort", sort);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.categoriesSalesExport.method,
    });
  }
}
