import React from "react";
import CloseIcon from "static/images/close.svg";

interface ModalProps {
  handleClose: () => void;
  showModal: boolean;
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  sideModal?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  handleClose,
  showModal,
  children,
  sideModal,
}) => {
  const showHideClassName = showModal
    ? "modal display-block"
    : "modal display-none";

  const showSideModal = sideModal && "modal--side";

  return (
    <div className={`${showHideClassName} ${showSideModal}`}>
      <section className="modal-main">
        {children}
        <button
          type="button"
          className="button--clear modal__close-button"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </section>
    </div>
  );
};
