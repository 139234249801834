/**
 * @author Ahmed Serag
 * @date 2021-07-01
 * @description declaration of orders details interface.
 * @filename orders details.ts
 */

import { ItemsSales } from "./overview";

export interface OrdersOverview {
  complete_order_count: number;
  pending_order_count: number;
  canceled_order_count: number;
  avg_value_per_order: number;
  pickup: number;
  delivery: number;
  totalOrders?: number;
  totalOrdersDelivery?: number;
  avg_time_between_orders: number;
  avg_basket_size: number;
}

export interface OrdersHourlyBreakdown {
  Monday: number[];
  Tuesday: number[];
  Wednesday: number[];
  Thursday: number[];
  Friday: number[];
  Saturday: number[];
  Sunday: number[];
}
export enum WeekDays {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAYS = "Saturday",
}

export interface CancelledOrdersPercentage {
  canceled_orders_percentage: number;
  canceled_orders_count: number;
}

export interface Product extends ItemsSales {
  url: string;
}

export interface CancellationPercentage {
  cancelation_percentage: string;
}

export interface ByPaymentMethodPercentage extends CancellationPercentage {
  payment_method: string;
}

export interface ByOrderSourcePercentage extends CancellationPercentage {
  order_source: string;
}

export interface ByFilterSegmentPercentage extends CancellationPercentage {
  filter_segment: string;
}
