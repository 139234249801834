/**
 * @author Sarah Nouh
 * @date 2022-01-04
 * @description settings payment and subscriptions tab content
 * @filename payments-and-subscriptiontsx
 */

import React from "react";
import { differenceInWeeks } from "date-fns";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { RouteComponentProps } from "react-router-dom";
import Edit from "static/images/edit.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getCards, getStripeBalance, unsubscribe } from "utilities/settings";
import { PaymentCardInterface, StripeBalance } from "interfaces/settings";
import i18next from "i18next";
import Loader from "common/Loader";
import { PUBLIC_WEBSITE_PLANS } from "consts/plans";
import PaymentMethodForm from "./payment-method-form";
import SubscriptionPlans from "./plans";
import PaymentMethod from "./payment-method";

interface PaymentSettingsState {
  addPaymentMethod?: boolean;
  changePlan?: boolean;
  loading: boolean;
  otherCards: PaymentCardInterface[];
  defaultCard?: PaymentCardInterface;
  stripeBalance?: StripeBalance;
}

// subscription plans to know their order
const subscriptionPlans = [
  "unsubscribed",
  "trial",
  "starter",
  "pro",
  "premium",
] as const;
class PaymentsAndSubscriptions extends React.Component<
  RouteComponentProps & WithTranslation,
  PaymentSettingsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  timeout: ReturnType<typeof setTimeout>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {
      addPaymentMethod: false,
      changePlan: false,
      loading: true,
      otherCards: [],
    };

    this.loadSubscriptionData = this.loadSubscriptionData.bind(this);
    this.paymentMethodChangeHandler =
      this.paymentMethodChangeHandler.bind(this);
    this.toggleChangePlan = this.toggleChangePlan.bind(this);
    this.toggleAddPaymentMethod = this.toggleAddPaymentMethod.bind(this);
  }

  componentDidMount() {
    /* because of the latency that happens between server to server responses, the updated data
      is not saved in the server by the time we are redirected from the payment service
      so we have to wait to ensure that the data is updated in the backend */
    this.timeout = setTimeout(this.loadSubscriptionData, 3500);
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const wantedPlan = params.get("plan");
    const addingCardStatus = params.get("success");
    if (
      wantedPlan &&
      /*
        The next line we had to set the queryPlan type to never because typescript throws
        an error because the argument of the includes method is a string and the array is
        readonly of a subset of the string. This is not logical because it will return
        false if it's not included, so we set the type to never to bypass it.
       */
      PUBLIC_WEBSITE_PLANS.includes(wantedPlan as never) &&
      !addingCardStatus
    ) {
      this.setState({ addPaymentMethod: true });
    } else if (addingCardStatus === "0") {
      toast.error("Card has been declined");
    } else if (addingCardStatus === "1") {
      toast.success("Card has been added successfully");
      /* since the waiting time from the payment service is not predicted or consistent
       so we check if the data is updated until we get the confirmation
       we need this update for the renewal date which is mainly changed when we add
       a credit card for the first time */
      if (
        this.context.settings.subscription_tier === "trial" &&
        !this.state.defaultCard
      ) {
        const interval = setInterval(() => {
          this.loadSubscriptionData();
          if (
            differenceInWeeks(
              new Date(this.context.settings.trial_ends_at),
              new Date(this.context.settings.created_at)
            ) >= 6
          ) {
            clearInterval(interval);
          }
        }, 6000);
      }
    }
    // check if we are redirected from exceeding orders limit modal
    if (this.context.showingExceedingOrdersLimitModal) {
      this.toggleChangePlan();
      this.context.hideExceedingOrdersLimitModal();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  // eslint-disable-next-line class-methods-use-this
  handleUnsubscribe() {
    unsubscribe()
      .then(() => {
        toast.success("You have successfully unsubscribed");
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }

  /**
   * return tier name according to subscription plan
   * if subscription tier is "trial" then automatically it's a "2 week trial"
   * but if the user adds their credit card it automatically becomes "6 week trial"
   * @returns tier name
   */
  getTier() {
    if (this.context.settings.subscription_tier) {
      return this.context.settings.subscription_tier.toUpperCase();
    }
    if (
      differenceInWeeks(
        new Date(this.context.settings.trial_ends_at),
        new Date(this.context.settings.created_at)
      ) >= 6
    ) {
      return "6 Weeks Free Trial";
    }

    return "2 Weeks Free Trial";
  }

  /**
   * return the renewal date in the desired format
   * @returns renewal date according to the application's language
   */
  getRenewalDate(t) {
    // if a user upgrades within a trial period
    if (
      this.context.settings.subscription_tier === "trial" &&
      this.context.settings.upcoming_tier !== "unsubscribed"
    ) {
      return `${t("trialEndsAt")} ${new Date(
        this.context.settings.trial_ends_at
      ).toLocaleDateString(i18next.language === "en" ? "en-us" : "ar-eg", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })} ${t("andYouWillBeAutomaticallyUpgradedTo")} ${
        this.context.settings.upcoming_tier
      }`;
    }
    // if the user cancels the subscription, whether in trial or not
    if (this.context.settings.upcoming_tier === "unsubscribed") {
      return `${t("yourPlanWillBeCancelledOn")} ${new Date(
        this.context.settings.expiry || this.context.settings.trial_ends_at
      ).toLocaleDateString(i18next.language === "en" ? "en-us" : "ar-eg", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`;
    }
    // if a user downgrades their subscription package from a larger package to a smaller package
    if (
      subscriptionPlans.indexOf(this.context.settings.subscription_tier) >
      subscriptionPlans.indexOf(this.context.settings.upcoming_tier)
    ) {
      return `${t("yourRenewalDateWillBeOn")} ${new Date(
        this.context.settings.expiry || this.context.settings.trial_ends_at
      ).toLocaleDateString(i18next.language === "en" ? "en-us" : "ar-eg", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })} ${t("andYouWillBeAutomaticallyDowngradedTo")} ${
        this.context.settings.upcoming_tier
      }`;
    }
    return `${t("yourRenewalDateWillBeOn")} ${new Date(
      this.context.settings.expiry
    ).toLocaleDateString(i18next.language === "en" ? "en-us" : "ar-eg", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })}`;
  }

  loadSubscriptionData() {
    this.setState({ loading: true });
    this.context?.loadSettings();
    getCards()
      .then((cards: PaymentCardInterface[]) => {
        if (cards.length) {
          this.setState({
            otherCards: cards.filter((c) => !c.is_default),
            defaultCard: cards.find((c) => c.is_default),
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
    getStripeBalance().then((stripeBalance) => {
      this.setState({
        stripeBalance,
      });
    });
  }

  paymentMethodChangeHandler() {
    this.setState({
      defaultCard: null,
      otherCards: [],
    });
    this.loadSubscriptionData();
  }

  toggleChangePlan() {
    this.setState({
      changePlan: !this.state.changePlan,
    });
  }

  toggleAddPaymentMethod() {
    this.setState({
      addPaymentMethod: !this.state.addPaymentMethod,
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        {!this.state.addPaymentMethod && !this.state.changePlan && (
          <div className="payment">
            <div className="card">
              <h1 className="card--title">{t("paymentsSubscriptions")}</h1>
              <div className="payment-card">
                <p className="payment-card__subtitle">{t("currentPlan")}</p>
                <div className="payment-card__plan">
                  <div>
                    <p className="font-bold">{this.getTier()}</p>
                    {/* TODO: add payment renewal date */}
                    <span className="payment-card__data">
                      {this.getRenewalDate(t)}
                    </span>
                    {this.state.stripeBalance?.balance ? (
                      <p className="payment-card__data">
                        {`${t("yourCurrentBalanceIs")} ${
                          this.state.stripeBalance?.balance
                        } ${this.state.stripeBalance?.currency} `}
                        <small className="text-grey block">
                          {t("balanceDisclaimer")}
                        </small>
                      </p>
                    ) : null}
                  </div>
                  <div className="payment-card__options">
                    {this.context.settings.subscription_tier !==
                      "unsubscribed" &&
                    !this.context.settings.is_old_subscription ? (
                      <a
                        className="external-link"
                        href={`${
                          process.env.STRIPE_CUSTOMER_PORTAL_URL
                        }?prefilled_email=${encodeURIComponent(
                          this.context.user.email
                        )}`}
                        rel="noreferrer"
                      >
                        {t("manageYourSubscription")}
                      </a>
                    ) : (
                      <button
                        type="button"
                        className="edit"
                        onClick={() => this.setState({ changePlan: true })}
                      >
                        <Edit /> {t("changePlan")}
                      </button>
                    )}
                    {/* show the "cancel subscription" button only if
                     user is not on trial and the upcoming tier is not "unsubscribed" */}
                    {this.context.settings.subscription_tier !== "trial" &&
                    this.context.settings.upcoming_tier !== "unsubscribed" &&
                    this.context.settings.is_old_subscription ? (
                      <button
                        type="button"
                        className="cancel-subscription"
                        onClick={this.handleUnsubscribe}
                      >
                        {t("cancelSubscription")}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>

              {this.context.settings.is_old_subscription ? (
                <div className="payment-details">
                  <h1 className="card--title">{t("paymentDetails")}</h1>

                  {this.state.defaultCard && (
                    <>
                      <p className="payment-details__subtitle">
                        {t("defaultPaymentMethod")}
                      </p>
                      <PaymentMethod
                        id={String(this.state.defaultCard.id)}
                        cardEndingWith={this.state.defaultCard.card_number}
                        expiryDate={this.state.defaultCard.expiry_date}
                        cardType={this.state.defaultCard.payment_option}
                        default
                        onFinish={() => this.paymentMethodChangeHandler()}
                      />
                    </>
                  )}

                  {this.state.otherCards.length > 0 && (
                    <p className="payment-details__subtitle">
                      {t("otherPaymentMethods")}
                    </p>
                  )}

                  {this.state.otherCards.length > 0 &&
                    this.state.otherCards.map((c) => {
                      return (
                        <PaymentMethod
                          key={c.id}
                          id={String(c.id)}
                          cardEndingWith={c.card_number}
                          expiryDate={c.expiry_date}
                          cardType={c.payment_option}
                          onFinish={() => this.paymentMethodChangeHandler()}
                        />
                      );
                    })}

                  <button
                    type="button"
                    className="button-outline my-10"
                    onClick={() => this.setState({ addPaymentMethod: true })}
                  >
                    + {t("addPaymentMethod")}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {this.state.addPaymentMethod && (
          <PaymentMethodForm
            onFinish={() => {
              this.setState({ addPaymentMethod: false });
            }}
          />
        )}
        {this.state.changePlan && (
          <SubscriptionPlans
            toggleChangePlan={this.toggleChangePlan}
            toggleAddPaymentMethod={this.toggleAddPaymentMethod}
            defaultCard={this.state.defaultCard}
          />
        )}
      </>
    );
  }
}
PaymentsAndSubscriptions.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(PaymentsAndSubscriptions);
