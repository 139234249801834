/**
 * @author Youssef Tarek
 * @date 2022-10-11
 * @description ecommerce platform integration
 * @filename index.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getProjectKeys } from "utilities/settings";
import CopyLink from "static/images/copy.svg";
import { TFunction } from "i18next";
import Loader from "../../../common/Loader";

interface IntegrationKeysSettingsState {
  loading: boolean;
  id: string;
  apiKey?: string;
  encryptionKey?: string;
  isApiKeyCopied: boolean;
  isEncryptionKeyCopied: boolean;
}

class IntegrationKeysSettings extends React.Component<
  RouteComponentProps & WithTranslation,
  IntegrationKeysSettingsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      id: "project-keys",
      isApiKeyCopied: false,
      isEncryptionKeyCopied: false,
    };
  }

  componentDidMount() {
    this.loadData();
    this.context.hideIntegrationKeysModal();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  /**
   * add the respective steps for integrating each platform with onesight
   * @param platform third party platform
   * @param t
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  getPlatformSteps(platform: string, t: TFunction) {
    switch (platform) {
      case "magento":
        return (
          <>
            <p>
              {`${t("downloadMagentoExtension")}: `}
              <a
                className="external-link"
                href="https://marketplace.magento.com/robusta-studio-analytics.html"
                target="_blank"
                rel="noreferrer"
              >
                Onesight Analytics
              </a>
            </p>
            <ol className="settings-integration-keys__sub-list">
              <li className="settings-integration-keys__sub-list-item">
                {t("goToMagentoAdminPanel")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("goToStores")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("goToConfiguration")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("goToRobustaAnalytics")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("pasteYourKeys")}
              </li>
            </ol>
          </>
        );
      case "woocommerce":
        return (
          <>
            <p>
              {`${t("downloadWoocommerceExtension")}: `}
              <a
                className="external-link"
                href="https://wordpress.org/plugins/onesight-integration-for-woocommerce/"
                target="_blank"
                rel="noreferrer"
              >
                Onesight Integration for WooCommerce
              </a>
            </p>
            <ol className="settings-integration-keys__sub-list">
              <li className="settings-integration-keys__sub-list-item">
                {t("goToWordpressAdminPanel")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("goToPlugins")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("selectAddNew")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("installOnesight")}
              </li>
              <li className="settings-integration-keys__sub-list-item">
                {t("pasteYourKeys")}
              </li>
            </ol>
          </>
        );
      default:
        return null;
    }
  }

  loadData() {
    getProjectKeys(this.context?.project).then((response) => {
      this.setState({
        apiKey: response.api_key,
        encryptionKey: response.encryption_key,
        loading: false,
      });
    });
  }

  /**
   *
   * @param text the keys to be put in the clipboard
   * @param copiedInput this indicates the state to show which key was copied
   * @param timeout to show that key has been copied for 2 seconds
   */
  copyTextToClipboard(text: string, copiedInput: string, timeout = 2000) {
    this.setState({ [copiedInput]: true });
    navigator.clipboard.writeText(text);

    setTimeout(() => {
      this.setState({ [copiedInput]: false });
    }, timeout);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="settings">
        <div className="card">
          <div className="settings-integration-keys">
            <h2 className="card--title">{t("integrationKeys")}</h2>
            <p className="note">{t("integrationKeysDescription")}</p>
            <form className="input-wrapper">
              <label htmlFor="apiKey">{t("apiIntegrationKey")}</label>
              <div className="input-with-copy">
                <input
                  type="password"
                  name="apiKey"
                  id="apiKey"
                  value={this.state.apiKey}
                  readOnly
                />
                <button
                  type="button"
                  className="button--clear"
                  onClick={() => {
                    this.copyTextToClipboard(
                      this.state.apiKey,
                      "isApiKeyCopied"
                    );
                  }}
                >
                  <CopyLink />
                  {this.state.isApiKeyCopied ? t("copied") : t("copy")}
                </button>
              </div>
            </form>
            <div className="input-wrapper">
              <label htmlFor="encryptionKey">
                {t("encryptionIntegrationKey")}
              </label>
              <div className="input-with-copy">
                <input
                  type="password"
                  name="encryptionKey"
                  id="encryptionKey"
                  value={this.state.encryptionKey}
                  readOnly
                />
                <button
                  type="button"
                  className="button--clear"
                  onClick={() => {
                    this.copyTextToClipboard(
                      this.state.encryptionKey,
                      "isEncryptionKeyCopied"
                    );
                  }}
                >
                  <CopyLink />
                  {this.state.isEncryptionKeyCopied ? t("copied") : t("copy")}
                </button>
              </div>
            </div>
            <div className="settings-integration-keys__steps">
              {this.getPlatformSteps(this.context?.settings?.platform, t)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
IntegrationKeysSettings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(IntegrationKeysSettings);
