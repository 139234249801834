/**
 * @author Youssef Tarek
 * @date 2021-12-04
 * @description Account information of the user
 * @filename account-information.tsx
 */
import { Formik, Form, FormikHelpers, Field } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { object as YUPObject, string as YUPString } from "yup";
import { updatePassword as updatePasswordUtility } from "utilities/settings";
import { YUPPasswordValidation } from "utilities/authenticator";

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import PasswordInput from "../../../common/password-input";

class EditPassword extends React.Component<
  WithTranslation & {
    onFinish: () => void;
  }
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: WithTranslation & { onFinish: () => void }) {
    super(props);
    this.updateAccountPassword = this.updateAccountPassword.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  updateAccountPassword(
    values: {
      currentPassword: string;
      newPassword: string;
    },
    formikHelpers: FormikHelpers<{
      currentPassword: string;
      newPassword: string;
    }>
  ) {
    if (values.currentPassword === values.newPassword) {
      formikHelpers.setSubmitting(false);
      return;
    }
    // TODO: Update account name
    formikHelpers.setSubmitting(false);
    updatePasswordUtility(values.currentPassword, values.newPassword)
      .then(() => {
        toast.success("Password updated successfully");
        formikHelpers.setSubmitting(false);
        this.props.onFinish();
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="account-settings">
        <div className="card">
          <h2 className="card--title">{t("editPassword")}</h2>
          <Formik
            onSubmit={this.updateAccountPassword}
            initialValues={{
              currentPassword: "",
              newPassword: "",
            }}
            validationSchema={YUPObject().shape({
              currentPassword: YUPString().required(
                "Current password is required"
              ),
              newPassword: YUPPasswordValidation.required(
                "Password is required"
              ),
              confirmPassword: Yup.string()
                .required("Confirm password is required")
                .oneOf(
                  [Yup.ref("newPassword")],
                  "password and confirm password do not match"
                ),
            })}
          >
            {(formikBag) => (
              <Form className="card--section">
                <Field name="currentPassword">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="currentPassword">
                        {t("currentPassword")}
                      </label>
                      <PasswordInput
                        placeholder="Enter your current password"
                        name="currentPassword"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="newPassword">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="newPassword">{t("password")}</label>
                      <PasswordInput
                        placeholder={t("passwordPlaceholder")}
                        name="newPassword"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="confirmPassword">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="confirmPassword">
                        {t("confirmPassword")}
                      </label>
                      <PasswordInput
                        placeholder={t("confirmPasswordPlaceholder")}
                        name="confirmPassword"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <div className="action-buttons">
                  <button
                    type="submit"
                    className="button save"
                    disabled={formikBag.isSubmitting}
                  >
                    {t("saveChanges")}
                  </button>
                  <button
                    className="button-outline"
                    type="button"
                    onClick={() => this.props.onFinish()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

EditPassword.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(EditPassword);
