/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description login page.
 * @filename index.tsx
 */
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ref, string as YUPString, object as YUPObject } from "yup";
import { toast } from "react-toastify";
import { Authenticator } from "utilities/authenticator";
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import SideImage from "static/images/illustrations/Component_6.png";
import { Field, Form, Formik } from "formik";
import Input from "../../common/Input";
import SectionLoader from "../../common/section-loader";

interface ForgotPasswordState {
  email: string;
  showOTP: boolean;
  otpValue: string;
  enableSubmit: boolean;
  showResetPasswordForm: boolean;
  newPassword: string;
  confirmPassword: string;
  isSendingOtp: boolean;
}

export class ForgotPassword extends React.Component<
  RouteComponentProps,
  ForgotPasswordState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  declare inputRefs: React.RefObject<HTMLInputElement>[];

  constructor(props) {
    super(props);
    // input refs used for the 6 otp number values we have
    // needed to toggle focus between the inputs
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];

    this.state = {
      email: "",
      showOTP: false,
      otpValue: "",
      enableSubmit: false,
      showResetPasswordForm: false,
      isSendingOtp: false,
      newPassword: null,
      confirmPassword: null,
    };

    this.sendResetPasswordRequest = this.sendResetPasswordRequest.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendOtp = this.handleSendOtp.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.sendResetPasswordRequest = this.sendResetPasswordRequest.bind(this);
  }

  /**
   * handles updating state with otp value
   * and toggling inputs focus
   * @param event input change event
   * @param itemIndex index of the input that changed
   */
  handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    itemIndex: number
  ) {
    let newValue = this.state.otpValue;
    newValue =
      newValue.slice(0, itemIndex) +
      event.target.value +
      newValue.slice(itemIndex + 1);

    this.setState({
      otpValue: newValue,
    });
    // if theres a next input to focus on -> focus on it
    if (itemIndex + 1 < 6 && event.target.value) {
      this.inputRefs[itemIndex].current.blur();
      this.inputRefs[itemIndex + 1].current.focus();
    } else {
      // else otp if fully entered -> enable submit
      this.setState({
        enableSubmit: true,
      });
    }
  }

  /**
   * handles submitting the otp entered by the user to validate it
   * @param event form submit event
   */
  handleSendOtp(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({ isSendingOtp: true });
    Authenticator.sendOtp(this.state.email, this.state.otpValue)
      .then((result) => {
        toast.success(result.message);
        this.setState({
          showResetPasswordForm: true,
          showOTP: false,
        });
      })
      .catch((error) => {
        const errorsArr = Array.isArray(error) ? error : [error];
        errorsArr.forEach((err) => toast.error(err));
        return Promise.reject();
      })
      .finally(() => {
        this.setState({ isSendingOtp: false });
      });
  }

  /**
   * handles submitting the new password entered by the user to reset it
   */
  handleResetPassword() {
    return Authenticator.resetPassword(
      this.state.email,
      this.state.newPassword,
      this.state.otpValue
    )
      .then((result) => {
        toast.success(result.message);
        this.props.history.push(ROUTES.Login.path);
        return Promise.resolve(result);
      })
      .catch((error) => {
        const errorsArr = Array.isArray(error) ? error : [error];
        errorsArr.forEach((err) => toast.error(err));
        return Promise.reject();
      });
  }

  /**
   * handles the submit event of the  requesting reset password form
   * and toggles the view to show the otp form
   * @param event form submit event
   */
  sendResetPasswordRequest() {
    return Authenticator.requestResetPassword(this.state.email)
      .then((result) => {
        toast.success(result.message);
        this.setState({
          showOTP: true,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        const errorsArr = Array.isArray(error) ? error : [error];
        errorsArr.forEach((err) => toast.error(err));
        return Promise.reject();
      });
  }

  render(): React.ReactNode {
    if (this.context.user) {
      return <Redirect to={ROUTES.Overview.path} />;
    }

    return (
      <div className="auth-wrapper">
        <img src={SideImage} alt="" />
        <div className="forgot-password">
          <h1 className="auth__title">Forgot Password</h1>
          {!this.state.showOTP && !this.state.showResetPasswordForm && (
            <Formik
              onSubmit={this.sendResetPasswordRequest}
              initialValues={{
                email: "",
              }}
              validationSchema={YUPObject().shape({
                email: YUPString()
                  .email("Please Input a proper Email")
                  .required("Email is required"),
              })}
              validateOnMount
            >
              {({ isValid, isSubmitting }) => (
                <Form noValidate>
                  <Field name="email">
                    {({ field, meta }) => (
                      <Input
                        label="Email"
                        type="email"
                        id="email"
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                        disabled={isSubmitting}
                        {...field}
                        onChange={(e) => {
                          this.setState({
                            email: e.target.value,
                          });
                          field.onChange(e);
                        }}
                      />
                    )}
                  </Field>
                  <button
                    className="black big square"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    aria-disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <SectionLoader
                        width={30}
                        height={20}
                        color="var(--disabled-button-font-color)"
                      />
                    ) : (
                      <span>Send Email</span>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {this.state.showOTP && (
            <form
              onSubmit={(event) => {
                this.handleSendOtp(event);
              }}
            >
              <div className="input-wrapper">
                <label htmlFor="otp">Enter the OTP received</label>
                <div className="values-container">
                  {[0, 1, 2, 3, 4, 5].map((item) => {
                    return (
                      <input
                        key={item}
                        type="number"
                        ref={this.inputRefs[item]}
                        className="otp-value"
                        onChange={(event) => {
                          this.handleInputChange(event, item);
                        }}
                        max="9"
                      />
                    );
                  })}
                </div>
              </div>
              <button
                className="black big square"
                type="submit"
                disabled={!this.state.enableSubmit || this.state.isSendingOtp}
              >
                {this.state.isSendingOtp ? (
                  <SectionLoader
                    width={30}
                    height={20}
                    color="var(--disabled-button-font-color)"
                  />
                ) : (
                  <span>Send OTP</span>
                )}
              </button>
            </form>
          )}
          {this.state.showResetPasswordForm && (
            <Formik
              onSubmit={this.handleResetPassword}
              initialValues={{
                newPassword: "",
                newPasswordRe: "",
              }}
              validationSchema={YUPObject().shape({
                newPassword: YUPString()
                  .min(
                    8,
                    ({ min }) => `Password must be at least ${min} characters`
                  )
                  .max(
                    255,
                    ({ max }) => `Password can't be more than ${max} characters`
                  )
                  .matches(
                    /^(?=.*[A-Z])(?=.*[!@#$%^&*+=-])(?=.*[0-9])(?=.*[a-z]).+$/g,
                    "Must contain number, lowercase, uppercase, special character"
                  )
                  .required("New password is required"),
                newPasswordRe: YUPString()
                  .min(
                    8,
                    ({ min }) => `Password must be at least ${min} characters`
                  )
                  .max(
                    255,
                    ({ max }) => `Password can't be more than ${max} characters`
                  )
                  .matches(
                    /^(?=.*[A-Z])(?=.*[!@#$%^&*+=-])(?=.*[0-9])(?=.*[a-z]).+$/g,
                    "Must contain number, lowercase, uppercase, special character"
                  )
                  .oneOf([ref("newPassword"), null], "Passwords must match")
                  .required("Confirming password is required."),
              })}
              validateOnMount
            >
              {({ isValid, isSubmitting }) => (
                <Form>
                  <Field name="newPassword">
                    {({ field, meta }) => (
                      <Input
                        label="New Password"
                        type="password"
                        id="new-password"
                        autoComplete="new-password"
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                        disabled={isSubmitting}
                        {...field}
                        onChange={(e) => {
                          this.setState({
                            newPassword: e.target.value,
                          });
                          field.onChange(e);
                        }}
                        required
                      />
                    )}
                  </Field>
                  <Field name="newPasswordRe">
                    {({ field, meta }) => (
                      <Input
                        label="Confirm New Password"
                        type="password"
                        id="new-password-re"
                        autoComplete="new-password"
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                        disabled={isSubmitting}
                        {...field}
                        onChange={(e) => {
                          this.setState({
                            confirmPassword: e.target.value,
                          });
                          field.onChange(e);
                        }}
                        required
                      />
                    )}
                  </Field>
                  <button
                    type="submit"
                    className="black big square"
                    disabled={!isValid || isSubmitting}
                    aria-disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <SectionLoader
                        width={30}
                        height={20}
                        color="var(--disabled-button-font-color)"
                      />
                    ) : (
                      <span>Reset Password</span>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    );
  }
}

ForgotPassword.contextType = ANALYTICS_CONTEXT;
