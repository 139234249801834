/**
 * @author Youssef Tarek
 * @date 2023-01-11
 * @description confirming registration redirection screen
 * @filename confirm-registration.tsx
 */

import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Authenticator } from "utilities/authenticator";
import SideImage from "static/images/illustrations/Component_6.png";
import SectionLoader from "common/section-loader";

interface ConfirmRegistrationState {
  token: string;
  loading: boolean;
}

export class ConfirmRegistration extends React.Component<
  RouteComponentProps,
  ConfirmRegistrationState
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      token: "",
      loading: true,
    };
  }

  componentDidMount(): void {
    this.setState({ loading: true });
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get("token");
    this.setState({ token });
    Authenticator.confirmRegistration(token)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    return (
      <>
        <div className="auth-wrapper">
          {this.state.loading ? (
            <div>
              <div className="text-size-20">
                We&apos;re setting up your playground, this might take up to 30
                seconds, don&apos;t worry!
              </div>
              <div className="auth-wrapper__loader">
                <SectionLoader />
              </div>
            </div>
          ) : (
            <div className="flex-col align-center gap-10 text-size-20">
              Thank you for verifying your email, let&apos;s start playing!
              <button type="button" className="button black big mt-40">
                <div className="button--link">
                  <Link to="/login">Login</Link>
                </div>
              </button>
            </div>
          )}
          <img src={SideImage} className="side-img" alt="" />
        </div>
      </>
    );
  }
}
