/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description start point of the react application that uses
 * react dom to manipulate the root div.
 * @filename index.tsx
 */
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  RouteProps,
  Redirect,
  Link,
} from "react-router-dom";
import { format, startOfMonth } from "date-fns";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Period } from "interfaces/period";
import { Authenticator } from "utilities/authenticator";
import { User as UserInterface } from "interfaces/user";
import {
  mapPeriodToQueryString,
  mapQueryStringToPeriod,
  mapTabsToQueryString,
} from "utilities/urls";
import {
  changeLanguage,
  getOrdersSinceLastSubscription,
  getSettings,
} from "utilities/settings";
import {
  Listings,
  OrdersSinceLastSubscriptionInterface,
  SettingsInterface,
} from "interfaces/settings";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllFiltersSegments,
  getAvailableFilters,
} from "utilities/advanced-filters";
import { getCookie, hasPermission, setCookie } from "utilities/common";
import { Firebase } from "utilities/firebase";
import LogoIcon from "static/images/logo.svg";
import { AdvancedFilters, SegmentFilters } from "interfaces/advanced-filters";
import CreateSegment from "common/advanced-filters/create-segment";
import { ROUTES } from "./definitions/consts/routes";
import SideNavbar from "./react-components/common/side-navbar";
import Loader from "./react-components/common/Loader";
import i18next from "../i18n";
import MenuIcon from "../static/images/menu.svg";
import ScrollDownIndicator from "./react-components/common/scroll-down";
import PublicWebsiteHeader from "./react-components/common/PublicWebsiteHeader";
import PublicWebsiteFooter from "./react-components/common/PublicWebsiteFooter";
import { Modal } from "./react-components/common/modal";

/**
 * state of application component.
 *
 * @interface AppState
 */
interface AppState {
  project: string;
  currentPeriod: Period;
  comparedPeriod: Period;
  periodsChangesCallbacks: Record<string, () => void>;
  loadingUser: boolean;
  user?: UserInterface;
  settings?: SettingsInterface;
  sideBarOpened?: boolean;
  sideNavBarCollapsed?: boolean;
  listBy?: Listings;
  appliedFilters: AdvancedFilters;
  availableFilters: AdvancedFilters;
  availableFiltersSegments?: SegmentFilters[];
  appliedFiltersSegment?: SegmentFilters;
  editingSegment?: SegmentFilters;
  loadingFiltersSegments: boolean;
  isCreateANewSegmentOpen: boolean;
  loadingFilters: boolean;
  showIntegrationKeysModal: boolean;
  showingExceedingOrdersLimitModal: boolean;
  ordersSinceLastSubscription?: OrdersSinceLastSubscriptionInterface;
}

/**
 * the Start point of the project.
 *
 * @class App
 * @extends {React.Component<{}, AppState>}
 */
class App extends React.Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);
    const query = window.location.search;
    const params = new URLSearchParams(query);

    this.state = {
      project: null,
      currentPeriod: {
        alias: "thisMonth",
        from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        to: format(new Date(), "yyyy-MM-dd"),
        type: "month",
      },
      comparedPeriod: null,
      periodsChangesCallbacks: {},
      loadingUser: true,
      sideBarOpened: false,
      sideNavBarCollapsed: false,
      listBy: (params.get("listBy") ?? "sales_value") as Listings,
      appliedFilters: {},
      availableFilters: {},
      availableFiltersSegments: [],
      loadingFiltersSegments: true,
      isCreateANewSegmentOpen: false,
      loadingFilters: true,
      showIntegrationKeysModal: false,
      showingExceedingOrdersLimitModal: false,
      ordersSinceLastSubscription: null,
    };

    this.updateUser = this.updateUser.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.loadSettings = this.loadSettings.bind(this);
    this.loadAvailableFilters = this.loadAvailableFilters.bind(this);
    this.checkOrdersLimitHandler = this.checkOrdersLimitHandler.bind(this);
    this.getOrdersSinceLastSubscription =
      this.getOrdersSinceLastSubscription.bind(this);
    this.checkOrdersLimit = this.checkOrdersLimit.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.updateAppearanceSettings = this.updateAppearanceSettings.bind(this);
    this.updateComparedPeriod = this.updateComparedPeriod.bind(this);
    this.updateCurrentPeriod = this.updateCurrentPeriod.bind(this);
    this.addUpdatesListener = this.addUpdatesListener.bind(this);
    this.removeUpdatesListener = this.removeUpdatesListener.bind(this);
    this.fireUpdatesCallbacks = this.fireUpdatesCallbacks.bind(this);
    this.updateSideBarOpened = this.updateSideBarOpened.bind(this);
    this.toggleSideNavBarCollapsed = this.toggleSideNavBarCollapsed.bind(this);
    this.updateUrlQueryParams = this.updateUrlQueryParams.bind(this);
    this.updateAppliedFilters = this.updateAppliedFilters.bind(this);
    this.loadFiltersFromQueryParams =
      this.loadFiltersFromQueryParams.bind(this);
    this.updateListingType = this.updateListingType.bind(this);
    this.showIntegrationKeysModal = this.showIntegrationKeysModal.bind(this);
    this.hideIntegrationKeysModal = this.hideIntegrationKeysModal.bind(this);
    this.showExceedingOrdersLimitModal =
      this.showExceedingOrdersLimitModal.bind(this);
    this.hideExceedingOrdersLimitModal =
      this.hideExceedingOrdersLimitModal.bind(this);
    this.loadAvailableFiltersSegments =
      this.loadAvailableFiltersSegments.bind(this);
    this.toggleCreateANewSegment = this.toggleCreateANewSegment.bind(this);
    this.updateFiltersSegments = this.updateFiltersSegments.bind(this);
    this.updateAppliedFiltersSegment =
      this.updateAppliedFiltersSegment.bind(this);
  }

  componentDidMount() {
    let promise: Promise<void | unknown> = Authenticator.getCurrentUser().then(
      (user) => {
        return new Promise<void>((resolve) => {
          this.setState({ user }, () => resolve());
        });
      }
    );

    promise = promise.then(() => {
      return this.loadSettings();
    });

    promise = promise.then(() => {
      return this.loadAvailableFilters();
    });

    promise = promise.then(() => {
      return this.loadAvailableFiltersSegments();
    });

    promise = promise.then(() => {
      return this.loadFiltersFromQueryParams();
    });

    promise.finally(() => {
      this.setState({
        loadingUser: false,
        loadingFilters: false,
      });
    });
  }

  getOrdersSinceLastSubscription() {
    return getOrdersSinceLastSubscription()
      .then((ordersSinceLastSubscription) => {
        this.setState({
          ordersSinceLastSubscription,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
      });
  }

  checkOrdersLimitHandler() {
    this.getOrdersSinceLastSubscription().then(() => {
      this.checkOrdersLimit();
    });
  }

  checkOrdersLimit() {
    if (
      this.state.ordersSinceLastSubscription?.max_orders &&
      this.state.ordersSinceLastSubscription?.orders_count >
        this.state.ordersSinceLastSubscription?.max_orders
    ) {
      this.showExceedingOrdersLimitModal();
    }
  }

  loadSettings() {
    return getSettings()
      .then((settings) => {
        const projectSettings = settings.find(
          (setting) => setting.code === this.state.project
        );
        if (projectSettings) {
          // DEBUG: check if project hasn't been integrated through super admin
          if (
            !projectSettings.currency &&
            !projectSettings.edition &&
            projectSettings.timezone_diff === "0"
          ) {
            this.showIntegrationKeysModal();
          }
          return this.updateSettings(projectSettings);
        }
        return new Promise((resolve) => {
          this.setState(
            {
              project: settings[0].code,
            },
            () => {
              resolve(this.updateSettings(settings[0]));
            }
          );
          // check if user has not finished integration upon login
          if (
            !this.state.settings.currency &&
            !this.state.settings.edition &&
            this.state.settings.timezone_diff === "0"
          ) {
            this.showIntegrationKeysModal();
          }
          this.checkOrdersLimitHandler();
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toast.error("Failed to load project settings");
        return Promise.resolve(error);
      });
  }

  loadAvailableFilters() {
    this.setState({ loadingFilters: true });
    getAvailableFilters(this.state.project)
      .then((availableFilters) => {
        this.setState({ availableFilters });
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        this.setState({ loadingFilters: false });
      });
  }

  loadAvailableFiltersSegments() {
    this.setState({ loadingFiltersSegments: true });
    getAllFiltersSegments(this.state.project)
      .then((availableFiltersSegments) => {
        this.setState({
          availableFiltersSegments,
        });
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        this.setState({ loadingFiltersSegments: false });
      });
  }

  updateFiltersSegments(filtersSegments: SegmentFilters[]) {
    this.setState({ availableFiltersSegments: filtersSegments });
  }

  updateAppliedFiltersSegment(filtersSegment: SegmentFilters) {
    this.setState({ appliedFiltersSegment: filtersSegment });
  }

  updateSettings(settings: SettingsInterface): Promise<unknown | void> {
    const updatedSettings: SettingsInterface = {
      ...settings,
      primary_color:
        settings.primary_color.indexOf("#") === 0
          ? settings.primary_color
          : `#${settings.primary_color}`,
      logo: settings.logo,
    };

    return new Promise<void>((resolve) => {
      this.setState({ settings: updatedSettings }, () => {
        if (this.state.user.language) {
          changeLanguage(this.state.user.language).then(() => {
            i18next.changeLanguage(this.state.user.language);
          });
        }
        // TODO update the primary color of the app
        // document.documentElement.style.setProperty(
        //   "--color-primary",
        //   updatedSettings?.primary_color
        // );
        if (this.state.user.language === "ar") {
          document.documentElement.setAttribute("dir", "rtl");
          document.documentElement.setAttribute("lang", "ar");
        } else {
          document.documentElement.setAttribute("dir", "ltr");
          document.documentElement.setAttribute("lang", "en");
        }
        this.fireUpdatesCallbacks();
        resolve();
      });
    });
  }

  updateAppliedFilters(advancedFilters: AppState["appliedFilters"]) {
    this.setState({ appliedFilters: advancedFilters }, () => {
      this.updateUrlQueryParams();
      this.fireUpdatesCallbacks();
      this.loadAvailableFiltersSegments();
    });
  }

  /**
   * handles updating the appearance settings (primary color and low stock threshold)
   * @param {SettingsInterface} settings
   * @returns {void}
   */
  updateAppearanceSettings(settings: Partial<SettingsInterface>) {
    this.setState(
      {
        settings: {
          ...this.state.settings,
          primary_color: `#${settings.primary_color}`,
          logo: settings.logo,
        },
      }
      // TODO update the primary color of the app
      // () => {
      //   document.documentElement.style.setProperty(
      //     "--color-primary",
      //     this.state.settings.primary_color
      //   );
      // }
    );
  }

  updateUrlQueryParams(activeTab?: string, listBy?: Listings) {
    const basePath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const currentPeriodQuery = mapPeriodToQueryString(
      "currentPeriod",
      this.state.currentPeriod
    );

    const comparedPeriodQuery = this.state.comparedPeriod
      ? `&${mapPeriodToQueryString(
          "comparedPeriod",
          this.state.comparedPeriod
        )}`
      : "";

    const activeTabsQuery =
      activeTab || listBy ? `&${mapTabsToQueryString(activeTab, listBy)}` : "";

    const advancedFiltersQuery = Object.keys(this.state.appliedFilters).length
      ? `&advancedFilters=${JSON.stringify(this.state.appliedFilters)}`
      : "";

    const finalPath = `${basePath}?${currentPeriodQuery}${comparedPeriodQuery}${activeTabsQuery}${advancedFiltersQuery}`;
    window.history.replaceState(
      {
        path: finalPath,
      },
      "",
      finalPath
    );
  }

  loadFiltersFromQueryParams() {
    const query = window.location.search;
    const comparedPeriod = mapQueryStringToPeriod("comparedPeriod", query);
    const currentPeriod = mapQueryStringToPeriod("currentPeriod", query) ?? {
      alias: "thisMonth",
      from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      to: format(new Date(), "yyyy-MM-dd"),
      type: "month",
    };
    const filtersJSON = new URLSearchParams(query).get("advancedFilters");
    const filters = filtersJSON ? JSON.parse(filtersJSON) : {};

    this.setState(
      {
        comparedPeriod,
        currentPeriod,
        appliedFilters: filters,
      },
      this.fireUpdatesCallbacks
    );
  }

  updateUser(user: UserInterface) {
    this.setState(
      {
        user,
        project: undefined,
        loadingUser: user ? true : this.state.loadingUser,
      },

      () => {
        if (user) {
          changeLanguage(user.language).then(() => {
            i18next.changeLanguage(user.language);
          });

          this.loadSettings().then(() => {
            this.setState({ loadingUser: false });
          });
        }
      }
    );
  }

  updateSideBarOpened(opened: boolean) {
    this.setState({
      sideBarOpened: opened,
    });
  }

  toggleSideNavBarCollapsed() {
    this.setState({ sideNavBarCollapsed: !this.state.sideNavBarCollapsed });
  }

  updateProject(project: string) {
    this.setState({ project, appliedFilters: {} }, () => {
      this.loadSettings();
      this.loadAvailableFilters();
      this.loadAvailableFiltersSegments();
      this.updateUrlQueryParams();
      this.fireUpdatesCallbacks();
    });
  }

  updateCurrentPeriod(period: Period) {
    if (
      period?.from !== this.state.currentPeriod?.from ||
      period?.to !== this.state.currentPeriod?.to ||
      period.alias !== this.state.currentPeriod.alias
    ) {
      this.setState(
        {
          currentPeriod: period,
        },
        () => {
          this.updateUrlQueryParams();
          this.fireUpdatesCallbacks();
        }
      );
    }
  }

  updateComparedPeriod(period: Period) {
    if (
      period?.from !== this.state.comparedPeriod?.from ||
      period?.to !== this.state.comparedPeriod?.to
    ) {
      this.setState(
        {
          comparedPeriod: period,
        },
        () => {
          this.updateUrlQueryParams();
          this.fireUpdatesCallbacks();
        }
      );
    }
  }

  /**
   * handles updating the listing type whether
   * it is by number of items sold or sales value
   * @param {Listings} listType
   * @returns {void}
   */

  updateListingType(activeTab: string, listType: Listings) {
    if (listType !== this.state.listBy) {
      this.setState(
        {
          listBy: listType,
        },
        () => {
          this.updateUrlQueryParams(activeTab, listType);
          this.fireUpdatesCallbacks();
        }
      );
    }
  }

  addUpdatesListener(id: string, callback: () => void) {
    this.setState((state) => ({
      periodsChangesCallbacks: {
        ...state.periodsChangesCallbacks,
        [id]: callback,
      },
    }));
  }

  removeUpdatesListener(id: string) {
    const periodsChangesCallbacks = this.state.periodsChangesCallbacks;
    delete periodsChangesCallbacks[id];
    this.setState({
      periodsChangesCallbacks,
    });
  }

  fireUpdatesCallbacks() {
    for (const callbackId in this.state.periodsChangesCallbacks) {
      if (this.state.periodsChangesCallbacks[callbackId]) {
        this.state.periodsChangesCallbacks[callbackId]();
      }
    }
  }

  hideIntegrationKeysModal() {
    this.setState({
      showIntegrationKeysModal: false,
    });
    setCookie("hideIntegrationKeysModal", "true", 1);
  }

  showIntegrationKeysModal() {
    if (!getCookie("hideIntegrationKeysModal")) {
      this.setState({
        showIntegrationKeysModal: true,
      });
    }
  }

  showExceedingOrdersLimitModal() {
    this.setState({
      showingExceedingOrdersLimitModal: true,
    });
  }

  hideExceedingOrdersLimitModal() {
    this.setState({
      showingExceedingOrdersLimitModal: false,
    });
  }

  toggleCreateANewSegment(filtersSegment?: SegmentFilters) {
    this.setState({
      isCreateANewSegmentOpen: !this.state.isCreateANewSegmentOpen,
      // handling a case when user is editing a segment that is not applied
      editingSegment: filtersSegment || this.state.appliedFiltersSegment,
    });
  }

  render(): React.ReactNode {
    if (this.state.loadingUser) {
      return <Loader />;
    }

    const availableRoutes = Object.keys(ROUTES).reduce((prev, cur) => {
      const noUserFound =
        !this.state.loadingUser && !this.state.user && !ROUTES[cur].public;

      // user denied access to route according to his role
      const userNotPermitted =
        this.state.user &&
        !hasPermission(ROUTES[cur].roles, this.state.user.roles);

      if (noUserFound || userNotPermitted) {
        return prev;
      }

      const Component = ROUTES[cur].component;
      return [
        ...prev,
        <Route
          key={ROUTES[cur].path}
          path={ROUTES[cur].path}
          exact={ROUTES[cur].exact}
          render={(renderProps: RouteProps) => (
            <>
              {ROUTES[cur].scrollDown && <ScrollDownIndicator />}
              <Component {...renderProps} {...ROUTES[cur].props} />
            </>
          )}
        />,
      ];
    }, []);

    return (
      <div
        className={
          this.state.user &&
          `${
            this.state.sideNavBarCollapsed
              ? "main-screen-collapsed"
              : "main-screen"
          }`
        }
      >
        <React.Suspense fallback={<Loader />}>
          <ANALYTICS_CONTEXT.Provider
            value={{
              comparedPeriod: this.state.comparedPeriod,
              currentPeriod: this.state.currentPeriod,
              project: this.state.project,
              user: this.state.user,
              loadingUser: this.state.loadingUser,
              sideBarOpened: this.state.sideBarOpened,
              sideNavBarCollapsed: this.state.sideNavBarCollapsed,
              settings: this.state.settings,
              listBy: this.state.listBy,
              appliedFilters: this.state.appliedFilters,
              availableFilters: this.state.availableFilters,
              availableFiltersSegments: this.state.availableFiltersSegments,
              appliedFiltersSegment: this.state.appliedFiltersSegment,
              loadingFiltersSegments: this.state.loadingFiltersSegments,
              isCreateANewSegmentOpen: this.state.isCreateANewSegmentOpen,
              loadingFilters: this.state.loadingFilters,
              toggleCreateANewSegment: this.toggleCreateANewSegment,
              updateSideBarOpened: this.updateSideBarOpened,
              toggleSideNavBarCollapsed: this.toggleSideNavBarCollapsed,
              updateUser: this.updateUser,
              setProject: this.updateProject,
              updateComparedPeriod: this.updateComparedPeriod,
              updateFiltersSegments: this.updateFiltersSegments,
              updateAppliedFiltersSegment: this.updateAppliedFiltersSegment,
              updateCurrentPeriod: this.updateCurrentPeriod,
              addUpdatesListener: this.addUpdatesListener,
              removeUpdatesListener: this.removeUpdatesListener,
              updateSettings: this.updateSettings,
              updateAppearanceSettings: this.updateAppearanceSettings,
              updateUrlQueryParams: this.updateUrlQueryParams,
              updateListingType: this.updateListingType,
              updateAppliedFilters: this.updateAppliedFilters,
              showIntegrationKeysModal: this.showIntegrationKeysModal,
              hideIntegrationKeysModal: this.hideIntegrationKeysModal,
              showExceedingOrdersLimitModal: this.showExceedingOrdersLimitModal,
              hideExceedingOrdersLimitModal: this.hideExceedingOrdersLimitModal,
              loadSettings: this.loadSettings,
              showingExceedingOrdersLimitModal:
                this.state.showingExceedingOrdersLimitModal,
              isIntegrated:
                !this.state.settings?.currency &&
                !this.state.settings?.edition &&
                !this.state.settings?.timezone_diff,
            }}
          >
            <BrowserRouter>
              {this.state.user && (
                <Route key="side-navbar" component={SideNavbar} />
              )}
              {this.state.user && (
                <div className="responsive-navbar">
                  <div
                    className="burger-menu-icon"
                    onClick={() => {
                      this.setState({ sideBarOpened: true });
                    }}
                  >
                    <MenuIcon />
                  </div>
                  <div className="logo">
                    <LogoIcon />
                  </div>
                </div>
              )}
              {!this.state.user && <PublicWebsiteHeader />}
              <div
                className={this.state.user && "main-section"}
                id="main-section"
              >
                <Switch>
                  {availableRoutes}
                  <Route render={() => <Redirect to={ROUTES.Home.path} />} />
                </Switch>
                {this.state.user && (
                  <>
                    <Modal
                      showModal={this.state.showIntegrationKeysModal}
                      handleClose={() => this.hideIntegrationKeysModal()}
                    >
                      <div className="settings__modal">
                        <h1 className="settings__modal-title">
                          {i18next.t("common:finishYourIntegration")}
                        </h1>
                        <p className="settings__modal-description">
                          {`${i18next.t(
                            "common:finishYourIntegrationDescription"
                          )}${this.state.settings?.platform} ${i18next.t(
                            "common:adminDashboard"
                          )}`}
                        </p>
                        <div className="settings__modal-actions flex gap-10">
                          <button
                            type="button"
                            className="button--main button--delete button--link"
                            onClick={this.hideIntegrationKeysModal}
                          >
                            <Link
                              to={`${ROUTES.settings.path}/integration-keys`}
                            >
                              {i18next.t("settings:integrationKeys")}
                            </Link>
                          </button>
                          <button
                            type="button"
                            className="button-outline"
                            onClick={() => this.hideIntegrationKeysModal()}
                          >
                            {i18next.t("common:cancel")}
                          </button>
                        </div>
                        <p className="modal__disclaimer">
                          {`${i18next.t("common:alreadyFinishedIntegration")} `}
                          <Link
                            to={`${ROUTES.support.path}`}
                            onClick={this.hideIntegrationKeysModal}
                            className="external-link"
                          >
                            {`${i18next.t("common:contactUs")}`}
                          </Link>
                        </p>
                      </div>
                    </Modal>
                    <Modal
                      showModal={this.state.showingExceedingOrdersLimitModal}
                      handleClose={() => this.hideExceedingOrdersLimitModal()}
                    >
                      <div className="settings__modal">
                        <p className="settings__modal-description">
                          {i18next.t(
                            "common:youExceededOrdersLimitDescription"
                          )}
                        </p>
                        <div className="settings__modal-actions">
                          <button
                            type="button"
                            className="button--main button--delete button--link"
                          >
                            <Link
                              to={`${ROUTES.settings.path}/payment-and-subscription`}
                            >
                              {i18next.t("settings:paymentsSubscriptions")}
                            </Link>
                          </button>
                        </div>
                      </div>
                    </Modal>
                    {this.state.isCreateANewSegmentOpen && (
                      <CreateSegment
                        toggleCreateANewSegment={this.toggleCreateANewSegment}
                        segment={this.state.editingSegment}
                      />
                    )}
                  </>
                )}
              </div>
              {!this.state.user && <PublicWebsiteFooter />}
            </BrowserRouter>
          </ANALYTICS_CONTEXT.Provider>
        </React.Suspense>
      </div>
    );
  }
}

/**
 * The application.
 *
 * @type {(void|Element|React.Component<*, React.ComponentState, *>)}
 */
ReactDOM.render(
  <>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>,
  document.getElementById("root")
);

// initialize the Firebase performance service.
Firebase.initPerformance();
