/**
 * @author Karim Shalapy
 * @date 2022-10-03
 * @description implementation of the applied filters indicator.
 * @filename applied-filters.tsx
 */

import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  KEY_VAL_SEPARATOR,
  SHIPPING_LOCATIONS_KEY,
} from "consts/advanced-filters";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { flattenFilters, unflattenFilters } from "utilities/advanced-filters";
import CloseIcon from "static/images/close.svg";

interface AppliedFiltersProps {
  appliedFilters: Record<string, string[]>;
}

interface AppliedFiltersState {
  appliedFilters: Record<string, string[]>;
  id: string;
}

class AppliedFilters extends React.Component<
  WithTranslation & AppliedFiltersProps,
  AppliedFiltersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      id: Date.now().toString(),
      appliedFilters: {},
    };

    this.updateFiltersState = this.updateFiltersState.bind(this);
  }

  componentDidMount(): void {
    this.updateFiltersState();
    this.context.addUpdatesListener(this.state.id, this.updateFiltersState);
  }

  componentDidUpdate(prevProps: AppliedFiltersProps) {
    if (prevProps.appliedFilters !== this.props.appliedFilters) {
      this.updateFiltersState();
    }
  }

  componentWillUnmount(): void {
    this.context.removeUpdatesListener(this.state.id);
  }

  updateFiltersState() {
    if (this.context.appliedFiltersSegment) {
      this.setState({
        appliedFilters: flattenFilters(
          this.context.appliedFiltersSegment.filters
        ),
      });
    }
    this.setState({
      appliedFilters: flattenFilters(this.context.appliedFilters),
    });
  }

  render() {
    const { t } = this.props;
    const keys = Object.keys(this.state.appliedFilters);
    return keys.length ? (
      <div className="applied-filters">
        <h2 className="applied-filters__title">{t("appliedFilters")}</h2>
        <div className="applied-filters__container">
          {keys.reduce((accum, key) => {
            const isLocation = key === SHIPPING_LOCATIONS_KEY;
            return accum.concat(
              this.state.appliedFilters[key].map((item) => (
                <button
                  type="button"
                  key={`${key}-${item}-applied`}
                  className="applied-filter"
                  onClick={() => {
                    this.context.updateAppliedFilters(
                      unflattenFilters({
                        ...this.state.appliedFilters,
                        [key]: this.state.appliedFilters[key].filter(
                          (appliedItem) => item !== appliedItem
                        ),
                      })
                    );
                  }}
                >
                  <span className="applied-filter__label">
                    {isLocation ? item.split(KEY_VAL_SEPARATOR)[1] : item}
                  </span>
                  <CloseIcon
                    width={10}
                    height={10}
                    className="applied-filter__icon"
                  />
                </button>
              ))
            );
          }, [])}
          <button
            className="button-underlined  black"
            type="button"
            onClick={() => {
              this.context.updateAppliedFilters({});
              this.context.updateAppliedFiltersSegment(null);
            }}
          >
            {t("clearAll")}
          </button>
          <div className="segment-button">
            <span className="plus-icon">+</span>
            <button
              className="button-underlined black"
              type="button"
              onClick={() => this.context.toggleCreateANewSegment()}
            >
              {this.context.appliedFiltersSegment
                ? t("editSegment")
                : t("createANewSegment")}
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

AppliedFilters.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["filters"])(AppliedFilters);
