import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

export const DatePickerField = ({ ...props }: ReactDatePickerProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name: props.name });
  return (
    <DatePicker
      {...field}
      {...props}
      selected={field.value ?? null}
      onChange={(val: Date) => {
        setFieldValue(
          field.name,
          props.showTimeSelectOnly ? val.getTime() : val
        );
      }}
    />
  );
};

export default DatePickerField;
