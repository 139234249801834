/**
 * @author Karim Shalapy
 * @date 2022-10-02
 * @description implementation of the advanced filters dropdown form UI component
 * @filename index.tsx
 */

import React from "react";
import Select, { StylesConfig, ThemeConfig } from "react-select";
import isEqual from "lodash.isequal";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { SHIPPING_LOCATIONS_KEY } from "consts/advanced-filters";
import ArrowDownIcon from "static/images/down-arrow.svg";
import { SearchDropdownOption } from "interfaces/advanced-filters";
import {
  flattenFilters,
  unflattenFilters,
  mapFlattenedToMultiSelectValue,
  mapMultiSelectToFlattenedValue,
} from "utilities/advanced-filters";
import SectionLoader from "../section-loader";
import DropdownIndicator from "./dropdown-indicator";
import MenuList from "./menu-list";

interface AdvancedFiltersProps {
  clickHandler?: (menu, e?) => void;
  isActive?: boolean;
  disableAdvancedFilters?: boolean;
}

interface AdvancedFiltersState {
  flattenedFilters: Record<string, string[]>;
  selectedFilters: Record<string, string[]>;
  id: string;
}

const customTheme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "rgba(69, 194, 255, 1)",
    primary25: "rgba(69, 194, 255, 0.1)",
  },
});

const customStyles: StylesConfig<SearchDropdownOption> = {
  control(provided) {
    const backgroundColor = "hsla(0, 0%, 0%, 0.03)";
    return {
      ...provided,
      borderRadius: 10,
      backgroundColor,
    };
  },
  multiValue(provided) {
    return {
      ...provided,
      padding: "5px 10px",
      borderRadius: "50000px",
      backgroundColor: "hsla(217, 90%, 61%, 0.1)",
      gap: 10,
    };
  },
  multiValueRemove(provided) {
    return {
      ...provided,
      borderRadius: "50%",
      backgroundColor: "black",
      color: "white",
    };
  },
};

class AdvancedFilters extends React.Component<
  AdvancedFiltersProps & WithTranslation,
  AdvancedFiltersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);

    this.state = {
      flattenedFilters: {},
      selectedFilters: {},
      id: Date.now().toString(),
    };

    this.updateFiltersState = this.updateFiltersState.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.getIsAllSelected = this.getIsAllSelected.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount(): void {
    this.updateFiltersState();
    this.context.addUpdatesListener(this.state.id, this.updateFiltersState);
  }

  componentWillUnmount(): void {
    this.context.removeUpdatesListener(this.state.id);
  }

  getIsAllSelected(key: string) {
    return isEqual(
      this.state.selectedFilters[key],
      this.state.flattenedFilters[key]
    );
  }

  updateFiltersState() {
    this.setState({
      flattenedFilters: flattenFilters(this.context.availableFilters),
      selectedFilters: flattenFilters(this.context.appliedFilters),
    });
  }

  changeHandler(key: string, value: string) {
    if (this.state.selectedFilters[key]?.includes(value)) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [key]: this.state.selectedFilters[key].filter(
            (storedValue) => storedValue !== value
          ),
        },
      });
    } else {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [key]: (this.state.selectedFilters[key] || []).concat(value),
        },
      });
    }
  }

  selectAll(key: string) {
    if (this.getIsAllSelected(key)) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [key]: [],
        },
      });
    } else {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [key]: this.state.flattenedFilters[key],
        },
      });
    }
  }

  submitHandler(e) {
    e.preventDefault();
    this.props.clickHandler("advancedFiltersMenu");
    this.context.updateAppliedFilters(
      unflattenFilters(this.state.selectedFilters)
    );
    this.context.updateAppliedFiltersSegment(null);
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className={`dropdown-container ${
          this.props.disableAdvancedFilters && "disabled"
        }`}
        onClick={(e) => {
          this.updateFiltersState();
          this.props.clickHandler("advancedFiltersMenu", e);
        }}
      >
        {t("advancedFilters")}
        <ArrowDownIcon className="dropdown-icon" />
        {this.props.isActive && (
          <div
            className="dropdown-menu auto-width"
            onClick={(e) => e.stopPropagation()}
          >
            <form
              className="advanced-filters"
              onSubmit={(e) => this.submitHandler(e)}
            >
              {this.context.loadingFilters ? (
                <SectionLoader />
              ) : (
                Object.keys(this.state.flattenedFilters)
                  // MOVE THE `SHIPPING_LOCATIONS_KEY` TO THE LAST INDEX
                  .sort((a, b) => {
                    return a === SHIPPING_LOCATIONS_KEY
                      ? 1
                      : b === SHIPPING_LOCATIONS_KEY
                      ? -1
                      : 0;
                  })
                  .map((key) => {
                    return key !== SHIPPING_LOCATIONS_KEY ? (
                      <fieldset
                        className="advanced-filters__fieldset"
                        key={key}
                      >
                        <legend>
                          {key === "state" ? t("orderState") : t(key)}
                        </legend>
                        <div className="advanced-filters__field">
                          <label htmlFor={`select-all-${key}`}>All</label>
                          <input
                            type="checkbox"
                            name={`all-${key}`}
                            id={`select-all-${key}`}
                            checked={this.getIsAllSelected(key)}
                            onChange={() => this.selectAll(key)}
                          />
                        </div>
                        {this.state.flattenedFilters[key].map((filter) => (
                          <div
                            className="advanced-filters__field"
                            key={`${key}-${filter}`}
                          >
                            <label htmlFor={`${key}-${filter}`}>{filter}</label>
                            <input
                              type="checkbox"
                              name={key}
                              id={`${key}-${filter}`}
                              value={filter}
                              checked={
                                !!this.state.selectedFilters[key]?.includes(
                                  filter
                                )
                              }
                              onChange={() => this.changeHandler(key, filter)}
                            />
                          </div>
                        ))}
                      </fieldset>
                    ) : (
                      <fieldset
                        className="advanced-filters__fieldset advanced-filters__location-field"
                        key={key}
                      >
                        <legend>{t(key)}</legend>
                        <Select
                          id={`${key}-input-container`}
                          inputId={`${key}-input`}
                          placeholder={t("searchLocationPlaceholder")}
                          theme={customTheme}
                          styles={customStyles}
                          components={{ DropdownIndicator, MenuList }}
                          options={mapFlattenedToMultiSelectValue(
                            this.state.flattenedFilters[key]
                          )}
                          value={mapFlattenedToMultiSelectValue(
                            this.state.selectedFilters[key]
                          )}
                          onChange={(val) => {
                            const value = Array.isArray(val) ? val : [val];
                            this.setState((prev) => ({
                              selectedFilters: {
                                ...prev.selectedFilters,
                                [key]: mapMultiSelectToFlattenedValue(value),
                              },
                            }));
                          }}
                          isMulti
                          isClearable
                        />
                      </fieldset>
                    );
                  })
              )}

              <div className="advanced-filters__cta">
                <button
                  className="button-underlined  black"
                  type="button"
                  onClick={() => this.setState({ selectedFilters: {} })}
                >
                  {t("reset", { ns: "settings" })}
                </button>
                <button className="button-primary small black" type="submit">
                  {t("showResults")}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

AdvancedFilters.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["filters", "settings"])(AdvancedFilters);
