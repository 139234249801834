/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description customers tab of the App.
 * @filename customers.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserRole } from "interfaces/user";
import Header from "../../common/header";
import CustomersOverview from "./customers-overview";
import CustomersSpending from "./customers-spending";
import PermissionsFilter from "../../common/permissions-filter";

type CustomersState = {
  activeTab: "customers_overview" | "platform_traffic" | "spending" | string;
  id: string;
};

class Customers extends React.Component<
  RouteComponentProps & WithTranslation,
  CustomersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  exportCustomersRef: React.RefObject<HTMLDivElement>;

  exportCustomersSpendingRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.ChartRef = React.createRef();

    this.exportCustomersRef = React.createRef();
    this.exportCustomersSpendingRef = React.createRef();

    this.state = {
      activeTab: params.get("activeTab") ?? "customers_overview",
      id: Date.now().toString(),
    };
  }

  componentDidMount() {
    this.context.updateUrlQueryParams(this.state.activeTab);
    // set page title
    document.title = "Customers";
  }

  componentDidUpdate(_prevProps, prevState: CustomersState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(this.state.activeTab);
    }
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="customers">
        <Header
          title={t("customers")}
          exportRef={
            this.state.activeTab === "customers_overview"
              ? this.exportCustomersRef
              : this.exportCustomersSpendingRef
          }
        />
        <div className="tabs">
          <div
            className={`tab ${
              this.state.activeTab === "customers_overview" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "customers_overview") {
                this.setState({ activeTab: "customers_overview" });
              }
            }}
          >
            {t("customersOverview")}
          </div>
          <PermissionsFilter
            allowedRoles={[
              UserRole.marketing,
              UserRole.management,
              UserRole.admin,
            ]}
          >
            <div
              className={`tab ${
                this.state.activeTab === "spending" ? " active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "spending") {
                  this.setState({ activeTab: "spending" });
                }
              }}
            >
              Spending
            </div>
          </PermissionsFilter>
        </div>
        {this.state.activeTab === "customers_overview" && (
          <CustomersOverview exportRef={this.exportCustomersRef} />
        )}
        {this.state.activeTab === "spending" && (
          <PermissionsFilter
            allowedRoles={[
              UserRole.marketing,
              UserRole.management,
              UserRole.admin,
            ]}
          >
            <CustomersSpending exportRef={this.exportCustomersSpendingRef} />
          </PermissionsFilter>
        )}
      </div>
    );
  }
}

Customers.contextType = ANALYTICS_CONTEXT;
export default withTranslation("customers")(Customers);
