/**
 * @author Youssef Tarek
 * @date 2022-05-24
 * @description promo code tab of the App.
 * @filename promo-codes.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { ExportedPromoCodesList, PromoCodes } from "interfaces/user-behavior";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import {
  exist,
  getDurationRange,
  getNumberReadableValue,
} from "utilities/common";
import { exportPromoCodes, getPromoCodes } from "utilities/user-behavior";
import i18next from "i18next";
import Loader from "../../common/Loader";
import TableView from "../../common/table-view";

type PromoCodesState = {
  id: string;
  loading: boolean;
  promoCodesSummary: PromoCodes;
  exportedPromoCodes: ExportedPromoCodesList[];
  promoCodesTotalCount: number;
  comparedPromoCodesTotalCount: number;
  comparedPromoCodesSummary: PromoCodes;
  comparedExportedPromoCodes: ExportedPromoCodesList[];
  pageSize?: number;
  currentPage?: number;
};

interface PromoCodesProps {
  exportRef: React.RefObject<HTMLDivElement>;
  updateShowAllPromosState: (state: boolean) => void;
  updateExportedPromoCodes: (
    exportedPromoCodes: ExportedPromoCodesList[]
  ) => void;
}

class Promocodes extends React.Component<
  PromoCodesProps & WithTranslation,
  PromoCodesState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: PromoCodesProps & WithTranslation) {
    super(props);

    this.state = {
      id: Date.now().toString(),
      loading: true,
      promoCodesSummary: null,
      exportedPromoCodes: null,
      promoCodesTotalCount: 0,
      comparedPromoCodesTotalCount: 0,
      comparedPromoCodesSummary: null,
      comparedExportedPromoCodes: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  /**
   * get the number of active promocodes
   * @param promocodes
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  getActivePromoCodesCount(promocodes: ExportedPromoCodesList[]) {
    return promocodes.filter(
      (promo) => promo.is_active === 1 || promo.is_active === null
    ).length;
  }

  loadData() {
    this.setState({ loading: true });
    let promoCodesSummaryPromise = Promise.resolve();
    let exportedPromoCodesPromise = Promise.resolve();
    let comparedPromoCodesSummaryPromise = Promise.resolve();
    let comparedExportedPromoCodesPromise = Promise.resolve();

    if (exist(this.context, ["project"])) {
      promoCodesSummaryPromise = getPromoCodes({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((promoCodesSummary) => {
        this.setState({ promoCodesSummary });
      });

      exportedPromoCodesPromise = exportPromoCodes({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((exportedPromoCodes) => {
        this.setState({
          exportedPromoCodes,
          promoCodesTotalCount:
            this.getActivePromoCodesCount(exportedPromoCodes),
        });
        this.props.updateExportedPromoCodes(exportedPromoCodes);
      });
    }

    if (exist(this.context, ["comparedPeriod"])) {
      comparedPromoCodesSummaryPromise = getPromoCodes({
        project: this.context.project,
        from: this.context.comparedPeriod?.from,
        to: this.context.comparedPeriod?.to,
        filters: this.context.appliedFilters,
      }).then((comparedPromoCodesSummary) => {
        this.setState({ comparedPromoCodesSummary });
      });

      comparedExportedPromoCodesPromise = exportPromoCodes({
        project: this.context.project,
        from: this.context.comparedPeriod?.from,
        to: this.context.comparedPeriod?.to,
        filters: this.context.appliedFilters,
      }).then((comparedExportedPromoCodes) => {
        this.setState({
          comparedExportedPromoCodes,
          comparedPromoCodesTotalCount: this.getActivePromoCodesCount(
            comparedExportedPromoCodes
          ),
        });
        this.props.updateExportedPromoCodes(comparedExportedPromoCodes);
      });
    }

    Promise.all([
      promoCodesSummaryPromise,
      exportedPromoCodesPromise,
      comparedPromoCodesSummaryPromise,
      comparedExportedPromoCodesPromise,
    ])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="promo-code" ref={this.props.exportRef}>
        <div className="card transparent-card overview-card">
          <h2 className="card--title">
            {t("promocodes")}{" "}
            {i18next.t(`common:${this.context.currentPeriod.alias}`)}
            <span className="duration">
              (
              {getDurationRange(
                this.context.currentPeriod.type,
                this.context.currentPeriod.from,
                this.context.currentPeriod.to
              )}
              )
            </span>
          </h2>
          <div className="overview-items">
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.promoCodesSummary.number_of_orders_with_promocode
                )}
              </span>
              <span className="title">{t("ordersUsedPromocodes")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.promoCodesSummary
                    .percentage_of_orders_with_promocode
                )}
                %
              </span>
              <span className="title">{t("ofOrdersUsedPromocodes")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.promoCodesSummary.sales_value
                )}{" "}
                {this.context.settings.currency}
              </span>
              <span className="title">
                {t("totalRevenueOfOrdersWithPromocode")}
              </span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(this.state.promoCodesSummary.discount)}{" "}
                {this.context.settings.currency}
              </span>
              <span className="title">{t("totalDiscountAmount")}</span>
            </div>
          </div>
        </div>
        {this.context.comparedPeriod && (
          <div className="card transparent-card overview-card">
            <h2 className="card--title">
              {t("promocodes")}{" "}
              {i18next.t(`common:${this.context.comparedPeriod?.alias}`)}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.comparedPeriod?.type,
                  this.context.comparedPeriod?.from,
                  this.context.comparedPeriod?.to
                )}
                )
              </span>
            </h2>
            <div className="overview-items">
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.comparedPromoCodesSummary
                      ?.number_of_orders_with_promocode
                  )}
                </span>
                <span className="title">{t("ordersUsedPromocodes")}</span>
              </div>
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.comparedPromoCodesSummary
                      ?.percentage_of_orders_with_promocode
                  )}
                  %
                </span>
                <span className="title">{t("ofOrdersUsedPromocodes")}</span>
              </div>
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.comparedPromoCodesSummary?.sales_value
                  )}{" "}
                  {this.context.settings.currency}
                </span>
                <span className="title">
                  {t("totalRevenueOfOrdersWithPromocode")}
                </span>
              </div>
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.comparedPromoCodesSummary?.discount
                  )}{" "}
                  {this.context.settings.currency}
                </span>
                <span className="title">{t("totalDiscountAmount")}</span>
              </div>
            </div>
          </div>
        )}
        <div className="card-container">
          <div className="card overview-card">
            <h2 className="card--title card--export">
              <div>
                {t("activePromocodes")}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.currentPeriod.type,
                    this.context.currentPeriod.from,
                    this.context.currentPeriod.to
                  )}
                  )
                </span>
              </div>
              {this.state.exportedPromoCodes.length ? (
                <CSVLink
                  data={this.state.exportedPromoCodes}
                  filename={`promocodes-from:${this.context.currentPeriod.from}-to:${this.context.currentPeriod.to}.csv`}
                  className="export-list-button"
                  target="_blank"
                >
                  {t("exportList")}
                </CSVLink>
              ) : null}
            </h2>
            <div className="overview-items">
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(this.state.promoCodesTotalCount)}
                </span>
                <span className="title">{t("promocodes")}</span>
              </div>
              {this.state.exportedPromoCodes.length ? (
                <button
                  className="item"
                  type="button"
                  onClick={() => {
                    this.props.updateShowAllPromosState(true);
                  }}
                >
                  {t("viewAllPromoCodes")}
                </button>
              ) : null}
            </div>
          </div>
          {this.context.comparedPeriod && (
            <div className="card overview-card">
              <h2 className="card--title card--export">
                <div>
                  {t("activePromocodes")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.comparedPeriod?.type,
                      this.context.comparedPeriod?.from,
                      this.context.comparedPeriod?.to
                    )}
                    )
                  </span>
                </div>
                {this.state.comparedExportedPromoCodes?.length ? (
                  <CSVLink
                    data={this.state.comparedExportedPromoCodes}
                    filename={`promocodes-from:${this.context.comparedPeriod?.from}-to:${this.context.comparedPeriod?.to}.csv`}
                    className="export-list-button"
                    target="_blank"
                  >
                    {t("exportList")}
                  </CSVLink>
                ) : null}
              </h2>
              <div className="overview-items">
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedPromoCodesTotalCount
                    )}
                  </span>
                  <span className="title">{t("promocodes")}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card">
          <h2 className="card--title">
            {t("topUsedPromocodes")}{" "}
            <span className="duration">
              (
              {getDurationRange(
                this.context.currentPeriod.type,
                this.context.currentPeriod.from,
                this.context.currentPeriod.to
              )}
              )
            </span>
          </h2>
          <div className="top-promocodes-table">
            {this.state.exportedPromoCodes && (
              <TableView
                promocodes={this.state.exportedPromoCodes.slice(0, 9)}
                type="promocodes"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Promocodes.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(Promocodes);
