/**
 * @author Youssef Tarek
 * @date 2022-03-23
 * @description implementation of pagination component
 * @filename Pagination.tsx
 */

import React from "react";
import ArrowIcon from "../../../static/images/down-arrow.svg";

interface PaginationProps {
  totalPageCount: number;
  currentPage: number;
  pageNeighbors: number;
  changePageHandler: (a: number) => void;
}

interface PaginationState {
  pagination: number[];
}

export default class Pagination extends React.Component<
  PaginationProps,
  PaginationState
> {
  constructor(props: PaginationProps) {
    super(props);
    this.state = {
      pagination: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.pagination.length !== nextProps.totalPageCount) {
      return {
        pagination: [...Array(nextProps.totalPageCount).keys()].map(
          (i) => i + 1
        ),
      };
    }

    return null;
  }

  /**
   * check whether the page number should be shown or not;
   * depending on the current page, the total number of pages,
   * and the number of specified neighboring pages
   * @param pageNumber
   * @returns
   */
  isPageNumberShown(pageNumber) {
    if (
      pageNumber <= 1 || // show first page
      pageNumber >= this.props.totalPageCount || // show last page
      (pageNumber >= this.props.currentPage - this.props.pageNeighbors && // show the previous page
        pageNumber <= this.props.currentPage + this.props.pageNeighbors) // show next page
    ) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div className="pagination">
        <button
          type="button"
          onClick={() => {
            this.props.changePageHandler(this.props.currentPage - 1);
          }}
          disabled={this.props.currentPage === 1}
        >
          <ArrowIcon className="left-arrow arrow" />
        </button>
        {this.state.pagination.map((number) => {
          if (this.isPageNumberShown(number)) {
            return (
              <span
                className={`page-number ${
                  number === this.props.currentPage && "active"
                }`}
                key={number}
                onClick={(e) => {
                  this.props.changePageHandler(
                    parseInt(e.target.textContent, 10)
                  );
                }}
              >
                {number}
              </span>
            );
          }
          if (number === 2 || number === this.props.totalPageCount - 1) {
            // display breaks only after first page and before last page
            // and avoid showing breaks instead of all the not showing numbers
            return (
              <span key={number} className="page-number break">
                ..
              </span>
            );
          }
          return null;
        })}
        <button
          type="button"
          onClick={() => {
            this.props.changePageHandler(this.props.currentPage + 1);
          }}
          disabled={this.props.currentPage === this.props.totalPageCount}
        >
          <ArrowIcon className="right-arrow arrow" />
        </button>
      </div>
    );
  }
}
