/**
 * @author Sarah Nouh
 * @date 2022-01-09
 * @description subscription plans options page
 * @filename plans.tsx
 */

import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  changeTier,
  getChangeTierInfo,
  getPricings,
  subscribe,
} from "utilities/settings";
import { toast } from "react-toastify";
import {
  ChangeTierInfo,
  PaymentCardInterface,
  PricingsInterface,
} from "interfaces/settings";
import FeatureCheckIcon from "static/images/feature-check.svg";
import Loader from "common/Loader";
import BackArrowIcon from "static/images/left-arrow.svg";
import FeatureCancelIcon from "static/images/cancel.svg";
import { plans } from "consts/plans";
import { Modal } from "common/modal";
import Plan from "./plan";

interface SubscriptionPlansProps {
  toggleChangePlan: () => void;
  toggleAddPaymentMethod: () => void;
  defaultCard?: PaymentCardInterface;
}
interface SubscriptionPlansState {
  loading: boolean;
  pricings: PricingsInterface[];
  selectedPlan: string;
  showChangeTierModal: boolean;
  changeTierInfo: ChangeTierInfo;
  isButtonDisabled: boolean;
}

class SubscriptionPlans extends React.Component<
  SubscriptionPlansProps & WithTranslation,
  SubscriptionPlansState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: SubscriptionPlansProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      pricings: [],
      selectedPlan: "",
      showChangeTierModal: false,
      changeTierInfo: null,
      isButtonDisabled: false,
    };

    this.loadData = this.loadData.bind(this);
    this.changeTierHandler = this.changeTierHandler.bind(this);
    this.showChangeTierModal = this.showChangeTierModal.bind(this);
    this.hideChangeTierModal = this.hideChangeTierModal.bind(this);
    this.confirmChangeTier = this.confirmChangeTier.bind(this);
    this.choosePlanHandler = this.choosePlanHandler.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  // eslint-disable-next-line class-methods-use-this
  showPricing(pricing: PricingsInterface, t) {
    if (pricing.max_orders) {
      return `${t("upTo")} ${pricing.max_orders} ${t("orders")}`;
    }
    return `${t("unlimited")} ${t("orders")}`;
  }

  loadData() {
    this.setState({ loading: true });

    getPricings()
      .then((pricings) => {
        this.setState({ pricings: pricings.data });
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  choosePlanHandler(t) {
    if (this.state.selectedPlan === "sixWeeksTrial") {
      this.props.toggleChangePlan();
      this.props.toggleAddPaymentMethod();
    }
    // TODO: add condition for trial tier
    if (this.context.settings.subscription_tier === "unsubscribed") {
      this.setState({ isButtonDisabled: true });
      subscribe(this.state.selectedPlan)
        .then(() => {
          toast.success(t("subscriptionPlanChanged"));
        })
        .catch((err) => {
          toast.error(err[0]);
        })
        .finally(() => {
          this.setState({ isButtonDisabled: false });
        });
    } else {
      this.changeTierHandler();
    }
  }

  changeTierHandler() {
    if (this.props.defaultCard) {
      getChangeTierInfo(this.state.selectedPlan)
        .then((changeTierInfo) => {
          this.setState({ changeTierInfo });
          this.showChangeTierModal();
        })
        .catch((err) => {
          toast.error(err[0]);
        });
    } else {
      this.props.toggleAddPaymentMethod();
      this.props.toggleChangePlan();
    }
  }

  confirmChangeTier(t) {
    changeTier(this.state.selectedPlan)
      .then(() => {
        this.hideChangeTierModal();
        toast.success(t("upcomingSubscriptionPlanChanged"));
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  showChangeTierModal() {
    this.setState({ showChangeTierModal: true });
  }

  hideChangeTierModal() {
    this.setState({ showChangeTierModal: false });
  }

  selectPlan(plan: string) {
    this.setState({ selectedPlan: plan });
  }

  render() {
    const { t } = this.props;
    const isTrial = this.context.settings.subscription_tier === "trial";
    const isSixWeekTrial = isTrial && this.props.defaultCard;
    const isTwoWeekTrial = isTrial && !this.props.defaultCard;
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="payment">
        <button
          className="payment--button-back"
          type="button"
          onClick={this.props.toggleChangePlan}
        >
          <BackArrowIcon />
          {t("backToPaymentAndSubscriptions")}
        </button>
        {this.context.settings.is_old_subscription ? (
          <div className="card">
            <h1 className="card--title">{t("changePlan")}</h1>
            <div className="payment-card">
              <p className="payment-card__subtitle">
                {t("chooseTheBestPlanForYourService")}
              </p>
              {/* dont show free plans if user us subscribed */}
              <ul className="plans-list">
                {isTrial && (
                  <>
                    {/* dont show the two weeks trial if user is on trial and have a credit card */}
                    {isTwoWeekTrial && (
                      <li
                        className={
                          this.context.settings.subscription_tier === "trial" &&
                          !this.props.defaultCard
                            ? "plans-list__item selected"
                            : "plans-list__item"
                        }
                      >
                        <p className="title">
                          {t("twoWeeksTrial")}
                          {/* TODO show current plan according to tier
                    {this.context.settings?.subscription_tier ===
                      "plan.title.toLowerCase()" && (
                      <span>- {t("currentPlan")}</span>
                    )} */}
                        </p>
                        <p className="price">{t("free")}</p>
                        <ul className="feature-list">
                          <li className="feature-list__item">
                            <FeatureCheckIcon />
                            <p>{t("accessAllFeatures")}</p>
                          </li>
                          <li className="feature-list__item">
                            <FeatureCancelIcon className="feature-list__cancel-icon" />
                            <p>{t("noCreditCardRequired")}</p>
                          </li>
                        </ul>
                      </li>
                    )}
                    <li
                      className={`${
                        isSixWeekTrial
                          ? "plans-list__item selected"
                          : "plans-list__item"
                      } ${
                        this.state.selectedPlan === "sixWeeksTrial" && "chosen"
                      }`}
                      onClick={() => {
                        if (isTwoWeekTrial) {
                          this.setState({
                            selectedPlan: "sixWeeksTrial",
                          });
                        }
                      }}
                    >
                      <p className="title">
                        {t("sixWeeksTrial")}
                        {/* TODO show current plan according to tier
                    {this.context.settings?.subscription_tier ===
                      "plan.title.toLowerCase()" && (
                      <span>- {t("currentPlan")}</span>
                    )} */}
                      </p>
                      <p className="price">{t("free")}</p>
                      <ul className="feature-list">
                        <li className="feature-list__item">
                          <FeatureCheckIcon />
                          <p>{t("accessAllFeatures")}</p>
                        </li>
                        <li className="feature-list__item">
                          <FeatureCheckIcon />
                          <p>{t("creditCardRequired")}</p>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
                {this.state.pricings.map((plan) => (
                  <Plan
                    maxOrders={plan.max_orders}
                    price={plan.price}
                    maxUsers={plan.max_users}
                    description={t(plans[plan.tier].description)}
                    tier={plan.tier.toUpperCase()}
                    key={plan.id}
                    currency={plan.currency}
                    active={
                      this.context.settings.subscription_tier === plan.tier
                    }
                    selected={this.state.selectedPlan === plan.tier}
                    onSelect={() => this.selectPlan(plan.tier)}
                    features={[
                      plan.max_orders
                        ? `${t("upTo")} ${plan.max_orders} ${t("orders")} / ${t(
                            "month"
                          )}`
                        : t("unlimitedOrdersForever"),
                      t("allReportsAndFeaturesAccess"),
                      plan.max_users
                        ? `${t("upTo")} ${plan.max_users} ${t("users")} / ${t(
                            "month"
                          )}`
                        : t("unlimitedUsers"),
                      ...plans[plan.tier].features,
                    ]}
                  />
                ))}
              </ul>
            </div>
            <button
              type="submit"
              disabled={!this.state.selectedPlan || this.state.isButtonDisabled}
              onClick={() => {
                this.choosePlanHandler(t);
              }}
            >
              {t("choosePlan")}
            </button>
          </div>
        ) : (
          <>
            {this.context.settings.subscription_tier === "unsubscribed" ? (
              <stripe-pricing-table
                pricing-table-id={process.env.STRIPE_ID}
                publishable-key={process.env.STRIPE_PUBLISHABLE_KEY}
              />
            ) : null}
          </>
        )}
        <Modal
          showModal={this.state.showChangeTierModal}
          handleClose={this.hideChangeTierModal}
        >
          <div className="settings__modal">
            {this.state.changeTierInfo ? (
              <>
                <h1 className="settings__modal-title">{t("changeYourPlan")}</h1>
                <p className="settings__modal-description">
                  {`${t("youWillBeCharged")} ${
                    this.state.changeTierInfo?.amount_owed
                  } $ ${t("now")} ${t("andThePackageAutomaticallyRenews")} ${
                    this.state.changeTierInfo?.monthly_payment_amount
                  } $`}
                </p>
                <div className="settings__modal-actions">
                  <button
                    type="button"
                    className="button--main button--delete button--link"
                    onClick={() => this.confirmChangeTier(t)}
                  >
                    {t("changePlan")}
                  </button>
                  <button
                    type="button"
                    className="button-outline"
                    onClick={() => this.hideChangeTierModal()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

SubscriptionPlans.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(SubscriptionPlans);
