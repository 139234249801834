/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Analytics context that
 * holds meta info about the current analytics.
 * @filename analytics-context.ts
 */
import { Period } from "interfaces/period";
import { User } from "interfaces/user";
import { Listings, SettingsInterface } from "interfaces/settings";
import * as React from "react";
import { AdvancedFilters } from "interfaces/advanced-filters";
import { SegmentFilters } from "../definitions/interfaces/advanced-filters";

/**
 * Menu context that holds active branch's menu.
 */
export const ANALYTICS_CONTEXT: React.Context<{
  user?: User;
  loadingUser?: boolean;
  sideBarOpened?: boolean;
  sideNavBarCollapsed?: boolean;
  settings?: SettingsInterface;
  /**
   * defines the listing type whether by sales value
   * or numbers sold which is then used for API calls
   */
  listBy?: Listings;

  updateListingType: (activeTab: string, listType: Listings) => void;
  updateSettings?: (settings: Partial<SettingsInterface>) => void;
  updateSideBarOpened?: (opened: boolean) => void;
  toggleSideNavBarCollapsed?: () => void;
  updateUser?: (user: User) => void;
  loadSettings: () => void;
  project: string;
  isCreateANewSegmentOpen: boolean;
  toggleCreateANewSegment: (filtersSegment?: SegmentFilters) => void;
  setProject: (project: string) => void;
  currentPeriod: Period;

  updateCurrentPeriod: (period: Period) => void;
  comparedPeriod: Period;
  updateComparedPeriod: (period: Period) => void;
  /**
   * handles updating the theme and low stock threshold
   */
  updateAppearanceSettings: (settings: Partial<SettingsInterface>) => void;
  addUpdatesListener: (id: string, callback: () => void) => void;
  removeUpdatesListener: (id: string) => void;
  appliedFilters: AdvancedFilters;
  availableFilters: AdvancedFilters;
  availableFiltersSegments: SegmentFilters[];
  appliedFiltersSegment: SegmentFilters;
  loadingFiltersSegments: boolean;
  loadingFilters: boolean;
  updateAppliedFilters: (filters: AdvancedFilters) => void;
  updateAppliedFiltersSegment: (filters: SegmentFilters) => void;
  updateFiltersSegments: (filterSegments: SegmentFilters[]) => void;
  updateUrlQueryParams: (activeTab?: string, listBy?: Listings) => void;
  hideIntegrationKeysModal: () => void;
  showIntegrationKeysModal: () => void;
  showExceedingOrdersLimitModal: () => void;
  hideExceedingOrdersLimitModal: () => void;
  showingExceedingOrdersLimitModal: boolean;
  isIntegrated: boolean;
}> = React.createContext({
  project: null,
  settings: null,
  setProject: null,
  currentPeriod: null,
  updateCurrentPeriod: null,
  comparedPeriod: null,
  updateComparedPeriod: null,
  loadSettings: null,
  appliedFilters: null,
  appliedFiltersSegment: null,
  updateFiltersSegments: null,
  updateAppliedFiltersSegment: null,
  updateAppliedFilters: null,
  updateAppearanceSettings: null,
  addUpdatesListener: null,
  removeUpdatesListener: null,
  updateUrlQueryParams: null,
  updateListingType: null,
  availableFilters: null,
  isCreateANewSegmentOpen: null,
  toggleCreateANewSegment: null,
  availableFiltersSegments: null,
  loadingFiltersSegments: null,
  loadingFilters: null,
  showIntegrationKeysModal: null,
  hideIntegrationKeysModal: null,
  showExceedingOrdersLimitModal: null,
  hideExceedingOrdersLimitModal: null,
  showingExceedingOrdersLimitModal: null,
  isIntegrated: null,
});
