/**
 * @author Youssef Tarek
 * @date 2022-03-28
 * @description implementation of Abandoned Carts related API calls.
 * @filename abandoned-carts.ts
 */

import {
  AbandonedCartRequestPayload,
  AbandonedCartsDetails,
} from "interfaces/abandoned-carts";
import { Payload } from "interfaces/payload";
// import { appendFiltersToSearchParam } from "utilities/urls";
import { ENDPOINTS } from "./endpoints";
import { Network } from "./network";

export class AbandonedCarts {
  public static getAbandonedCarts({
    project,
    from,
    to,
  }: AbandonedCartRequestPayload): Promise<Payload<AbandonedCartsDetails>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getAbandonedCarts.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getAbandonedCarts.method,
    });
  }
}
