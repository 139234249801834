/**
 * @author Ahmed Serag
 * @date 2022-07-19
 * @description Page Views Chart component of page views page.
 * @filename page-views-chart.tsx
 */
import React from "react";
import { Chart } from "chart.js";
import { getDurationRange, exist } from "utilities/common";
import {
  normalizeData,
  getChartData,
  getChartRange,
  getLineChart,
} from "utilities/charts";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { WeekDays } from "interfaces/orders-overview";
import { getOrdersPerDay } from "utilities/orders";
import { ChartData } from "interfaces/charts";
import { getCustomersPerDay } from "utilities/customers";

interface PageViewsChartState {
  dataPerDay: number[];
  totalData?: number;
  comparedDataPerDay?: number[];
  comparedTotalData?: number;
  activeDay?: WeekDays;
  loading: boolean;
  id: string;
  chart?: Chart<"line", number[], string> | Chart<"bar", number[], string>;
  normalizedChart?:
    | Chart<"line", number[], string>
    | Chart<"bar", number[], string>;
}

interface PageViewsChartProps {
  dataPerDay: number[];
}

class PageViewsCharts extends React.Component<
  PageViewsChartProps & WithTranslation,
  PageViewsChartState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  NormalizedChartRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: PageViewsChartProps & WithTranslation) {
    super(props);
    this.ChartRef = React.createRef();
    this.NormalizedChartRef = React.createRef();

    this.state = {
      dataPerDay: null,
      loading: true,
      activeDay: WeekDays.SUNDAY,
      id: `page-views-chart-${Date.now().toString()}`,
    };
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    this.loadChart();
    this.loadNormalizedChart();
  }

  componentWillUnmount() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }
    if (this.state.normalizedChart) {
      this.state.normalizedChart.destroy();
    }
  }

  loadData(): Promise<ChartData[]> {
    const datasets: ChartData[] = [];
    let ordersPerDayPromise = Promise.resolve(null);
    let customersPerDayPromise = Promise.resolve(null);

    if (exist(this.context, ["project", "currentPeriod"])) {
      ordersPerDayPromise = getOrdersPerDay({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((dataPerDay) => {
        if (this.context.comparedPeriod?.type === "quarter") {
          // to get the total data for quarter
          const totalData = dataPerDay.reduce(
            (prevValue, currValue) => prevValue + currValue,
            0
          );
          this.setState({ totalData });
        } else {
          datasets.push({
            label: i18next.t(`common:orders`),
            values: getChartData(this.context.currentPeriod, dataPerDay),
            title: i18next.t(`common:orders`),
          });
        }
      });
      customersPerDayPromise = getCustomersPerDay({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((customersPerDay) => {
        datasets.push({
          label: i18next.t("customers:newCustomers"),
          values: getChartData(
            this.context.currentPeriod,
            customersPerDay.new_customer_count,
            "sum"
          ),
          backgroundColor: "#3DD2CE",
          title: i18next.t("customers:newCustomers"),
        });
      });
    }
    return Promise.all([ordersPerDayPromise, customersPerDayPromise]).then(
      () => datasets
    );
  }

  /**
   * Draws the chart when the day is changed
   * @param activeDay Name of the day to be graphed
   */
  async loadChart() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }

    // Chart loaded custom data - orders.
    const chartData = await this.loadData();

    let chart = this.state.chart;
    const datasets: {
      values: number[];
      label: string;
      backgroundColor?: string;
      borderColor?: string;
      title: string;
    }[] = [...chartData];

    datasets.push({
      label: i18next.t(`user-behavior:views`),
      values: getChartData(this.context.currentPeriod, this.props.dataPerDay),
      backgroundColor: "#A260F0",
      borderColor: "#A260F0",
      title: t("user-behavior:views"),
    });
    chart = getLineChart(
      this.ChartRef.current,
      getChartRange(this.context.currentPeriod),
      datasets,
      t("user-behavior:views"),
      this.state.id,
      // maintainAspectRatio to give the hourly breakdown graph full width
      true
    );
    this.setState({ loading: false, chart });
  }

  async loadNormalizedChart() {
    if (this.state.normalizedChart) {
      this.state.normalizedChart.destroy();
    }

    const chartData = await this.loadData();
    let chart = this.state.normalizedChart;
    const datasets: {
      values: number[];
      label: string;
      backgroundColor?: string;
      borderColor?: string;
      title: string;
    }[] = chartData.map((data) => {
      return {
        label: data.label,
        values: normalizeData(data.values),
        backgroundColor: data.backgroundColor,
        borderColor: data.borderColor,
        title: "",
      };
    });

    datasets.push({
      label: t("user-behavior:views"),
      values: normalizeData(
        getChartData(this.context.currentPeriod, this.props.dataPerDay)
      ),
      backgroundColor: "#A260F0",
      borderColor: "#A260F0",
      title: "",
    });
    chart = getLineChart(
      this.NormalizedChartRef.current,
      getChartRange(this.context.currentPeriod),
      datasets,
      "normalized scale",
      `growth-${this.state.id}`,
      // maintainAspectRatio to give the hourly breakdown graph full width
      true
    );
    this.setState({ loading: false, normalizedChart: chart });
  }

  render(): React.ReactNode {
    return (
      <>
        <div className="card orders-card">
          <h2 className="card--title card-graph--title">
            <div>
              {t("user-behavior:pageViewsChartTitle")}{" "}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </div>
            <div className="card--options">
              {/* TODO: fix export chart
              {this.state.chart && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="" id={this.state.id}>
                  {t("common:exportChart")}
                </a>
              )} */}
            </div>
          </h2>
          <div className="line-chart__container">
            <canvas ref={this.ChartRef} id={this.state.id} />
          </div>
        </div>
        <div className="card orders-card">
          <h2 className="card--title card-graph--title">
            <div>
              {t("user-behavior:normalizedPageViews")}{" "}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </div>
          </h2>
          <div className="line-chart__container">
            <canvas
              ref={this.NormalizedChartRef}
              id={`growth-${this.state.id}`}
            />
          </div>
        </div>
      </>
    );
  }
}

PageViewsCharts.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(PageViewsCharts);
