export const subscriptionPlans = [
  {
    title: "Basic",
    price: "Free",
    featruesList: [
      "Overview Reports",
      "Orders Reports",
      "Customers Reports",
      "Categories' Reports",
      "Products' Reports",
      "Comparing Pre-defined Time Periods",
    ],
  },
  {
    title: "Premium",
    price: "150EGP",
    featruesList: [
      "Overview Reports",
      "Orders Reports",
      "Customers Reports",
      "Categories' Reports",
      "Products' Reports",
      "Comparing Pre-defined Time Periods",
      "Comparing Custom Time Periods",
      "Exporting as a CSV",
      "Email Notifications",
    ],
  },
  {
    title: "Professional",
    price: "200EGP",
    featruesList: [
      "Overview Reports",
      "Orders Reports",
      "Customers Reports",
      "Categories' Reports",
      "Products' Reports",
      "Comparing Pre-defined Time Periods",
      "Comparing Custom Time Periods",
      "Exporting as a CSV",
      "Email Notifications",
      "Google Analytics Integration",
      "Product Insights",
    ],
  },
];

export const PUBLIC_WEBSITE_PLANS = [
  "twoweekstrial",
  "sixweekstrial",
  "starter",
  "pro",
  "premium",
] as const;

export const plans = {
  starter: {
    description: "starterDesc",
    features: ["standardSupport"],
  },
  pro: {
    description: "proDesc",
    features: ["standardSupport"],
  },
  premium: {
    description: "premiumDesc",
    features: ["dedicatedSupport"],
  },
};
