import React from "react";
import ShowPasswordSVG from "static/images/show-password.svg";
import HidePasswordSVG from "static/images/hide-password.svg";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string | number;
  id: string;
  containerClassName?: string;
  errorMsg?: string;
  tooltip?: string;
}

interface InputState {
  innerType?: React.InputHTMLAttributes<HTMLInputElement>["type"];
}

export default class Input extends React.Component<InputProps, InputState> {
  static defaultProps = {
    containerClassName: "",
    errorMsg: "",
    tooltip: "",
  };

  declare inputElRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {};
    this.inputElRef = React.createRef();
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  toggleShowPassword() {
    this.inputElRef.current?.focus();
    this.setState((prev) => ({
      innerType: prev.innerType ? undefined : "text",
    }));
  }

  render() {
    const {
      type = "text",
      id,
      label,
      required,
      disabled,
      containerClassName,
      errorMsg,
      placeholder,
      ...rest
    } = this.props;

    return (
      <div
        className={`custom-input-container ${containerClassName || ""}`}
        aria-invalid={errorMsg ? true : undefined}
      >
        <label htmlFor={id}>{label + (required ? "*" : "")}</label>
        <input
          {...rest}
          ref={this.inputElRef}
          id={id}
          type={this.state.innerType || type}
          required={required}
          placeholder={placeholder || label.toString()}
          disabled={disabled}
          aria-disabled={disabled}
          aria-invalid={errorMsg ? true : undefined}
        />
        {!!errorMsg && <p className="error">{errorMsg}</p>}
        {type === "password" && (
          <button
            type="button"
            className="show-password-button"
            onClick={this.toggleShowPassword}
            disabled={disabled}
            aria-disabled={disabled}
          >
            {this.state.innerType ? (
              <HidePasswordSVG role="img" aria-label="Hide Password" />
            ) : (
              <ShowPasswordSVG role="img" aria-label="Show Password" />
            )}
          </button>
        )}
        {this.props.tooltip && (
          <div className="tooltip">
            <span>{this.props.tooltip}</span>
          </div>
        )}
      </div>
    );
  }
}
