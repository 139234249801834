import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";
import CopyLink from "static/images/copy.svg";
import InfoIcon from "static/images/info.svg";
import DeleteIcon from "static/images/delete-icon.svg";
import EditIcon from "static/images/edit.svg";
import { deleteGA4PropertyId, setGA4PropertyId } from "utilities/settings";
import SectionLoader from "../../../common/section-loader";

interface IntegrationSettingsState {
  setIdLoading: boolean;
  id: string;
  isCopied: boolean;
  propertyId: string;
  showPropertyIdInput?: boolean;
  showInfoContainer: boolean;
}

class IntegrationSettings extends React.Component<
  RouteComponentProps & WithTranslation,
  IntegrationSettingsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.state = {
      setIdLoading: false,
      id: "settings-update",
      isCopied: false,
      showInfoContainer: false,
      showPropertyIdInput: true,
      propertyId: "",
    };

    this.updatePropertyId = this.updatePropertyId.bind(this);
    this.deletePropertyId = this.deletePropertyId.bind(this);
    this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    if (this.context.settings.ga4_property_id) {
      this.setState({
        showPropertyIdInput: false,
        propertyId: this.context.settings.ga4_property_id,
      });
    }
  }

  updatePropertyId(e, propertyId: string) {
    const { t } = this.props;
    this.setState({ setIdLoading: true });

    e.preventDefault();

    if (/\D/.test(propertyId)) {
      toast.error(t("numbersValidation"));
      return;
    }

    if (this.context.project) {
      setGA4PropertyId(this.context.project, propertyId)
        .then(() => {
          toast.success(t("idSetSuccessfully"));
          this.context.setProject(this.context.project);
          this.setState({
            propertyId,
          });
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          this.setState({ showPropertyIdInput: false, setIdLoading: false });
        });
    }
  }

  deletePropertyId() {
    const { t } = this.props;
    this.setState({ setIdLoading: true });

    if (this.context.project) {
      deleteGA4PropertyId(this.context.project)
        .then(() => {
          toast.success(t("idSetSuccessfully"));
          this.setState({ showPropertyIdInput: true, propertyId: "" });
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          this.setState({ setIdLoading: false });
        });
    }
  }

  copyTextToClipboard(text: string, timeout = 2000) {
    this.setState({ isCopied: true });
    navigator.clipboard.writeText(text);

    setTimeout(() => {
      this.setState({ isCopied: false });
    }, timeout);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="settings">
        <div className="card">
          <div className="settings-ga4">
            <h2 className="card--title">{t("Integration")}</h2>
            <p className="note">{t("integrationSettingsDescription")}</p>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/89/Logo_Google_Analytics.svg"
              className="settings-ga4__image"
              alt=""
            />
            <ol className="settings-ga4__main-list">
              <li className="settings-ga4__main-list-item">
                <span className="settings-ga4__main-list-item--title">
                  {t("enterYourID")}
                </span>
                {this.state.setIdLoading ? (
                  <SectionLoader />
                ) : !this.state.showPropertyIdInput ? (
                  <div className="settings-ga4__main-list-item--id">
                    <h2>{t("googleAnalyticsPropertyID")}</h2>
                    <div className="property-id">
                      <p className="property-id">{this.state.propertyId}</p>
                      <div className="property-id__buttons">
                        <button
                          className="property-id--delete button--clear"
                          type="button"
                          onClick={this.deletePropertyId}
                        >
                          <DeleteIcon />
                          {t("removeIntegration")}
                        </button>
                        <button
                          className="property-id--edit  button--clear"
                          type="button"
                          onClick={() => {
                            this.setState({
                              showPropertyIdInput: true,
                            });
                          }}
                        >
                          <EditIcon />
                          {t("editID")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <form className="inputs-container settings-ga4__main-list-item--form">
                    <div className="input-wrapper">
                      <label htmlFor="ga4-id">
                        {t("googleAnalyticsPropertyID")}
                      </label>
                      <input
                        type="text"
                        name="ga4-id"
                        id="ga4-id"
                        placeholder="Ex.: 153293282"
                        value={this.state.propertyId}
                        onChange={(e) => {
                          this.setState({
                            propertyId: e.target.value,
                          });
                        }}
                      />
                      <button
                        type="button"
                        className="info-svg button--clear"
                        onClick={() => {
                          this.setState({
                            showInfoContainer: !this.state.showInfoContainer,
                          });
                        }}
                      >
                        <InfoIcon />
                      </button>
                      {this.state.showInfoContainer && (
                        <div className="info-container">
                          <p>{t("idInfoDesc")}.</p>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.datadrivenu.com/ga4-measurement-id/#:~:text=You%20can%20find%20your%20GA4,or%20in%20Google%20Tag%20Manager."
                          >
                            {t("whereThat")}
                          </a>
                        </div>
                      )}
                    </div>

                    <button
                      className="submit-btn"
                      type="submit"
                      disabled={!this.state.propertyId}
                      onClick={(e) => {
                        this.updatePropertyId(e, this.state.propertyId);
                      }}
                    >
                      {t("saveText")}
                    </button>
                  </form>
                )}
              </li>
              <li className="settings-ga4__main-list-item">
                <span className="settings-ga4__main-list-item--title">
                  {t("authorizeGoogleAnalytics")}
                </span>
                <div className="settings-ga4__steps">
                  <p>{t("completeSteps")}</p>
                  <ol className="settings-ga4__sub-list">
                    <li className="settings-ga4__sub-list-item">
                      {t("goGA4AdminPanel")}
                    </li>
                    <li className="settings-ga4__sub-list-item">
                      {t("clickPropertyAccess")}
                    </li>
                    <li className="settings-ga4__sub-list-item settings-ga4__sub-list-item--flex">
                      {t("authorizeEmail")}:{" "}
                      <span className="settings-ga4__sub-list-item--email">
                        {process.env.GA4_SERVICE_ACCOUNT_EMAIL}
                      </span>
                      <div className="settings-ga4__sub-list-item--copy">
                        <CopyLink />
                        <button
                          type="button"
                          className="button--clear"
                          onClick={() => {
                            // method used to copy text in clipboard
                            this.copyTextToClipboard(this.context.user?.email);
                          }}
                        >
                          {this.state.isCopied ? t("copied") : t("copy")}
                        </button>
                      </div>
                    </li>
                    <li className="settings-ga4__sub-list-item">
                      {t("clickSave")}
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

IntegrationSettings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(IntegrationSettings);
