/**
 * @author Ahmed Serag
 * @date 2019-07-16
 * @description implementation of endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */
// eslint-disable-next-line object-curly-newline
export const ENDPOINTS = {
  overview: {
    method: "GET",
    path: "/overview",
  },
  orders: {
    method: "GET",
    path: "/orders",
  },
  ordersPerDay: {
    method: "GET",
    path: "/orders-breakdown",
  },
  payments: {
    method: "GET",
    path: "/payments",
  },
  customers: {
    method: "GET",
    path: "/customers",
  },
  customersPerDay: {
    method: "GET",
    path: "/customers-breakdown",
  },
  categories: {
    method: "GET",
    path: "/categories-items",
  },
  categoriesSalesExport: {
    method: "GET",
    path: "/categories-export",
  },
  items: {
    method: "GET",
    path: "/items",
  },
  productSummary: {
    method: "GET",
    path: "/product/summary",
  },
  productRecommendations: {
    method: "GET",
    path: "/product/recommendations",
  },
  relatedProducts: {
    method: "GET",
    path: "/product/bought-with",
  },
  projects: {
    method: "GET",
    path: "/projects",
  },
  categoriesSales: {
    method: "GET",
    path: "/categories-sales",
  },
  categoriesSalesChildren: {
    method: "GET",
    path: "/categories-sales/children",
  },
  stock: {
    method: "GET",
    path: "/stock",
  },
  customersCity: {
    method: "GET",
    path: "/customer-city",
  },
  customersCountry: {
    method: "GET",
    path: "/customer-country",
  },
  customersArea: {
    method: "GET",
    path: "/customer-area",
  },
  requestCSRF: {
    method: "GET",
    path: "/csrf-cookie",
  },
  register: {
    method: "POST",
    path: "/auth/register",
  },
  login: {
    method: "POST",
    path: "/auth/login",
  },
  logout: {
    method: "POST",
    path: "/auth/logout",
  },
  currentUser: {
    method: "GET",
    path: "/auth/current",
  },
  updateSettings: {
    method: "PATCH",
    path: "/project",
  },
  updateGoals: {
    method: "PATCH",
    path: "/goals",
  },
  getSettings: {
    method: "GET",
    path: "/projects",
  },
  support: {
    method: "POST",
    path: "/contact",
  },
  updateName: {
    method: "PATCH",
    path: "/auth/change-name",
  },
  updateEmail: {
    method: "PATCH",
    path: "/auth/change-email",
  },
  updatePassword: {
    method: "POST",
    path: "/auth/change-password",
  },
  brands: {
    method: "GET",
    path: "/brands",
  },
  requestPasswordReset: {
    method: "GET",
    path: "/auth/request-reset-password",
  },
  sendOTP: {
    method: "GET",
    path: "/auth/send-otp",
  },
  resetPassword: {
    method: "GET",
    path: "/auth/reset-password",
  },
  setPassword: {
    method: "PATCH",
    path: "/create-user/set-password",
  },
  brandsItemsSales: {
    method: "GET",
    path: "/brand-items",
  },
  customersSpending: {
    method: "GET",
    path: "/customer-segmentation/revenue",
  },
  customersSpentOrderedMore: {
    method: "GET",
    path: "/customer-segmentation/order",
  },
  changeLanguage: {
    method: "PATCH",
    path: "/change-language",
  },
  getSignature: {
    method: "GET",
    path: "/payment/get-signature",
  },
  getCards: {
    method: "GET",
    path: "/payment/cards",
  },
  setDefaultCard: {
    method: "POST",
    path: "/payment/set-card-default",
  },
  deleteCard: {
    method: "POST",
    path: "/payment/delete-card",
  },
  searchItem: {
    method: "GET",
    path: "/product-listing",
  },
  deleteLogo: {
    method: "DELETE",
    path: "/logo",
  },
  getAbandonedCarts: {
    method: "GET",
    path: "/abandoned-carts",
  },
  mostSearchedTerms: {
    method: "GET",
    path: "/search-terms",
  },
  getAllUsers: {
    method: "GET",
    path: "/user-management/get-users",
  },
  getAllRoles: {
    method: "GET",
    path: "/user-management/get-roles",
  },
  deleteUser: {
    method: "DELETE",
    path: "/user-management/delete-user",
  },
  editUserRole: {
    method: "PATCH",
    path: "/user-management/edit-role",
  },
  addUser: {
    method: "POST",
    path: "/user-management/add-user",
  },
  orderHourlyBreakdown: {
    method: "GET",
    path: "/orders-hour-breakdown",
  },
  getLoyaltyPoints: {
    method: "GET",
    path: "/reward-points/summary",
  },
  getLoyaltyPointsPerDay: {
    method: "GET",
    path: "/reward-points/breakdown",
  },
  exportLoyalPoints: {
    method: "GET",
    path: "/reward-points/export",
  },
  getPromoCodes: {
    method: "GET",
    path: "/promocodes/summary",
  },
  exportPromoCodes: {
    method: "GET",
    path: "/promocodes/export",
  },
  getActivityLogs: {
    method: "GET",
    path: "/activity-log",
  },
  deleteAccount: {
    method: "DELETE",
    path: "/auth/user",
  },
  overviewStockRecommendations: {
    method: "GET",
    path: "/recommendation/stock",
  },
  overviewPromotionRecommendations: {
    method: "GET",
    path: "/recommendation/promotion",
  },
  overviewBundleRecommendations: {
    method: "GET",
    path: "/recommendation/bundle",
  },
  getSpecificPageSummary: {
    method: "GET",
    path: "/ga4/page",
  },
  getAllGA4PagesSummary: {
    method: "GET",
    path: "/ga4/summary",
  },
  setGA4PropertyId: {
    method: "PATCH",
    path: "/ga4/set-property-id",
  },
  deleteGA4PropertyId: {
    method: "PATCH",
    path: "/ga4/delete-property-id",
  },
  enableTwoFactorAuthentication: {
    method: "POST",
    path: "/auth/enable-tfa",
  },
  validateTwoFactorAuthentication: {
    method: "POST",
    path: "/auth/validate-tfa-code",
  },
  disableTwoFactorAuthentication: {
    method: "POST",
    path: "/auth/disable-tfa",
  },
  getPricings: {
    method: "GET",
    path: "/pricing",
  },
  getOrdersSinceLastSubscription: {
    method: "GET",
    path: "/subscriptions/upcoming-subscription",
  },
  ordersRevenueBreakdown: {
    method: "GET",
    path: "/orders-revenue-breakdown",
  },
  unsubscribe: {
    method: "POST",
    path: "/subscriptions/unsubscribe",
  },
  advancedFilters: {
    method: "GET",
    path: "/filters",
  },
  signup: {
    method: "POST",
    path: "/auth/register",
  },
  getProjectKeys: {
    method: "GET",
    path: "/keys",
  },
  changeTier: {
    method: "POST",
    path: "/subscriptions/change-tier",
  },
  subscribe: {
    method: "POST",
    path: "/subscriptions/subscribe",
  },
  getChangeTierInfo: {
    method: "POST",
    path: "/subscriptions/change-tier-info",
  },
  confirmRegistration: {
    method: "GET",
    path: "/auth/confirmation-registration",
  },
  customerSegmentsByType: {
    method: "GET",
    path: "/customer-segmentation/segments",
  },
  createSegment: {
    method: "POST",
    path: "/filters-segments",
  },
  getAllFiltersSegments: {
    method: "GET",
    path: "/filters-segments",
  },
  deleteFiltersSegment: {
    method: "DELETE",
    path: "/filters-segments",
  },
  updateFiltersSegment: {
    method: "PUT",
    path: "/filters-segments",
  },
  getStripeBalance: {
    method: "GET",
    path: "/stripe/balance",
  },
  getCancelledOrdersPercentage: {
    method: "GET",
    path: "/canceled-orders/percentage",
  },
  getTopProductsInCancelledOrders: {
    method: "GET",
    path: "/canceled-orders/top-products",
  },
  getTopCategoriesInCancelledOrders: {
    method: "GET",
    path: "/canceled-orders/top-categories",
  },
  getTopBrandsInCancelledOrders: {
    method: "GET",
    path: "/canceled-orders/top-brands",
  },
  getCancelledOrdersByPaymentMethodPercentage: {
    method: "GET",
    path: "/canceled-orders/percentage/by-payment-method",
  },
  getCancelledOrdersByOrderSourcePercentage: {
    method: "GET",
    path: "/canceled-orders/percentage/by-order-source",
  },
  getCancelledOrdersByFilterSegmentPercentage: {
    method: "GET",
    path: "/canceled-orders/percentage/by-filter-segments",
  },
  getCancelledOrdersBreakdown: {
    method: "GET",
    path: "/canceled-orders/breakdown",
  },
  getCustomersCountBySegment: {
    method: "GET",
    path: "/customers/segment-count",
  },
};
