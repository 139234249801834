/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Stock related utilities.
 * @filename stock.ts
 */
import {
  GenericRequestPayload,
  ItemsSales,
  StockResponse,
} from "interfaces/overview";
import { Stock } from "api/stock";
import { PaginationPayload } from "interfaces/payload";
import {
  ProductRecommendations,
  ProductRequestPayload,
  ProductSummary,
  RelatedProducts,
  StockRequestPayload,
} from "interfaces/stock";
import { getPaginatedPayloadData, getPayloadData, handleError } from "./common";

/**
 * handles fetching the products top sales data.
 * @param payload
 * @returns
 */
export function getItemsSales(
  payload: GenericRequestPayload
): Promise<PaginationPayload<ItemsSales[]>> {
  return Stock.itemsSales(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching items stock for a given project to be displayed in the stock chart
 * @param payload
 * @returns
 */
export function getItemsStock(
  payload: StockRequestPayload
): Promise<StockResponse> {
  return Stock.itemsStock(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching the product summary.
 * @param payload
 * @returns
 */
export function getProductSummary(
  payload: ProductRequestPayload
): Promise<ProductSummary> {
  return Stock.productSummary(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching the product recommendations.
 * @param payload
 * @returns
 */
export function getProductRecommendations(
  payload: ProductRequestPayload
): Promise<ProductRecommendations> {
  return Stock.productRecommendations(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching Products Frequently Bought with a certain Product.
 * @param payload
 * @returns
 */
export function getRelatedProducts(
  payload: ProductRequestPayload
): Promise<RelatedProducts[]> {
  return Stock.relatedProducts(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
/**
 * handles searching items in products analytics page
 * @param payload
 * @returns
 */
export function getSearchItems(
  payload: GenericRequestPayload
): Promise<PaginationPayload<ItemsSales[]>> {
  return Stock.searchItem(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
