/**
 * @author Salma Hefnawy
 * @date 2022-04-06
 * @description implementation of User management related API calls.
 * @filename user-management.ts
 */
import { Payload } from "interfaces/payload";
import { User as UserInterface } from "interfaces/user-management";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class UserManagement {
  public static getAllUsers(): Promise<Payload<UserInterface[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getAllUsers.path}`,
      {
        method: ENDPOINTS.getAllUsers.method,
      }
    );
  }

  public static getAllRoles(): Promise<Payload<string[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getAllRoles.path}`,
      {
        method: ENDPOINTS.getAllRoles.method,
      }
    );
  }

  /**
   * Delete user.
   * @param {string} id id
   */
  public static deleteUser(id: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteUser.path}`
    );
    url.searchParams.append("id", id);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteUser.method,
    });
  }

  /**
   * Edit user role.
   * @param {string} id
   * @param {string} role
   */
  public static editUserRole(
    id: string,
    role: string
  ): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.editUserRole.path}`
    );
    url.searchParams.append("id", id);
    url.searchParams.append("role", role);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.editUserRole.method,
    });
  }

  /**
   * Add user role.
   * @param {string} name
   * @param {string} email
   * @param {string} role
   */
  public static addUser(
    name: string,
    email: string,
    role: string
  ): Promise<Payload<unknown>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.addUser.path}`);
    url.searchParams.append("name", name);
    url.searchParams.append("email", email);
    url.searchParams.append("role", role);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.addUser.method,
    });
  }
}
