/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description login page.
 * @filename index.tsx
 */
import React from "react";
import { Field, Form, Formik } from "formik";
import { object as YUPObject, string as YUPString } from "yup";
import { Redirect, RouteComponentProps, NavLink, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Authenticator } from "utilities/authenticator";
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import SideImage from "static/images/illustrations/Component_6.png";
import OtpBox from "../../common/otp-box";
import Input from "../../common/Input";
import SectionLoader from "../../common/section-loader";

interface LoginState {
  email: string;
  password: string;
  showOtpBox: boolean;
  phoneExt?: string;
  phoneNumber?: string;
  errorMessage: string;
}

export class Login extends React.Component<RouteComponentProps, LoginState> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showOtpBox: false,
      errorMessage: "",
    };
    this.toggleOtpBox = this.toggleOtpBox.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.loginWithOtp = this.loginWithOtp.bind(this);
  }

  componentDidMount() {
    // set page title
    document.title = "Login";
  }

  /**
   * show/hide the OTP box
   */
  toggleOtpBox() {
    this.setState((prevState) => ({
      showOtpBox: !prevState.showOtpBox,
    }));
  }

  submitLogin(values: Pick<LoginState, "email" | "password">) {
    return Authenticator.login(values.email, values.password)
      .then((result) => {
        this.context.updateUser(result.user);
        return Promise.resolve(result);
      })
      .catch((error) => {
        /* after logging in if user was blocked there will be a message
        that user has been blocked so if the error message returned from backend
        contained "blocked" we show a message that user is blocked */
        if (error[0].includes("blocked")) {
          toast.error(error[0]);
          return Promise.reject();
        }
        /* if user has 2FA enabled the error message for the login will be
        an array that contains the phone number extension (country code)
        and the phone number which we then use to which we send the otp fo the user */
        if (error.length === 2) {
          this.setState({
            phoneExt: error[0],
            phoneNumber: error[1],
          });
          this.toggleOtpBox();
        }
        const errorsArr = Array.isArray(error) ? error : [error];
        errorsArr.forEach((err) => toast.error(err));
        return Promise.reject();
      });
  }

  /**
   * @description user login with otp
   * @param email user's email
   * @param password user's password
   * @param otp otp sent to user's phone
   */
  loginWithOtp(email: string, password: string, otp: string) {
    Authenticator.login(email, password, otp)
      .then((result) => {
        this.context.updateUser(result.user);
      })
      .catch((error) => {
        toast.error(error);
        if (error.length === 1) {
          this.setState({
            errorMessage: error,
          });
        }
      });
  }

  render(): React.ReactNode {
    if (this.context.user) {
      return <Redirect to={ROUTES.Overview.path} />;
    }

    return (
      <div className="form-container">
        <div className="auth-wrapper">
          <img src={SideImage} alt="" />
          <Formik
            onSubmit={this.submitLogin}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={YUPObject().shape({
              email: YUPString().required("Email is required"),
              password: YUPString().required("Password is required"),
            })}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => (
              <Form className="auth__form" noValidate>
                <h1 className="auth__title">Dashboard Sign In</h1>
                <Field name="email">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <Input
                        type="email"
                        id="email"
                        label="Email"
                        autoComplete="email"
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                        disabled={isSubmitting}
                        {...field}
                        onChange={(e) => {
                          this.setState({
                            email: e.target.value,
                          });
                          field.onChange(e);
                        }}
                        required
                      />
                    </div>
                  )}
                </Field>
                <Field name="password">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <Input
                        type="password"
                        id="password"
                        label="Password"
                        autoComplete="password"
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                        disabled={isSubmitting}
                        {...field}
                        onChange={(e) => {
                          this.setState({
                            password: e.target.value,
                          });
                          field.onChange(e);
                        }}
                        required
                      />
                      <NavLink
                        to={ROUTES.ForgotPassword.path}
                        className="auth__info-text"
                      >
                        Forgot Password?
                      </NavLink>
                    </div>
                  )}
                </Field>
                <div className="auth__cta-container">
                  <button
                    type="submit"
                    className="auth-button"
                    aria-label="Sign in"
                    disabled={!isValid || isSubmitting}
                    aria-disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <SectionLoader
                        width={30}
                        height={20}
                        color="var(--disabled-button-font-color)"
                      />
                    ) : (
                      <span>Sign in</span>
                    )}
                  </button>
                </div>
                <p className="auth__info-text">
                  Don&apos;t have an account?{" "}
                  <Link to={ROUTES.Signup.path}>Sign up</Link>
                </p>
              </Form>
            )}
          </Formik>
          {this.state.showOtpBox && (
            <OtpBox
              title="Two-Factor Authentication"
              toggleOtpBox={this.toggleOtpBox}
              phone={`${this.state.phoneExt}${this.state.phoneNumber}`}
              email={this.state.email}
              password={this.state.password}
              loginWithOtp={this.loginWithOtp}
              errorMessage={this.state.errorMessage}
            />
          )}
        </div>
      </div>
    );
  }
}

Login.contextType = ANALYTICS_CONTEXT;
