/**
 * @author Ahmed Serag
 * @date 2021-12-05
 * @description settings nav-bar
 * @filename settings-nav-bar.tsx
 */
import React from "react";
import { RouteComponentProps, Route, Switch } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserRole } from "interfaces/user";
import { hasPermission } from "utilities/common";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import IntegrationIcon from "static/images/integration.svg";
import UserManagementIcon from "static/images/user-management.svg";
import AccountSettingsIcon from "static/images/account-settings.svg";
import AppearanceSettingsIcon from "static/images/appearance-settings.svg";
import PaymentAndSubscriptionsIcon from "static/images/payment-and-subscriptions.svg";
import DataSettingsIcon from "static/images/data-settings.svg";
import AccountSettings from "./account-settings";
import Header from "../../common/header";
import AppearanceSettings from "./appearance-settings";
import IntegrationSettings from "./integration-settings";
import SettingsNavBar from "./settings-nav-bar";
import DataSettings from "./data-settings";
import UserManagement from "./user-management";
import IntegrationKeysSettings from "./ecommerce-integration-settings";
import PaymentsAndSubscriptions from "./payment-and-subscriptions";

class Settings extends React.Component<RouteComponentProps & WithTranslation> {
  /**
   * path to settings page in the url
   */
  static PATH = "/settings";

  /**
   * map of available routes in the app to their locations in the url.
   */
  static ROUTES_TABS = {
    // Add Available Routes with it's Components and other details
    AccountSettings: {
      component: AccountSettings,
      path: `${Settings.PATH}`,
      exact: true,
      public: false,
      details: {
        icon: <AccountSettingsIcon />,
        label: "accountSettings",
        description: "settings:managePersonalInformation",
      },
      roles: [],
    },
    PaymentAndSubscription: {
      component: PaymentsAndSubscriptions,
      path: `${Settings.PATH}/payment-and-subscription`,
      exact: true,
      public: false,
      details: {
        icon: <PaymentAndSubscriptionsIcon />,
        label: "settings:paymentSettings",
        description: "settings:managePaymentDetails",
      },
      roles: [UserRole.admin],
    },
    DataSettings: {
      component: DataSettings,
      path: `${Settings.PATH}/data-settings`,
      exact: true,
      public: false,
      details: {
        icon: <DataSettingsIcon />,
        label: "settings:dataSettings",
        description: "settings:setStockSettingsOrderGoals",
      },
      roles: [],
    },
    AppearanceSettings: {
      component: AppearanceSettings,
      path: `${Settings.PATH}/appearance-settings`,
      exact: true,
      public: false,
      details: {
        icon: <AppearanceSettingsIcon />,
        label: "settings:appearanceSettings",
        description: "settings:customizeColorsAddLogo",
      },
      roles: [UserRole.admin, UserRole.management],
    },
    UserManagement: {
      component: UserManagement,
      path: `${Settings.PATH}/user-management`,
      exact: true,
      public: false,
      details: {
        icon: <UserManagementIcon />,
        label: "userManagement",
        description: "settings:ManageUsersAndRoles",
      },
      roles: [UserRole.admin],
    },
    Integration: {
      component: IntegrationSettings,
      path: `${Settings.PATH}/integration`,
      exact: true,
      public: false,
      details: {
        icon: <IntegrationIcon />,
        label: "GA4Integration",
        description: "settings:authorizeGoogleAnalytics",
      },
      roles: [],
    },
    EcommerceIntgeration: {
      component: IntegrationKeysSettings,
      path: `${Settings.PATH}/integration-keys`,
      exact: true,
      public: false,
      details: {
        icon: <IntegrationIcon />,
        label: "integrationKeys",
        description: "settings:platformIntegrationKeys",
      },
      roles: [],
    },
  };

  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  componentDidMount() {
    // set page title
    document.title = "Settings";
  }

  render() {
    const { t } = this.props;

    const ROUTES: React.ReactNode[] = [];
    const TABS: {
      icon: string;
      label: string;
      description: string;
      active: boolean;
      onSelectTab: () => void;
    }[] = [];
    const AvailableTabs = Object.keys(Settings.ROUTES_TABS);
    for (const tab of AvailableTabs) {
      // show tab only if the user is permitted according to his role
      const userHasPermission = hasPermission(
        Settings.ROUTES_TABS[tab].roles,
        this.context.user.roles
      );

      // deny access to user management tab if user is super admin
      const userManagementTabAccessRight = !(
        this.context.user.is_admin &&
        Settings.ROUTES_TABS[tab].details.label === "userManagement"
      );

      if (userHasPermission && userManagementTabAccessRight) {
        ROUTES.push(
          <Route
            key={tab}
            path={Settings.ROUTES_TABS[tab].path}
            exact={Settings.ROUTES_TABS[tab].exact}
            component={Settings.ROUTES_TABS[tab].component}
          />
        );
        TABS.push({
          label: t(Settings.ROUTES_TABS[tab].details.label),
          description: t(Settings.ROUTES_TABS[tab].details.description),
          icon: Settings.ROUTES_TABS[tab].details.icon,
          active:
            Settings.ROUTES_TABS[tab].path === this.props.location.pathname,
          onSelectTab: () => {
            if (
              Settings.ROUTES_TABS[tab].path !== this.props.location.pathname
            ) {
              this.props.history.push(Settings.ROUTES_TABS[tab].path);
            }
          },
        });
      }
    }

    return (
      <div className="settings">
        <Header title={t("settings")} showPeriod={false} />
        <div className="settings-container">
          <SettingsNavBar tabs={TABS} />
          <Switch>{ROUTES}</Switch>
        </div>
      </div>
    );
  }
}
Settings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(Settings);
