/**
 * @author Raghda Wessam
 * @date 2020-08-10
 * @description implementation of loader component
 * to be used while loading.
 * @filename loader.tsx
 */
import React from "react";
import LoaderSpinner from "react-loader-spinner";

export default class Loader extends React.Component {
  render() {
    return (
      <div className="loader-wrapper">
        <LoaderSpinner
          type="Puff"
          color="var(--black)"
          height={100}
          width={100}
          timeout={10000}
          visible
        />
      </div>
    );
  }
}
