/**
 * This regex verifies the following (using positive lookahead):
 * - At least 1 lowercase letter /(?=.*[a-z])/
 * - At least 1 uppercase letter /(?=.*[A-Z])/
 * - At least 1 digit /(?=.*\d)/
 * - At least 1 special character (any character not in the previous classes /(?=.*[^A-Za-z0-9])/
 *
 * And then proceeds to match whatever's passed under these conditions, with
 * minimum length of 8 characters. /([A-Za-z0-9]|[^A-Za-z0-9]){8,}/
 */
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])([A-Za-z0-9]|[^A-Za-z0-9]){8,}$/;

/**
 * regex to validate a valid URL accounting for all paths/url locations and query params.
 */

export const URL_REGEX =
  /((http(s)?):\/\/)?([(www.)?a-zA-Z0-9@:%.+~#=]{2,256}\.[a-z]{2,6}\b)([-a-zA-Z0-9@:%+.~#?&//=]*)/g;
