export const PUBLIC_WEBSITE_URL = "https://www.onesight.ai";

export const MENU_NAV_ITEMS = [
  { label: "Home", url: PUBLIC_WEBSITE_URL },
  { label: "Pricing", url: `${PUBLIC_WEBSITE_URL}/pricing` },
  { label: "Features", url: `${PUBLIC_WEBSITE_URL}/features` },
  { label: "Contact", url: `${PUBLIC_WEBSITE_URL}/contact` },
  { label: "About", url: `${PUBLIC_WEBSITE_URL}/about` },
];
export const UTILITY_NAV_ITEMS = [
  {
    label: "Terms & Conditions",
    url: `${PUBLIC_WEBSITE_URL}/utility-pages/terms`,
  },
  {
    label: "Privacy Policy",
    url: `${PUBLIC_WEBSITE_URL}/utility-pages/privacy`,
  },
  {
    label: "Refund Policy",
    url: `${PUBLIC_WEBSITE_URL}/utility-pages/refundpolicy`,
  },
];
