/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description customers tab of the App.
 * @filename customers.tsx
 */
import {
  CustomersOverview as CustomersOverviewInterface,
  CustomersPerDay,
} from "interfaces/customers-overview";
import React from "react";
import { Chart } from "chart.js";
import {
  getCustomerSegmentsByType,
  getCustomersOverview,
  getCustomersPerDay,
} from "utilities/customers";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { getBarChart, getChartData, getChartRange } from "utilities/charts";
import {
  exist,
  getDurationRange,
  getNumberFormatWithSeparators,
  getNumberReadableValue,
} from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import { CSVLink } from "react-csv";
import { CustomerDetails } from "interfaces/customers-spending";
import Loader from "common/Loader";
import SectionLoader from "common/section-loader";
import CustomersIcon from "static/images/total-customers.svg";
import ArrowDownIcon from "static/images/down-arrow.svg";

type CustomersOverviewState = Partial<CustomersOverviewInterface> & {
  loading: boolean;
  customersPerDay?: CustomersPerDay;
  comparedCustomersPerDay?: CustomersPerDay;
  comparedCustomersOverview?: CustomersOverviewInterface;
  customerSegmentType?: number;
  segmentTotalCount?: number;
  segmentList?: CustomerDetails[];
  customerSegmentTypeMenu?: boolean;
  customerSegmentTypeLoading?: boolean;
  id: string;
  chart?: Chart<"bar", number[], string>;
};

interface CustomersOverviewProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class CustomersOverview extends React.Component<
  CustomersOverviewProps & WithTranslation,
  CustomersOverviewState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  /* the allowed segment types using there localization keys
  the segment types are mapped to their indices such as
  0 -> allUsers
  1 -> newUsers
  2 -> newCustomers
  3 -> returningCustomers
  4-> newUsersWithNoOrders */
  customerSegmentTypes = [
    "allUsers",
    "newUsers",
    "newCustomers",
    "returningCustomers",
    "newUsersWithNoOrders",
  ];

  constructor(props: CustomersOverviewProps & WithTranslation) {
    super(props);
    this.ChartRef = React.createRef();
    this.state = {
      loading: true,
      customerSegmentType: 3,
      customerSegmentTypeMenu: false,
      customerSegmentTypeLoading: true,
      id: Date.now().toString(),
    };
    this.loadData = this.loadData.bind(this);
    this.toggleCustomerSegmentTypeMenu =
      this.toggleCustomerSegmentTypeMenu.bind(this);
    this.getCustomerSegmentsByType = this.getCustomerSegmentsByType.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Customers Overview";
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  getCustomerSegmentsByType() {
    if (exist(this.context, ["project", "currentPeriod"])) {
      this.setState({ customerSegmentTypeLoading: true });
      getCustomerSegmentsByType({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
        segment: this.state.customerSegmentType,
      })
        .then((data) => {
          this.setState({
            segmentList: data.list,
            segmentTotalCount: data.total_count,
            customerSegmentTypeLoading: false,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  loadData() {
    this.setState({ loading: true });
    const datasets: {
      values: number[];
      label: string;
      backgroundColor?: string;
      title: string;
    }[] = [];
    let chart = this.state.chart;
    let customersOverviewPromise = Promise.resolve();
    let customersPerDayPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      customersOverviewPromise = getCustomersOverview({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((overview) => {
          this.setState({
            ...overview,
          });
          this.getCustomerSegmentsByType();
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      customersPerDayPromise = getCustomersPerDay({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((customersPerDay) => {
          datasets.push({
            label: i18next.t("customers:newCustomers"),
            values: getChartData(
              this.context.currentPeriod,
              customersPerDay.new_customer_count,
              "sum"
            ),
            backgroundColor: "#3DD2CE",
            title: i18next.t("customers:monthlyBreakdown"),
          });
          datasets.push({
            label: i18next.t("customers:returnCustomers"),
            values: getChartData(
              this.context.currentPeriod,
              customersPerDay.returning_customer_count,
              "sum"
            ),
            backgroundColor: "#1792FF",
            title: i18next.t("customers:monthlyBreakdown"),
          });
          this.setState({
            customersPerDay,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }

    let comparedCustomersOverviewPromise = Promise.resolve();
    let comparedCustomersPerDayPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedCustomersOverviewPromise = getCustomersOverview({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedCustomersOverview) => {
          this.setState({
            comparedCustomersOverview,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      comparedCustomersPerDayPromise = getCustomersPerDay({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedCustomersPerDay) => {
          datasets.push({
            label: "compared new customers",
            values: getChartData(
              this.context.comparedPeriod,
              comparedCustomersPerDay.new_customer_count,
              "sum"
            ),
            title: i18next.t("customers:monthlyBreakdown"),
          });
          datasets.push({
            label: "compared returning customers",
            values: getChartData(
              this.context.comparedPeriod,
              comparedCustomersPerDay.returning_customer_count,
              "sum"
            ),
            title: i18next.t("customers:monthlyBreakdown"),
          });
          this.setState({
            comparedCustomersPerDay,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      this.setState({
        comparedCustomersOverview: null,
        comparedCustomersPerDay: null,
      });
    }

    Promise.all([
      customersOverviewPromise,
      customersPerDayPromise,
      comparedCustomersOverviewPromise,
      comparedCustomersPerDayPromise,
    ]).then(() => {
      this.setState({
        loading: false,
      });
      if (chart) {
        chart.destroy();
      }
      if (this.ChartRef.current) {
        chart = getBarChart(
          this.ChartRef.current,
          getChartRange(this.context.currentPeriod),
          datasets,
          this.state.id
        );
      }
    });
  }

  toggleCustomerSegmentTypeMenu() {
    this.setState({
      customerSegmentTypeMenu: !this.state.customerSegmentTypeMenu,
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div ref={this.props.exportRef}>
        <div className="card overview-card transparent-card">
          {Object.keys(this.context.appliedFilters).length > 0 && (
            <div className="disclaimer">
              <span>{t("customersAppliedFiltersDisclaimer")}</span>
            </div>
          )}
          <h2 className="card--title">
            {t("customers")} {t(this.context.currentPeriod.alias)}
            <span className="duration">
              (
              {getDurationRange(
                this.context.currentPeriod.type,
                this.context.currentPeriod.from,
                this.context.currentPeriod.to
              )}
              )
            </span>
          </h2>
          <div className="overview-items">
            <div className="item">
              <span className="tooltip">{t("newCustomersDisclaimer")}</span>
              <span className="header">
                {getNumberFormatWithSeparators(this.state.new_customer_count)}
              </span>
              <span className="title">{t("newCustomers")}</span>
            </div>
            <div className="item">
              <span className="tooltip">{t("newUsersDisclaimer")}</span>
              <span className="header">
                {getNumberFormatWithSeparators(this.state.new_users_count)}
              </span>
              <span className="title">{t("newUsers")}</span>
            </div>
            <div className="item">
              <span className="tooltip">
                {t("returningCustomersDisclaimer")}
              </span>
              <span className="header">
                {getNumberFormatWithSeparators(
                  this.state.returning_customer_count
                )}
              </span>
              <span className="title">{t("returnCustomers")}</span>
            </div>
            {this.state.guest_customer_count > 0 && (
              <div className="item">
                <span className="header">
                  {getNumberFormatWithSeparators(
                    this.state.guest_customer_count
                  )}
                </span>
                <span className="title">{t("guestCustomers")}</span>
              </div>
            )}
            <div className="item">
              <span className="tooltip">{t("totalCustomersDisclaimer")}</span>
              <span className="header">
                {getNumberFormatWithSeparators(this.state.total_customers)}
              </span>
              <span className="title">{t("totalCustomersAllTime")}</span>
            </div>
            <div className="item">
              <span className="tooltip">{t("totalUsersDisclaimer")}</span>
              <span className="header">
                {getNumberFormatWithSeparators(this.state.total_users)}
              </span>
              <span className="title">{t("totalUsersAllTime")}</span>
            </div>
          </div>
          {this.context.comparedPeriod && (
            <>
              <h2 className="card--title">
                {t("customers")} {t(this.context.comparedPeriod?.alias)}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.comparedPeriod.type,
                    this.context.comparedPeriod.from,
                    this.context.comparedPeriod.to
                  )}
                  )
                </span>
              </h2>
              <div className="overview-items">
                <div className="item">
                  <span className="header">
                    {getNumberFormatWithSeparators(
                      this.state.comparedCustomersOverview?.new_customer_count
                    )}
                  </span>
                  <span className="title">{t("newCustomers")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberFormatWithSeparators(
                      this.state.comparedCustomersOverview
                        ?.returning_customer_count
                    )}
                  </span>
                  <span className="title">{t("returnCustomers")}</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card">
          <h2 className="card--title card-graph--title">
            <div>
              {t("monthlyBreakdown")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod?.from,
                  this.context.currentPeriod?.to
                )}
                )
              </span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* TODO: fix export chart
            <a href="" id={this.state.id}>
              {t("common:exportChart")}
            </a> */}
          </h2>
          <div className="bar-chart__container">
            <canvas ref={this.ChartRef} id={this.state.id} />
          </div>
        </div>
        <div className="calculators">
          <div className="card">
            <h2 className="card--title">{t("exportCustomerList")}</h2>
            <div className="card--section">
              <div className="dropdown-container">
                <span className="secondary">{t("customerType")}</span>
                {t(this.customerSegmentTypes[this.state.customerSegmentType])}
                <ArrowDownIcon
                  className="dropdown-icon"
                  enableBackground="new 0 0 330 330"
                  onClick={this.toggleCustomerSegmentTypeMenu}
                />
                {this.state.customerSegmentTypeMenu && (
                  <div className="dropdown-menu">
                    {/* populating the dropdown menu with segment types
                    segments are sent to backend as a number, this is taken care of
                    using the {customerSegmentTypes} array as every type is mapped to
                    its index */}
                    {this.customerSegmentTypes.map((segment, index) => (
                      <div
                        className={`item ${
                          this.state.customerSegmentType === index
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          this.setState({ customerSegmentType: index });
                          this.toggleCustomerSegmentTypeMenu();
                        }}
                      >
                        {t(segment)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="button save"
                onClick={this.getCustomerSegmentsByType}
              >
                {t("apply")}
              </button>
            </div>
            <dl className="quartile-card__list">
              <dt>
                <CustomersIcon /> {t("totalCustomers")}
              </dt>
              <dd>
                {this.state.customerSegmentTypeLoading ? (
                  <SectionLoader />
                ) : (
                  getNumberReadableValue(this.state.segmentTotalCount)
                )}
              </dd>
            </dl>
            {this.state.segmentList &&
            !this.state.customerSegmentTypeLoading ? (
              <CSVLink
                data={this.state.segmentList}
                filename={`${t(
                  this.customerSegmentTypes[this.state.customerSegmentType]
                )}.csv`}
                className="export-list-button"
                target="_blank"
              >
                {t("exportList")}
              </CSVLink>
            ) : (
              <SectionLoader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

CustomersOverview.contextType = ANALYTICS_CONTEXT;
export default withTranslation("customers")(CustomersOverview);
