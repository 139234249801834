/**
 * @author Ahmed Serag
 * @date 2020-07-20
 * @description implementation of Customer related API calls.
 * @filename customer.ts
 */
import { Payload } from "interfaces/payload";
// import {
//   CustomersOverview,
//   CustomersPerDay,
// } from "interfaces/customers-overview";
import {
  CustomersCount,
  MarketingCampaignRecieverCustomers,
} from "interfaces/customers-overview";
// import { GenericRequestPayload } from "interfaces/overview";
// import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class MarketingCampaigns {
  public static getCustomersCountBySegment(
    project: string,
    recievers?: MarketingCampaignRecieverCustomers
  ): Promise<Payload<CustomersCount>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCustomersCountBySegment.path}`
    );

    url.searchParams.append("project", project);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCustomersCountBySegment.method,
      body: JSON.stringify(recievers),
    });
  }
}
