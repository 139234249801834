/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @author Ahmed Serag
 * @date 2019-07-16
 * @description implementation of network calls.
 * @filename network.ts
 */

import { ERRORS } from "consts/errors";

/**
 * class responsible for executing network requests.
 *
 * @export
 * @class network
 */
export class Network {
  /**
   * execute a request to a given url with given options
   *
   * @static
   * @param {string} url url of the request.
   * @param {RequestInit} init set of options sent with the url.
   * @returns {Promise<any>} promise to return the Json response of the request.
   * @memberof Network
   */
  public static fetch(
    url: string,
    init: RequestInit,
    addAuth = true
  ): Promise<any> {
    return fetch(url, {
      mode: "cors",
      credentials: "include",
      ...init,
      headers: Network.getHeaders(init.headers, addAuth),
    })
      .catch((error) => {
        return Promise.reject(error);
      })
      .then((response: Response) => {
        return Network.handleResponseBasedOnStatus(response);
      });
  }

  /**
   * get headers that should be added to the request.
   *
   * @static
   * @param {HeadersInit} [originalHeaders] optional headers to be
   * added/overwrite the default headers.
   * @returns {HeadersInit} headers object that needs to be added to the request.
   * @memberof Network
   */
  public static getHeaders(
    originalHeaders?: HeadersInit,
    addAuth = true
  ): HeadersInit {
    const CookiesArray = document.cookie.split("=");
    const XSRFCookieIndex = CookiesArray.findIndex((z) => z === "XSRF-TOKEN");

    const headers: HeadersInit = originalHeaders ?? {
      "content-type": "application/json",
      accept: "application/json",
    };

    if (XSRFCookieIndex >= 0 && XSRFCookieIndex < CookiesArray.length) {
      headers["X-XSRF-TOKEN"] = decodeURI(CookiesArray[XSRFCookieIndex + 1]);
    }

    if (addAuth && localStorage.getItem(process.env.ACCESS_TOKEN_KEY)) {
      headers["Authorization"] = `Bearer ${localStorage.getItem(
        process.env.ACCESS_TOKEN_KEY
      )}`;
    }

    return headers;
  }

  /**
   * handle various types of errors from network request based on response status.
   *
   * @static
   * @param {Response} response response from network request
   * @returns {Promise<any>} promise to return an error with a specific message
   * @memberof Network
   */
  private static handleResponseBasedOnStatus(
    response: Response
  ): Promise<unknown> {
    let promise: Promise<unknown>;

    switch (response.status) {
      case 200:
      case 201:
        promise = response.json();
        break;
      case 204:
        promise = Promise.resolve();
        break;
      case 409:
        // trying to create a resource that
        // some of it's dependencies aren't available.
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
        break;
      case 422:
        promise = response.json().then((data) => {
          return Promise.reject(data && (data.errors || data.message));
        });
        break;
      case 401:
        // localStorage.removeItem(process.env.ACCESS_TOKEN_KEY);
        promise = response.json().then((data) => {
          return Promise.reject(data?.data ? data?.data : data?.message);
        });
        break;
      case 400:
        promise = response.json().then((data) => {
          return Promise.reject(data.message);
        });
        break;
      default:
        promise = response.json().then((data) => {
          return Promise.reject({
            message: data?.message,
            status: response.status,
          });
        });
    }

    return promise.catch((error) => {
      return Promise.reject(error ?? ERRORS.unexpected);
    });
  }
}
