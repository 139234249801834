/**
 * @author Youssef Tarek
 * @date 2022-06-27
 * @description ios-like toggle switch
 * @filename toggle-switch.tsx
 */

import React from "react";

interface ToggleSwitchProps {
  id: string;
  checked: boolean;
  onChange(checked: boolean): void;
  name: string;
}

export class ToggleSwitch extends React.Component<ToggleSwitchProps, never> {
  render(): React.ReactNode {
    return (
      <div>
        <div dir="ltr" className="toggle-switch small-switch">
          <input
            type="checkbox"
            name={this.props.name}
            className="toggle-switch-checkbox"
            id={this.props.id}
            checked={this.props.checked}
            onChange={(e) => this.props.onChange(e.target.checked)}
          />
          {this.props.id ? (
            <label
              className="toggle-switch-label clearfix"
              htmlFor={this.props.id}
            >
              <span className="toggle-switch-inner" />
              <span className="toggle-switch-switch" />
            </label>
          ) : null}
        </div>
        <label htmlFor={this.props.id} className="toggle-switch--label">
          {this.props.name}
        </label>
      </div>
    );
  }
}
