/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description BreakdownChart tab of the App.
 * @filename overview.tsx
 */
import React from "react";
import { Chart } from "chart.js";
import { exist, getDurationRange } from "utilities/common";
import {
  getBarChart,
  getChartData,
  getChartRange,
  getLineChart,
} from "utilities/charts";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { DayHours, WeekDaysLong } from "consts/periods";
import { OrdersHourlyBreakdown, WeekDays } from "interfaces/orders-overview";
import { GenericRequestPayload } from "interfaces/overview";

interface BreakdownChartState {
  dataPerDay: number[];
  totalData?: number;
  comparedDataPerDay?: number[];
  comparedTotalData?: number;
  activeDay?: WeekDays;
  loading: boolean;
  id: string;
  chart?: Chart<"line", number[], string> | Chart<"bar", number[], string>;
}

interface BreakdownChartProps {
  getDataPerDay?(payload: GenericRequestPayload): Promise<number[]>;
  chartLabel: string;
  chartTitle: string;
  hourlyBreakdown?: OrdersHourlyBreakdown;
  dataPerDay?: number[];
}

class BreakdownChart extends React.Component<
  BreakdownChartProps & WithTranslation,
  BreakdownChartState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: BreakdownChartProps & WithTranslation) {
    super(props);
    this.ChartRef = React.createRef();

    this.state = {
      dataPerDay: null,
      loading: true,
      activeDay: WeekDays.SUNDAY,
      id: `${Date.now().toString()}-BreakdownChart`,
    };
    this.loadData = this.loadData.bind(this);
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    if (this.props.getDataPerDay) {
      this.loadData();
      this.context.addUpdatesListener(this.state.id, this.loadData);
    } else {
      this.loadChart();
    }
  }

  componentWillUnmount() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    let chart = this.state.chart;
    const datasets: {
      values: number[];
      label: string;
      backgroundColor?: string;
      borderColor?: string;
      title: string;
    }[] = [];

    const currDate = new Date();
    const prevDate = new Date(
      new Date().setFullYear(currDate.getFullYear() - 1)
    );
    const currentYear = currDate.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );
    const previousYear = prevDate.toLocaleDateString(
      i18next.language === "en" ? "en-us" : "ar-eg",
      { year: "numeric" }
    );

    let DataPerDayPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      DataPerDayPromise = this.props
        .getDataPerDay({
          project: this.context.project,
          from: this.context.currentPeriod.from,
          to: this.context.currentPeriod.to,
          filters: this.context.appliedFilters,
        })
        .then((dataPerDay) => {
          if (this.context.comparedPeriod?.type === "quarter") {
            // to get the total data for quarter
            const totalData = dataPerDay.reduce(
              (prevValue, currValue) => prevValue + currValue,
              0
            );
            this.setState({ totalData });
          } else {
            datasets.push({
              label: i18next.t(`common:${this.context.currentPeriod.alias}`),
              values: getChartData(this.context.currentPeriod, dataPerDay),
              backgroundColor: "#44EBA6",
              borderColor: "#44EBA6",
              title: this.props.chartTitle,
            });
            this.setState({ dataPerDay });
          }
        });
    }

    let PreviousDataPerDayPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      PreviousDataPerDayPromise = this.props
        .getDataPerDay({
          project: this.context.project,
          from: this.context.comparedPeriod.from,
          to: this.context.comparedPeriod.to,
          filters: this.context.appliedFilters,
        })
        .then((dataPerDay) => {
          if (this.context.comparedPeriod.type === "quarter") {
            const comparedTotalData = dataPerDay.reduce(
              (prevValue, currValue) => prevValue + currValue,
              0
            );
            this.setState({ comparedTotalData });
          } else {
            datasets.push({
              label: i18next.t(`common:${this.context.comparedPeriod.alias}`),
              values: getChartData(this.context.comparedPeriod, dataPerDay),
              title: this.props.chartTitle,
            });
            this.setState({
              dataPerDay,
            });
          }
        });
    } else {
      this.setState({
        comparedDataPerDay: null,
      });
    }

    Promise.all([DataPerDayPromise, PreviousDataPerDayPromise])
      .then(() => {
        if (chart) {
          chart.destroy();
        }
        if (this.context.comparedPeriod?.type === "quarter") {
          chart = getBarChart(
            this.ChartRef.current,
            [
              i18next.t(`common:${this.context.currentPeriod.alias}`),
              // TODO adjust comparing quarters translations
              this.context.comparedPeriod?.alias.startsWith("previous")
                ? `${t(
                    `common:${this.context.comparedPeriod?.alias
                      .match(/First|Second|Third|Fourth/)[0]
                      .toLowerCase()}Quarter`
                  )} ${previousYear}`
                : `${t(
                    `common:${this.context.comparedPeriod.alias}`
                  )} ${currentYear}`,
            ],
            [
              {
                label: this.props.chartLabel,
                values: [this.state.totalData, this.state.comparedTotalData],
                title: this.props.chartTitle,
              },
            ],
            this.state.id
          );
        } else {
          chart = getLineChart(
            this.ChartRef.current,
            getChartRange(this.context.currentPeriod),
            datasets,
            this.props.chartLabel,
            this.state.id
          );
        }
        this.setState({ loading: false, chart });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // eslint-disable-next-line no-console
        console.error(error);
        // TODO handle error loading the chart
      });
  }

  /**
   * Draws the chart when the day is changed
   * @param activeDay Name of the day to be graphed
   */
  loadChart() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }

    let chart = this.state.chart;
    const datasets: {
      values: number[];
      label: string;
      backgroundColor?: string;
      borderColor?: string;
      title: string;
    }[] = [];
    datasets.push({
      label: this.props.chartLabel,
      values: this.props.hourlyBreakdown
        ? this.props.hourlyBreakdown[this.state.activeDay]
        : this.props.dataPerDay,
      backgroundColor: "#44EBA6",
      borderColor: "#44EBA6",
      title: this.props.chartTitle,
    });
    chart = getLineChart(
      this.ChartRef.current,
      this.props.hourlyBreakdown
        ? DayHours
        : getChartRange(this.context.currentPeriod),
      datasets,
      this.props.chartLabel,
      this.state.id,
      // maintainAspectRatio to give the hourly breakdown graph full width
      true
    );
    this.setState({ loading: false, chart });
  }

  render(): React.ReactNode {
    return (
      <div className="card orders-card">
        <h2 className="card--title card-graph--title">
          <div>
            {this.props.chartTitle}{" "}
            {this.props.getDataPerDay &&
              t(`common:${this.context.currentPeriod.alias}`)}
          </div>
          <span className="duration">
            (
            {getDurationRange(
              this.context.currentPeriod.type,
              this.context.currentPeriod.from,
              this.context.currentPeriod.to
            )}
            )
          </span>
        </h2>
        <div className="card--options">
          {
            // don't show days if currenPeriod is today
            this.props.hourlyBreakdown &&
              this.context.currentPeriod.alias !== "today" && (
                <>
                  {WeekDaysLong.map((weekDay) => (
                    <div
                      className={`day option ${
                        weekDay === this.state.activeDay ? "active" : ""
                      }`}
                      key={weekDay}
                      onClick={(e) => {
                        this.setState({ activeDay: e.target.innerText });
                        this.loadChart(e.target.innerText);
                      }}
                    >
                      {weekDay}
                    </div>
                  ))}
                </>
              )
          }
          {/* TODO: fix export chart
          {this.state.chart && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="" id={this.state.id}>
              {t("common:exportChart")}
            </a>
          )} */}
        </div>
        <div className="line-chart__container line-chart__full-width">
          <canvas ref={this.ChartRef} id={this.state.id} />
        </div>
      </div>
    );
  }
}

BreakdownChart.contextType = ANALYTICS_CONTEXT;
export default withTranslation("common")(BreakdownChart);
