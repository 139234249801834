/**
 * @author Salma Hefnawy
 * @date 2022-05-17
 * @description loyalty program tab of the App.
 * @filename loyalty-program.tsx
 */
import React from "react";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { number as YUPNumber, object as YUPObject } from "yup";
import { CSVLink } from "react-csv";
import {
  getNumberReadableValue,
  getDurationRange,
  exist,
} from "utilities/common";
import {
  getLoyaltyPoints,
  getLoyaltyPointsPerDay,
  exportLoyalPoints,
} from "utilities/user-behavior";
import { ExportedLoyaltyPoints, LoyaltyPoints } from "interfaces/user-behavior";
import Loader from "../../common/Loader";
import SectionLoader from "../../common/section-loader";
import BreakdownChart from "../../common/breakdown-chart";
import CustomersIcon from "../../../../static/images/total-customers.svg";
import ArrowDownIcon from "../../../../static/images/arrow-down.svg";

type LoyaltyProgramState = Partial<LoyaltyPoints> & {
  id: string;
  loading: boolean;
  loadingLoyaltyCalculator: boolean;
  loyaltyPoints?: LoyaltyPoints;
  comparedLoyaltyPoints?: LoyaltyPoints;
  exportedLoyaltyPoints?: ExportedLoyaltyPoints;
  initialLoyaltyPercentage?: number;
};
interface LoyaltyProgramProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class LoyaltyProgram extends React.Component<
  LoyaltyProgramProps & WithTranslation,
  LoyaltyProgramState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: LoyaltyProgramProps & WithTranslation) {
    super(props);

    this.state = {
      id: Date.now().toString(),
      loading: true,
      loadingLoyaltyCalculator: false,
    };
    this.loadData = this.loadData.bind(this);
    this.updateLoyaltyPoints = this.updateLoyaltyPoints.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  updateLoyaltyPoints(
    values: {
      percentage: number;
    },
    formikHelpers: FormikHelpers<{
      percentage: number;
    }>
  ) {
    this.setState({ loadingLoyaltyCalculator: true });

    if (exist(this.context, ["project", "currentPeriod"])) {
      exportLoyalPoints(this.context.project, String(values.percentage))
        .then((exportedLoyaltyPoints) => {
          this.setState({
            exportedLoyaltyPoints,
            initialLoyaltyPercentage: values.percentage,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
          this.setState({
            loadingLoyaltyCalculator: false,
          });
        });
    }
  }

  loadData() {
    this.setState({ loading: true });

    let loyaltyPointsPromise = Promise.resolve();
    let comparedLoyaltyPointsPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      loyaltyPointsPromise = getLoyaltyPoints({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      }).then((loyaltyPoints) => {
        this.setState({
          ...loyaltyPoints,
        });
      });
    }
    if (this.context.comparedPeriod) {
      comparedLoyaltyPointsPromise = getLoyaltyPoints({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedLoyaltyPoints) => {
          this.setState({
            comparedLoyaltyPoints,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      this.setState({
        comparedLoyaltyPoints: null,
      });
    }

    Promise.all([loyaltyPointsPromise, comparedLoyaltyPointsPromise])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="loyalty-program" ref={this.props.exportRef}>
        <div className="card transparent-card overview-card">
          <h2 className="card--title">
            {t("loyaltyPoints")} {t(this.context.currentPeriod.alias)}
            <span className="duration">
              (
              {getDurationRange(
                this.context.currentPeriod.type,
                this.context.currentPeriod.from,
                this.context.currentPeriod.to
              )}
              )
            </span>
          </h2>
          <div className="overview-items">
            <div className="item">
              <span className="header">
                {getNumberReadableValue(this.state.reward_points_redeemed)}
              </span>
              <span className="title">{t("pointsRedeemed")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.reward_points_redeemed_value
                )}
              </span>
              <span className="title">{t("totalPointsValue")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.total_reward_points_in_wallets
                )}
              </span>
              <span className="title">{t("totalPointsWallet")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.total_orders_with_reward_points_redeemed
                )}
              </span>
              <span className="title">{t("ordersWithPointsUsed")}</span>
            </div>
          </div>
          {this.context.comparedPeriod && (
            <>
              <h2 className="card--title">
                {t("loyaltyPoints")} - {t(this.context.comparedPeriod?.alias)}
              </h2>
              <div className="overview-items">
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedLoyaltyPoints?.reward_points_redeemed
                    )}
                  </span>
                  <span className="title">{t("pointsRedeemed")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedLoyaltyPoints
                        ?.reward_points_redeemed_value
                    )}
                  </span>
                  <span className="title">{t("totalPointsValue")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedLoyaltyPoints
                        ?.total_reward_points_in_wallets
                    )}
                  </span>
                  <span className="title">{t("totalPointsWallet")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedLoyaltyPoints
                        ?.total_orders_with_reward_points_redeemed
                    )}
                  </span>
                  <span className="title">{t("ordersWithPointsUsed")}</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card-container">
          <BreakdownChart
            getDataPerDay={getLoyaltyPointsPerDay}
            chartLabel={t("points")}
            chartTitle={t("spentLoyaltyPoints")}
          />
          <div className="customers-spending__calculators loyalty-program__calculators">
            {this.state.loadingLoyaltyCalculator ? (
              <div className="card">
                <SectionLoader />
              </div>
            ) : (
              <div className="card">
                <Formik
                  onSubmit={this.updateLoyaltyPoints}
                  initialValues={{
                    percentage: this.state.initialLoyaltyPercentage ?? 0,
                  }}
                  validationSchema={YUPObject().shape({
                    percentage: YUPNumber()
                      .positive()
                      .integer()
                      .max(100)
                      .required("Number is required"),
                  })}
                >
                  {(formikBag) => (
                    <Form className="card--section">
                      <Field name="percentage">
                        {({ field, meta }) => (
                          <div className="form-field">
                            <label htmlFor="percentage">
                              {t("topLoyaltyPoints")}
                            </label>
                            <div>
                              <input
                                id="percentage"
                                name="percentage"
                                type="number"
                                {...field}
                                value={field.value ?? ""}
                              />

                              <span>{t("percentageOfUsers")}</span>
                            </div>
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </div>
                        )}
                      </Field>
                      <button
                        type="submit"
                        className="button save"
                        disabled={formikBag.isSubmitting}
                      >
                        {t("apply")}
                      </button>
                    </Form>
                  )}
                </Formik>

                <dl className="quartile-card__list">
                  <dt>
                    <CustomersIcon /> {t("totalCustomers")}
                  </dt>
                  <dd>
                    {this.state.exportedLoyaltyPoints?.count
                      ? getNumberReadableValue(
                          this.state.exportedLoyaltyPoints?.count
                        )
                      : "0"}
                  </dd>

                  <dt>
                    <ArrowDownIcon />
                    {t("maxPoints")}
                  </dt>
                  <dd>
                    {this.state.exportedLoyaltyPoints?.maximum
                      ? getNumberReadableValue(
                          this.state.exportedLoyaltyPoints?.maximum
                        )
                      : "N/A"}{" "}
                    {t("points")}
                  </dd>
                </dl>
                {this.state.exportedLoyaltyPoints && (
                  <CSVLink
                    data={this.state.exportedLoyaltyPoints.list}
                    filename="loyalty-points.csv"
                    className="export-list-button"
                    target="_blank"
                  >
                    {t("exportList")}
                  </CSVLink>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

LoyaltyProgram.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(LoyaltyProgram);
