/**
 * @author Salma Hefnawy
 * @date 2022-06-08
 * @description page report.
 * @filename page-report.tsx
 */
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  exist,
  getDurationRange,
  getNumberReadableValue,
} from "utilities/common";
import { getSpecificPageSummary } from "utilities/user-behavior";
import { GA4PagesReport, SpecificPageSummary } from "interfaces/user-behavior";
import Loader from "../../../common/Loader";
import PageViewsCharts from "./page-views-charts";

interface PageReportState {
  id: string;
  loading: boolean;
  pageReport?: GA4PagesReport;
  comparedPageReport?: GA4PagesReport;
  specificPageSummary: SpecificPageSummary;
  comparedSpecificPageSummary?: SpecificPageSummary;
}

interface PageReportProps {
  pageTitle: string;
}

class PageReport extends React.Component<
  PageReportProps & WithTranslation,
  PageReportState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: PageReportProps & WithTranslation) {
    super(props);

    this.state = {
      id: `Page-report-${Date.now().toString()}`,
      loading: false,
      pageReport: null,
      specificPageSummary: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({ loading: true });

    let pageReportSummaryPromise = Promise.resolve();

    if (exist(this.context, ["project"])) {
      pageReportSummaryPromise = getSpecificPageSummary({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        pageTitle: this.props.pageTitle,
        filters: this.context.appliedFilters,
      }).then((pageSummary) => {
        this.setState({
          specificPageSummary: pageSummary,
          pageReport: pageSummary.pageReport[0],
        });
      });
    }

    let comparedPageReportSummaryPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedPageReportSummaryPromise = getSpecificPageSummary({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        pageTitle: this.props.pageTitle,
        filters: this.context.appliedFilters,
      }).then((pageSummary) => {
        this.setState({
          comparedSpecificPageSummary: pageSummary,
          comparedPageReport: pageSummary.pageReport[0],
        });
      });
    }

    Promise.all([pageReportSummaryPromise, comparedPageReportSummaryPromise])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="page-views specific-page-view">
        <div className="page-view-info">
          <div className="page-view-info--data">
            <p className="label">{t("pageName")}</p>
            <p className="desc">{this.state.pageReport?.pageName}</p>
          </div>
          <div className="page-view-info--data">
            <p className="label">{t("pageLink")}</p>
            <a
              href={`//${this.state.pageReport?.pagePath}`}
              className="desc"
              target="_blank"
              rel="noreferrer"
            >
              {this.state.pageReport?.pagePath}
            </a>
          </div>
        </div>
        <div className="card-container">
          <div className="card overview-card">
            <h2 className="card--title">
              <div>
                {t("pageViews")}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.currentPeriod.type,
                    this.context.currentPeriod.from,
                    this.context.currentPeriod.to
                  )}
                  )
                </span>
              </div>
            </h2>
            <div className="overview-items">
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(this.state.pageReport?.pageViews)}
                </span>
                <span className="title">{t("totalPageViews")}</span>
              </div>
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.pageReport?.averageViewsPerDay
                  )}
                </span>
                <span className="title">{t("averageViewsPerDay")}</span>
              </div>
              <div className="item">
                <span className="header">
                  {this.state.pageReport?.averageEngagementTime}
                </span>
                <span className="title">{t("averageEngagementTime")}</span>
              </div>
            </div>

            {/* compared period statistics */}
            {this.context.comparedPeriod && (
              <>
                <h2 className="card--title">
                  <div>
                    {t("pageViews")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod.type,
                        this.context.comparedPeriod.from,
                        this.context.comparedPeriod.to
                      )}
                      )
                    </span>
                  </div>
                </h2>
                <div className="overview-items">
                  <div className="item">
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.comparedPageReport?.pageViews
                      )}
                    </span>
                    <span className="title">{t("totalPageViews")}</span>
                  </div>
                  <div className="item">
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.comparedPageReport?.averageViewsPerDay
                      )}
                    </span>
                    <span className="title">{t("averageViewsPerDay")}</span>
                  </div>
                  <div className="item">
                    <span className="header">
                      {this.state.comparedPageReport?.averageEngagementTime}
                    </span>
                    <span className="title">{t("averageEngagementTime")}</span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="card overview-card">
            <h2 className="card--title">
              <div>
                {t("customerStatistics")}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.currentPeriod.type,
                    this.context.currentPeriod.from,
                    this.context.currentPeriod.to
                  )}
                  )
                </span>
              </div>
            </h2>
            <div className="overview-items">
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.specificPageSummary?.newCustomers
                  )}
                </span>
                <span className="title">{t("customersTotal")}</span>
              </div>
              <div className="item">
                <span className="header">
                  {getNumberReadableValue(
                    this.state.specificPageSummary?.totalOrders
                  )}
                </span>
                <span className="title">{t("ordersTotal")}</span>
              </div>
            </div>

            {/* compared period statistics */}
            {this.context.comparedPeriod && (
              <>
                <h2 className="card--title">
                  <div>
                    {t("customerStatistics")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod.type,
                        this.context.comparedPeriod.from,
                        this.context.comparedPeriod.to
                      )}
                      )
                    </span>
                  </div>
                </h2>
                <div className="overview-items">
                  <div className="item">
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.comparedSpecificPageSummary?.newCustomers
                      )}
                    </span>
                    <span className="title">{t("customersTotal")}</span>
                  </div>
                  <div className="item">
                    <span className="header">
                      {getNumberReadableValue(
                        this.state.comparedSpecificPageSummary?.totalOrders
                      )}
                    </span>
                    <span className="title">{t("ordersTotal")}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="card-container card-container--graph">
          <PageViewsCharts
            dataPerDay={this.state.specificPageSummary?.viewsBreakdown}
          />
        </div>
        <div className="card">
          <h2 className="card--title">
            <div>
              {t("topSource/mediums")}{" "}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </div>
          </h2>

          <div className="page-view__table">
            {this.state.specificPageSummary?.sessionSourceMediumList && (
              <div role="table" className="table">
                <div role="row" className="table-headers">
                  <div role="columnheader" className="table-head">
                    {t("source/medium")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("landingPage")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("averageEngagement")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("totalPageViews")}
                  </div>
                </div>
                <div role="rowgroup" className="table-body">
                  <ul>
                    {this.state.specificPageSummary?.sessionSourceMediumList.map(
                      (session, index) => {
                        return (
                          <li className="table-row" key={index}>
                            <div role="cell" className="item">
                              <p>{session.sessionSourceMedium}</p>
                            </div>
                            <div
                              role="cell"
                              className="item item-name item-link"
                            >
                              <a href={session.landingPage}>
                                {session.landingPage}
                              </a>
                            </div>
                            <div role="cell" className="item">
                              <p>{session.averageEngagementTime}</p>
                            </div>
                            <div role="cell" className="item">
                              <span role="cell" className="item-sold">
                                {`${getNumberReadableValue(
                                  session.pageViews
                                )} ${t("views")}`}
                              </span>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* compared page reports */}
        {this.context.comparedPeriod && (
          <div className="card">
            <h2 className="card--title">
              <div>
                {t("topSource/mediums")}{" "}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.comparedPeriod.type,
                    this.context.comparedPeriod.from,
                    this.context.comparedPeriod.to
                  )}
                  )
                </span>
              </div>
            </h2>

            <div className="page-views__table">
              {this.state.comparedSpecificPageSummary
                ?.sessionSourceMediumList && (
                <div role="table" className="table">
                  <div role="row" className="table-headers">
                    <div role="columnheader" className="table-head">
                      {t("source/medium")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("landingPage")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("averageEngagement")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("totalPageViews")}
                    </div>
                  </div>
                  <div role="rowgroup" className="table-body">
                    <ul>
                      {this.state.comparedSpecificPageSummary?.sessionSourceMediumList.map(
                        (session, index) => {
                          return (
                            <li className="table-row" key={index}>
                              <div role="cell" className="item">
                                <p>{session.sessionSourceMedium}</p>
                              </div>
                              <div
                                role="cell"
                                className="item item-name item-link"
                              >
                                <a href={session.landingPage}>
                                  {session.landingPage}
                                </a>
                              </div>
                              <div role="cell" className="item">
                                <p>{session.averageEngagementTime}</p>
                              </div>
                              <div role="cell" className="item">
                                <span role="cell" className="item-sold">
                                  {`${getNumberReadableValue(
                                    session.pageViews
                                  )} ${t("views")}`}
                                </span>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

PageReport.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(PageReport);
