/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Customers related utilities.
 * @filename customers.ts
 */

import { MarketingCampaigns } from "api/marketing-campaigns";
import {
  CustomersCount,
  MarketingCampaignRecieverCustomers,
} from "interfaces/customers-overview";
import { getPayloadData, handleError } from "./common";

export function getCustomersCountBySegment(
  project: string,
  recievers?: MarketingCampaignRecieverCustomers
): Promise<CustomersCount> {
  return MarketingCampaigns.getCustomersCountBySegment(project, recievers)
    .then((userCount) => {
      return getPayloadData(userCount);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
