/**
 * @author Salma Hefnawy
 * @date 2022-04-06
 * @description implementation of User management related utilities.
 * @filename user-management.ts
 */
import { UserManagement } from "api/user-management";
import { Payload } from "interfaces/payload";
import { User as UserInterface } from "interfaces/user-management";
import { getPayloadData, handleError } from "./common";

/**
 * handles fetching all users
 * @returns UserInterface[]
 */
export function getAllUsers(): Promise<UserInterface[]> {
  return UserManagement.getAllUsers()
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * handles fetching all users roles
 * @returns string[]
 */
export function getAllRoles(): Promise<string[]> {
  return UserManagement.getAllRoles()
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export async function deleteUser(id: string): Promise<Payload> {
  return UserManagement.deleteUser(id).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export async function editUserRole(id: string, role: string): Promise<Payload> {
  return UserManagement.editUserRole(id, role).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export async function addUser(
  name: string,
  email: string,
  role: string
): Promise<Payload> {
  return UserManagement.addUser(name, email, role).catch((error) => {
    return Promise.reject(handleError(error));
  });
}
