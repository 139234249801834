/**
 * @author Ahmed Serag
 * @date 2020-12-09
 * @description implementation of Settings related utilities.
 * @filename settings.ts
 */
import { string, object } from "yup";
import { Common as CommonAPI } from "api/common";
import { Settings } from "api/settings";
import {
  ActivityLogInterface,
  PaymentCardInterface,
  SettingsInterface,
  SignatureInterface,
  SupportCategories,
} from "interfaces/settings";
import { Payload } from "interfaces/payload";
import { getPayloadData, handleError } from "./common";

export const CONTACT_US_SCHEMA = object().shape({
  title: string().max(200).required("Support message is required"),
  message: string().max(1000).required("Topic is required"),
});

export async function getSettings(): Promise<SettingsInterface[]> {
  try {
    const response = await Settings.get();
    const settings = await getPayloadData<SettingsInterface[]>(response);
    return settings;
  } catch (error) {
    return await Promise.reject(handleError(error));
  }
}

export async function updateSettings(
  project: string,
  settings: SettingsInterface
) {
  try {
    const updatedSettingsResponsePayload = await Settings.update(
      project,
      settings
    );
    const updatedSettingsResponseDate = await getPayloadData(
      updatedSettingsResponsePayload
    );
    return updatedSettingsResponseDate[0];
  } catch (error) {
    return await Promise.reject(handleError(error));
  }
}

/**
 * create a support ticket in the data source.
 *
 * @param {string} title title the user needs support in.
 * @param {string} message message sent for support.
 * @param {SupportCategories} category category of the support request.
 * @return {Promise<unknown>} promise of sending the support request.
 */
export function sendSupportMessage(
  title: string,
  message: string,
  category: SupportCategories
) {
  return CommonAPI.support(title, message, category)
    .then((response) => getPayloadData(response))
    .catch((error) => handleError(error));
}

export function updateUserName(name: string): Promise<Payload> {
  return Settings.updateName(name).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function updateEmail(email: string, password: string): Promise<Payload> {
  return Settings.updateEmail(email, password).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function updatePassword(
  currentPassword: string,
  newPassword: string
): Promise<Payload> {
  return Settings.updatePassword(currentPassword, newPassword).catch(
    (error) => {
      return Promise.reject(handleError(error));
    }
  );
}

export function updateGoals(
  project: string,
  dailyGoal: number,
  weeklyGoal: number,
  monthlyGoal: number,
  yearlyGoal: number
): Promise<Payload> {
  return Settings.updateGoals(
    project,
    dailyGoal,
    weeklyGoal,
    monthlyGoal,
    yearlyGoal
  ).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function changeLanguage(lang: string): Promise<void | Payload<unknown>> {
  return Settings.changeLanguage(lang)
    .then(() => {
      if (lang === "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        document.documentElement.setAttribute("lang", "ar");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.setAttribute("lang", "en");
      }
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export async function getSignature(): Promise<SignatureInterface> {
  try {
    const response = await Settings.getSignature();
    const signature = await getPayloadData<SignatureInterface>(response);
    return signature;
  } catch (error) {
    return await Promise.reject(handleError(error));
  }
}
export async function getCards(): Promise<PaymentCardInterface[]> {
  try {
    const response = await Settings.getCards();
    const cards = await getPayloadData<PaymentCardInterface[]>(response);
    return cards;
  } catch (error) {
    return await Promise.reject(handleError(error));
  }
}

export async function setDefaultCard(id: string): Promise<Payload> {
  return Settings.setDefaultCard(id).catch((error) => {
    return Promise.reject(handleError(error));
  });
}
export async function deleteCard(id: string): Promise<Payload> {
  return Settings.deleteCard(id).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export async function deleteLogo(project: string): Promise<Payload> {
  return Settings.deleteLogo(project).catch((error) => {
    return Promise.reject(handleError(error));
  });
}
/**
 * handles fetching user's activity logs
 * @returns ActivityLogInterface[]
 */
export function getActivityLogs(): Promise<ActivityLogInterface[]> {
  return Settings.getActivityLogs()
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export async function deleteAccount(): Promise<Payload> {
  return Settings.deleteAccount().catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function setGA4PropertyId(
  project: string,
  propertyId: string
): Promise<Payload> {
  return Settings.setGA4PropertyId(project, propertyId).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function deleteGA4PropertyId(project: string): Promise<Payload> {
  return Settings.deleteGA4PropertyId(project).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

/**
 *
 * @param phoneNumberExt phone number extension of the user
 * @param phoneNumber phone number of the user
 * @returns
 */
export function enableTwoFactorAuthentication(
  phoneNumberExt: string,
  phoneNumber: string
) {
  return Settings.enableTwoFactorAuthentication(
    phoneNumberExt,
    phoneNumber
  ).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

/**
 *
 * @param email
 * @param otp
 * @returns
 */
export function validateTwoFactorAuthentication(email: string, otp: string) {
  return Settings.validateTwoFactorAuthentication(email, otp).catch((error) => {
    return Promise.reject(handleError(error));
  });
}

export function disableTwoFactorAuthentication() {
  return Settings.disableTwoFactorAuthentication().catch((error) => {
    return Promise.reject(handleError(error));
  });
}
/**
 * get the pricings rates
 * @returns
 */
export function getPricings() {
  return Settings.getPricings().catch((error) => {
    return Promise.reject(handleError(error));
  });
}

/**
 * get the number of orders and the expected invoice amount
 * @returns
 */
export function getOrdersSinceLastSubscription() {
  return Settings.getOrdersSinceLastSubscription()
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * unsubscribe from plan
 */
export function unsubscribe() {
  return Settings.unsubscribe().catch((error) => {
    return Promise.reject(handleError(error));
  });
}

/**
 * get integration keys for the project
 */
export function getProjectKeys(project: string) {
  return Settings.getProjectKeys(project)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * change subscription tier for user
 * @param subscription_tier plan to change to
 */
export function changeTier(subscription_tier: string) {
  return Settings.changeTier(subscription_tier)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * subscribe for unsubscribed users
 * @param subscription_tier plan to subscribe to
 */
export function subscribe(subscription_tier: string) {
  return Settings.subscribe(subscription_tier)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get the info for the tier changing for user
 * @param subscription_tier plan to change to
 */
export function getChangeTierInfo(subscription_tier: string) {
  return Settings.getChangeTierInfo(subscription_tier)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get stripe balance for user
 */
export function getStripeBalance() {
  return Settings.getStripeBalance()
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
