import React, { ReactNode, FunctionComponent, useContext } from "react";

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";

import { UserPlatform, UserRole } from "interfaces/user";
import { hasFeature, hasPermission } from "utilities/common";

interface PermissionsFilterProps {
  /**
   * Any role in this array will be permitted access to the resource at hand.
   * Admins have permission regardless of this array.
   */

  allowedRoles?: UserRole[];
  allowedPlatforms?: UserPlatform[];
  children: ReactNode;
}

/**
 * Renders children if user has permission to access them per their list of
 * roles and a list of required roles. Admins have permission regardless.
 */
const PermissionsFilter: FunctionComponent<PermissionsFilterProps> = ({
  allowedRoles = [],
  allowedPlatforms = [],
  children,
}) => {
  const { user, settings } = useContext(ANALYTICS_CONTEXT);

  if (!hasPermission(allowedRoles, user?.roles)) {
    return null;
  }
  if (!hasFeature(allowedPlatforms, settings?.platform)) {
    return null;
  }

  return <>{children}</>;
};

PermissionsFilter.defaultProps = {
  allowedRoles: [],
  allowedPlatforms: [],
};

export default PermissionsFilter;
