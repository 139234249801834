/**
 * @author Salma Hefnawy
 * @date 2022-06-05
 * @description implementation of Recommendations related API calls.
 * @filename recommendations.ts
 */

import { GenericRequestPayload, ItemsSales } from "interfaces/overview";
import { Payload } from "interfaces/payload";
import {
  OverviewBundleRecommendation,
  OverviewStockRecommendation,
} from "interfaces/recommendations";
import { appendFiltersToSearchParam } from "utilities/urls";
import { ENDPOINTS } from "./endpoints";
import { Network } from "./network";

/**
 * implement Recommendations related calls.
 */
export class Recommendations {
  public static overviewStockRecommendations({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<OverviewStockRecommendation[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.overviewStockRecommendations.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.overviewStockRecommendations.method,
    });
  }

  public static overviewPromotionRecommendations({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<ItemsSales[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.overviewPromotionRecommendations.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.overviewPromotionRecommendations.method,
    });
  }

  public static overviewBundleRecommendations({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<OverviewBundleRecommendation[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.overviewBundleRecommendations.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.overviewBundleRecommendations.method,
    });
  }
}
