/**
 * @author Salma Hefnawy
 * @date 2022-06-05
 * @description Implementation of Recommendations related utilities.
 * @filename recommendations.ts
 */

import { Recommendations } from "api/recommendations";
import { GenericRequestPayload, ItemsSales } from "interfaces/overview";
import {
  OverviewBundleRecommendation,
  OverviewStockRecommendation,
} from "interfaces/recommendations";

import { getPayloadData, handleError } from "./common";

export function getOverviewStockRecommendations(
  payload: GenericRequestPayload
): Promise<OverviewStockRecommendation[]> {
  return Recommendations.overviewStockRecommendations(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOverviewPromotionRecommendations(
  payload: GenericRequestPayload
): Promise<ItemsSales[]> {
  return Recommendations.overviewPromotionRecommendations(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOverviewBundleRecommendations(
  payload: GenericRequestPayload
): Promise<OverviewBundleRecommendation[]> {
  return Recommendations.overviewBundleRecommendations(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
