/**
 * @author Youssef Tarek
 * @date 2023-04-09
 * @description Cancelled Orders Layout
 * @filename cancelled-orders.tsx
 */

import Loader from "common/Loader";
import TableView from "common/table-view";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  ByFilterSegmentPercentage,
  ByOrderSourcePercentage,
  ByPaymentMethodPercentage,
  CancelledOrdersPercentage,
  Product,
} from "interfaces/orders-overview";
import { ItemsSales } from "interfaces/overview";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getDurationRange, getNumberReadableValue } from "utilities/common";
import {
  getCancelledOrdersBreakdown,
  getCancelledOrdersByFilterSegmentsPercentage,
  getCancelledOrdersByOrderSourcePercentage,
  getCancelledOrdersByPaymentMethodPercentage,
  getCancelledOrdersPercentage,
  getTopBrandsInCancelledOrders,
  getTopCategoriesInCancelledOrders,
  getTopProductsInCancelledOrders,
} from "utilities/orders";
import ProductComponent from "layouts/stock/product";
import ErrorBoundary from "common/error-boundary";
import CategoryDetails from "layouts/categories/categories-details";
import BrandDetails from "layouts/brands/brand-details";
import Arrow from "static/images/down-arrow.svg";
import BreakdownChart from "../../common/breakdown-chart";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";

interface CancelledOrdersState {
  cancelledOrdersPercentage: CancelledOrdersPercentage;
  topProductsInCancelledOrders: Product[];
  topCategoriesInCancelledOrders: Partial<ItemsSales>[];
  topBrandsInCancelledOrders: Partial<ItemsSales>[];
  byPaymentMethodPercentage: ByPaymentMethodPercentage[];
  byOrderSourcePercentage: ByOrderSourcePercentage[];
  byFilterSegmentsPercentage: ByFilterSegmentPercentage[];
  viewAll: "byPaymentMethod" | "byOrderSource" | "byFilterSegments" | "";
  cancelledOrdersBreakdown: number[];
  productSku: string;
  categoryName: string;
  brandName: string;
  loading: boolean;
  id: string;
}

class CancelledOrders extends React.Component<
  WithTranslation,
  CancelledOrdersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      id: Date.now().toString(),
      loading: true,
      cancelledOrdersPercentage: null,
      topProductsInCancelledOrders: [],
      topCategoriesInCancelledOrders: [],
      topBrandsInCancelledOrders: [],
      byPaymentMethodPercentage: [],
      byFilterSegmentsPercentage: [],
      byOrderSourcePercentage: [],
      cancelledOrdersBreakdown: [],
      productSku: "",
      categoryName: "",
      brandName: "",
      viewAll: "",
    };
    this.loadData = this.loadData.bind(this);
    this.updateProductSku = this.updateProductSku.bind(this);
    this.updateCategoryName = this.updateCategoryName.bind(this);
    this.updateBrandName = this.updateBrandName.bind(this);
    this.setViewAll = this.setViewAll.bind(this);
    this.getProductsByType = this.getProductsByType.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  setViewAll(viewAll) {
    this.setState({ viewAll });
  }

  // get the products to be displayed in the expanded table
  // according to the type of the table we want to expand
  getProductsByType() {
    if (this.state.viewAll === "byPaymentMethod") {
      return this.state.byPaymentMethodPercentage;
    }
    if (this.state.viewAll === "byOrderSource") {
      return this.state.byOrderSourcePercentage;
    }
    if (this.state.viewAll === "byFilterSegments") {
      return this.state.byFilterSegmentsPercentage;
    }
    return null;
  }

  loadData() {
    this.setState({ loading: true });
    let cancelledOrdersPercentagePromise = Promise.resolve();

    cancelledOrdersPercentagePromise = getCancelledOrdersPercentage({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((cancelledOrdersPercentage) => {
        this.setState({ cancelledOrdersPercentage });
      })
      .catch((err) => {
        toast.error(err);
      });

    let topProductsInCancelledOrdersPromise = Promise.resolve();

    topProductsInCancelledOrdersPromise = getTopProductsInCancelledOrders({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((topProductsInCancelledOrders) => {
        this.setState({ topProductsInCancelledOrders });
      })
      .catch((err) => {
        toast.error(err);
      });

    let topCategoriesInCancelledOrdersPromise = Promise.resolve();

    topCategoriesInCancelledOrdersPromise = getTopCategoriesInCancelledOrders({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((topCategoriesInCancelledOrders) => {
        this.setState({ topCategoriesInCancelledOrders });
      })
      .catch((err) => {
        toast.error(err);
      });

    let topBrandsInCancelledOrdersPromise = Promise.resolve();

    topBrandsInCancelledOrdersPromise = getTopBrandsInCancelledOrders({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((topBrandsInCancelledOrders) => {
        this.setState({ topBrandsInCancelledOrders });
      })
      .catch((err) => {
        toast.error(err);
      });

    let byPaymentMethodPercentagePromise = Promise.resolve();

    byPaymentMethodPercentagePromise =
      getCancelledOrdersByPaymentMethodPercentage({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
      })
        .then((byPaymentMethodPercentage) => {
          this.setState({ byPaymentMethodPercentage });
        })
        .catch((err) => {
          toast.error(err);
        });

    let byOrderSourcePercentagePromise = Promise.resolve();

    byOrderSourcePercentagePromise = getCancelledOrdersByOrderSourcePercentage({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((byOrderSourcePercentage) => {
        this.setState({ byOrderSourcePercentage });
      })
      .catch((err) => {
        toast.error(err);
      });

    let byFilterSegmentsPercentagePromise = Promise.resolve();

    byFilterSegmentsPercentagePromise =
      getCancelledOrdersByFilterSegmentsPercentage({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
      })
        .then((byFilterSegmentsPercentage) => {
          this.setState({ byFilterSegmentsPercentage });
        })
        .catch((err) => {
          toast.error(err);
        });

    let cancelledOrdersBreakdownPromise = Promise.resolve();

    cancelledOrdersBreakdownPromise = getCancelledOrdersBreakdown({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
    })
      .then((cancelledOrdersBreakdown) => {
        this.setState({ cancelledOrdersBreakdown });
      })
      .catch((err) => {
        toast.error(err);
      });

    Promise.all([
      cancelledOrdersPercentagePromise,
      topProductsInCancelledOrdersPromise,
      topCategoriesInCancelledOrdersPromise,
      topBrandsInCancelledOrdersPromise,
      byPaymentMethodPercentagePromise,
      byOrderSourcePercentagePromise,
      byFilterSegmentsPercentagePromise,
      cancelledOrdersBreakdownPromise,
    ]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  updateProductSku(productSku: string) {
    this.setState({ productSku });
  }

  updateCategoryName(categoryName: string) {
    this.setState({ categoryName });
  }

  updateBrandName(brandName: string) {
    this.setState({ brandName });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.productSku) {
      return (
        <>
          <button
            className="products-button--back"
            type="button"
            onClick={() => {
              this.updateProductSku("");
            }}
          >
            <BackArrowIcon />
            {t("backToList")}
          </button>
          <ErrorBoundary>
            <ProductComponent
              productSku={this.state.productSku}
              updateProductSku={this.updateProductSku}
            />
          </ErrorBoundary>
        </>
      );
    }

    if (this.state.categoryName) {
      return (
        <>
          <button
            className="products-button--back"
            type="button"
            onClick={() => {
              this.updateCategoryName("");
            }}
          >
            <BackArrowIcon />
            {t("backToList")}
          </button>
          <CategoryDetails
            categoryName={this.state.categoryName}
            updateCategoryName={this.updateCategoryName}
          />
        </>
      );
    }

    if (this.state.brandName) {
      return (
        <>
          <button
            className="products-button--back"
            type="button"
            onClick={() => {
              this.updateBrandName("");
            }}
          >
            <BackArrowIcon />
            {t("backToList")}
          </button>
          <BrandDetails
            brandName={this.state.brandName}
            updateBrandName={this.updateBrandName}
          />
        </>
      );
    }

    return (
      <div className="cancelled-orders">
        <section className="cancelled-orders__overview">
          <div className="card overview-card transparent-card">
            <div className="overview-items">
              <div className="item area-a">
                <h4 className="title">{t("totalCancelledOrders")}</h4>
                <span className="header">
                  {getNumberReadableValue(
                    this.state.cancelledOrdersPercentage.canceled_orders_count
                  )}
                </span>
              </div>
              <div className="item area-c">
                <h4 className="title">{t("cancellationPercentage")}</h4>
                <span className="header">
                  {`${getNumberReadableValue(
                    this.state.cancelledOrdersPercentage
                      .canceled_orders_percentage
                  )} %`}
                </span>
              </div>
              <div className="area-b">
                <BreakdownChart
                  chartLabel={t("cancelledOrders")}
                  chartTitle={t("cancelledOrders")}
                  getDataPerDay={getCancelledOrdersBreakdown}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="percentage-cards-container">
          <div className="card flex-col">
            <h2 className="card--title">
              {t("paymentMethod")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.byPaymentMethodPercentage.slice(0, 3)}
                type="byPaymentMethod"
              />
            </div>
            <button
              type="button"
              className="button-underlined black"
              onClick={() => this.setViewAll("byPaymentMethod")}
            >
              <span>{t("viewAll")}</span>
              <Arrow className="right-arrow" />
            </button>
          </div>
          <div className="card flex-col">
            <h2 className="card--title">
              {t("orderSource")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.byOrderSourcePercentage.slice(0, 3)}
                type="byOrderSource"
              />
            </div>
            <button
              type="button"
              className="button-underlined black"
              onClick={() => this.setViewAll("byOrderSource")}
            >
              <span>{t("viewAll")}</span>
              <Arrow className="right-arrow" />
            </button>
          </div>
          <div className="card flex-col">
            <h2 className="card--title">
              {t("filterSegments")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.byFilterSegmentsPercentage.slice(0, 3)}
                type="byFilterSegments"
              />
            </div>
            <button
              type="button"
              className="button-underlined black"
              onClick={() => this.setViewAll("byFilterSegments")}
            >
              <span>{t("viewAll")}</span>
              <Arrow className="right-arrow" />
            </button>
          </div>
        </div>
        <div className="top-cards-container">
          <div className="card">
            <h2 className="card--title">
              {t("topProductsInCancelledOrders")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.topProductsInCancelledOrders.slice(0, 3)}
                type="top-products-cancelled-orders"
                updateProductSku={this.updateProductSku}
              />
            </div>
          </div>
          <div className="card">
            <h2 className="card--title">
              {t("topCategoriesInCancelledOrders")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.topCategoriesInCancelledOrders.slice(0, 3)}
                type="top-categories-cancelled-orders"
                updateProductSku={this.updateCategoryName}
              />
            </div>
          </div>
          <div className="card">
            <h2 className="card--title">
              {t("topBrandsInCancelledOrders")}
              <span className="duration">
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
              </span>
            </h2>
            <div className="cancellation-table">
              <TableView
                products={this.state.topBrandsInCancelledOrders.slice(0, 3)}
                type="top-brands-cancelled-orders"
                updateProductSku={this.updateBrandName}
              />
            </div>
          </div>
        </div>
        {this.state.viewAll && (
          <div className="create-segment">
            <section className="create-segment__main">
              <div className="create-segment__back">
                <BackArrowIcon />
                <button
                  className="button-underlined black"
                  onClick={() => this.setState({ viewAll: "" })}
                  type="button"
                >
                  {t("backToCancellationReport")}
                </button>
              </div>
              <div className="card--title">
                <h3>{`${t("cancellation")} ${t(this.state.viewAll)}`}</h3>
                <span className="duration">
                  {getDurationRange(
                    this.context.currentPeriod.type,
                    this.context.currentPeriod.from,
                    this.context.currentPeriod.to
                  )}
                </span>
              </div>
              <div className="cancellation-table">
                <TableView
                  type={this.state.viewAll}
                  expanded
                  products={this.getProductsByType()}
                />
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}
CancelledOrders.contextType = ANALYTICS_CONTEXT;
export default withTranslation("orders")(CancelledOrders);
