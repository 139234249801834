import { ChartDataset } from "chart.js";
import { BrandsValues, Counts, SalesValues } from "interfaces/overview";
import Chart from "chart.js/auto";
import { Period } from "interfaces/period";
import {
  DaysInMonth,
  MonthDays,
  MonthsNames,
  WeekDays,
  DayHours,
} from "consts/periods";
import { addDays, differenceInCalendarDays, format } from "date-fns";
import { ChartData } from "interfaces/charts";
import { t } from "i18next";
import { generateRandomColor } from "./common";

export function getLineChart(
  canvas: HTMLCanvasElement,
  labels: string[],
  data: ChartData[],
  yAxisLabel: string,
  id?: string,
  maintainAspectRatio?: boolean
): Chart<"line", number[], string> {
  const ctx = canvas?.getContext("2d");
  const datasets: ChartDataset<"line", unknown>[] = data.map((stream) => {
    const gradient = ctx?.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, stream.borderColor || generateRandomColor());
    gradient.addColorStop(1, "rgba(255,205,205,0.09)");
    console.log(maintainAspectRatio);
    return {
      tension: 0.5,
      label: stream.label,
      data: stream.values,
      borderColor: stream.borderColor || generateRandomColor(),
      backgroundColor: gradient || generateRandomColor(),
      fill: true,
      hoverBackgroundColor: stream.backgroundColor || generateRandomColor(),
      title: stream.title,
    };
  });

  return new Chart(ctx, {
    type: "line",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1500,
        onComplete() {
          const exportGraph = document.getElementById(id) as HTMLAnchorElement;

          if (exportGraph) {
            exportGraph.setAttribute("href", this.toBase64Image());
            exportGraph.download = `${datasets[0].title} ${datasets[0].label}`;
          }
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: yAxisLabel,
            padding: { top: 30, left: 0, right: 0, bottom: 0 },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            color: "#152C5B",
            font: {
              size: 14,
              family: "Nunito Sans",
              weight: "600",
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.title}: ${
                context.dataset.data[context.dataIndex]
              }`;
            },
          },
        },
      },
    },
    data: {
      labels,
      datasets,
    },
  });
}

export function addDataSet(chart: Chart, data: number[], label: string) {
  chart.data.datasets.push({
    tension: 0.5,
    label,
    data,
    borderColor: generateRandomColor(),
  });
}

export function getBarChart(
  canvas: HTMLCanvasElement,
  labels: string[],
  data: {
    values: number[];
    label: string;
    backgroundColor?: string;
    title?: string;
  }[],
  id?: string
): Chart<"bar", number[], string> {
  const ctx = canvas?.getContext("2d");
  const datasets: ChartDataset<"line", unknown>[] = data.map((stream) => {
    const color = generateRandomColor();
    return {
      tension: 0.5,
      label: stream.label,
      data: stream.values,
      borderColor: color,
      backgroundColor: stream.backgroundColor || color,
      borderRadius: 50,
      barThickness: 6,
      title: stream.title,
    };
  });

  return new Chart(ctx, {
    type: "bar",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
      animation: {
        duration: 1500,
        onComplete() {
          const exportGraph = document.getElementById(id) as HTMLAnchorElement;
          if (exportGraph) {
            exportGraph.setAttribute("href", this.toBase64Image());
            exportGraph.download = datasets[0].title;
          }
        },
      },
      scales: {
        x: {
          ticks: {
            callback(value) {
              if (this.getLabelForValue(value).length > 30) {
                return `${this.getLabelForValue(value).substr(0, 20)}...`;
              }
              return this.getLabelForValue(value);
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "right",
          maxWidth: 200,
          labels: {
            boxWidth: 6,
            color: "#152C5B",
            usePointStyle: true,
            font: {
              size: 14,
              family: "Nunito Sans",
              weight: "600",
            },
          },
        },
      },
    },
    data: {
      labels,
      datasets,
    },
  });
}

export function getChartRange(period: Period): string[] {
  let range: string[];
  const rangeDays = differenceInCalendarDays(
    new Date(period.from),
    new Date(period.to)
  );

  switch (period.type) {
    case "year":
      range = MonthsNames;
      break;
    case "month":
      range = MonthDays;
      break;
    case "week":
      range = WeekDays;
      break;
    case "day":
      range = DayHours;
      break;
    default:
      range = [];
      for (let i = 0; i <= Math.abs(rangeDays); i += 1) {
        range.push(format(addDays(new Date(period.from), i), "dd/MM/yy"));
      }
      break;
  }

  return range;
}

export function getChartData(
  period: Period,
  data: number[],
  operation: "sum" | "avg" = "sum"
): number[] {
  let rangeData: number[] = [];

  switch (period.type) {
    case "year":
      // eslint-disable-next-line no-case-declarations
      let day = 0;
      for (let i = 0; i < 12; i += 1) {
        let sum = 0;
        if (day >= data?.length) {
          break;
        }
        for (let j = 0; j < DaysInMonth[i] && day < data?.length; j += 1) {
          // for percentages it is returned as string from the backend
          sum += parseInt(data[day++].toString(), 10);
        }
        if (operation === "avg") {
          rangeData.push(sum / DaysInMonth[i]);
        } else {
          rangeData.push(sum);
        }
      }
      break;
    case "month":
      rangeData = data;
      break;
    case "week":
      rangeData = data;
      break;
    default:
      rangeData = data;
      break;
  }

  return rangeData;
}

export function prepareDoughnutChartData(sections: Counts[]): Counts[] {
  const totalCounts: number = sections.reduce((prev, cur) => {
    return prev + Number(cur.count);
  }, 0);
  const OthersThreshold = 0.005;
  const newSections: Counts[] = [];
  const others: Counts = {
    count: `0`,
    name: "Other",
  };
  for (const i in sections) {
    if (Number(sections[i].count) < totalCounts * OthersThreshold) {
      others.count = `${Number(sections[i].count) + Number(others.count)}`;
    } else {
      newSections.push(sections[i]);
    }
  }

  if (Number(others.count) > 0) {
    newSections.push(others);
  }
  return newSections;
}

export function getDoughnutChart(
  canvas: HTMLCanvasElement,
  sections: Counts[],
  title: string,
  id?: string,
  legendPosition = "right",
  cutout = "75%",
  radius = "85%",
  blackThemed = false
): Chart<"doughnut", number[], string> {
  const ctx = canvas?.getContext("2d");
  const updatedSections = prepareDoughnutChartData(sections);
  const { labels, data, backgroundColor } = updatedSections.reduce(
    (prev, cur) => {
      const usedColors = prev.backgroundColor;
      return {
        labels: [...prev.labels, cur.name],
        data: [...prev.data, cur.count],
        backgroundColor: [
          ...prev.backgroundColor,
          generateRandomColor(usedColors),
        ],
      };
    },
    { labels: [], data: [], backgroundColor: [] }
  );

  return new Chart(ctx, {
    type: "doughnut",
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          borderRadius: 2,
          borderWidth: 0,
        },
      ],
    },
    options: {
      cutout,
      radius,
      layout: {
        padding: 0,
      },
      animation: {
        duration: 1500,
        onComplete() {
          const exportGraph = document.getElementById(id) as HTMLAnchorElement;
          if (exportGraph) {
            exportGraph.setAttribute("href", this.toBase64Image());
            exportGraph.download = title;
          }
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: legendPosition,
          align: "start",
          labels: {
            boxWidth: 10,
            boxHeight: 42,
            padding: 15,
            color: blackThemed ? "#fff" : "#000",
            font: {
              size: 14,
              family: "Nunito Sans",
              weight: "600",
            },
          },
        },
      },
    },
  });
}

export function normalizeData(data: number[]): number[] {
  const maxNumber = Math.max(...data);
  const minNumber = Math.min(...data);

  const updatedData = [];
  for (const i of data) {
    updatedData.push(((i - minNumber) / (maxNumber - minNumber)) * 100);
  }

  return updatedData;
}

export function mapChartData(data?: SalesValues[] | BrandsValues[]): Counts[] {
  return (
    data?.map((category) => {
      return {
        name: category.name || category.brand_name || t("common:unnamed"),
        count: category.sales_value,
      };
    }) || []
  );
}
