/**
 * @author Ahmed Serag
 * @date 2019-12-13
 * @description not found layout.
 * @filename not-found.tsx
 */
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { PeriodType } from "interfaces/period";
import { getNumberFormatWithSeparators } from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import SetGoalsIcon from "static/images/set-goals.svg";
import CircularProgressBar from "./circular-progress-bar";

interface GoalAchievementCardProps {
  totalRevenue: number;
}

/**
 * React Component that renders the goal achievement card.
 *
 * @extends {React.Component}
 */
class GoalAchievementCard extends React.Component<
  GoalAchievementCardProps & WithTranslation
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  static getPeriodDescription(periodType: PeriodType): string {
    if (periodType === "year") {
      return `${i18next.t("overview:annual")}`;
    }

    if (periodType === "month") {
      return `${i18next.t("overview:monthly")}`;
    }

    if (periodType === "week") {
      return `${i18next.t("overview:weekly")}`;
    }

    if (periodType === "day") {
      return `${i18next.t("overview:daily")}`;
    }

    return "";
  }

  static getProgressColor(percentage: number): string {
    if (percentage >= 100) {
      return "rgba(56, 204, 170) ";
    }

    if (percentage <= 70) {
      return `rgba(255, ${0 + percentage * 2}, 0)`;
    }
    return `rgba(${255 - percentage * 2}, 195, 0)`;
  }

  render(): React.ReactNode {
    const { t } = this.props;

    let ordersCompletedPercentage = 0;
    let periodGoal = 0;
    if (this.context.currentPeriod.type === "custom") {
      return "";
    }

    if (
      this.context.currentPeriod.type === "year" &&
      this.context.settings?.yearly_goal
    ) {
      periodGoal = this.context.settings.yearly_goal;
      ordersCompletedPercentage = (this.props.totalRevenue / periodGoal) * 100;
    }

    if (
      this.context.currentPeriod.type === "month" &&
      this.context.settings?.monthly_goal
    ) {
      periodGoal = this.context.settings.monthly_goal;
      ordersCompletedPercentage = (this.props.totalRevenue / periodGoal) * 100;
    }

    if (
      this.context.currentPeriod.type === "week" &&
      this.context.settings?.weekly_goal
    ) {
      periodGoal = this.context.settings.weekly_goal;
      ordersCompletedPercentage = (this.props.totalRevenue / periodGoal) * 100;
    }

    if (
      this.context.currentPeriod.type === "day" &&
      this.context.settings?.daily_goal
    ) {
      periodGoal = this.context.settings.daily_goal;
      ordersCompletedPercentage = (this.props.totalRevenue / periodGoal) * 100;
    }

    return (
      <div className="card goal-achievement-card">
        <h2 className="card--title">
          {GoalAchievementCard.getPeriodDescription(
            this.context.currentPeriod?.type
          )}{" "}
          {t("goalAchievement")}
        </h2>
        <div className="card--body">
          <div className="numbers">
            <div className="numbers--progress">
              {getNumberFormatWithSeparators(
                Math.round(this.props.totalRevenue)
              )}
              /<span>{getNumberFormatWithSeparators(periodGoal)}</span>
            </div>
            <div className="footer">
              <SetGoalsIcon />
              <Link to={`${ROUTES.settings.path}/data-settings`}>
                {t("setGoals")}
              </Link>
            </div>
          </div>
          <CircularProgressBar
            progress={
              ordersCompletedPercentage <= 100
                ? Number(ordersCompletedPercentage.toFixed(0))
                : 100
            }
          />
        </div>
      </div>
    );
  }
}

GoalAchievementCard.contextType = ANALYTICS_CONTEXT;
export default withTranslation("overview")(GoalAchievementCard);
