/**
 * @author Karim Shalapy
 * @date 2022-10-02
 * @description Implementation of advanced filters related API calls.
 * @filename advanced-filters.ts
 */

import { Payload } from "interfaces/payload";
import {
  AdvancedFilters as IAdvancedFilters,
  SegmentFilters,
  SegmentFiltersParams,
} from "interfaces/advanced-filters";
import { appendSegmentFiltersToSearchParam } from "utilities/urls";
import { ENDPOINTS } from "./endpoints";
import { Network } from "./network";

export class AdvancedFilters {
  public static getAvailableFilters(
    project: string
  ): Promise<Payload<IAdvancedFilters>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.advancedFilters.path}`
    );

    url.searchParams.append("project", project);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.advancedFilters.method,
    });
  }

  /**
   * Creates a new segment
   * @param title title of segment
   * @param project project name
   * @param filtersArg filters to be applied
   * @returns
   */
  public static createNewSegment({
    title,
    project,
    filtersArg,
  }: SegmentFiltersParams): Promise<Payload<SegmentFilters>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.createSegment.path}`
    );

    return Network.fetch(
      appendSegmentFiltersToSearchParam({
        urlArg: url,
        title,
        project,
        filtersArg,
      }).toString(),
      {
        method: ENDPOINTS.createSegment.method,
      }
    );
  }

  /**
   * get all segments
   * @param project project name
   * @returns
   */
  public static getAllFiltersSegments(
    project: string
  ): Promise<Payload<SegmentFilters[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getAllFiltersSegments.path}`
    );

    url.searchParams.append("project", project);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getAllFiltersSegments.method,
    });
  }

  /**
   * delete a segment by id
   * @param project project name
   * @param id segment id
   * @returns
   */
  public static deleteFiltersSegment(
    project: string,
    id: number
  ): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteFiltersSegment.path}/${id}`
    );

    url.searchParams.append("project", project);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteFiltersSegment.method,
    });
  }

  /**
   * update a segment by id
   * @param id segment id
   * @returns
   */
  public static updateFiltersSegment({
    project,
    id,
    title,
    filtersArg,
  }: SegmentFiltersParams): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateFiltersSegment.path}/${id}`
    );

    return Network.fetch(
      appendSegmentFiltersToSearchParam({
        title,
        project,
        filtersArg,
        urlArg: url,
      }).toString(),
      {
        method: ENDPOINTS.updateFiltersSegment.method,
      }
    );
  }
}
