/**
 * @author Salma Hefnawy
 * @date 2022-06-07
 * @description page views tab of the App.
 * @filename page-views.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  exist,
  getDurationRange,
  getNumberReadableValue,
} from "utilities/common";
import { getAllGA4PagesSummary } from "utilities/user-behavior";
import {
  GA4PagesSummary as GA4PagesSummaryInterface,
  GA4SortByDirection,
  GA4SortByValue,
} from "interfaces/user-behavior";
import Arrow from "static/images/down-arrow.svg";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";

interface PageViewsState {
  id: string;
  loading: boolean;
  pageViewsSummary: GA4PagesSummaryInterface;
  comparedPageViewsSummary?: GA4PagesSummaryInterface;
  totalPageCount?: number;
  currentPage?: number;
  comparedTotalPageCount?: number;
  comparedCurrentPage?: number;
  sort: "pageViews" | "averageEngagementTime" | "";
  direction: "asc" | "desc" | "";
  pageViewsSortingMenu: boolean;
  sortedByTextValue: string;
}

interface PageViewsProps {
  loadPage: (pageTitle: string) => void;
}

class PageViewsOverview extends React.Component<
  PageViewsProps & WithTranslation,
  PageViewsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  dropdownFilters = [
    {
      text: "default",
      onClickFunction: (e) => {
        this.sortPageViews(
          e,
          GA4SortByValue.PageViews,
          GA4SortByDirection.Desc
        );
      },
    },
    {
      text: "page views (low to high)",
      onClickFunction: (e) => {
        this.sortPageViews(e, GA4SortByValue.PageViews, GA4SortByDirection.Asc);
      },
    },
    {
      text: "page views (high to low)",
      onClickFunction: (e) => {
        this.sortPageViews(
          e,
          GA4SortByValue.PageViews,
          GA4SortByDirection.Desc
        );
      },
    },
    {
      text: "average engagement time (low to high)",
      onClickFunction: (e) => {
        this.sortPageViews(
          e,
          GA4SortByValue.AverageEngagementTime,
          GA4SortByDirection.Asc
        );
      },
    },
    {
      text: "average engagement time (high to low)",
      onClickFunction: (e) => {
        this.sortPageViews(
          e,
          GA4SortByValue.AverageEngagementTime,
          GA4SortByDirection.Desc
        );
      },
    },
  ];

  constructor(props: PageViewsProps & WithTranslation) {
    super(props);

    this.state = {
      id: `page-views-overview-${Date.now().toString()}`,
      loading: true,
      currentPage: 1,
      comparedCurrentPage: 1,
      pageViewsSummary: null,
      sort: "",
      direction: "",
      pageViewsSortingMenu: false,
      sortedByTextValue: "",
    };
    this.loadData = this.loadData.bind(this);
    this.changePageHandler = this.changePageHandler.bind(this);
    this.sortPageViews = this.sortPageViews.bind(this);
  }

  componentDidMount() {
    this.loadData(this.state.currentPage);
    this.context.updateUrlQueryParams();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  // load the the data depending on the sort filter
  getSortResults(sort, direction) {
    this.setState({ loading: true });
    const pageSize = 10;
    let pageViewsSummaryPromise = Promise.resolve();
    pageViewsSummaryPromise = getAllGA4PagesSummary({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      currentPage: this.state.currentPage,
      pageSize,
      sort,
      direction,
      filters: this.context.appliedFilters,
    }).then((sortResults) => {
      this.setState({
        pageViewsSummary: sortResults.data,
        totalPageCount: Math.ceil(sortResults.pagination.totalCount / pageSize),
        currentPage: sortResults.pagination.currentPage,
        loading: false,
      });
    });
    Promise.all([pageViewsSummaryPromise]);
  }

  loadData(currentPage?: number) {
    this.setState({ loading: true });

    let pageViewsSummaryPromise = Promise.resolve();

    const pageSize = 10;

    if (exist(this.context, ["project"])) {
      pageViewsSummaryPromise = getAllGA4PagesSummary({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        currentPage: currentPage || this.state.currentPage,
        pageSize,
        filters: this.context.appliedFilters,
      }).then((allPagesSummary) => {
        this.setState({
          pageViewsSummary: allPagesSummary.data,
          totalPageCount: Math.ceil(
            allPagesSummary.pagination.totalCount / pageSize
          ),
          currentPage: allPagesSummary.pagination.currentPage,
        });
      });
    }

    let comparedPageViewsSummaryPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedPageViewsSummaryPromise = getAllGA4PagesSummary({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        currentPage: currentPage || this.state.currentPage,
        pageSize,
        filters: this.context.appliedFilters,
      }).then((allPagesSummary) => {
        this.setState({
          comparedPageViewsSummary: allPagesSummary.data,
          comparedTotalPageCount: Math.ceil(
            allPagesSummary.pagination.totalCount / pageSize
          ),
          comparedCurrentPage: allPagesSummary.pagination.currentPage,
        });
      });
    }

    Promise.all([pageViewsSummaryPromise, comparedPageViewsSummaryPromise])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  changePageHandler(currentPage) {
    this.setState(
      {
        currentPage,
      },
      () => {
        this.loadData(currentPage);
      }
    );
  }

  sortPageViews(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    sort?: "pageViews" | "averageEngagementTime" | "",
    direction?: "asc" | "desc" | ""
  ) {
    const input = e.target as HTMLElement;
    this.setState({
      sortedByTextValue: input.innerText,
      sort,
      direction,
    });
    this.getSortResults(sort, direction);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="page-views">
        <div className="card overview-card transparent-card">
          <h2 className="card--title">
            <div>
              {t("General Statistics")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </div>
          </h2>
          <div className="overview-items overview-grid-fit-items">
            <div className="item">
              <span className="header">
                {this.state.pageViewsSummary?.ordersConversionRate}%
              </span>
              <span className="title">{t("ordersConversionRate")}</span>
            </div>
            <div className="item">
              <span className="header">
                {this.state.pageViewsSummary?.registrationsConversionRate}%
              </span>
              <span className="title">{t("registrationsConversionRate")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.pageViewsSummary?.totalOrders
                )}
              </span>
              <span className="title">{t("totalOrders")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.pageViewsSummary?.newCustomersWithOrders
                )}
              </span>
              <span className="title">{t("ordersFromNewUsers")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.pageViewsSummary?.newCustomers
                )}
              </span>
              <span className="title">{t("newUserRegistrations")}</span>
            </div>
            <div className="item">
              <span className="header">
                {getNumberReadableValue(
                  this.state.pageViewsSummary?.totalUsers
                )}
              </span>
              <span className="title">{t("totalUniqueUsers")}</span>
            </div>
          </div>

          {/* compared period statistics */}
          {this.context.comparedPeriod && (
            <>
              <h2 className="card--title">
                <div>
                  {t("General Statistics")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.comparedPeriod.type,
                      this.context.comparedPeriod.from,
                      this.context.comparedPeriod.to
                    )}
                    )
                  </span>
                </div>
              </h2>
              <div className="overview-items">
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedPageViewsSummary?.totalOrders
                    )}
                  </span>
                  <span className="title">{t("totalOrders")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedPageViewsSummary
                        ?.newCustomersWithOrders
                    )}
                  </span>
                  <span className="title">{t("ordersFromNewUsers")}</span>
                </div>
                <div className="item">
                  <span className="header">
                    {getNumberReadableValue(
                      this.state.comparedPageViewsSummary?.newCustomers
                    )}
                  </span>
                  <span className="title">{t("newUserRegistrations")}</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="dropdown-container"
          onClick={() => {
            this.setState({
              pageViewsSortingMenu: !this.state.pageViewsSortingMenu,
            });
          }}
        >
          Sort By
          <span className="dropdown-container__sort">
            {this.state.sortedByTextValue}
          </span>
          <Arrow className="dropdown-icon" />
          {this.state.pageViewsSortingMenu && (
            <ul className="dropdown-menu">
              {this.dropdownFilters.map((filter, index) => (
                <li
                  key={index}
                  className="item"
                  onClick={(e) => {
                    filter.onClickFunction(e);
                  }}
                >
                  {filter.text}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="card">
          <h2 className="card--title">
            <div>
              {t("pages")}{" "}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod.type,
                  this.context.currentPeriod.from,
                  this.context.currentPeriod.to
                )}
                )
              </span>
            </div>
          </h2>

          <div className="page-views__table">
            {this.state.pageViewsSummary && (
              <div role="table" className="table">
                <div role="row" className="table-headers">
                  <div role="columnheader" className="table-head">
                    {t("page")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("pageLink")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("averageEngagement")}
                  </div>
                  <div role="columnheader" className="table-head">
                    {t("totalPageViews")}
                  </div>
                </div>
                <div role="rowgroup" className="table-body">
                  <ul>
                    {this.state.pageViewsSummary.pagesReport.map(
                      (pagesReport, index) => {
                        return (
                          <li className="table-row" key={index}>
                            <div role="cell" className="item">
                              <p>{pagesReport.pageName}</p>
                            </div>
                            <div
                              role="cell"
                              className="item item-link item-name"
                            >
                              <a
                                href={`//${pagesReport.pagePath}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {pagesReport.pagePath}
                              </a>
                              {/* <span className="tooltip">{item.name}</span> */}
                            </div>
                            <div role="cell" className="item">
                              <p>{pagesReport.averageEngagementTime}</p>
                            </div>
                            <div role="cell" className="item">
                              <span role="cell" className="item-sold">
                                {`${getNumberReadableValue(
                                  pagesReport.pageViews
                                )} ${t("views")}`}
                              </span>
                            </div>

                            <span role="cell" className="item-more-details">
                              <button
                                type="submit"
                                onClick={() => {
                                  this.props.loadPage(pagesReport.pageName);
                                }}
                              >
                                {t("viewPage")}
                                <Arrow className="right-arrow" />
                              </button>
                            </span>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            <div className="page-navigator">
              <Pagination
                totalPageCount={this.state.totalPageCount}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.changePageHandler}
              />
            </div>
          </div>
        </div>
        {/* compared page reports */}
        {this.context.comparedPeriod && (
          <div className="card">
            <h2 className="card--title">
              <div>
                {t("pages")}{" "}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.comparedPeriod.type,
                    this.context.comparedPeriod.from,
                    this.context.comparedPeriod.to
                  )}
                  )
                </span>
              </div>
            </h2>

            <div className="page-views__table">
              {this.state.comparedPageViewsSummary && (
                <div role="table" className="table">
                  <div role="row" className="table-headers">
                    <div role="columnheader" className="table-head">
                      {t("page")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("pageLink")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("averageEngagement")}
                    </div>
                    <div role="columnheader" className="table-head">
                      {t("totalPageViews")}
                    </div>
                  </div>
                  <div role="rowgroup" className="table-body">
                    <ul>
                      {this.state.comparedPageViewsSummary.pagesReport.map(
                        (pagesReport, index) => {
                          return (
                            <li className="table-row" key={index}>
                              <div role="cell" className="item">
                                <p>{pagesReport.pageName}</p>
                              </div>
                              <div
                                role="cell"
                                className="item item-link item-name"
                              >
                                <a
                                  href={`//${pagesReport.pagePath}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {pagesReport.pagePath}
                                </a>
                                {/* <span className="tooltip">{item.name}</span> */}
                              </div>
                              <div role="cell" className="item">
                                <p>{pagesReport.averageEngagementTime}</p>
                              </div>
                              <div role="cell" className="item">
                                <span role="cell" className="item-sold">
                                  {`${getNumberReadableValue(
                                    pagesReport.pageViews
                                  )} ${t("views")}`}
                                </span>
                              </div>

                              <span role="cell" className="item-more-details">
                                <button
                                  type="submit"
                                  onClick={() => {
                                    this.props.loadPage(pagesReport.pageName);
                                  }}
                                >
                                  {t("viewPage")}
                                  <Arrow className="right-arrow" />
                                </button>
                              </span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}

              <div className="page-navigator">
                <Pagination
                  totalPageCount={this.state.comparedTotalPageCount}
                  currentPage={this.state.comparedCurrentPage}
                  pageNeighbors={1}
                  changePageHandler={this.changePageHandler}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PageViewsOverview.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(PageViewsOverview);
