/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description user behavior of the App.
 * @filename user-behavior.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { ExportedPromoCodesList } from "interfaces/user-behavior";
import AddCampaignDetails from "./campaign-details";
import Header from "../../common/header";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";

type CreateCampaignTab = "campaign_details" | "add_content" | "edit_template";

type CreateCampaignState = {
  activeTab: CreateCampaignTab;
  id: string;
  showAllPromos: boolean;
  exportedPromoCodes?: ExportedPromoCodesList[];
  pageTitle?: string;
};

class CreateCampaign extends React.Component<
  RouteComponentProps & WithTranslation,
  CreateCampaignState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportCreateCampaignRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    // const query = window.location.search;
    // const params = new URLSearchParams(query);

    this.exportCreateCampaignRef = React.createRef();

    this.state = {
      activeTab: "campaign_details",
      id: Date.now().toString(),
      showAllPromos: false,
      pageTitle: "",
    };

    this.updateShowAllPromosState = this.updateShowAllPromosState.bind(this);
    this.updateExportedPromoCodes = this.updateExportedPromoCodes.bind(this);
    this.updatePageTitle = this.updatePageTitle.bind(this);
  }

  componentDidMount() {
    // set page title
    document.title = "Campaign Details";
  }

  componentDidUpdate(_prevProps, prevState: CreateCampaignState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(this.state.activeTab);
      // set page title according to the active tab
      switch (this.state.activeTab) {
        case "campaign_details":
          document.title = "Campaign Details";
          break;
        case "add_content":
          document.title = "Add Content";
          break;
        case "edit_template":
          document.title = "Edit Template";
          break;
        default:
          document.title = "Campaign Details";
          break;
      }
    }
  }

  updateShowAllPromosState(showPromosState) {
    this.setState({ showAllPromos: showPromosState });
  }

  updateExportedPromoCodes(exportedPromoCodes) {
    this.setState({ exportedPromoCodes });
  }

  updatePageTitle(pageTitle) {
    this.setState({ pageTitle });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="create-campaign">
        <Header
          title={t("marketingCampaign")}
          showPeriod={false}
          exportRef={this.exportCreateCampaignRef}
          disableDateFilters={this.state.activeTab === "campaign_details"}
          disableAdvancedFilters={this.state.activeTab === "campaign_details"}
        />
        <h1>{t("createEmailManualCampaign")}</h1>
        <ol className="tabs">
          <li
            className={`tab ${
              this.state.activeTab === "campaign_details" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "campaign_details") {
                this.setState({ activeTab: "campaign_details" });
              }
            }}
          >
            {t("campaignDetails")}
          </li>
          <div className="spacer" />
          <li
            className={`tab ${
              this.state.activeTab === "add_content" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "add_content") {
                this.setState({ activeTab: "add_content" });
              }
            }}
          >
            {t("addContent")}
          </li>
          <div className="spacer" />

          <li
            className={`tab ${
              this.state.activeTab === "edit_template" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "edit_template") {
                this.setState({ activeTab: "edit_template" });
              }
            }}
          >
            {t("editTemplate")}
          </li>
        </ol>
        <div className="create-campaign__content">
          {this.state.activeTab === "campaign_details" && (
            <AddCampaignDetails />
          )}

          {this.state.activeTab === "add_content" &&
            (!this.state.showAllPromos ? (
              <h1>hi</h1>
            ) : (
              // <Promocodes
              //   exportRef={this.exportCreateCampaignRef}
              //   updateShowAllPromosState={this.updateShowAllPromosState}
              //   updateExportedPromoCodes={this.updateExportedPromoCodes}
              // />
              <>
                <button
                  className="products-button--back"
                  type="button"
                  onClick={() => {
                    this.setState({
                      showAllPromos: false,
                    });
                  }}
                >
                  <BackArrowIcon />
                  {t("backToPagesList")}
                </button>
              </>
            ))}
          {this.state.activeTab === "edit_template" && (
            <>
              <h1>hi</h1>
            </>
          )}
        </div>
      </div>
    );
  }
}

CreateCampaign.contextType = ANALYTICS_CONTEXT;
export default withTranslation("marketing-campaigns")(CreateCampaign);
