import { Payload } from "interfaces/payload";
import {
  ActivityLogInterface,
  ChangeTierInfo,
  IntegrationKeys,
  PaymentCardInterface,
  SettingsInterface,
  SignatureInterface,
  StripeBalance,
} from "interfaces/settings";
import { exist } from "utilities/common";
import {
  OrdersSinceLastSubscriptionInterface,
  PricingsInterface,
} from "../definitions/interfaces/settings";
import { ENDPOINTS } from "./endpoints";
import { Network } from "./network";

export class Settings {
  public static get(): Promise<Payload<SettingsInterface[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getSettings.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getSettings.method,
    });
  }

  public static update(
    project: string,
    settings: SettingsInterface
  ): Promise<Payload<SettingsInterface[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateSettings.path}`
    );
    let headers;
    let body;

    const key = encodeURIComponent("logo");
    url.searchParams.append("project", project);
    if (settings.primary_color) {
      url.searchParams.append(
        "primary_color",
        settings.primary_color.substring(1)
      );
    }
    if (settings.secondary_color) {
      url.searchParams.append(
        "secondary_color",
        settings.secondary_color.substring(1)
      );
    }
    if (settings.threshold) {
      url.searchParams.append("threshold", settings.threshold);
    }

    if (settings.logo) {
      const value = encodeURIComponent(settings.logo);

      body = `${key}=${value}`;

      headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        accept: "application/x-www-form-urlencoded;charset=UTF-8",
      };
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.updateSettings.method,
      body,
      headers,
    });
  }

  /**
   * update current user email with new one.
   *
   * @param {string} email new email to replace the old one
   * @param {string} password current user password
   * @return {*}  {Promise<Payload<void>>} promise to update user email.
   */
  public static updateEmail(
    email: string,
    password: string
  ): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateEmail.path}`
    );
    url.searchParams.set("email", email);
    url.searchParams.set("password", password);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.updateEmail.method,
    });
  }

  /**
   * update current user name with new one.
   *
   * @param {string} name new name to replace the old one
   * @return {*}  {Promise<Payload<void>>} promise to update user name.
   */
  public static updateName(name: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateName.path}`
    );
    url.searchParams.set("name", name);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.updateName.method,
    });
  }

  /**
   * update current user password with new one.
   *
   * @param {string} currentPassword existing password.
   * @param {string} newPAssword new password to replace the old one
   * @return {*}  {Promise<Payload<void>>} promise to update user password.
   */
  public static updatePassword(
    currentPassword: string,
    newPAssword
  ): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updatePassword.path}`
    );
    url.searchParams.set("old_password", currentPassword);
    url.searchParams.set("new_password", newPAssword);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.updatePassword.method,
    });
  }

  public static updateGoals(
    project: string,
    dailyGoal: number,
    weeklyGoal: number,
    monthlyGoal: number,
    yearlyGoal: number
  ): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateGoals.path}`
    );
    url.searchParams.append("project", project);

    if (exist(dailyGoal)) {
      url.searchParams.set("daily_goal", dailyGoal.toString());
    }
    if (exist(weeklyGoal)) {
      url.searchParams.set("weekly_goal", weeklyGoal.toString());
    }
    if (exist(monthlyGoal)) {
      url.searchParams.set("monthly_goal", monthlyGoal.toString());
    }
    if (exist(yearlyGoal)) {
      url.searchParams.set("yearly_goal", yearlyGoal.toString());
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.updateGoals.method,
    });
  }

  /**
   * change current user language.
   *
   * @param {string} lang new language
   * @return {*}  {Promise<Payload<void>>} promise to language.
   */
  public static changeLanguage(lang: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.changeLanguage.path}`
    );
    url.searchParams.set("language", lang);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.changeLanguage.method,
    });
  }

  /**
   * Get Payfort signature.
   */
  public static getSignature(): Promise<Payload<SignatureInterface>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getSignature.path}`
    );

    url.searchParams.set("sandbox", process.env.PAYFORT_SANDBOX);
    url.searchParams.set("return_url", process.env.PAYFORT_RETURN_URL);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getSignature.method,
    });
  }

  /**
   * Get cards.
   */
  public static getCards(): Promise<Payload<PaymentCardInterface[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getCards.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getCards.method,
    });
  }

  /**
   * Set default card.
   * @param {string} id card id
   */
  public static setDefaultCard(id: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.setDefaultCard.path}`
    );
    url.searchParams.set("id", id);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.setDefaultCard.method,
    });
  }

  /**
   * Delete card.
   * @param {string} id card id
   */
  public static deleteCard(id: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteCard.path}`
    );
    url.searchParams.set("id", id);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteCard.method,
    });
  }

  /**
   * Delete logo.
   * @param {string} project project
   */
  public static deleteLogo(project: string): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteLogo.path}`
    );
    url.searchParams.append("project", project);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteLogo.method,
    });
  }

  /**
   * get user's activity logs.
   */
  public static getActivityLogs(): Promise<Payload<ActivityLogInterface[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getActivityLogs.path}`
    );
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getActivityLogs.method,
    });
  }

  /**
   * Delete account.
   */
  public static deleteAccount(): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteAccount.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteAccount.method,
    });
  }

  /**
   * Set GA4 Property ID.
   *
   * @param {string} project
   * @param {string} propertyId current user ga4 propertyId
   * @return {*}  {Promise<Payload<void>>} promise to set ga4 id.
   */
  public static setGA4PropertyId(
    project: string,
    propertyId: string
  ): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.setGA4PropertyId.path}`
    );
    url.searchParams.set("project", project);
    url.searchParams.set("propertyId", propertyId);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.setGA4PropertyId.method,
    });
  }

  /**
   * Delete GA4 Property ID.
   *
   * @param {string} project
   * @return {*}  {Promise<Payload<void>>} promise to set ga4 id.
   */
  public static deleteGA4PropertyId(project: string): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.deleteGA4PropertyId.path}`
    );
    url.searchParams.set("project", project);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.deleteGA4PropertyId.method,
    });
  }

  /**
   * handles sending a request to the server to activate two-factor authentication.
   * @param phoneNumberExt
   * @param phoneNumber
   */
  public static enableTwoFactorAuthentication(
    phoneNumberExt: string,
    phoneNumber: string
  ): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.enableTwoFactorAuthentication.path}`
    );
    url.searchParams.append("phoneNumberExt", phoneNumberExt);
    url.searchParams.append("phoneNumber", phoneNumber);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.enableTwoFactorAuthentication.method,
    });
  }

  /**
   * handles validating the two-factor authentication code.
   * @param email
   * @param otp
   */
  public static validateTwoFactorAuthentication(
    email: string,
    otp: string
  ): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.validateTwoFactorAuthentication.path}`
    );
    url.searchParams.append("email", email);
    url.searchParams.append("otp", otp);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.validateTwoFactorAuthentication.method,
    });
  }

  /**
   * handles disabling two-factor authentication.
   */
  public static disableTwoFactorAuthentication(): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.disableTwoFactorAuthentication.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.disableTwoFactorAuthentication.method,
    });
  }

  /**
   * get plans pricings
   */
  public static getPricings(): Promise<Payload<PricingsInterface[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getPricings.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getPricings.method,
    });
  }

  /**
   * get orders since last subscription
   */
  public static getOrdersSinceLastSubscription(): Promise<
    Payload<OrdersSinceLastSubscriptionInterface>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getOrdersSinceLastSubscription.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getOrdersSinceLastSubscription.method,
    });
  }

  /**
   * unsubscribe from plan
   */
  public static unsubscribe(): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.unsubscribe.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.unsubscribe.method,
    });
  }

  /**
   * get integration keys for the project
   */
  public static getProjectKeys(
    project: string
  ): Promise<Payload<IntegrationKeys>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getProjectKeys.path}`
    );
    url.searchParams.append("project", project);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getProjectKeys.method,
    });
  }

  /**
   * change the tier of the subscription for user
   * @param subscription_tier tier to change to
   */
  public static changeTier(subscription_tier: string): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.changeTier.path}`
    );
    url.searchParams.append("subscription_tier", subscription_tier);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.changeTier.method,
    });
  }

  /**
   * subscribe to a plan when user is unsubscribed
   * @param subscription_tier tier to subscribe to
   */
  public static subscribe(subscription_tier: string): Promise<Payload<void>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.subscribe.path}`
    );
    url.searchParams.append("subscription_tier", subscription_tier);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.subscribe.method,
    });
  }

  /**
   * get the info for the tier changing for user
   * @param subscription_tier tier to change to
   */
  public static getChangeTierInfo(
    subscription_tier: string
  ): Promise<Payload<ChangeTierInfo>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getChangeTierInfo.path}`
    );
    url.searchParams.append("subscription_tier", subscription_tier);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getChangeTierInfo.method,
    });
  }

  /**
   * get stripe balance for user
   */
  public static getStripeBalance(): Promise<Payload<StripeBalance>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getStripeBalance.path}`
    );

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getStripeBalance.method,
    });
  }
}
