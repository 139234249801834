/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description Orders tab of the App.
 * @filename orders.tsx
 */
import { Counts } from "interfaces/overview";
import React from "react";
import { getPaymentOverview } from "utilities/orders";
import { exist, getNumberReadableValue } from "utilities/common";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Chart } from "chart.js";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import DoughnutCard from "../../common/doughnut-card";
import Loader from "../../common/Loader";

type OrdersState = {
  paymentMethods?: Counts[];
  comparedPaymentMethods?: Counts[];
  loading: boolean;
  id: string;
  chart?: Chart<"doughnut", number[], string>;
  comparedChart?: Chart<"doughnut", number[], string>;
  totalOrders?: number;
  comparedTotalOrders?: number;
};

interface PaymentMethodsProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class PaymentMethods extends React.Component<
  PaymentMethodsProps & WithTranslation,
  OrdersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  ComparedChartRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: PaymentMethodsProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      id: Date.now().toString(),
    };
    this.ChartRef = React.createRef();
    this.ComparedChartRef = React.createRef();
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({ loading: true });
    let paymentMethodsOverview = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      paymentMethodsOverview = getPaymentOverview({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((paymentMethods) => {
          const totalOrders = paymentMethods.reduce(
            (total, paymentMethod) => total + Number(paymentMethod.count),
            0
          );
          this.setState({
            paymentMethods: paymentMethods.map((paymentMethod) => {
              return {
                name: [
                  `${paymentMethod.name}`,
                  `${Math.round(
                    (Number(paymentMethod.count) / totalOrders) * 100
                  )}% . ${paymentMethod.count} ${i18next.t("orders:orders")}`,
                  `${getNumberReadableValue(paymentMethod.sales_value)} ${
                    this.context.settings.currency
                  }`,
                ],
                count: paymentMethod.count,
              };
            }),
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }

    let comparedPaymentMethodsOverview = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedPaymentMethodsOverview = getPaymentOverview({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedPaymentMethods) => {
          const comparedTotalOrders = comparedPaymentMethods.reduce(
            (total, paymentMethod) => total + Number(paymentMethod.count),
            0
          );
          this.setState({
            comparedPaymentMethods: comparedPaymentMethods.map(
              (paymentMethod) => {
                return {
                  name: [
                    `${paymentMethod.name}`,
                    `${Math.round(
                      (Number(paymentMethod.count) / comparedTotalOrders) * 100
                    )}% . ${paymentMethod.count} ${i18next.t("orders:orders")}`,

                    `${getNumberReadableValue(paymentMethod.sales_value)} ${
                      this.context.settings.currency
                    }`,
                  ],
                  count: paymentMethod.count,
                };
              }
            ),
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      this.setState({
        comparedPaymentMethods: undefined,
      });
    }

    Promise.all([paymentMethodsOverview, comparedPaymentMethodsOverview]).then(
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div
        ref={this.props.exportRef}
        className={
          this.context.comparedPeriod ? "card-container" : "flex justify-center"
        }
      >
        <div className="pie-chart-only-page">
          <DoughnutCard
            title={t("paymentMethodsStatistics")}
            items={this.state.paymentMethods}
            currentPeriod={this.context?.currentPeriod}
            blackThemed
          />
        </div>
        {this.context?.comparedPeriod && (
          <div className="pie-chart-only-page">
            <DoughnutCard
              title={t("paymentMethodsStatistics")}
              items={this.state.comparedPaymentMethods}
              currentPeriod={this.context?.comparedPeriod}
              blackThemed
            />
          </div>
        )}
      </div>
    );
  }
}

PaymentMethods.contextType = ANALYTICS_CONTEXT;

export default withTranslation("orders")(PaymentMethods);
