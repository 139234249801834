/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description declaration of user behavior interface.
 * @filename user-behavior.ts
 */

import { AbandonedCartsItems } from "./abandoned-carts";
import { GenericRequestPayload } from "./overview";

export interface SearchedTerms {
  search_term: string;
  frequency: number;
}

export interface MostSearchedTerms {
  no_result_search_count: number;
  most_searched_terms: SearchedTerms[];
  most_searched_no_result_terms: SearchedTerms[];
}

export interface LoyaltyPoints {
  reward_points_redeemed: number;
  reward_points_redeemed_value: number;
  total_reward_points_in_wallets: number;
  total_orders_with_reward_points_redeemed: number;
}

export interface ExportedLoyaltyPointsList {
  firstname: string;
  lastname: string;
  email: string;
  loyalty_points_balance: number;
  last_order_date: string;
  abandoned_cart_items: AbandonedCartsItems[];
}
export interface ExportedLoyaltyPoints {
  count: number;
  maximum: number;
  list: ExportedLoyaltyPointsList[];
}

export interface PromoCodes {
  sales_value: number;
  discount: number;
  number_of_orders_with_promocode: number;
  percentage_of_orders_with_promocode: number;
}

export interface ExportedPromoCodesList {
  coupon_code: string;
  is_active: 1 | 0;
  from_date: string;
  to_date: string;
  number_used: number;
  sales_value: number;
  discount: number;
}

export interface GA4PagesReport {
  pageName: string;
  pagePath: string;
  averageEngagementTime: string;
  averageViewsPerDay?: number;
  pageViews: number;
}
export interface SessionSourceMediumList {
  sessionSourceMedium: string;
  landingPage: string;
  averageEngagementTime: string;
  pageViews: number;
}
export interface GA4PagesSummary {
  totalOrders: number;
  newCustomers: number;
  newCustomersWithOrders: number;
  pagesReport?: GA4PagesReport[];
  ordersConversionRate: number;
  registrationsConversionRate: number;
  totalUsers: number;
}

export interface SpecificPageSummary extends GA4PagesSummary {
  viewsBreakdown: number[];
  pageReport: GA4PagesReport[];
  sessionSourceMediumList: SessionSourceMediumList[];
}

export enum GA4SortByValue {
  PageViews = "pageViews",
  AverageEngagementTime = "averageEngagementTime",
}

export enum GA4SortByDirection {
  Asc = "asc",
  Desc = "desc",
}

export interface PageSummaryRequestPayload extends GenericRequestPayload {
  pageTitle: string;
}
