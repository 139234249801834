/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description declaration of available routes in the app.
 * @filename routes.ts
 */
import AbandonedCarts from "layouts/abandoned-carts";
import { AccountInformation } from "layouts/account-information";
import Categories from "layouts/categories";
import Customers from "layouts/customers";
import UserBehavior from "layouts/user-behavior";
import { Login } from "layouts/auth/login";
import Signup from "layouts/auth/signup";
import { ForgotPassword } from "layouts/auth/forgot-password";
import { SetPassword } from "layouts/auth/set-password";
import Orders from "layouts/orders";
import Overview from "layouts/overview";
import Settings from "layouts/settings";
import { Stock } from "layouts/stock";
import Product from "layouts/stock/product";
import { Support } from "layouts/support";
import RecommendationsList from "layouts/overview/recommendations-list";
import CreateCampaign from "layouts/marketing-campaigns/create-campaign";
import { UserPlatform, UserRole } from "interfaces/user";
import { ConfirmRegistration } from "layouts/auth/confirm-registration";
import Brands from "../../react-components/layouts/brands/index";
import AllPromoCodes from "../../react-components/layouts/user-behavior/all-promo-codes";

/**
 * map of available routes in the app to their locations in the url.
 */
export const ROUTES = {
  // Add Available Routes with it's Components and other details
  Login: {
    path: "/login",
    component: Login,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  Signup: {
    path: "/signup",
    component: Signup,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  ForgotPassword: {
    path: "/forgot-password",
    component: ForgotPassword,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  SetPassword: {
    path: "/set-password",
    component: SetPassword,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  Home: {
    component: Login,
    path: "/",
    exact: true,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  Overview: {
    component: Overview,
    path: "/overview",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: true,
  },
  orders: {
    component: Orders,
    path: "/orders",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: true,
  },
  customers: {
    component: Customers,
    path: "/customers",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  categories: {
    component: Categories,
    path: "/categories",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  brands: {
    component: Brands,
    path: "/brands",
    exact: true,
    public: false,
    roles: [],
    platform: [UserPlatform.magento],
    scrollDown: false,
  },
  stock: {
    component: Stock,
    path: "/stock",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  settings: {
    component: Settings,
    path: "/settings",
    exact: false,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },

  accountInformation: {
    component: AccountInformation,
    path: "/account-information",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  abandonedCarts: {
    component: AbandonedCarts,
    path: "/abandoned-carts",
    exact: true,
    public: false,
    roles: [UserRole.marketing, UserRole.management, UserRole.admin],
    platform: [UserPlatform.magento],
    scrollDown: false,
  },
  support: {
    component: Support,
    path: "/support",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  product: {
    component: Product,
    path: "/product-analytics",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  userBehavior: {
    component: UserBehavior,
    path: "/user-behavior",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  AllPromoCodes: {
    component: AllPromoCodes,
    path: "/user-behavior/all-promo-codes",
    exact: true,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  recommendationsList: {
    component: RecommendationsList,
    path: "/recommendations-list",
    exact: false,
    public: false,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  confirmRegistration: {
    component: ConfirmRegistration,
    path: "/confirm-registration",
    exact: true,
    public: true,
    roles: [],
    platform: [],
    scrollDown: false,
  },
  createCampaignn: {
    component: CreateCampaign,
    path: "/create-campaign",
    exact: true,
    public: true,
    roles: [],
    scrollDown: false,
  },
};
