/**
 * @author Ahmed Elshantaly
 * @date 2022-01-18
 * @description declaration of customers spending interface.
 * @filename customers-spending.ts
 */

import { GenericRequestPayload } from "./overview";

export enum Quartiles {
  Quartile1 = "Quartile 1",
  Quartile2 = "Quartile 2",
  Quartile3 = "Quartile 3",
  Quartile4 = "Quartile 4",
}

export interface CustomerDetails {
  email: string;
  first_name: string;
  last_name: string;
  order_value: number;
  order_count: number;
}

export interface CustomersSpending {
  minimum: number;
  maximum: number;
  average: number;
  number: number;
  list: CustomerDetails[];
}

export interface CustomersSpentOrderedMore {
  count: number;
  maximum: number;
  list: CustomerDetails[];
}

export interface CustomerSegmentsByType {
  total_count: number;
  list: CustomerDetails[];
}

export interface CustomerSpendingMoreRequestPayload
  extends GenericRequestPayload {
  order_value: string;
}

export interface CustomerOrderedMoreRequestPayload
  extends GenericRequestPayload {
  frequency: string;
}

export interface CustomerSegmentsByTypePayload extends GenericRequestPayload {
  segment: number;
}
