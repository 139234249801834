/**
 * @author Karim Shalapy
 * @date 2019-10-11
 * @description signup page.
 * @filename index.tsx
 */

import React from "react";
import SideImage from "static/images/illustrations/Component_6.png";
import { withTranslation, WithTranslation } from "react-i18next";
import { ROUTES } from "consts/routes";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { SignupPayload } from "interfaces/auth";
import { Authenticator } from "utilities/authenticator";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import SignupForm from "./signup-form";

interface SignupState {
  // we need customer email to send to stripe for the first time
  customerEmail: string;
  isSubmit: boolean;
}

class Signup extends React.Component<
  WithTranslation & RouteComponentProps,
  SignupState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);

    this.state = {
      customerEmail: "",
      isSubmit: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler(values: SignupPayload) {
    return Authenticator.signup({
      ...values,
    })
      .then(() => {
        this.setState({ isSubmit: true, customerEmail: values.email });
        return Promise.resolve();
      })
      .catch((error) => {
        const errorsArr = Array.isArray(error) ? error : [error];
        errorsArr.forEach((err) => toast.error(err));
        return Promise.reject();
      });
  }

  render() {
    if (this.context.user) {
      return <Redirect to={ROUTES.Overview.path} />;
    }

    return (
      <>
        {this.state.isSubmit ? (
          <div className="py-60 background-white">
            <stripe-pricing-table
              pricing-table-id={process.env.STRIPE_TRIAL_ID}
              publishable-key={process.env.STRIPE_PUBLISHABLE_KEY}
              customer-email={this.state.customerEmail}
            />
          </div>
        ) : (
          <div className="auth-wrapper">
            <img src={SideImage} alt="" />
            {!this.state.isSubmit && (
              <SignupForm
                submitHandler={this.submitHandler}
                isSubmit={this.state.isSubmit}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

Signup.contextType = ANALYTICS_CONTEXT;

export default withTranslation(["common"])(Signup);
