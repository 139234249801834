/**
 * @author Ahmed Serag
 * @date 2020-12-07
 * @description implementation of common API calls.
 * @filename common.ts
 */
import { Payload } from "interfaces/payload";
import { SupportCategories } from "interfaces/settings";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Common {
  /**
   * send support request with the input title, message and category in support screen.
   * @param title
   * @param message
   * @param category
   * @returns
   */
  public static support(
    title: string,
    message: string,
    category: SupportCategories
  ): Promise<Payload<unknown>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.support.path}`);
    url.searchParams.append("title", title);
    url.searchParams.append("message", message);
    url.searchParams.append("category", category);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.support.method,
    });
  }
}
