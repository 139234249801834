/**
 * @author Ahmed Serag
 * @date 2021-07-13
 * @description implementation of authentication related API calls.
 * @filename authentication.ts
 */
import { LoginResponse, Payload } from "interfaces/payload";
import { User as UserInterface } from "interfaces/user";
import { SignupPayload } from "interfaces/auth";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Authentication {
  public static requestCSRF(): Promise<unknown> {
    return Network.fetch(
      `${process.env.SANTCUM_ENDPOINT}${ENDPOINTS.requestCSRF.path}`,
      {
        method: ENDPOINTS.requestCSRF.method,
      }
    );
  }

  public static async login(
    email: string,
    password: string,
    otp?: string
  ): Promise<Payload<LoginResponse>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.login.path}`);
    url.searchParams.append("email", email);
    url.searchParams.append("password", password);
    if (otp) {
      url.searchParams.append("otp", otp);
    }
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.login.method,
    }).then((result: Payload<LoginResponse>) => {
      const token = result.data["access-token"]?.token;

      localStorage.setItem(process.env.ACCESS_TOKEN_KEY, token);

      return result;
    });
  }

  public static async logout(): Promise<unknown> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.logout.path}`,
      {
        method: ENDPOINTS.logout.method,
      }
    );
  }

  public static currentUser(): Promise<UserInterface> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.currentUser.path}`,
      {
        method: ENDPOINTS.currentUser.method,
      }
    );
  }

  /**
   * handles sending a request to the server to reset the password.
   * @param email email of the user to send the otp to.
   * @returns
   */
  public static requestResetPassword(email: string): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.requestPasswordReset.path}`
    );
    url.searchParams.append("email", email);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.requestPasswordReset.method,
    });
  }

  public static validateOTP(
    email: string,
    otp: string
  ): Promise<Payload<string>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.sendOTP.path}`);

    url.searchParams.append("email", email);
    url.searchParams.append("otp", otp);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.sendOTP.method,
    });
  }

  /**
   * handles sending the reset password request for the given email
   * @param email
   * @param newPassword
   * @param otp
   * @returns
   */
  public static resetPassword(
    email: string,
    newPassword: string,
    otp: string
  ): Promise<Payload> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.resetPassword.path}`
    );

    url.searchParams.append("email", email);
    url.searchParams.append("password", newPassword);
    url.searchParams.append("otp", otp);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.resetPassword.method,
    });
  }

  /**
   * handles sending a request to the server to set the user's password.
   * @param password
   * @param registration_key
   */
  public static setPassword(
    password: string,
    registration_key: string
  ): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.setPassword.path}`
    );
    url.searchParams.append("password", password);
    url.searchParams.append("registration_key", registration_key);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.setPassword.method,
    });
  }

  /**
   * handles sending a request to the server to register a new user.
   * @param payload
   */
  public static signup({
    businessName,
    email,
    name,
    password,
    platformProvider,
    storeCategory,
    storeUrl,
  }: SignupPayload): Promise<Payload<LoginResponse>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.signup.path}`);
    url.searchParams.append("name", name);
    url.searchParams.append("email", email);
    url.searchParams.append("password", password);
    url.searchParams.append("store", platformProvider);
    url.searchParams.append("store_category", storeCategory);
    url.searchParams.append("project", businessName);
    url.searchParams.append("url", storeUrl);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.signup.method,
    });
  }

  /**
   * handles sending token sent to user's email to confirm their registration
   * @param token
   * @returns
   */
  public static confirmRegistration(token: string): Promise<Payload<string>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.confirmRegistration.path}`
    );
    url.searchParams.append("registration_key", token);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.confirmRegistration.method,
    });
  }
}
