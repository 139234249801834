/* eslint-disable react/no-did-update-set-state */
/**
 * @author Sarah Nouh
 * @date 2021-01-01
 * @description Represents the content of any top selling table in the app
 * @filename table-content.tsx
 */
import React from "react";
import {
  getNumberReadableValue,
  getTwoDecimalNumber,
  showDefaultImage,
} from "utilities/common";
import defaultImg from "static/images/default.png";
import { Listings } from "interfaces/settings";
import { ItemsSales } from "../../definitions/interfaces/overview";

interface TableContentProps {
  rowItem: ItemsSales;
  itemIndex: number;
  salesValue?: string;
  currency?: string;
  stock?: string;
  listBy: Listings;
  percentageValue: number;
  isCompare?: boolean;
  type: "categories" | "stock";
}

export class TableContent extends React.Component<TableContentProps, never> {
  static defaultProps = {
    salesValue: "",
    currency: "",
    stock: "",
    isCompare: true,
  };

  render(): React.ReactNode {
    return (
      <li className="category table-row" key={this.props.rowItem.name}>
        {this.props.rowItem.image_url ? (
          <img
            src={this.props.rowItem.image_url}
            alt={this.props.rowItem.name}
            onError={(e) => {
              showDefaultImage(e);
            }}
          />
        ) : (
          <img src={defaultImg} alt={this.props.rowItem.name} />
        )}
        <div className="row-id">
          <div className="row-id__number">
            {getTwoDecimalNumber(this.props.itemIndex + 1)}
          </div>
          <div className="row-id__title">
            <span className="item-name">{this.props.rowItem.name}</span>
            <span className="tooltip">{this.props.rowItem.name}</span>
          </div>
          {this.props.rowItem.sku && (
            <span className="item-sku">{`sku: ${this.props.rowItem.sku}`}</span>
          )}
        </div>
        <div className={`percentage ${!this.props.isCompare && "no-compare"}`}>
          <div className="percentage__value">
            <span>{`${this.props.percentageValue}%`}</span>
            {this.props.type === "categories" ? (
              <span>
                {this.props.listBy === "sales_value"
                  ? `${this.props.salesValue} ${this.props.currency}`
                  : `${getNumberReadableValue(
                      this.props.rowItem.number_sold
                    )} sold`}
              </span>
            ) : null}
            {this.props.type === "stock" ? (
              <span>
                {`${getNumberReadableValue(
                  this.props.rowItem.number_of_items
                )} sold . ${this.props.salesValue} ${this.props.currency}`}
              </span>
            ) : null}
          </div>
          <div className="progress-bar">
            <span
              className={`filling ${
                this.props.percentageValue >= 100 ? "full" : null
              }`}
              style={{
                width: `${this.props.percentageValue}%`,
              }}
            />
          </div>
          {this.props.type === "stock" ? (
            <div className="percentage__stock">
              <span>{`${this.props.stock} in stock`}</span>
            </div>
          ) : null}
        </div>
      </li>
    );
  }
}
