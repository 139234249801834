/**
 * @author Youssef Tarek
 * @date 2022-05-24
 * @description promo code tab of the App.
 * @filename promo-codes.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { ExportedPromoCodesList } from "interfaces/user-behavior";
import { PaginationPayload } from "interfaces/payload";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { exist, getDurationRange } from "utilities/common";
import { exportPromoCodesPaginated } from "utilities/user-behavior";
import Pagination from "../../common/Pagination";
import Loader from "../../common/Loader";
import TableView from "../../common/table-view";

type PromoCodesState = {
  id: string;
  loading: boolean;
  exportedPromoCodes: PaginationPayload<ExportedPromoCodesList[]>;
  totalPageCount?: number;
  currentPage?: number;
};

interface AllPromoCodesProps {
  exportRef: React.RefObject<HTMLDivElement>;
  unpaginatedExportedPromoCodes: ExportedPromoCodesList[];
}

class AllPromoCodes extends React.Component<
  AllPromoCodesProps & WithTranslation,
  PromoCodesState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: AllPromoCodesProps & WithTranslation) {
    super(props);

    this.state = {
      id: Date.now().toString(),
      loading: true,
      exportedPromoCodes: null,
      currentPage: 1,
    };
    this.loadData = this.loadData.bind(this);
    this.changePageHandler = this.changePageHandler.bind(this);
  }

  componentDidMount() {
    this.loadData(this.state.currentPage);
    this.context.updateUrlQueryParams();
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData(currentPage: number) {
    this.setState({ loading: true });

    let exportedPromoCodesPromise = Promise.resolve();

    const pageSize = 10;

    if (exist(this.context, ["project"])) {
      exportedPromoCodesPromise = exportPromoCodesPaginated({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        currentPage,
        pageSize,
        filters: this.context.appliedFilters,
      }).then((exportedPromoCodes) => {
        this.setState({
          exportedPromoCodes,
          totalPageCount: Math.ceil(
            exportedPromoCodes.pagination.totalCount / pageSize
          ),
          currentPage: exportedPromoCodes.pagination.currentPage,
        });
      });
    }

    Promise.all([exportedPromoCodesPromise])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error);
      });
  }

  changePageHandler(currentPage) {
    this.setState({
      currentPage,
    });
    this.loadData(currentPage);
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="card promo-codes__card" ref={this.props.exportRef}>
        <h2 className="card--title">
          <div>
            {t("allPromoCodes")}{" "}
            <span className="duration">
              (
              {getDurationRange(
                this.context.currentPeriod.type,
                this.context.currentPeriod.from,
                this.context.currentPeriod.to
              )}
              )
            </span>
          </div>
          {this.props.unpaginatedExportedPromoCodes.length ? (
            <CSVLink
              data={this.props.unpaginatedExportedPromoCodes}
              filename={`promocodes-from:${this.context.currentPeriod.from}-to:${this.context.currentPeriod.to}.csv`}
              className="export-list-button"
              target="_blank"
            >
              {t("exportList")}
            </CSVLink>
          ) : null}
        </h2>
        <div className="promo-code" ref={this.props.exportRef}>
          <div className="all-promo-codes-table">
            {this.state.exportedPromoCodes && (
              <TableView
                promocodes={this.state.exportedPromoCodes.data}
                type="allPromoCodes"
              />
            )}

            <div className="page-navigator">
              <Pagination
                totalPageCount={this.state.totalPageCount}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.changePageHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AllPromoCodes.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(AllPromoCodes);
