/**
 * @author Karim Shalapy
 * @date 2022-10-03
 * @description implementation of the advanced filters search bar dropdown indicator.
 * @filename dropdown-indicator.tsx
 */

import React from "react";
import { DropdownIndicatorProps, components } from "react-select";
import { SearchDropdownOption } from "interfaces/advanced-filters";
import SearchIcon from "static/images/search.svg";

export default class DropdownIndicator extends React.Component<
  DropdownIndicatorProps<SearchDropdownOption, true>
> {
  render() {
    return (
      <components.DropdownIndicator {...this.props}>
        <SearchIcon width={20} height={20} />
      </components.DropdownIndicator>
    );
  }
}
