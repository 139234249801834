import composeRefs from "@seznam/compose-react-refs";
import React, {
  ChangeEventHandler,
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  useCallback,
  useRef,
} from "react";

type BaseProps = HTMLProps<HTMLInputElement>;
type OmittedProps =
  | "role"
  | "onClick"
  | "tabIndex"
  | "className"
  | "aria-label";

interface ColorSelectorProps extends Omit<BaseProps, OmittedProps> {
  // defaultValue: HexColor;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

/**
 * A simple wrapper around <input type='color'> with SVG preview and keyboard
 * accessibility. Passed props go directly to the input itself, not the
 * component.
 *
 * @param props html input element props except for role, onClick, tabIndex,
 * className, and aria-label
 */

export const ColorSelector = forwardRef(
  (props: ColorSelectorProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const selectorRef = useRef<HTMLInputElement>();

    const openSelectorOnClick = useCallback(() => {
      selectorRef.current.click();
    }, []);

    const openSelectorOnKeyUp = useCallback((ev: KeyboardEvent) => {
      if (ev.code === "Enter" || ev.code === "Space") {
        selectorRef.current.click();
      }
    }, []);

    return (
      <div
        role="button"
        onClick={openSelectorOnClick}
        onKeyUp={openSelectorOnKeyUp}
        tabIndex={0}
        className="color-selector"
        aria-label="Select a color"
      >
        {`${props?.value}`.toUpperCase()}
        <input
          type="color"
          ref={composeRefs<HTMLInputElement>(selectorRef, ref)}
          tabIndex={-1}
          {...props}
        />
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill={`${props?.value}`} />
        </svg>
      </div>
    );
  }
);
