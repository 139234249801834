/**
 * @author Salma Hefnawy
 * @date 2022-06-05
 * @description recommendations list.
 * @filename recommendations-list.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { exist, getDurationRange } from "utilities/common";
import {
  getOverviewBundleRecommendations,
  getOverviewPromotionRecommendations,
  getOverviewStockRecommendations,
} from "utilities/recommendations";
import {
  OverviewBundleRecommendation,
  OverviewStockRecommendation,
  RecommendationsType,
} from "interfaces/recommendations";
import { ItemsSales } from "interfaces/overview";
import Product from "layouts/stock/product";
import ErrorBoundary from "../../common/error-boundary";
import Header from "../../common/header";
import TableView from "../../common/table-view";
import Loader from "../../common/Loader";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";

interface RecommendationsListState {
  loading: boolean;
  id: string;
  productSku?: string;
  recommendationType: string;

  stockRecommendations: OverviewStockRecommendation[];
  comparedStockRecommendations: OverviewStockRecommendation[];

  bundleRecommendations?: OverviewBundleRecommendation[];
  comparedBundleRecommendations?: OverviewBundleRecommendation[];

  promotionRecommendations?: ItemsSales[];
  comparedPromotionRecommendations?: ItemsSales[];
}

/**
 * React Component that renders the Recommendations List.
 *
 * @extends {React.Component}
 */
class RecommendationsList extends React.Component<
  RouteComponentProps & WithTranslation,
  RecommendationsListState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportRef = React.createRef();

    this.state = {
      loading: true,
      id: Date.now().toString(),
      recommendationType: RecommendationsType.STOCK,

      stockRecommendations: null,
      comparedStockRecommendations: null,
    };
    this.loadData = this.loadData.bind(this);
    this.updateProductSku = this.updateProductSku.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
    this.context.updateUrlQueryParams();
    // set page title
    document.title = "Recommendation List";
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  /**  initializes overview for recommendations according to viewed list
   */
  loadData() {
    /**  get recommendation type from view list in the overview page
     */
    const { location } = this.props;
    // to get the current recommendation list state
    const recommendationType = location.pathname.split("/").pop();

    /**  set overview recommendations from api according to chosen type
     */
    let overviewRecommendationPromise = Promise.resolve();
    let comparedOverviewRecommendationPromise = Promise.resolve();

    this.setState({ loading: true });

    if (recommendationType === RecommendationsType.BUNDLE) {
      if (exist(this.context, ["project", "currentPeriod"])) {
        overviewRecommendationPromise = getOverviewBundleRecommendations({
          project: this.context.project,
          from: this.context.currentPeriod.from,
          to: this.context.currentPeriod.to,
          filters: this.context.appliedFilters,
        }).then((bundleRecommendations) => {
          this.setState({ bundleRecommendations });
        });
      }

      if (this.context.comparedPeriod) {
        comparedOverviewRecommendationPromise =
          getOverviewBundleRecommendations({
            project: this.context.project,
            from: this.context.comparedPeriod.from,
            to: this.context.comparedPeriod.to,
            filters: this.context.appliedFilters,
          }).then((comparedBundleRecommendations) => {
            this.setState({
              comparedBundleRecommendations,
            });
          });
      }
      this.setState({ recommendationType });
    } else if (recommendationType === RecommendationsType.PROMOTION) {
      if (exist(this.context, ["project", "currentPeriod"])) {
        overviewRecommendationPromise = getOverviewPromotionRecommendations({
          project: this.context.project,
          from: this.context.currentPeriod.from,
          to: this.context.currentPeriod.to,
          filters: this.context.appliedFilters,
        }).then((promotionRecommendations) => {
          this.setState({ promotionRecommendations });
        });
      }

      if (this.context.comparedPeriod) {
        comparedOverviewRecommendationPromise =
          getOverviewPromotionRecommendations({
            project: this.context.project,
            from: this.context.comparedPeriod.from,
            to: this.context.comparedPeriod.to,
            filters: this.context.appliedFilters,
          }).then((comparedPromotionRecommendations) => {
            this.setState({
              comparedPromotionRecommendations,
            });
          });
      }
      this.setState({ recommendationType });
    } else {
      if (exist(this.context, ["project", "currentPeriod"])) {
        overviewRecommendationPromise = getOverviewStockRecommendations({
          project: this.context.project,
          from: this.context.currentPeriod.from,
          to: this.context.currentPeriod.to,
          filters: this.context.appliedFilters,
        }).then((stockRecommendations) => {
          this.setState({ stockRecommendations });
        });
      }

      if (this.context.comparedPeriod) {
        comparedOverviewRecommendationPromise = getOverviewStockRecommendations(
          {
            project: this.context.project,
            from: this.context.comparedPeriod.from,
            to: this.context.comparedPeriod.to,
            filters: this.context.appliedFilters,
          }
        ).then((comparedStockRecommendations) => {
          this.setState({
            comparedStockRecommendations,
          });
        });
      }
    }
    Promise.all([
      overviewRecommendationPromise,
      comparedOverviewRecommendationPromise,
    ])
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  updateProductSku(productSku: string) {
    this.setState({
      productSku,
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="recommendations" ref={this.exportRef}>
        <Header title={t("recommendations")} exportRef={this.exportRef} />
        {this.state.productSku ? (
          <>
            <button
              className="products-button--back"
              type="button"
              onClick={() => {
                this.setState({
                  productSku: "",
                });
              }}
            >
              <BackArrowIcon />
              {t("backToRecommendations")}
            </button>
            <ErrorBoundary>
              <Product
                productSku={this.state.productSku}
                updateProductSku={this.updateProductSku}
                exportRef={this.exportRef}
              />
            </ErrorBoundary>
          </>
        ) : (
          <>
            {/* stock recommendations */}
            {this.state.stockRecommendations && (
              <div className="card product-analytics">
                <h2 className="card--title">
                  {t("stockingRecommendationsProductsList")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod?.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </h2>

                <div className="recommendations-table products-analytics-table">
                  <TableView
                    products={this.state.stockRecommendations}
                    updateProductSku={this.updateProductSku}
                    type="products-analytics"
                  />
                </div>
              </div>
            )}
            {this.state.comparedStockRecommendations &&
              this.context.comparedPeriod && (
                <div className="card product-analytics">
                  <h2 className="card--title">
                    {t("stockingRecommendationsProductsList")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod?.type,
                        this.context.comparedPeriod?.from,
                        this.context.comparedPeriod?.to
                      )}
                      )
                    </span>
                  </h2>

                  <div className="recommendations-table products-analytics-table">
                    <TableView
                      products={this.state.comparedStockRecommendations}
                      updateProductSku={this.updateProductSku}
                      type="products-analytics"
                    />
                  </div>
                </div>
              )}

            {/* bundle recommendations */}
            {this.state.bundleRecommendations && (
              <div className="card product-analytics">
                <h2 className="card--title">
                  {t("bundleRecommendationsProductsList")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod?.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </h2>

                <div className="recommendations-table products-analytics-table">
                  <TableView
                    products={this.state.bundleRecommendations}
                    updateProductSku={this.updateProductSku}
                    type="bundle-recommendation"
                  />
                </div>
              </div>
            )}
            {this.state.comparedBundleRecommendations &&
              this.context.comparedPeriod && (
                <div className="card product-analytics">
                  <h2 className="card--title">
                    {t("bundleRecommendationsProductsList")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod?.type,
                        this.context.comparedPeriod?.from,
                        this.context.comparedPeriod?.to
                      )}
                      )
                    </span>
                  </h2>

                  <div className="recommendations-table products-analytics-table">
                    <TableView
                      products={this.state.comparedBundleRecommendations}
                      updateProductSku={this.updateProductSku}
                      type="bundle-recommendation"
                    />
                  </div>
                </div>
              )}

            {/* promotions recommendations */}
            {this.state.promotionRecommendations && (
              <div className="card product-analytics">
                <h2 className="card--title">
                  {t("promotionRecommendationsProductsList")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.currentPeriod?.type,
                      this.context.currentPeriod?.from,
                      this.context.currentPeriod?.to
                    )}
                    )
                  </span>
                </h2>

                <div className="promotions-recommendations-table products-analytics-table">
                  <TableView
                    products={this.state.promotionRecommendations}
                    updateProductSku={this.updateProductSku}
                    type="promotions-recommendation"
                  />
                </div>
              </div>
            )}
            {this.state.comparedPromotionRecommendations &&
              this.context.comparedPeriod && (
                <div className="card product-analytics">
                  <h2 className="card--title">
                    {t("promotionRecommendationsProductsList")}
                    <span className="duration">
                      (
                      {getDurationRange(
                        this.context.comparedPeriod?.type,
                        this.context.comparedPeriod?.from,
                        this.context.comparedPeriod?.to
                      )}
                      )
                    </span>
                  </h2>

                  <div className="promotions-recommendations-table recommendations-table products-analytics-table">
                    <TableView
                      products={this.state.comparedPromotionRecommendations}
                      updateProductSku={this.updateProductSku}
                      type="promotions-recommendation"
                    />
                  </div>
                </div>
              )}
          </>
        )}
      </div>
    );
  }
}

RecommendationsList.contextType = ANALYTICS_CONTEXT;
export default withTranslation("overview")(RecommendationsList);
