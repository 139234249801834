/**
 * @author Youssef Tarek
 * @date 2022-10-23
 * @description component to show a plan
 * @filename plan.tsx
 */

import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import FeatureCheckIcon from "static/images/feature-check.svg";

interface PlanProps {
  maxOrders: number;
  price: number;
  maxUsers: number;
  tier: string;
  description: string;
  currency: string;
  selected?: boolean;
  active?: boolean;
  features: string[];
  onSelect?: () => void;
}

class Plan extends React.Component<PlanProps & WithTranslation> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  render() {
    const { t } = this.props;
    return (
      <li
        className={`${
          this.props.active ? "plans-list__item selected" : "plans-list__item"
        } ${this.props.selected && "chosen"}`}
        onClick={this.props.onSelect}
      >
        <p className="title">{this.props.tier}</p>
        <p className="price">
          {`${this.props.currency} ${this.props.price}`}
          <span className="price__period">{` / ${t("month")}`}</span>
          <p className="description">{this.props.description}</p>
        </p>
        <ul className="feature-list">
          {this.props.features.map((feature, index) => (
            <li className="feature-list__item" key={index}>
              <FeatureCheckIcon />
              <p>{t(feature)}</p>
            </li>
          ))}
        </ul>
      </li>
    );
  }
}

Plan.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(Plan);
