/* eslint-disable react/no-did-update-set-state */
/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description Orders tab of the App.
 * @filename orders.tsx
 */
import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Chart } from "chart.js";
import { getDoughnutChart } from "utilities/charts";
import { Counts } from "interfaces/overview";
import isEqual from "lodash.isequal";
import { getDurationRange, getNumberReadableValue } from "utilities/common";
import { withTranslation, WithTranslation } from "react-i18next";

interface DoughnutCardState {
  chart?: Chart<"doughnut", number[], string>;
  id: string;
}

interface DoughnutCardProps {
  items: Counts[];
  title: string;
  currentPeriod: { from: string; to: string; type: string; alias: string };
  legendPosition?: string;
  cutout?: string;
  radius?: string;
  blackThemed?: boolean;
  totalOrders?: number;
}

class DoughnutCard extends React.Component<
  DoughnutCardProps & WithTranslation,
  DoughnutCardState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: DoughnutCardProps & WithTranslation) {
    super(props);
    this.state = {
      id: Date.now().toString(),
    };
    this.ChartRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({
        chart: getDoughnutChart(
          this.ChartRef.current,
          this.props.items,
          this.props.title,
          this.state.id,
          this.props.legendPosition,
          this.props.cutout,
          this.props.radius,
          this.props.blackThemed
        ),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps?.items, this.props.items) || !this.props.items) {
      return null;
    }

    if (this.state.chart) {
      this.state.chart.destroy();
    }

    this.setState({
      chart: getDoughnutChart(
        this.ChartRef.current,
        this.props.items,
        this.props.title,
        this.state.id,
        this.props.legendPosition,
        this.props.cutout,
        this.props.radius,
        this.props.blackThemed
      ),
    });
    return null;
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <>
        <div className="card transparent-card">
          <h2 className="card--title">
            <div
              className={`card-graph--title ${
                this.props.blackThemed && "font-white"
              }`}
            >
              {this.props.title}
              <div
                className={`duration ${this.props.blackThemed && "font-white"}`}
              >
                {getDurationRange(
                  this.props.currentPeriod?.type,
                  this.props.currentPeriod?.from,
                  this.props.currentPeriod?.to
                )}
              </div>
            </div>
            {/* TODO: fix export chart
             {this.state.chart && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" id={this.state.id}>
                {t("common:exportChart")}
              </a>
            )} */}
          </h2>
          <div className="doughnut-card__container">
            <canvas
              id={this.state.id}
              ref={this.ChartRef}
              className="orders-overview__doughnut-canvas"
            />
            {this.props.totalOrders && (
              <div className="doughnut-card__inner-text">
                <span className="font-bold">
                  {getNumberReadableValue(this.props.totalOrders)}
                </span>
                <span>{t("orders:totalOrders")}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation("common")(DoughnutCard);
