/**
 * @author Ahmed Serag
 * @date 2021-12-11
 * @description implementation of password input component
 * @filename password input.tsx
 */
import React from "react";
import EYE_ICON from "../../../static/images/show-password.svg";

export default class PasswordInput extends React.Component<
  unknown,
  { showPassword: boolean }
> {
  constructor(props: unknown) {
    super(props);
    this.state = { showPassword: false };
  }

  render() {
    return (
      <div className="password-input-wrapper">
        <input
          {...this.props}
          className="password-input"
          type={this.state.showPassword ? "text" : "password"}
        />
        <EYE_ICON
          className="password-input-icon"
          onClick={() => {
            this.setState({ showPassword: !this.state.showPassword });
          }}
        />
      </div>
    );
  }
}
