/**
 * @author Youssef Tarek
 * @date 2022-08-18
 * @description circular progress bar for goal achievements.
 * @filename circular-progress-bar.tsx
 */

import React from "react";
import { getNumberFormatWithSeparators } from "utilities/common";

interface CircularProgressBarProps {
  progress: number;
}

class CircularProgressBar extends React.Component<CircularProgressBarProps> {
  render(): React.ReactNode {
    const strokeDashOffset = 100 - this.props.progress;
    return (
      <div className="circular-progress-bar">
        <svg
          className="circle-container"
          viewBox="2 -2 28 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle-container__background"
            r="16"
            cx="16"
            cy="16"
          />
          <circle
            className="circle-container__progress"
            r="16"
            cx="16"
            cy="16"
            style={{ strokeDashoffset: strokeDashOffset }}
          />
        </svg>
        <div className="circle-container__text goal">
          {getNumberFormatWithSeparators(this.props.progress)}%
        </div>
      </div>
    );
  }
}

export default CircularProgressBar;
