/* eslint-disable class-methods-use-this */
/**
 * @author Ahmed Elshantaly
 * @date 2022-02-13
 * @description Payment method form
 * @filename payment-method-form.tsx
 */
import { Formik, Form, FormikHelpers, Field } from "formik";
import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { string as YUPString, object as YUPObject } from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { getSignature } from "utilities/settings";
import { SignatureInterface } from "interfaces/settings";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

interface PaymentMethodState extends SignatureInterface {
  loading: boolean;
}

interface FormElements extends HTMLFormControlsCollection {
  card_number: HTMLInputElement;
  expiry_date: HTMLInputElement;
}

const postURL = process.env.PAYFORT_URL;
class PaymentMethodForm extends React.Component<
  WithTranslation & {
    onFinish: () => void;
  },
  PaymentMethodState
> {
  // Format expiry date to YYMM format as required by Payfort
  static formatExpiryDate(date: string) {
    const formatedDate = date.split("/");

    return formatedDate.length === 2
      ? `${formatedDate[1]}${formatedDate[0]}`
      : date;
  }

  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  FormRef: React.RefObject<HTMLFormElement>;

  constructor(props: WithTranslation & { onFinish: () => void }) {
    super(props);
    this.state = {
      loading: true,
      access_code: "",
      language: "",
      merchant_identifier: "",
      merchant_reference: "",
      return_url: "",
      service_command: "",
      signature: "",
      remember_me: "YES",
    };
    this.FormRef = React.createRef();

    this.addPaymentMethod = this.addPaymentMethod.bind(this);
    this.loadSignature = this.loadSignature.bind(this);
  }

  componentDidMount() {
    this.loadSignature();
    this.context.hideIntegrationKeysModal();
  }

  loadSignature() {
    getSignature()
      .then((signature) => {
        this.setState({ ...signature, loading: false });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
        this.setState({
          loading: false,
        });
      });
  }

  addPaymentMethod(
    values: {
      card_number: string;
      card_holder_name: string;
      expiry_date: string;
      card_security_code: string;
      access_code: string;
      language: string;
      merchant_identifier: string;
      merchant_reference: string;
      return_url: string;
      service_command: string;
      signature: string;
      remember_me: string;
    },
    formikHelpers: FormikHelpers<{
      card_number: string;
      card_holder_name: string;
      expiry_date: string;
      card_security_code: string;
    }>
  ) {
    const formElements = this.FormRef.current.elements as FormElements;

    // remove spaces between numbers before form submission
    formElements.card_number.value = formElements.card_number.value.replace(
      /\s/g,
      ""
    );
    formElements.expiry_date.value = PaymentMethodForm.formatExpiryDate(
      formElements.expiry_date.value
    );

    this.FormRef.current.submit();
    this.setState({
      loading: true,
    });
    formikHelpers.resetForm();
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="account-settings">
        <div className="card">
          <h2 className="card--title">{t("addCardInformation")}</h2>

          <Formik
            onSubmit={this.addPaymentMethod}
            initialValues={{
              card_number: "",
              card_holder_name: "",
              expiry_date: "",
              card_security_code: "",
              access_code: this.state.access_code,
              language: this.state.language,
              merchant_identifier: this.state.merchant_identifier,
              merchant_reference: this.state.merchant_reference,
              return_url: this.state.return_url,
              service_command: this.state.service_command,
              signature: this.state.signature,
              remember_me: this.state.remember_me,
            }}
            validationSchema={YUPObject().shape({
              card_number: YUPString()
                .length(19, t("cardNumberError"))
                .required(t("requiredField")),
              card_holder_name: YUPString().required(t("requiredField")),
              expiry_date: YUPString()
                .length(5, t("cardExpiryDateError"))
                .required(t("requiredField")),
              card_security_code: YUPString()
                .length(3, t("cardCVVError"))
                .required(t("requiredField")),
            })}
          >
            {(formikBag) => (
              <Form
                ref={this.FormRef}
                className="card--section"
                method="post"
                action={postURL}
              >
                <input
                  name="access_code"
                  type="hidden"
                  value={this.state.access_code}
                />

                <input
                  name="signature"
                  type="hidden"
                  value={this.state.signature}
                />

                <input
                  name="service_command"
                  type="hidden"
                  value={this.state.service_command}
                />

                <input
                  name="merchant_identifier"
                  type="hidden"
                  value={this.state.merchant_identifier}
                />

                <input
                  name="merchant_reference"
                  type="hidden"
                  value={this.state.merchant_reference}
                />

                <input
                  name="language"
                  type="hidden"
                  value={this.state.language}
                />

                <input
                  name="return_url"
                  type="hidden"
                  value={this.state.return_url}
                />

                <input
                  name="remember_me"
                  type="hidden"
                  value={this.state.remember_me}
                />

                <Field name="card_number">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="card_number">{t("cardNumber")}</label>
                      <input
                        placeholder="XXXX XXXX XXXX XXXX"
                        name="card_number"
                        type="tel"
                        maxLength="19"
                        pattern="[0-9\s]{13,19}"
                        {...field}
                        value={
                          field.value
                            .replace(/\s/g, "")
                            .replace(/(\d{4})/g, "$1 ")
                            .trim() ?? ""
                        }
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="card_holder_name">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="card_holder_name">{t("cardName")}</label>
                      <input
                        placeholder={t("cardName")}
                        name="card_holder_name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="expiry_date">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="expiry_date">{t("cardExpiryDate")}</label>
                      <input
                        placeholder="XX/XX"
                        name="expiry_date"
                        type="tel"
                        maxLength="5"
                        {...field}
                        value={
                          field.value
                            .replace(
                              /^([1-9]\/|[2-9])$/g,
                              "0$1/" // 3 > 03/
                            )
                            .replace(
                              /^(0[1-9]|1[0-2])$/g,
                              "$1/" // 11 > 11/
                            )
                            .replace(
                              /^1([3-9])$/g,
                              "01/$1" // 13 > 01/3
                              // ).replace(
                              //   /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
                            )
                            .replace(
                              /^0\/|0+$/g,
                              "0" // 0/ > 0 and 00 > 0
                            )
                            .replace(
                              /[^\d|^/]*/g,
                              "" // To allow only digits and `/`
                            )
                            .replace(
                              /\/\//g,
                              "/" // Prevent entering more than 1 `/`
                            ) ?? ""
                        }
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="card_security_code">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="card_security_code">{t("cardCVV")}</label>
                      <input
                        placeholder="XXX"
                        name="card_security_code"
                        type="tel"
                        maxLength="3"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <div className="action-buttons">
                  <button
                    type="submit"
                    className="button save"
                    disabled={formikBag.isSubmitting}
                  >
                    {t("save")}
                  </button>
                  <button
                    className="button-outline"
                    type="button"
                    onClick={() => this.props.onFinish()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

PaymentMethodForm.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(PaymentMethodForm);
