/**
 * @author Salma Hefnawy
 * @date 2019-04-05
 * @description set password page.
 * @filename set-password.tsx
 */
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { Authenticator, SET_PASSWORD_SCHEMA } from "utilities/authenticator";
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import PatternedPad from "static/images/patternpad.svg";
import HidePassword from "static/images/hide-password.svg";
import ShowPassword from "static/images/show-password.svg";

interface SetPasswordState {
  showPassword: boolean;
  showConfirmPassword: boolean;
}
export class SetPassword extends React.Component<
  RouteComponentProps,
  SetPasswordState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
    };
    this.handleSetPassword = this.handleSetPassword.bind(this);
  }

  handleSetPassword(values: { password: string; confirmPassword: string }) {
    const { search } = this.props.history.location;
    const query = new URLSearchParams(search);
    const token = query.get("token");

    Authenticator.setPassword(values.confirmPassword, token)
      .then((result) => {
        toast.success(result.message);
        this.props.history.push(ROUTES.Login.path);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  render(): React.ReactNode {
    if (this.context.user) {
      return <Redirect to={ROUTES.Overview.path} />;
    }

    return (
      <div className="login-wrapper">
        <PatternedPad className="pattern" />
        <div className="login">
          <h2 className="title">Set Password</h2>
          <Formik
            isInitialValid={false}
            validationSchema={SET_PASSWORD_SCHEMA}
            onSubmit={(values) => {
              this.handleSetPassword(values);
            }}
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
          >
            {({ errors, touched, isValid }) => (
              <Form>
                <div className="input-wrapper">
                  <label htmlFor="password">password</label>
                  <Field
                    type={this.state.showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                  />
                  <button
                    type="button"
                    className="button--clear show-password--set"
                    onClick={() => {
                      this.setState({
                        showPassword: !this.state.showPassword,
                      });
                    }}
                  >
                    {this.state.showPassword ? (
                      <ShowPassword />
                    ) : (
                      <HidePassword />
                    )}
                  </button>
                  {errors.password && touched.password ? (
                    <div className="login__error">{errors.password}</div>
                  ) : null}
                </div>

                <div className="input-wrapper">
                  <label htmlFor="confirmPassword">confirm password</label>
                  <Field
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                  />

                  <button
                    type="button"
                    className="button--clear show-password--set"
                    onClick={() => {
                      this.setState({
                        showConfirmPassword: !this.state.showConfirmPassword,
                      });
                    }}
                  >
                    {this.state.showConfirmPassword ? (
                      <ShowPassword />
                    ) : (
                      <HidePassword />
                    )}
                  </button>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="login__error">{errors.confirmPassword}</div>
                  ) : null}
                </div>
                <div className="actions-wrapper">
                  <button type="submit" disabled={!isValid}>
                    save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

SetPassword.contextType = ANALYTICS_CONTEXT;
