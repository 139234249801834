/**
 * @author Salma Hefnawy
 * @date 2021-03-17
 * @description implementation of converting file to base64
 * @filename get-base64.ts
 */

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
