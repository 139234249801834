/**
 * @author Ahmed Serag
 * @date 2020-07-20
 * @description implementation of Customer related API calls.
 * @filename customer.ts
 */
import { Payload } from "interfaces/payload";
import {
  CustomersOverview,
  CustomersPerDay,
} from "interfaces/customers-overview";
import {
  CustomerOrderedMoreRequestPayload,
  CustomerSegmentsByType,
  CustomerSegmentsByTypePayload,
  CustomerSpendingMoreRequestPayload,
  CustomersSpending,
  CustomersSpentOrderedMore,
  Quartiles,
} from "interfaces/customers-spending";
import { GenericRequestPayload } from "interfaces/overview";
import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Customer {
  public static CustomersOverview({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<CustomersOverview>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customers.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customers.method,
    });
  }

  public static numberOfCustomersPerDay({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<CustomersPerDay>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersPerDay.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.ordersPerDay.method,
    });
  }

  public static customersSpending({
    project,
    from,
    to,
    filters = {},
    with_list = 0,
  }: GenericRequestPayload): Promise<
    Payload<Record<Quartiles, CustomersSpending>>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersSpending.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("with_list", with_list.toString());

    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersSpending.method,
    });
  }

  public static customersSpentMore({
    project,
    from,
    to,
    order_value,
    filters = {},
  }: CustomerSpendingMoreRequestPayload): Promise<
    Payload<CustomersSpentOrderedMore>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersSpentOrderedMore.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("order_value", order_value);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersSpentOrderedMore.method,
    });
  }

  public static customersOrderedMore({
    project,
    from,
    to,
    frequency,
    filters = {},
  }: CustomerOrderedMoreRequestPayload): Promise<
    Payload<CustomersSpentOrderedMore>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customersSpentOrderedMore.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("frequency", frequency);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customersSpentOrderedMore.method,
    });
  }

  /**
   * get list and total count of a customer segment according to type
   * @param project
   * @param from
   * @param to
   * @param filters
   * @param segment
   * @returns
   */
  public static customerSegmentsByType({
    project,
    from,
    to,
    filters = {},
    segment,
  }: CustomerSegmentsByTypePayload): Promise<Payload<CustomerSegmentsByType>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.customerSegmentsByType.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("segment", segment.toString());
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.customerSegmentsByType.method,
    });
  }
}
