/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description Orders tab of the App.
 * @filename orders.tsx
 */
import {
  OrdersHourlyBreakdown,
  OrdersOverview,
} from "interfaces/orders-overview";
import { Counts } from "interfaces/overview";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  getOrdersDetails,
  getOrdersHourlyBreakdown,
  getPaymentOverview,
  getOrdersPerDay,
} from "utilities/orders";
import { toast } from "react-toastify";
import { getNumberReadableValue, exist } from "utilities/common";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";

import Header from "../../common/header";
import BreakdownChart from "../../common/breakdown-chart";
import PaymentMethods from "./payment-methods";
import DoughnutCard from "../../common/doughnut-card";
import Loader from "../../common/Loader";
import ShippingLocations from "./shipping-locations";
import CancelledOrders from "./cancelled-orders";

type OrdersState = Partial<OrdersOverview> & {
  activeTab:
    | "orders_overview"
    | "payment_method"
    | "delivery_method"
    | "shipping_locations"
    | "cancelled_orders"
    | string;
  paymentMethods?: Counts[];
  comparedPaymentMethods?: Counts[];
  loading: boolean;
  id: string;
  comparedOverview?: OrdersOverview;
  totalOrders?: number;
  totalOrdersDelivery?: number;
  ordersHourlyBreakdown?: OrdersHourlyBreakdown;
  comparedOrdersHourlyBreakdown?: OrdersHourlyBreakdown;
};

class Orders extends React.Component<
  RouteComponentProps & WithTranslation,
  OrdersState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportOrdersRef: React.RefObject<HTMLDivElement>;

  exportOrderPaymentsRef: React.RefObject<HTMLDivElement>;

  exportOrdersDeliveryRef: React.RefObject<HTMLDivElement>;

  exportShippingLocationsRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportOrdersRef = React.createRef();
    this.exportOrderPaymentsRef = React.createRef();
    this.exportOrdersDeliveryRef = React.createRef();

    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.state = {
      loading: true,
      activeTab: params.get("activeTab") ?? "orders_overview",
      id: Date.now().toString(),
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams(this.state.activeTab);
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Orders Overview";
  }

  componentDidUpdate(_prevProps, prevState: OrdersState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(this.state.activeTab);
      // set page title according to the active tab
      switch (this.state.activeTab) {
        case "orders_overview":
          document.title = "Orders Overview";
          break;
        case "payment_method":
          document.title = "Payment Methods";
          break;
        case "delivery_method":
          document.title = "Delivery Methods";
          break;
        case "shipping_locations":
          document.title = "Shipping Locations";
          break;
        default:
          document.title = "Orders Overview";
          break;
      }
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({ loading: true });
    let ordersDetailsPromise = Promise.resolve();
    let paymentMethodsOverview = Promise.resolve();
    let ordersHourlyBreakdownPromise = Promise.resolve();
    if (exist(this.context, ["project", "currentPeriod"])) {
      ordersDetailsPromise = getOrdersDetails({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((ordersOverview) => {
          this.setState({
            ...ordersOverview,
            totalOrders:
              ordersOverview.canceled_order_count +
              ordersOverview.complete_order_count +
              ordersOverview.pending_order_count,
            totalOrdersDelivery:
              ordersOverview.delivery + ordersOverview.pickup,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      paymentMethodsOverview = getPaymentOverview({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((paymentMethods) => {
          this.setState({
            paymentMethods,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }

    ordersHourlyBreakdownPromise = getOrdersHourlyBreakdown({
      project: this.context.project,
      from: this.context.currentPeriod.from,
      to: this.context.currentPeriod.to,
      filters: this.context.appliedFilters,
    })
      .then((ordersHourlyBreakdown) => {
        this.setState({ ordersHourlyBreakdown });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    let comparedDetailsPromise = Promise.resolve();
    let comparedPaymentMethodsOverview = Promise.resolve();
    let comparedOrdersHourlyBreakdownPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedDetailsPromise = getOrdersDetails({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((ordersOverview) => {
          this.setState({
            comparedOverview: {
              ...ordersOverview,
              totalOrders:
                ordersOverview.canceled_order_count +
                ordersOverview.complete_order_count +
                ordersOverview.pending_order_count,
              totalOrdersDelivery:
                ordersOverview.delivery + ordersOverview.pickup,
            },
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      comparedPaymentMethodsOverview = getPaymentOverview({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedPaymentMethods) => {
          this.setState({
            comparedPaymentMethods,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          // TODO handle error loading the chart
        });

      comparedOrdersHourlyBreakdownPromise = getOrdersHourlyBreakdown({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((comparedOrdersHourlyBreakdown) => {
          this.setState({ comparedOrdersHourlyBreakdown });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      this.setState({
        comparedOverview: undefined,
        comparedPaymentMethods: undefined,
        comparedOrdersHourlyBreakdown: undefined,
      });
    }

    Promise.all([
      ordersDetailsPromise,
      paymentMethodsOverview,
      ordersHourlyBreakdownPromise,
      comparedDetailsPromise,
      comparedPaymentMethodsOverview,
      comparedOrdersHourlyBreakdownPromise,
    ]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  generateDoughnutChartItemText(
    name: string,
    count: number,
    total: number,
    orders: string
  ) {
    const percentage = Math.round((count / total) * 100);
    return [name, `${percentage}% . ${count} ${orders}`];
  }

  // eslint-disable-next-line class-methods-use-this
  generateDoughnutChartItemCount(count: number) {
    return count ?? "0";
  }

  render(): React.ReactNode {
    const { t } = this.props;
    const orderStatusDoughnutCardItems = [
      {
        name: this.generateDoughnutChartItemText(
          t("completed"),
          this.state.complete_order_count,
          this.state.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.complete_order_count
        ),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("pending"),
          this.state.pending_order_count,
          this.state.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.pending_order_count
        ),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("cancelled"),
          this.state.canceled_order_count,
          this.state.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.canceled_order_count
        ),
      },
    ];

    const comparedOrderStatusDoughnutCardItems = [
      {
        name: this.generateDoughnutChartItemText(
          t("completed"),
          this.state.comparedOverview?.complete_order_count,
          this.state.comparedOverview?.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.comparedOverview?.complete_order_count
        ),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("pending"),
          this.state.comparedOverview?.pending_order_count,
          this.state.comparedOverview?.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.comparedOverview?.pending_order_count
        ),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("cancelled"),
          this.state.comparedOverview?.canceled_order_count,
          this.state.comparedOverview?.totalOrders,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.comparedOverview?.canceled_order_count
        ),
      },
    ];

    const deliveryMethodsDoughnutCardItems = [
      {
        name: this.generateDoughnutChartItemText(
          t("pickup"),
          this.state.pickup,
          this.state.totalOrdersDelivery,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(this.state.pickup),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("homeDelivery"),
          this.state.delivery,
          this.state.totalOrdersDelivery,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(this.state.delivery),
      },
    ];

    const comparedDeliveryMethodsDoughnutCardItems = [
      {
        name: this.generateDoughnutChartItemText(
          t("pickup"),
          this.state.comparedOverview?.pickup,
          this.state.comparedOverview?.totalOrdersDelivery,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.comparedOverview?.pickup
        ),
      },
      {
        name: this.generateDoughnutChartItemText(
          t("homeDelivery"),
          this.state.comparedOverview?.delivery,
          this.state.comparedOverview?.totalOrdersDelivery,
          t("orders")
        ),
        count: this.generateDoughnutChartItemCount(
          this.state.comparedOverview?.delivery
        ),
      },
    ];

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="orders">
        <Header
          title={t("orders")}
          exportRef={
            this.state.activeTab === "orders_overview"
              ? this.exportOrdersRef
              : this.state.activeTab === "payment_method"
              ? this.exportOrderPaymentsRef
              : this.state.activeTab === "delivery_method"
              ? this.exportOrdersDeliveryRef
              : this.exportShippingLocationsRef
          }
        />
        <div className="tabs">
          <div
            className={`tab ${
              this.state.activeTab === "orders_overview" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "orders_overview") {
                this.setState({ activeTab: "orders_overview" });
              }
            }}
          >
            {t("ordersOverview")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "shipping_locations" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "shipping_locations") {
                this.setState({ activeTab: "shipping_locations" });
              }
            }}
          >
            {t("shippingLocations")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "payment_method" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "payment_method") {
                this.setState({ activeTab: "payment_method" });
              }
            }}
          >
            {t("paymentMethod")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "delivery_method" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "delivery_method") {
                this.setState({ activeTab: "delivery_method" });
              }
            }}
          >
            {t("deliveryMethod")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "cancelled_orders" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "cancelled_orders") {
                this.setState({ activeTab: "cancelled_orders" });
              }
            }}
          >
            {t("cancelledOrders")}
          </div>
        </div>
        {this.state.activeTab === "orders_overview" && (
          <div
            ref={this.exportOrdersRef}
            className={`orders-overview ${
              this.context.comparedPeriod && "compared-orders-overview"
            }`}
          >
            <div className="card overview-card transparent-card">
              <div className="overview-items">
                <div className="item">
                  <span className="title">{t("completedOrders")}</span>
                  <span className="header">
                    {getNumberReadableValue(this.state.complete_order_count)}
                  </span>
                </div>
                <div className="item">
                  <span className="title">{t("pendingOrders")}</span>
                  <span className="header">
                    {getNumberReadableValue(this.state.pending_order_count)}
                  </span>
                </div>
                <div className="item">
                  <span className="title">{t("cancelledOrders")}</span>
                  <span className="header">
                    {getNumberReadableValue(this.state.canceled_order_count)}
                  </span>
                </div>
                <div className="item">
                  <span className="title">{t("avgOrderValue")}</span>
                  <span className="header">
                    {`${getNumberReadableValue(
                      this.state.avg_value_per_order
                    )} ${this.context.settings.currency}`}
                  </span>
                </div>
                <div className="item">
                  <span className="title">{t("avgBasketSize")}</span>
                  <span className="header">
                    {getNumberReadableValue(this.state.avg_basket_size)}
                  </span>
                </div>
              </div>
              {this.context.comparedPeriod && (
                <>
                  <div className="overview-items">
                    <div className="item">
                      <span className="title">{t("completedOrders")}</span>
                      <span className="header">
                        {getNumberReadableValue(
                          this.state.comparedOverview?.complete_order_count
                        )}
                      </span>
                    </div>
                    <div className="item">
                      <span className="title">{t("pendingOrders")}</span>
                      <span className="header">
                        {getNumberReadableValue(
                          this.state.comparedOverview?.pending_order_count
                        )}
                      </span>
                    </div>
                    <div className="item">
                      <span className="title">{t("avgOrderValue")}</span>
                      <span className="header">
                        {`${getNumberReadableValue(
                          this.state.comparedOverview?.avg_value_per_order
                        )} ${this.context.settings.currency}`}
                      </span>
                    </div>
                    <div className="item">
                      <span className="title">{t("cancelledOrders")}</span>
                      <span className="header">
                        {getNumberReadableValue(
                          this.state.comparedOverview?.canceled_order_count
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="orders-overview__orders-per-day">
              <BreakdownChart
                getDataPerDay={getOrdersPerDay}
                chartLabel={t("common:numberOfOrders")}
                chartTitle={t("common:orders")}
              />
            </div>
            <div className="orders-overview__order-status">
              <DoughnutCard
                title={t("orderStatus")}
                items={orderStatusDoughnutCardItems}
                currentPeriod={this.context.currentPeriod}
                legendPosition="bottom"
                cutout="95%"
                radius="120"
                blackThemed
                totalOrders={this.state.totalOrders}
              />
            </div>
            {this.context.comparedPeriod && (
              <div className="orders-overview__compared-order-status">
                <DoughnutCard
                  title={t("orderStatus")}
                  items={comparedOrderStatusDoughnutCardItems}
                  currentPeriod={this.context.comparedPeriod}
                  legendPosition="bottom"
                  cutout="95%"
                  radius="120"
                  blackThemed
                  totalOrders={this.state.comparedOverview?.totalOrders}
                />
              </div>
            )}
            <div className="orders-overview__orders-hourly">
              <BreakdownChart
                chartLabel={t("orders")}
                chartTitle={t("averageOrdersPerHour")}
                hourlyBreakdown={this.state.ordersHourlyBreakdown}
              />
            </div>
          </div>
        )}
        {this.state.activeTab === "payment_method" && (
          <PaymentMethods exportRef={this.exportOrderPaymentsRef} />
        )}
        {this.state.activeTab === "delivery_method" && (
          <div
            ref={this.exportOrdersDeliveryRef}
            className={
              this.context.comparedPeriod
                ? "card-container"
                : "flex justify-center"
            }
          >
            <div className="pie-chart-only-page">
              <DoughnutCard
                title={t("deliveryMethodsStatistics")}
                items={deliveryMethodsDoughnutCardItems}
                currentPeriod={this.context.currentPeriod}
                blackThemed
              />
            </div>
            {this.context.comparedPeriod && (
              <div className="pie-chart-only-page">
                <DoughnutCard
                  title={t("deliveryMethodsStatistics")}
                  items={comparedDeliveryMethodsDoughnutCardItems}
                  currentPeriod={this.context.comparedPeriod}
                  blackThemed
                />
              </div>
            )}
          </div>
        )}
        {this.state.activeTab === "shipping_locations" && (
          <ShippingLocations exportRef={this.exportShippingLocationsRef} />
        )}
        {this.state.activeTab === "cancelled_orders" && <CancelledOrders />}
      </div>
    );
  }
}

Orders.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["orders", "common"])(Orders);
