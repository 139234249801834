/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Categories related utilities.
 * @filename categories.ts
 */

import { Category } from "api/category";
import { ChildrenRequestPayload } from "interfaces/category";
import {
  BrandsValues,
  ExportedSalesValues,
  GenericRequestPayload,
  ItemsSales,
  PartialBy,
} from "interfaces/overview";
import { getPaginatedPayloadData, getPayloadData, handleError } from "./common";

export function getCategories(
  payload: PartialBy<GenericRequestPayload, "currentPage">
): Promise<ItemsSales[]> {
  return Category.CategoriesPercentages(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCategoriesSales(payload: GenericRequestPayload) {
  return Category.categoriesSales(payload)
    .then((overviewPayload) => {
      return getPaginatedPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCategoriesSalesChildren(payload: ChildrenRequestPayload) {
  return Category.categoriesSalesChildren(payload)
    .then((overviewPayload) => {
      return getPaginatedPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCategoriesSalesExport(
  payload: PartialBy<GenericRequestPayload, "currentPage">
): Promise<ExportedSalesValues[]> {
  return Category.categoriesSalesExport(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getSalesSum(
  categorySales: ItemsSales[] | BrandsValues[]
): Pick<ItemsSales, "number_sold" | "sales_value"> {
  const result: Pick<ItemsSales, "number_sold" | "sales_value"> = {
    number_sold: 0,
    sales_value: 0,
  };
  categorySales.forEach((sale) => {
    result.number_sold += sale.number_sold;
    result.sales_value += sale.sales_value;
  });
  return result;
}
