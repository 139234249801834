/**
 * @author Youssef Tarek
 * @date 2021-12-04
 * @description Account information of the user
 * @filename account-information.tsx
 */
import { string as YUPString, object as YUPObject } from "yup";
import { Formik, Form, FormikHelpers, Field } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  changeLanguage,
  updateUserName as updateUserNameUtility,
  getActivityLogs,
  deleteAccount,
  enableTwoFactorAuthentication,
  validateTwoFactorAuthentication,
  disableTwoFactorAuthentication,
} from "utilities/settings";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Authenticator } from "utilities/authenticator";
import { ROUTES } from "consts/routes";
import i18next from "i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import { ActivityLogInterface } from "interfaces/settings";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/mobile";
import EditPassword from "./edit-password";
import { Modal } from "../../../common/modal";
import SectionLoader from "../../../common/section-loader";
import { ToggleSwitch } from "../../../common/toggle-switch";
import "react-phone-input-2/lib/material.css";
import OtpBox from "../../../common/otp-box";

interface AccountSettingsState {
  editingPassword?: boolean;
  showDeleteModal: boolean;
  showLogsModal: boolean;
  accountLogs: ActivityLogInterface[];
  loadingAccountLogs: boolean;
  enable2FA: boolean;
  phone: string;
  country: string;
  countryCallingCode: string;
  showOtpBox: boolean;
}

class AccountSettings extends React.Component<
  RouteComponentProps & WithTranslation,
  AccountSettingsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showLogsModal: false,
      accountLogs: null,
      loadingAccountLogs: false,
      enable2FA: false,
      phone: "",
      country: "",
      countryCallingCode: "",
      showOtpBox: false,
    };
    this.updateAccountName = this.updateAccountName.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.handleDownloadAccountLogs = this.handleDownloadAccountLogs.bind(this);
    this.hideLogsModal = this.hideLogsModal.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.toggleEnable2FA = this.toggleEnable2FA.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.toggleOtpBox = this.toggleOtpBox.bind(this);
    this.validate2FA = this.validate2FA.bind(this);
  }

  // give the enable 2FA toggler the right value
  componentDidMount() {
    this.setState({
      enable2FA: !!this.context.user.tfa_enabled,
    });
  }

  handleDownloadAccountLogs() {
    this.setState({ loadingAccountLogs: true });

    getActivityLogs()
      .then((accountLogs) => {
        this.setState({ accountLogs });
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        this.setState({ loadingAccountLogs: false });
      });
  }

  handleDeleteAccount() {
    deleteAccount()
      .then((result) => {
        Authenticator.logout();
        this.context.updateUser(null);
        this.props.history.push(ROUTES.Login.path);
        toast.success(result.message);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        this.hideDeleteModal();
      });
  }

  /**
   * validate the entered phone number against its country code,
   * update the state with these as well as the dialling code
   * @param phone the phone number to be validated
   * @param country the country name as a code
   */
  handlePhoneChange(phone: string, country) {
    if (isValidPhoneNumber(phone, country.countryCode.toUpperCase())) {
      this.setState({
        phone,
        country: country.countryCode.toUpperCase(),
        countryCallingCode: country.dialCode,
      });
    } else {
      this.setState({ phone: "" });
    }
  }

  updateAccountName(
    values: {
      name: string;
    },
    formikHelpers: FormikHelpers<{
      name: string;
    }>
  ) {
    if (this.context.user?.name === values.name) {
      formikHelpers.setSubmitting(false);
      return Promise.resolve();
    }
    return updateUserNameUtility(values.name)
      .then(() => {
        toast.success("Account name updated successfully");
        this.context.updateUser({ ...this.context.user, name: values.name });
        formikHelpers.setSubmitting(false);
      })
      .catch((err) => {
        toast.error("Error updating account name");
        toast.error(err?.message);
        formikHelpers.setSubmitting(false);
      });
  }

  toggleLanguage() {
    const lang = this.context.user.language === "ar" ? "en" : "ar";
    return changeLanguage(lang)
      .then(() => {
        toast.success("Language updated successfully");
        i18next.changeLanguage(lang);
        this.context.updateUser({ ...this.context.user, language: lang });
      })
      .catch((err) => {
        toast.error("Error updating language");
        toast.error(err?.message);
      });
  }

  showDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  hideDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  showLogsModal() {
    this.setState({ showLogsModal: true });
  }

  hideLogsModal() {
    this.setState({ showLogsModal: false });
  }

  /**
   * toggle the whether we are the state of enabling 2FA
   * according to the toggle switch.
   * disable 2FA if it is already enabled
   * @param checked the value of the toggle switch
   */
  toggleEnable2FA(checked: boolean) {
    this.setState({ enable2FA: checked });
    if (this.context.user.tfa_enabled && !checked) {
      this.disable2FA();
    }
  }

  /**
   * show/hide the OTP box
   */
  toggleOtpBox() {
    this.setState((prevState) => ({
      showOtpBox: !prevState.showOtpBox,
    }));
  }

  /**
   *
   * @param phoneNumberExt the country dialling code
   * @param phoneNumber phone number without the dialling code
   * @param toggleBox a flag to show/hide the OTP box which to be used if we want to resend the otp
   */
  enable2FA(phoneNumberExt: string, phoneNumber: string, toggleBox = true) {
    enableTwoFactorAuthentication(phoneNumberExt, phoneNumber)
      .then(() => {
        toast.success("OTP sent successfully");
        if (toggleBox) {
          this.toggleOtpBox();
        }
      })
      .catch((err) => {
        toast.error("Error enabling 2FA");
        toast.error(err?.message);
      });
  }

  /**
   * validates the the otp and assign the mobile number to the user
   * @param email the email address to be used for 2FA
   * @param otp the sent otp
   */
  validate2FA(email: string, otp: string) {
    validateTwoFactorAuthentication(email, otp)
      .then(() => {
        this.context.updateUser({
          ...this.context.user,
          tfa_enabled: 1,
          phone_number: this.state.phone.slice(
            this.state.countryCallingCode.length
          ),
          phone_number_ext: this.state.countryCallingCode,
        });
        toast.success("2FA validated successfully");
        this.toggleOtpBox();
      })
      .catch((err) => {
        toast.error("Error validating 2FA");
        toast.error(err?.message);
      });
  }

  /**
   * disable 2FA for the user
   */
  disable2FA() {
    disableTwoFactorAuthentication()
      .then(() => {
        this.context.updateUser({
          ...this.context.user,
          tfa_enabled: 0,
          phone_number: null,
          phone_number_ext: null,
        });
        toast.success("2FA disabled successfully");
        this.setState({ enable2FA: false });
      })
      .catch((err) => {
        toast.error("Error disabling 2FA");
        toast.error(err?.message);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.editingPassword) {
      return (
        <EditPassword
          onFinish={() => {
            Authenticator.logout();
            this.context.updateUser(null);
            this.context.updateSideBarOpened(false);
            this.props.history.push(ROUTES.Login.path);
          }}
        />
      );
    }
    return (
      <div className="account-settings">
        <div className="card">
          <h2 className="card--title">{t("accountSettings")}</h2>
          <Formik
            onSubmit={this.updateAccountName}
            initialValues={{
              name: this.context.user?.name,
            }}
            validationSchema={YUPObject().shape({
              name: YUPString().max(200).required("Name is required"),
            })}
          >
            {(formikBag) => (
              <Form className="card--section">
                <Field name="name">
                  {({ field, meta }) => (
                    <div className="form-field">
                      <label htmlFor="name">{t("fullName")}</label>
                      <input
                        id="topic"
                        placeholder="enter message topic"
                        name="name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <button
                  type="submit"
                  className="button save"
                  disabled={formikBag.isSubmitting}
                >
                  {t("updateName")}
                </button>
              </Form>
            )}
          </Formik>
          <div className="card--section">
            <div className="listing">
              <span className="listing__title">{t("email")}</span>
              <div className="listing__item">
                <div className="title">{this.context.user.email}</div>
              </div>
            </div>
            <div className="listing">
              <span className="listing__title">{t("password")}</span>
              <div className="listing__item">
                <div className="title">********</div>
                <button
                  type="button"
                  className="link"
                  onClick={() => this.setState({ editingPassword: true })}
                >
                  {t("editPassword")}
                </button>
              </div>
            </div>
            {/* TODO: enabled 2FA when sendgrid is ready */}
            <div className="listing disabled">
              <form>
                <ToggleSwitch
                  // checked={this.state.enable2FA}
                  checked={false}
                  // onChange={this.toggleEnable2FA}
                  onChange={() => {}}
                  id="enable2FA"
                  name={t("enableTwoFactorAuthentication")}
                />
              </form>
              {this.state.enable2FA && (
                <>
                  {this.context.user.tfa_enabled ? (
                    <div className="my-10">
                      <span className="listing__title">
                        {`${t("phoneNumber")}`}
                      </span>
                      <div className="title">
                        {`${parsePhoneNumber(
                          `+${this.context.user.phone_number_ext}${this.context.user.phone_number}`
                        ).formatInternational()}`}
                      </div>
                    </div>
                  ) : (
                    <form
                      className="inputs-container"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.enable2FA(
                          this.state.countryCallingCode,
                          this.state.phone.slice(
                            this.state.countryCallingCode.length
                          )
                        );
                      }}
                    >
                      <div className="my-10">
                        <span className="listing__title">
                          {`${t("phoneNumber")}`}
                        </span>
                        <PhoneInput
                          country="eg"
                          specialLabel=""
                          countryCodeEditable={false}
                          onChange={(phone, country) => {
                            this.handlePhoneChange(phone, country);
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="button submit-btn"
                        disabled={!this.state.phone}
                      >
                        {`${t("verify")}`}
                      </button>
                    </form>
                  )}
                </>
              )}
              {this.state.showOtpBox && (
                <OtpBox
                  title={t("verifyYourNumber")}
                  toggleOtpBox={this.toggleOtpBox}
                  phone={this.state.phone}
                  countryCallingCode={this.state.countryCallingCode}
                  country={this.state.country}
                  validate2FA={this.validate2FA}
                  email={this.context.user.email}
                  enable2FA={this.enable2FA}
                />
              )}
            </div>
          </div>
          <div className="card--section">
            <div className="listing">
              <span className="listing__title">{t("language")}</span>
              <div className="listing__item">
                <div className="title">
                  {this.context.user.language === "ar" ? "العربية" : "English"}
                </div>
                <button
                  type="button"
                  className="link"
                  onClick={() => this.toggleLanguage()}
                >
                  {this.context.user.language === "ar"
                    ? "Change to English"
                    : "التغيير إلى اللغة العربية"}
                </button>
              </div>
            </div>
          </div>
          {!this.context.user.is_admin && (
            <>
              <div className="card--section">
                <div className="listing listing--logs">
                  <span>{t("myActivity")}</span>
                  <div className="listing__item">
                    <p className="listing__item-paragraph">
                      {t("myActivityDescription")}
                    </p>
                    <button
                      type="submit"
                      className="button save"
                      onClick={() => {
                        this.handleDownloadAccountLogs();
                        this.showLogsModal();
                      }}
                    >
                      {t("requestMyActivity")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card--section">
                <div className="listing">
                  <div className="listing__item listing__item--delete">
                    <button
                      type="button"
                      className="link"
                      onClick={() => this.showDeleteModal()}
                    >
                      {t("deleteMyAccount")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal
          showModal={this.state.showDeleteModal}
          handleClose={() => this.hideDeleteModal()}
        >
          <div className="settings__modal">
            <h1 className="settings__modal-title">
              {t("deleteAccountConfirmationTitle")}
            </h1>
            <p className="settings__modal-description">
              {t("deleteAccountDescription")}
            </p>
            <div className="settings__modal-actions">
              <button
                type="button"
                className="button--main button--delete"
                onClick={this.handleDeleteAccount}
              >
                {t("delete")}
              </button>
              <button
                type="button"
                className="button-outline"
                onClick={() => this.hideDeleteModal()}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          showModal={this.state.showLogsModal}
          handleClose={() => this.hideLogsModal()}
        >
          <div className="settings__modal">
            <h1 className="settings__modal-title">{t("requestMyActivity")}</h1>
            <p className="settings__modal-description">
              {t("requestMyActivityDescription")}
            </p>
            {this.state.loadingAccountLogs ? (
              <SectionLoader />
            ) : (
              <div className="settings__modal-actions">
                <button type="button" className="button--main button--download">
                  {this.state.accountLogs && (
                    <CSVLink
                      data={this.state.accountLogs}
                      filename="loyalty-points.csv"
                      className="export-list-button"
                      target="_blank"
                      onClick={this.hideLogsModal}
                    >
                      {t("downloadFile")}
                    </CSVLink>
                  )}
                </button>
                <button
                  type="button"
                  className="button-outline"
                  onClick={() => this.hideLogsModal()}
                >
                  {t("cancel")}
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

AccountSettings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(AccountSettings);
