import {
  AdvancedFilters,
  SegmentFiltersParams,
} from "interfaces/advanced-filters";
import { Period, PeriodType } from "interfaces/period";
import { Listings } from "interfaces/settings";

/**
 * Returns name and value concatenated into camelCase format for use as URL
 * search query parameter keys
 *
 * @example
 * generateQueryKey('name', 'value') // returns 'nameValue'
 */
export function generateQueryKey(name: string, value: string) {
  const capitalizedValue =
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

  return name + capitalizedValue;
}

/**
 * Maps a Period object to a URL query string
 */
export function mapPeriodToQueryString(
  label: "comparedPeriod" | "currentPeriod",
  { from, to, type, alias }: Period
) {
  const query = new URLSearchParams();

  const typeQueryKey = generateQueryKey(label, "type");
  const fromQueryKey = generateQueryKey(label, "from");
  const toQueryKey = generateQueryKey(label, "to");
  const aliasQueryKey = generateQueryKey(label, "alias");

  query.append(typeQueryKey, type);
  query.append(fromQueryKey, from);
  query.append(toQueryKey, to);
  query.append(aliasQueryKey, encodeURIComponent(alias));

  return query.toString();
}

/**
 * Maps the activeTabs and listBy to the URL query parameters
 */

export function mapTabsToQueryString(activeTab?: string, listBy?: Listings) {
  const query = new URLSearchParams();
  if (activeTab) {
    query.append("activeTab", activeTab);
  }
  if (listBy) {
    query.append("listBy", listBy);
  }

  return query.toString();
}

/**
 * Maps comparedPeriod and currentPeriod URL query parameters to Period objects
 */
export function mapQueryStringToPeriod(
  label: "comparedPeriod" | "currentPeriod",
  query: string
): Period {
  const params = new URLSearchParams(query);

  const from = params.get(generateQueryKey(label, "from"));
  const to = params.get(generateQueryKey(label, "to"));
  const alias = params.get(generateQueryKey(label, "alias"));
  const type = params.get(generateQueryKey(label, "type")) as PeriodType;

  if ([type, from, to, alias].includes(null)) {
    return null;
  }

  return {
    type,
    from,
    to,
    alias: decodeURIComponent(alias),
  };
}

/**
 * Appends {@link AdvancedFilters filters} to a url as search params just like how the backend
 * required.
 * @param urlArg The url to add the filters as search params to
 * @param filtersArg The filters to be transformed to search params and appended to the url
 * @returns The inputted url with the inputted filters appended as search params.
 *
 * @example
 * const filters: AdvancedFilters = {
 *  payment_method: ["1", "2"],
 *  shipping_location: {
 *    country: ["EG"]
 *  }
 * }
 * const newUrl = appendFiltersToSearchParam("https://www.google.com", filters)
 *
 * console.log(newUrl.toString()) //https://www.google.com/?advanced_filters%5Bpayment_method%5D%5B0%5D=1&advanced_filters%5Bpayment_method%5D%5B1%5D=2&advanced_filters%5Bshipping_location%5D%5Bcountry%5D%5B0%5D=EG
 * console.log(decodeURI(newUrl.toString())) //https://www.google.com/?advanced_filters[payment_method][0]=1&advanced_filters[payment_method][1]=2&advanced_filters[shipping_location][country][0]=EG
 */
export function appendFiltersToSearchParam(
  urlArg: URL | string,
  filtersArg?: AdvancedFilters,
  segmentFilters = false
) {
  const filters = filtersArg || {};
  const url = new URL(urlArg);
  for (const filter in filters) {
    if (Array.isArray(filters[filter])) {
      for (const i in filters[filter]) {
        url.searchParams.append(
          `${segmentFilters ? "filters" : "advanced_filters"}[${filter}][${i}]`,
          filters[filter][i]
        );
      }
    } else {
      for (const nestedFilter in filters[filter]) {
        for (const i in filters[filter][nestedFilter]) {
          url.searchParams.append(
            `${
              segmentFilters ? "filters" : "advanced_filters"
            }[${filter}][${nestedFilter}][${i}]`,
            filters[filter][nestedFilter][i]
          );
        }
      }
    }
  }
  return url;
}

/**
 * same as {@link appendFiltersToSearchParam} but for segment filters
 * @param title The title of the segment
 * @param project The project of the segment
 * @param urlArg The url to add the filters as search params to
 * @param filtersArg The filters to be transformed to search params and appended to the url
 * @returns The inputted url with the inputted filters appended as search params.
 */
export function appendSegmentFiltersToSearchParam({
  title,
  project,
  urlArg,
  filtersArg,
}: SegmentFiltersParams) {
  const url = appendFiltersToSearchParam(urlArg, filtersArg, true);
  url.searchParams.append("title", title);
  url.searchParams.append("project", project);
  return url;
}
