/**
 * @author Karim Shalapy
 * @date 2020-09-27
 * @description implementation of a form containing a search input and a sort dropdown
 * @filename sort-search.tsx
 */
import React from "react";
import SearchIcon from "static/images/search.svg";
import ArrowDownIcon from "static/images/down-arrow.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  SortByDirection,
  SortByValueMinimal,
  SortByValue,
  SortOption,
  Direction,
} from "interfaces/overview";
import { deBounce } from "utilities/common";

interface SortWithDirection {
  sortBy?: SortOption;
  direction?: Direction;
  displayText: string;
}

interface SortSearchState {
  searchInput: string;
  productSortingMenu: boolean;
}

type SortSearchProps = {
  mode?: "minimal" | "full";
  sortBy: SortOption | undefined;
  direction: Direction | undefined;
  searchHandler: (value: string) => void;
  sortHandler: (sort?: SortOption, direction?: Direction) => void;
} & WithTranslation;

class SortSearch extends React.Component<SortSearchProps, SortSearchState> {
  static defaultProps: Partial<SortSearchProps>;

  debouncedSearchHandler = deBounce(this.props.searchHandler.bind(this));

  constructor(props: SortSearchProps) {
    super(props);
    this.state = {
      productSortingMenu: false,
      searchInput: "",
    };

    this.getSortDisplayText = this.getSortDisplayText.bind(this);
  }

  getSortDisplayText(sort: SortOption, direction: Direction) {
    const sortDisplayText = this.props.t(sort);
    if (sort === "name") {
      return `${sortDisplayText} (${
        direction === "asc" ? this.props.t("a-z") : this.props.t("z-a")
      })`;
    }
    return `${sortDisplayText} (${
      direction === "asc"
        ? this.props.t("lowToHigh")
        : this.props.t("highToLow")
    })`;
  }

  render() {
    const { t } = this.props;

    const sortEnum =
      this.props.mode === "minimal" ? SortByValueMinimal : SortByValue;

    const directions: Direction[] = Object.values(SortByDirection);

    const sortByWithDirection: SortWithDirection[] = [];

    // Add the default sort in the beginning
    sortByWithDirection.push({
      displayText: this.props.t("default"),
    });
    // Push in the `sortByWithDirection` all the sort enum values with the two directions.
    (Object.values(sortEnum) as SortOption[]).forEach((sortBy) => {
      directions.forEach((direction) => {
        sortByWithDirection.push({
          direction,
          sortBy,
          displayText: this.getSortDisplayText(sortBy, direction),
        });
      });
    });

    return (
      <form className="products-search">
        <SearchIcon className="search-icon" />

        <input
          type="text"
          className="search-input"
          placeholder={t("searchPlaceholder")}
          value={this.state.searchInput}
          onChange={(e) => {
            const searchInput = e.target.value;
            this.setState({ searchInput });
            this.debouncedSearchHandler(searchInput);
          }}
        />
        {!!this.state.searchInput && (
          <input
            type="reset"
            className="clear-icon"
            value="+"
            onClick={() => {
              this.debouncedSearchHandler.cancel();
              this.setState({ searchInput: "" });
              this.props.searchHandler("");
            }}
          />
        )}
        <div
          className="dropdown-container"
          role="listbox"
          aria-expanded={this.state.productSortingMenu}
          aria-label={t("sortBy")}
          tabIndex={0}
          onClick={() => {
            this.setState({
              productSortingMenu: !this.state.productSortingMenu,
            });
          }}
        >
          {t("sortBy")}
          <span className="dropdown-container__sort">
            {this.props.sortBy && this.props.direction
              ? this.getSortDisplayText(this.props.sortBy, this.props.direction)
              : ""}
          </span>
          <ArrowDownIcon className="dropdown-icon" />
          {this.state.productSortingMenu && (
            <ul className="dropdown-menu">
              {sortByWithDirection.map((item, i) => {
                if (item.direction && item.sortBy) {
                  return (
                    <li
                      role="option"
                      aria-selected={
                        this.props.direction === item.direction &&
                        this.props.sortBy === item.sortBy
                      }
                      aria-label={item.displayText}
                      key={`${item.sortBy}-${item.direction}-${i}`}
                      className="item"
                      onClick={() => {
                        this.props.sortHandler(item.sortBy, item.direction);
                      }}
                    >
                      {item.displayText}
                    </li>
                  );
                }
                return (
                  <li
                    role="option"
                    aria-selected={!this.props.sortBy && !this.props.direction}
                    aria-label={item.displayText}
                    key={`default-${i}`}
                    className="item"
                    onClick={() => {
                      this.props.sortHandler();
                    }}
                  >
                    {item.displayText}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </form>
    );
  }
}

SortSearch.defaultProps = {
  mode: "full",
};

export default withTranslation("sort-search")(SortSearch);
