/**
 * @author Ahmed Serag
 * @date 2021-06-08
 * @description side navbar component
 * @filename side-navbar.tsx
 */
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import DoubleArrowsIcon from "static/images/double-arrows.svg";
import LogoIcon from "static/images/logo.svg";
import OverviewIcon from "static/images/overview.svg";
import OrdersIcon from "static/images/order.svg";
import CustomersIcon from "static/images/customer.svg";
import CategoriesIcon from "static/images/category.svg";
import ProductIcon from "static/images/product.svg";
import BrandsIcon from "static/images/brands.svg";
import AbandonedCartsIcon from "static/images/abandoned-carts.svg";
import UserBehaviorIcon from "static/images/user-behavior.svg";
import PermissionsFilter from "./permissions-filter";

interface SideNavbarState {
  settingsMenu: boolean;
}
class SideNavbar extends React.Component<
  RouteComponentProps & WithTranslation,
  SideNavbarState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      settingsMenu: false,
    };
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div
        className={`side-navbar ${
          this.context.sideBarOpened ? "side-navbar--opened" : ""
        } ${this.context.sideNavBarCollapsed ? "side-navbar--collapsed" : ""}`}
      >
        {this.context.sideBarOpened && (
          <button
            type="button"
            className="side-navbar--exit"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            x
          </button>
        )}
        <div className="logo">
          <LogoIcon />
        </div>
        <div className="nav-items">
          <NavLink
            to={ROUTES.Overview.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <OverviewIcon className="nav-icon" />
            <span className="sidenav--label">{t("overview")}</span>
          </NavLink>
          <NavLink
            to={ROUTES.orders.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <OrdersIcon className="nav-icon" />
            <span className="sidenav--label">{t("orders")}</span>
          </NavLink>
          <NavLink
            to={ROUTES.customers.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <CustomersIcon className="nav-icon" />
            <span className="sidenav--label">{t("customers")}</span>
          </NavLink>
          <NavLink
            to={ROUTES.categories.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <CategoriesIcon className="nav-icon" />
            <span className="sidenav--label">{t("categories")}</span>
          </NavLink>
          {/* remove the brands tab for non internal projects */}
          {this.context.settings.is_internal ? (
            <PermissionsFilter allowedPlatforms={ROUTES.brands.platform}>
              <NavLink
                to={ROUTES.brands.path}
                className="item"
                onClick={() => {
                  this.context.updateSideBarOpened(false);
                }}
              >
                <BrandsIcon className="nav-icon" />
                <span className="sidenav--label">{t("brands")}</span>
              </NavLink>
            </PermissionsFilter>
          ) : null}
          <NavLink
            to={ROUTES.stock.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <ProductIcon className="nav-icon" />
            <span className="sidenav--label">{t("productStock")}</span>
          </NavLink>
          <PermissionsFilter
            allowedRoles={ROUTES.abandonedCarts.roles}
            allowedPlatforms={ROUTES.abandonedCarts.platform}
          >
            <NavLink
              to={ROUTES.abandonedCarts.path}
              className="item"
              onClick={() => {
                this.context.updateSideBarOpened(false);
              }}
            >
              <AbandonedCartsIcon className="nav-icon" />
              <span className="sidenav--label">{t("abandonedCarts")}</span>
            </NavLink>
          </PermissionsFilter>
          <NavLink
            to={ROUTES.userBehavior.path}
            className="item"
            onClick={() => {
              this.context.updateSideBarOpened(false);
            }}
          >
            <UserBehaviorIcon className="nav-icon" />
            <span className="sidenav--label">{t("userBehavior")}</span>
          </NavLink>
        </div>
        {!this.context.sideBarOpened && (
          <button
            className="hide-button"
            type="button"
            onClick={this.context.toggleSideNavBarCollapsed}
          >
            <span>
              {this.context.sideNavBarCollapsed ? t("view") : t("hide")}
            </span>
            <DoubleArrowsIcon />
          </button>
        )}
      </div>
    );
  }
}

SideNavbar.contextType = ANALYTICS_CONTEXT;
export default withTranslation("common")(SideNavbar);
