/* eslint-disable react/no-did-update-set-state */
/**
 * @author Ahmed Serag
 * @date 2019-06-09
 * @description Orders tab of the App.
 * @filename orders.tsx
 */
import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { Chart } from "chart.js";
import { getBarChart } from "utilities/charts";
import { Counts } from "interfaces/overview";
import isEqual from "lodash.isequal";
import { getDurationRange } from "utilities/common";
import { Period } from "interfaces/period";
import { withTranslation, WithTranslation } from "react-i18next";

interface DoughnutCardState {
  chart?: Chart<"doughnut", number[], string>;
  id: string;
  activeTab?: string;
  activeFragment?: number;
}

interface CollectionCardProps {
  tabs: Record<
    string,
    {
      items: Counts[];
      title: string;
    }
  >;
  title: string;
  subtitle?: string;
  duration?: boolean;
  currentPeriod?: Period;
}

class CollectionCard extends React.Component<
  CollectionCardProps & WithTranslation,
  DoughnutCardState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  ChartRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: CollectionCardProps & WithTranslation) {
    super(props);
    const tabsKeys = Object.keys(this.props.tabs);

    this.state = {
      id: Date.now().toString(),
      activeTab: tabsKeys[0],
      activeFragment: 0,
    };
    this.ChartRef = React.createRef();
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    if (this.props.tabs[this.state.activeTab].items) {
      this.setState({
        chart: getBarChart(
          this.ChartRef.current,
          this.getBarChartData().barChartLabels,
          this.getBarChartData().barChartData,
          this.state.id
        ),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isEqual(
        prevProps.tabs[this.state.activeTab].items,
        this.props.tabs[this.state.activeTab].items
      ) ||
      !this.props.tabs[this.state.activeTab].items
    ) {
      return null;
    }
    this.loadChart();

    return null;
  }

  getBarChartData() {
    const totalChartData = this.props.tabs[this.state.activeTab].items;
    let chartData = totalChartData;
    const chartItemsMaxLength = 10;
    // Paginate chart data if there are more than chart items max length

    if (totalChartData.length > chartItemsMaxLength) {
      chartData = totalChartData.slice(
        this.state.activeFragment * chartItemsMaxLength,
        this.state.activeFragment * chartItemsMaxLength + chartItemsMaxLength
      );
    }

    const barChartLabels = chartData.map((val) => val.name);
    const barChartValues = chartData.map((val) => {
      return Number(val.count);
    });
    const barChartData = [
      {
        values: barChartValues,
        label: this.props.tabs[this.state.activeTab].title,
        title: this.props.title,
      },
    ];
    return { barChartLabels, barChartData };
  }

  loadChart() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }
    const chartData = this.getBarChartData();
    this.setState({
      chart: getBarChart(
        this.ChartRef.current,
        chartData.barChartLabels,
        chartData.barChartData,
        this.state.id
      ),
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const tabsKeys = Object.keys(this.props.tabs);
    const totalChartData = this.props.tabs[this.state.activeTab]?.items;
    const fragments = totalChartData && Math.ceil(totalChartData.length / 10);

    return (
      <>
        <div className="card">
          <h2 className="card--title card-graph--title">
            <div>
              {this.props.title}
              {this.props.duration && (
                <span className="duration">
                  (
                  {getDurationRange(
                    this.props.currentPeriod?.type,
                    this.props.currentPeriod?.from,
                    this.props.currentPeriod?.to
                  )}
                  )
                </span>
              )}
            </div>
            {/* TODO: fix export chart
            {this.state.chart && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" id={this.state.id}>
                {t("common:exportChart")}
              </a>
            )} */}
          </h2>
          {this.props.subtitle && (
            <h3 className="card--subtitle">***{this.props.subtitle}***</h3>
          )}
          <div className="card-tabs">
            {tabsKeys.map((key) => {
              return (
                <div
                  key={key}
                  className={`tab${
                    this.state.activeTab === key ? " active" : ""
                  }`}
                  onClick={() => {
                    if (this.state.activeTab !== key) {
                      this.setState(
                        {
                          activeTab: key,
                          activeFragment: 0,
                        },
                        () => {
                          this.loadChart();
                        }
                      );
                    }
                  }}
                >
                  {key}
                </div>
              );
            })}
          </div>
          <hr />
          {fragments > 1 && (
            <div className="card-pagination">
              <div
                className={`pagination-item${
                  this.state.activeFragment === 0 ? " disabled" : ""
                }`}
                onClick={() => {
                  if (this.state.activeFragment !== 0) {
                    this.setState(
                      {
                        activeFragment: this.state.activeFragment - 1,
                      },
                      () => {
                        this.loadChart();
                      }
                    );
                  }
                }}
              >
                {t("previous")}
              </div>
              <div
                className={`pagination-item${
                  this.state.activeFragment === fragments - 1 ? " disabled" : ""
                }`}
                onClick={() => {
                  if (this.state.activeFragment !== fragments - 1) {
                    this.setState(
                      {
                        activeFragment: this.state.activeFragment + 1,
                      },
                      () => {
                        this.loadChart();
                      }
                    );
                  }
                }}
              >
                {t("next")}
              </div>
            </div>
          )}
          <div className="bar-chart__container">
            <canvas id={this.state.id} ref={this.ChartRef} />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("common")(CollectionCard);
