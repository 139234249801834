/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description user behavior of the App.
 * @filename user-behavior.tsx
 */
import React from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import { ExportedPromoCodesList } from "interfaces/user-behavior";
import { ROUTES } from "consts/routes";
import Header from "../../common/header";
import SearchTerms from "./search-terms";
import LoyaltyProgram from "./loyalty-program";
import Promocodes from "./promo-codes";
import AllPromoCodes from "./all-promo-codes";
import BackArrowIcon from "../../../../static/images/left-arrow.svg";
import PageViews from "./page-views/index";

type UserBehaviorTab =
  | "search_terms"
  | "search_categories"
  | "searched_products"
  | "loyalty_program"
  | "promo_codes"
  | "page_views";

type UserBehaviorState = {
  activeTab: UserBehaviorTab;
  id: string;
  showAllPromos: boolean;
  exportedPromoCodes?: ExportedPromoCodesList[];
  pageTitle?: string;
};

class UserBehavior extends React.Component<
  RouteComponentProps & WithTranslation,
  UserBehaviorState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportUserBehaviorRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    const query = window.location.search;
    const params = new URLSearchParams(query);

    this.exportUserBehaviorRef = React.createRef();

    this.state = {
      activeTab:
        (params.get("activeTab") as UserBehaviorTab) ??
        this.context?.settings.platform === "magento"
          ? "search_terms"
          : "promo_codes",
      id: Date.now().toString(),
      showAllPromos: false,
      pageTitle: "",
    };

    this.updateShowAllPromosState = this.updateShowAllPromosState.bind(this);
    this.updateExportedPromoCodes = this.updateExportedPromoCodes.bind(this);
    this.updatePageTitle = this.updatePageTitle.bind(this);
  }

  componentDidMount() {
    // set page title
    document.title = "User Behavior";
  }

  componentDidUpdate(_prevProps, prevState: UserBehaviorState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(this.state.activeTab);
      // set page title according to the active tab
      switch (this.state.activeTab) {
        case "search_terms":
          document.title = "Search Terms";
          break;
        case "loyalty_program":
          document.title = "Loyalty Program";
          break;
        case "promo_codes":
          document.title = "Promocodes";
          break;
        case "page_views":
          document.title = "Page Views";
          break;
        default:
          document.title = "User Behavior";
          break;
      }
    }
  }

  updateShowAllPromosState(showPromosState) {
    this.setState({ showAllPromos: showPromosState });
  }

  updateExportedPromoCodes(exportedPromoCodes) {
    this.setState({ exportedPromoCodes });
  }

  updatePageTitle(pageTitle) {
    this.setState({ pageTitle });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="customers">
        <Header
          title={t("userBehavior")}
          exportRef={this.exportUserBehaviorRef}
          disableDateFilters={this.state.activeTab === "search_terms"}
          disableAdvancedFilters={this.state.activeTab === "search_terms"}
        />
        <div className="tabs">
          {this.context.settings.platform !== "woocommerce" && (
            <div
              className={`tab ${
                this.state.activeTab === "search_terms" ? " active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "search_terms") {
                  this.setState({ activeTab: "search_terms" });
                }
              }}
            >
              {t("searchTerms")}
            </div>
          )}
          <div
            className={`tab ${
              this.state.activeTab === "promo_codes" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "promo_codes") {
                this.setState({ activeTab: "promo_codes" });
              }
            }}
          >
            {t("promocodes")}
          </div>
          {this.context.settings?.edition !== "community" && (
            <div
              className={`tab ${
                this.state.activeTab === "loyalty_program" ? " active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "loyalty_program") {
                  this.setState({ activeTab: "loyalty_program" });
                }
              }}
            >
              {t("loyaltyProgram")}
            </div>
          )}
          <div
            className={`tab ${
              this.state.activeTab === "page_views" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "page_views") {
                this.setState({ activeTab: "page_views" });
              }
            }}
          >
            {t("pageViews")}
          </div>
        </div>
        {this.state.activeTab === "search_terms" && (
          <SearchTerms exportRef={this.exportUserBehaviorRef} />
        )}
        {this.state.activeTab === "loyalty_program" && (
          <LoyaltyProgram exportRef={this.exportUserBehaviorRef} />
        )}
        {this.state.activeTab === "promo_codes" &&
          (!this.state.showAllPromos ? (
            <Promocodes
              exportRef={this.exportUserBehaviorRef}
              updateShowAllPromosState={this.updateShowAllPromosState}
              updateExportedPromoCodes={this.updateExportedPromoCodes}
            />
          ) : (
            <>
              <button
                className="products-button--back"
                type="button"
                onClick={() => {
                  this.setState({
                    showAllPromos: false,
                  });
                }}
              >
                <BackArrowIcon />
                {t("backToPagesList")}
              </button>
              <AllPromoCodes
                exportRef={this.exportUserBehaviorRef}
                unpaginatedExportedPromoCodes={this.state.exportedPromoCodes}
              />
            </>
          ))}
        {this.state.activeTab === "page_views" && (
          <>
            {this.context.settings.ga4_property_id ? (
              <PageViews />
            ) : (
              <div className="page-views page-views__empty">
                <p>{t("GA4NotIntegrated")}</p>
                <NavLink
                  className="button save"
                  to={`${ROUTES.settings.path}/integration`}
                >
                  {t("integrateGA4")}
                </NavLink>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

UserBehavior.contextType = ANALYTICS_CONTEXT;
export default withTranslation("user-behavior")(UserBehavior);
