/**
 * @author Ahmed Serag
 * @date 2019-12-13
 * @description not found layout.
 * @filename not-found.tsx
 */
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "consts/routes";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import BundleIcon from "static/images/bundle.svg";
import DiscountIcon from "static/images/discount.svg";
import StockIcon from "static/images/stock.svg";
import Arrow from "static/images/down-arrow.svg";
import { RecommendationsType } from "interfaces/recommendations";

/**
 * React Component that renders overview recommendations card.
 *
 * @extends {React.Component}
 */
class OverviewRecommendations extends React.Component<WithTranslation> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="card overview-card transparent-card m-0">
        <div className="recommendations">
          <div className="stock-banner banner">
            <div className="banner-icon">
              <StockIcon />
            </div>
            <div className="banner-text">
              <span className="title">{t("stockingRecommendation")}</span>
              <span className="description">
                {t("stockingRecommendationsDesc")}
              </span>
              <Link
                to={{
                  pathname: `${ROUTES.recommendationsList.path}/stock`,
                  state: { recommendationType: RecommendationsType.STOCK },
                }}
              >
                {t("viewList")}
                <Arrow className="right-arrow" />
              </Link>
            </div>
          </div>
          <div className="bundle-banner banner">
            <div className="banner-icon">
              <BundleIcon />
            </div>
            <div className="banner-text">
              <span className="title">{t("bundleRecommendation")}</span>
              <span className="description">
                {t("bundleRecommendationsDesc")}
              </span>
              <Link
                to={{
                  pathname: `${ROUTES.recommendationsList.path}/bundle`,
                  state: { recommendationType: RecommendationsType.BUNDLE },
                }}
              >
                {t("viewRecommendations")}
                <Arrow className="right-arrow" />
              </Link>
            </div>
          </div>
          <div className="discount-banner banner">
            <div className="banner-icon">
              <DiscountIcon />
            </div>
            <div className="banner-text">
              <span className="title">{t("promotionsRecommendation")}</span>
              <span className="description">
                {t("seasonalityRecommendationsDesc")}
              </span>
              <Link
                to={{
                  pathname: `${ROUTES.recommendationsList.path}/promotion`,
                  state: { recommendationType: RecommendationsType.PROMOTION },
                  search: `?type=${RecommendationsType.PROMOTION}`,
                }}
              >
                {t("viewList")}
                <Arrow className="right-arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OverviewRecommendations.contextType = ANALYTICS_CONTEXT;
export default withTranslation("overview")(OverviewRecommendations);
