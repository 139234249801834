/**
 * @author Salma Hefnawy
 * @date 2021-04-03
 * @description add user component
 * @filename add-user.tsx
 */
import { Formik, Form, Field } from "formik";
import React from "react";
// import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { string as YUPString, object as YUPObject } from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Select from "react-select";
import { SegmentFilters } from "interfaces/advanced-filters";
import CreateSegment from "common/advanced-filters/create-segment";
import BackArrowIcon from "static/images/left-arrow.svg";
import CustomersIcon from "static/images/no-customers.svg";
import { Modal } from "common/modal";
import { getCustomersCountBySegment } from "utilities/marketing-campaigns";
import { MarketingCampaignRecieverCustomers } from "interfaces/customers-overview";
import Loader from "../../common/Loader";

interface AddCampaignDetailsState {
  isCreateANewSegmentOpen: boolean;
  loading: boolean;
  loadingFiltersSegments: boolean;
  availableFiltersSegments?: SegmentFilters[];
  editingSegment?: SegmentFilters;
  recievers?: MarketingCampaignRecieverCustomers;
  addCustomerAttributes: boolean;
  customersCount?: number;
  id: string;
}
interface AddCampaignDetailsProps {
  onSubmit?: () => void;
}
class AddCampaignDetails extends React.Component<
  WithTranslation & AddCampaignDetailsProps,
  AddCampaignDetailsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: WithTranslation & AddCampaignDetailsProps) {
    super(props);

    this.state = {
      isCreateANewSegmentOpen: false,
      loading: false,
      availableFiltersSegments: [],
      loadingFiltersSegments: false,
      addCustomerAttributes: false,
      id: Date.now().toString(),
    };
    // this.handleAddCampaignDetails = this.handleAddCampaignDetails.bind(this);
    this.loadData = this.loadData.bind(this);

    this.toggleCreateANewSegment = this.toggleCreateANewSegment.bind(this);
    this.showAddCustomerAttributesModal =
      this.showAddCustomerAttributesModal.bind(this);
    this.hideAddCustomerAttributesModal =
      this.hideAddCustomerAttributesModal.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentDidUpdate(_prevProps, prevState: AddCampaignDetailsState) {
    if (prevState.recievers !== this.state.recievers) {
      console.log(this.state.recievers);
      //   this.setState({ loading: true });
      getCustomersCountBySegment(this.context.project, this.state.recievers)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        });
      // .finally(() => {
      //   this.setState({
      //     loading: false,
      //   });
      // });
    }
  }

  toggleCreateANewSegment(filtersSegment?: SegmentFilters) {
    this.setState({
      isCreateANewSegmentOpen: !this.state.isCreateANewSegmentOpen,
      editingSegment: filtersSegment,
    });
  }

  loadData() {
    this.setState({ loading: true });
    getCustomersCountBySegment(this.context.project)
      .then((res) => {
        this.setState({ customersCount: res.users_count });
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }
  //   handleAddCampaignDetails(values: AddCampaignDetailsInput) {
  // AddCampaignDetails(values.name, values.email, values.role)
  //   .then((result) => {
  //     toast.success(result.message);
  //     this.props.onSubmit();
  //     this.props.onFinish();
  //   })
  //   .catch((errors) => {
  //     errors.forEach((error: string) => toast.error(error));
  //   });
  //   }

  showAddCustomerAttributesModal() {
    this.setState({ addCustomerAttributes: true });
  }

  hideAddCustomerAttributesModal() {
    this.setState({ addCustomerAttributes: false });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const segmentsOptions = this.context.availableFiltersSegments.map(
      (segment) => {
        return {
          value: segment.id,
          label: segment.title,
        };
      }
    );

    if (this.state.loading) {
      return (
        <div className="campaign-details">
          <Loader />
        </div>
      );
    }

    return (
      <div className="campaign-details">
        <Formik
          onSubmit={(values) => console.log(values)}
          initialValues={{
            campaignName: "",
            recievers: "all-customers",
            customersSegment: "",
            customerSpending: "",
            customerSpendingAmount: "",
          }}
          validationSchema={YUPObject().shape({
            campaignName: YUPString()
              .required(t("settings:requiredName"))
              .min(3, t("settings:min3Characters")),
            campaignSubject: YUPString()
              .required(t("settings:requiredSubject"))
              .min(3, t("settings:min3Characters")),
            recievers: YUPString().required(),
            storeCategory: YUPString().equals(
              segmentsOptions.map((item) => item.value),
              "Please choose a proper segment."
            ),
          })}
        >
          {(formikBag) => (
            <Form className="card--section">
              <Field name="campaignName">
                {({ field, meta }) => (
                  <div className="form-field">
                    <label htmlFor="campaignName">{t("campaignName")}</label>
                    <input
                      placeholder={t("enterCampaignName")}
                      name="campaignName"
                      type="text"
                      {...field}
                      value={field.value ?? ""}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <Field name="campaignSubject">
                {({ field, meta }) => (
                  <div className="form-field form-field--subject">
                    <label htmlFor="campaignSubject">
                      {t("campaignSubject")}
                    </label>
                    <input
                      placeholder={t("entercampaignSubject")}
                      name="campaignSubject"
                      type="text"
                      {...field}
                      value={field.value ?? ""}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <div className="spacer" />
              <div className="form-field">
                <label id="radio-group">{t("recievers")}</label>
                <div
                  role="group"
                  aria-labelledby="radio-group"
                  className="campaign-details__radio-group"
                >
                  <label>
                    <Field
                      type="radio"
                      name="recievers"
                      value="all-customers"
                    />
                    {t("allCustomers")}
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="recievers"
                      value="customers-segment"
                    />
                    {t("customerSegment")}
                  </label>
                </div>
              </div>

              {formikBag.values.recievers === "customers-segment" && (
                <>
                  <Field name="customersSegment">
                    {({ field, meta, form }) => {
                      return (
                        <div
                          className="campaign-details__select-field form-field"
                          aria-invalid={!!(meta.touched && meta.error)}
                        >
                          <label htmlFor="customersSegment">
                            {t("customerSegment")}
                          </label>
                          <Select
                            id="customersSegment"
                            name={field.name}
                            placeholder={t("customerSegmentPlaceHolder")}
                            classNames={{
                              control: (state) => {
                                if (state.isFocused) {
                                  return "campaign-details__select-focused";
                                }
                                return "campaign-details__select";
                              },
                            }}
                            options={segmentsOptions}
                            value={segmentsOptions.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(
                              option: (typeof segmentsOptions)[number]
                            ) => {
                              form.setFieldValue(
                                field.name,
                                option?.value || ""
                              );

                              this.setState({
                                recievers: {
                                  filter_segment_id: option.value.toString(),
                                },
                              });
                            }}
                            isClearable
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      );
                    }}
                  </Field>
                  <button
                    type="button"
                    className="button-underlined black modal-button--show"
                    onClick={() => {
                      this.setState({ isCreateANewSegmentOpen: true });
                    }}
                  >
                    {t("Create a New Segment")}
                  </button>
                  <div className="campaign-details__attributes form-field">
                    <label htmlFor="customersAttributes">
                      {t("Add User Attributes")}
                    </label>
                    <button
                      type="button"
                      className="button-underlined black modal-button--show"
                      onClick={() => {
                        this.showAddCustomerAttributesModal();
                      }}
                    >
                      {t("Add User Attributes")}
                    </button>
                    <Modal
                      showModal={this.state.addCustomerAttributes}
                      handleClose={() => this.hideAddCustomerAttributesModal()}
                      sideModal
                    >
                      <div className="campaign-details__attributes-modal">
                        <div className="create-segment__back">
                          <BackArrowIcon />
                          <button
                            className="button-underlined black"
                            onClick={this.hideAddCustomerAttributesModal}
                            type="button"
                          >
                            {t("back")}
                          </button>
                        </div>
                        <h2> {t("Add User Attributes")}</h2>

                        <div className="campaign-details__attribute">
                          <Field name="customerSpending">
                            {({ field, meta, form }) => {
                              return (
                                <div
                                  className="campaign-details__select-field form-field"
                                  aria-invalid={!!(meta.touched && meta.error)}
                                >
                                  <h3>{t("Customer Spending Attribute")}</h3>
                                  <label htmlFor="customerSpending">
                                    {t("customertype")}
                                  </label>
                                  <Select
                                    id="customerSpending"
                                    name={field.name}
                                    placeholder={t(
                                      "customerSegmentPlaceHolder"
                                    )}
                                    classNames={{
                                      control: (state) => {
                                        if (state.isFocused) {
                                          return "campaign-details__select-focused";
                                        }
                                        return "campaign-details__select";
                                      },
                                    }}
                                    options={segmentsOptions}
                                    value={segmentsOptions.find(
                                      (option) => option.value === field.value
                                    )}
                                    onChange={(
                                      option: (typeof segmentsOptions)[number]
                                    ) => {
                                      form.setFieldValue(
                                        field.name,
                                        option?.value || ""
                                      );
                                    }}
                                    isClearable
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                          <Field name="customerSpendingAmount">
                            {({ field, meta }) => (
                              <div className="form-field">
                                <label htmlFor="customerSpendingAmount">
                                  {t("Amount")}
                                </label>
                                <input
                                  placeholder={t("entercustomerSpendingAmount")}
                                  name="customerSpendingAmount"
                                  type="number"
                                  min="0"
                                  {...field}
                                  value={
                                    field.value ? field.value.toString() : ""
                                  }
                                />
                                <span>{t("egp")}</span>
                                {meta.touched && meta.error && (
                                  <div className="error">{meta.error}</div>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="spacer" />
                        <div className="campaign-details__attribute">
                          <Field name="customerOrdersFrequency">
                            {({ field, meta, form }) => {
                              return (
                                <div
                                  className="campaign-details__select-field form-field"
                                  aria-invalid={!!(meta.touched && meta.error)}
                                >
                                  <h3>
                                    {t("Customer OrdersFrequency Attribute")}
                                  </h3>
                                  <label htmlFor="customerOrdersFrequency">
                                    {t("customertype")}
                                  </label>
                                  <Select
                                    id="customerOrdersFrequency"
                                    name={field.name}
                                    placeholder={t(
                                      "customerSegmentPlaceHolder"
                                    )}
                                    classNames={{
                                      control: (state) => {
                                        if (state.isFocused) {
                                          return "campaign-details__select-focused";
                                        }
                                        return "campaign-details__select";
                                      },
                                    }}
                                    options={segmentsOptions}
                                    value={segmentsOptions.find(
                                      (option) => option.value === field.value
                                    )}
                                    onChange={(
                                      option: (typeof segmentsOptions)[number]
                                    ) => {
                                      form.setFieldValue(
                                        field.name,
                                        option?.value || ""
                                      );
                                    }}
                                    isClearable
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                          <Field name="customerOrdersFrequencyAmount">
                            {({ field, meta }) => (
                              <div className="form-field">
                                <label htmlFor="customerOrdersFrequencyAmount">
                                  {t("Amount")}
                                </label>
                                <input
                                  placeholder={t(
                                    "enterCustomerOrdersFrequencyAmount"
                                  )}
                                  name="customerOrdersFrequencyAmount"
                                  type="number"
                                  min="0"
                                  {...field}
                                  value={
                                    field.value ? field.value.toString() : ""
                                  }
                                />
                                <span>{t("egp")}</span>
                                {meta.touched && meta.error && (
                                  <div className="error">{meta.error}</div>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="action-buttons">
                          <button type="button" className="button save black">
                            {t("save")}
                          </button>
                          <button
                            className="button-underlined black"
                            type="button"
                            onClick={this.hideAddCustomerAttributesModal}
                          >
                            {t("cancel")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </>
              )}
              <div className="campaign-details__count form-field">
                <div>
                  <CustomersIcon />
                  <h3>{t("Number of customers")}</h3>
                </div>
                <p>{this.state.customersCount}</p>
              </div>
              <div className="action-buttons">
                <button
                  type="submit"
                  className="button save black"
                  disabled={!(formikBag.isValid && formikBag.dirty)}
                >
                  {t("saveAddContent")}
                </button>
                <button
                  className="button-underlined black"
                  type="button"
                  //   onClick={() => this.props.onFinish()}
                >
                  {t("draft")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {this.state.isCreateANewSegmentOpen && (
          <CreateSegment
            toggleCreateANewSegment={this.toggleCreateANewSegment}
            segment={this.state.editingSegment}
          />
        )}
      </div>
    );
  }
}

AddCampaignDetails.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["marketing-campaigns", "settings"])(
  AddCampaignDetails
);
