/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Customers related utilities.
 * @filename customers.ts
 */

import { Customer } from "api/customer";
import {
  CustomersOverview,
  CustomersPerDay,
} from "interfaces/customers-overview";
import {
  CustomerOrderedMoreRequestPayload,
  CustomerSegmentsByType,
  CustomerSegmentsByTypePayload,
  CustomerSpendingMoreRequestPayload,
  CustomersSpending,
  CustomersSpentOrderedMore,
  Quartiles,
} from "interfaces/customers-spending";
import { GenericRequestPayload } from "interfaces/overview";
import { getPayloadData, handleError } from "./common";

export function getCustomersPerDay(
  payload: GenericRequestPayload
): Promise<CustomersPerDay> {
  return Customer.numberOfCustomersPerDay(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCustomersOverview(
  payload: GenericRequestPayload
): Promise<CustomersOverview> {
  return Customer.CustomersOverview(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload).then((overview) => {
        return overview;
      });
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCustomersSpending(
  payload: GenericRequestPayload
): Promise<Record<Quartiles, CustomersSpending>> {
  return Customer.customersSpending(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
export function getCustomersSpentMore(
  payload: CustomerSpendingMoreRequestPayload
): Promise<CustomersSpentOrderedMore> {
  return Customer.customersSpentMore(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
export function getCustomersOrderedMore(
  payload: CustomerOrderedMoreRequestPayload
): Promise<CustomersSpentOrderedMore> {
  return Customer.customersOrderedMore(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
export function getCustomerSegmentsByType(
  payload: CustomerSegmentsByTypePayload
): Promise<CustomerSegmentsByType> {
  return Customer.customerSegmentsByType(payload)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
