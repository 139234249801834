/**
 * @author Karim Shalapy
 * @date 2022-10-02
 * @description Implementation of advanced filters related utilities.
 * @filename advanced-filters.ts
 */

import { AdvancedFilters } from "api/advanced-filters";
import { KEY_VAL_SEPARATOR } from "consts/advanced-filters";
import {
  AdvancedFilters as IAdvancedFilters,
  SearchDropdownOption,
  SegmentFiltersParams,
} from "interfaces/advanced-filters";
import { MultiValue } from "react-select";
import { getPayloadData, handleError } from "./common";

/**
 * handles fetching the available filters
 * @param project
 */
export function getAvailableFilters(
  project: string
): Promise<IAdvancedFilters> {
  return AdvancedFilters.getAvailableFilters(project)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * Maps the flattened locations value array to a multiselect value to be used for the `react-select`
 * component
 * @param arr The array to be mapped
 * @returns The mapped array
 */
export function mapFlattenedToMultiSelectValue(
  arr?: string[]
): MultiValue<SearchDropdownOption> {
  const array = arr || [];
  return array.map((el) => ({
    value: el,
    label: el.split(KEY_VAL_SEPARATOR)[1],
  }));
}

/**
 * Maps the multiselect value to be used from the `react-select` component to flattened locations
 * @param arr The array to be mapped
 * @returns The mapped array
 */
export function mapMultiSelectToFlattenedValue(
  arr?: ReturnType<typeof mapFlattenedToMultiSelectValue>
) {
  const array = arr || [];
  return array.map((el) => el.value);
}

/**
 * Flattens the filters to make it have only one level of options.
 * @param filtersArg The filters to be flattened
 * @returns The flattened filters
 */
export function flattenFilters(filtersArg?: IAdvancedFilters) {
  const filters = filtersArg || {};
  const keys = Object.keys(filters);
  const flattenedFilters: Record<string, string[]> = {};
  keys.forEach((key) => {
    if (Array.isArray(filters[key])) {
      const item = filters[key] as string[];
      flattenedFilters[key] = item.filter(Boolean);
    } else if (typeof filters[key] === "object") {
      const item = filters[key] as Record<string, string[]>;
      flattenedFilters[key] = [];
      Object.keys(item).forEach((nestedKey) => {
        flattenedFilters[key] = flattenedFilters[key].concat(
          item[nestedKey]
            .filter(Boolean)
            .map((value) => `${nestedKey}${KEY_VAL_SEPARATOR}${value}`)
        );
      });
    }
  });
  return flattenedFilters;
}

/**
 * Undoes the flattening done by {@link flattenFilters}.
 * @param filtersArg The filters to be unflattened
 * @returns The unflattened filters
 */
export function unflattenFilters(
  differentFilters?: ReturnType<typeof flattenFilters>
) {
  const filters = differentFilters || this.state.flattenedFilters;
  const keys = Object.keys(filters);
  const unflattenedFilters: IAdvancedFilters = {};
  keys.forEach((key) => {
    if (!filters[key][0]) {
      return;
    }
    const isFlattened = filters[key][0].includes(KEY_VAL_SEPARATOR);
    if (!isFlattened) {
      unflattenedFilters[key] = filters[key].filter(Boolean);
    } else {
      unflattenedFilters[key] = {};
      filters[key].forEach((flattenedValue) => {
        const [nestedKey, value] = flattenedValue.split(KEY_VAL_SEPARATOR);
        unflattenedFilters[key][nestedKey] = (
          unflattenedFilters[key][nestedKey] || []
        ).concat(value);
      });
    }
  });
  return unflattenedFilters;
}
/**
 * creates a new segment which is a combination of predefined filters
 * @param title title of the segment
 * @param project project of the segment
 * @param filtersArg filters of the segment
 * @returns
 */
export function createNewSegment({
  title,
  project,
  filtersArg,
}: SegmentFiltersParams) {
  const filters = filtersArg || {};
  return AdvancedFilters.createNewSegment({
    title,
    project,
    filtersArg: filters,
  })
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
/**
 * get all filters segments for a project
 * @param project
 * @returns
 */
export function getAllFiltersSegments(project: string) {
  return AdvancedFilters.getAllFiltersSegments(project)
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * show filters preview under each segment detailing the filters in it
 * @param filters
 * @returns
 * @example "payment_methods: cashondelivery, +1 more, state: closed, +1 more"
 */
export function showFiltersPreview(filters: IAdvancedFilters) {
  const flattenedFilters = flattenFilters(filters);
  const filtersKeys = Object.keys(flattenedFilters);
  const filtersValues = Object.values(flattenedFilters);
  const filtersPreview = filtersKeys.map((key, index) => {
    // show one value for each key and add "+x more" if there are more values
    if (filtersValues[index].length > 1) {
      filtersValues[index].splice(
        1,
        filtersValues[index].length - 1,
        `+${filtersValues[index].length - 1} more`
      );
    }
    return `${key}: ${filtersValues[index].join(", ")}`;
  });
  // show only the first two filters and add "+x more" if there are more filters
  if (filtersPreview.length > 1) {
    filtersPreview.splice(
      1,
      filtersPreview.length - 1,
      `+${filtersPreview.length - 1} more`
    );
  }
  return filtersPreview.join(", ");
}

/**
 * delete a segment
 * @param project project of the segment
 * @param id id of the segment to be deleted
 * @returns
 */
export function deleteFiltersSegment(project: string, id: number) {
  return AdvancedFilters.deleteFiltersSegment(project, id)
    .then((response) => {
      return getPayloadData(response, "message");
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * update a segment
 * @param id id of the segment to be updated
 * @returns
 */
export function updateFiltersSegment({
  project,
  id,
  title,
  filtersArg,
}: SegmentFiltersParams) {
  return AdvancedFilters.updateFiltersSegment({
    project,
    id,
    title,
    filtersArg,
  })
    .then((response) => {
      return getPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
