/**
 * @author Ahmed Serag
 * @date 2021-07-01
 * @description Implementation of Orders related utilities.
 * @filename orders.ts
 */

import { Order } from "api/order";
import { Project } from "consts/project";
import {
  ByFilterSegmentPercentage,
  ByOrderSourcePercentage,
  ByPaymentMethodPercentage,
  CancelledOrdersPercentage,
  OrdersOverview,
  Product,
} from "interfaces/orders-overview";
import {
  Counts,
  GenericRequestPayload,
  ItemsSales,
  Overview,
} from "interfaces/overview";
import { OrdersHourlyBreakdown } from "../definitions/interfaces/orders-overview";
import { getPaginatedPayloadData, getPayloadData, handleError } from "./common";

export function getOverView(payload: GenericRequestPayload): Promise<Overview> {
  return Order.overview(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload).then((overview) => {
        return overview;
      });
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function listProjects(): Promise<Project[]> {
  return Order.listProjects()
    .then((projectsPayload) => {
      return getPayloadData(projectsPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOrdersPerDay(
  payload: GenericRequestPayload
): Promise<number[]> {
  return Order.numberOfOrdersPerDay(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOrdersDetails(
  payload: GenericRequestPayload
): Promise<OrdersOverview> {
  return Order.ordersDetails(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload).then((overview) => {
        return overview;
      });
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getPaymentOverview(
  payload: GenericRequestPayload
): Promise<Counts[]> {
  return Order.paymentMethods(payload)
    .then((overviewPayload) => {
      return getPayloadData(overviewPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOrdersHourlyBreakdown(
  payload: GenericRequestPayload
): Promise<OrdersHourlyBreakdown> {
  return Order.ordersHourlyBreakdown(payload)
    .then((ordersHourlyBreakdownPayload) => {
      return getPayloadData(ordersHourlyBreakdownPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getOrdersRevenueBreakdown(
  payload: GenericRequestPayload
): Promise<number[]> {
  return Order.ordersRevenueBreakdown(payload)
    .then((ordersRevenueBreakdownPayload) => {
      return getPayloadData(ordersRevenueBreakdownPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCustomersPerCity(payload: GenericRequestPayload) {
  return Order.customersPerCity(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCustomersPerCountry(payload: GenericRequestPayload) {
  return Order.customersPerCountry(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

export function getCustomersPerArea(payload: GenericRequestPayload) {
  return Order.customersPerArea(payload)
    .then((response) => {
      return getPaginatedPayloadData(response);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get cancelled orders percentage and count
 * @param payload
 * @returns
 */

export function getCancelledOrdersPercentage(
  payload: GenericRequestPayload
): Promise<CancelledOrdersPercentage> {
  return Order.cancelledOrdersPercentage(payload)
    .then((cancelledOrdersPercentagePayload) => {
      return getPayloadData(cancelledOrdersPercentagePayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get top products in cancelled orders count
 * @param payload
 * @returns
 */

export function getTopProductsInCancelledOrders(
  payload: GenericRequestPayload
): Promise<Product[]> {
  return Order.topProductsInCancelledOrders(payload)
    .then((topProductsInCancelledOrdersPayload) => {
      return getPayloadData(topProductsInCancelledOrdersPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get top categories in cancelled orders count
 * @param payload
 * @returns
 */

export function getTopCategoriesInCancelledOrders(
  payload: GenericRequestPayload
): Promise<Partial<ItemsSales[]>> {
  return Order.topCategoriesInCancelledOrders(payload)
    .then((topCategoriesInCancelledOrdersPayload) => {
      return getPayloadData(topCategoriesInCancelledOrdersPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get top brands in cancelled orders count
 * @param payload
 * @returns
 */

export function getTopBrandsInCancelledOrders(
  payload: GenericRequestPayload
): Promise<Partial<ItemsSales[]>> {
  return Order.topBrandsInCancelledOrders(payload)
    .then((topBrandsInCancelledOrdersPayload) => {
      return getPayloadData(topBrandsInCancelledOrdersPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get cancelled orders by payment method percentage and count
 * @param payload
 * @returns
 */

export function getCancelledOrdersByPaymentMethodPercentage(
  payload: GenericRequestPayload
): Promise<ByPaymentMethodPercentage[]> {
  return Order.cancelledOrdersByPaymentMethodPercentage(payload)
    .then((cancelledOrdersByPaymentMethodPercentagePayload) => {
      return getPayloadData(cancelledOrdersByPaymentMethodPercentagePayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get cancelled orders by order source percentage and count
 * @param payload
 * @returns
 */

export function getCancelledOrdersByOrderSourcePercentage(
  payload: GenericRequestPayload
): Promise<ByOrderSourcePercentage[]> {
  return Order.cancelledOrdersByOrderSourcePercentage(payload)
    .then((cancelledOrdersByOrderSourcePercentagePayload) => {
      return getPayloadData(cancelledOrdersByOrderSourcePercentagePayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get cancelled orders by filter segments percentage and count
 * @param payload
 * @returns
 */

export function getCancelledOrdersByFilterSegmentsPercentage(
  payload: GenericRequestPayload
): Promise<ByFilterSegmentPercentage[]> {
  return Order.cancelledOrdersByFilterSegmentsPercentage(payload)
    .then((cancelledOrdersByFilterSegmentsPercentagePayload) => {
      return getPayloadData(cancelledOrdersByFilterSegmentsPercentagePayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}

/**
 * get cancelled orders breakdown
 * @param payload
 * @returns
 */

export function getCancelledOrdersBreakdown(
  payload: GenericRequestPayload
): Promise<number[]> {
  return Order.cancelledOrdersBreakdown(payload)
    .then((cancelledOrdersBreakdownPayload) => {
      return getPayloadData(cancelledOrdersBreakdownPayload);
    })
    .catch((error) => {
      return Promise.reject(handleError(error));
    });
}
