/**
 * @author Youssef Tarek
 * @date 2022-01-10
 * @description Brands items sales in Brands tab in the app
 */

import React from "react";
import {
  BrandsItemsValues,
  BrandsValues,
  Counts,
  Direction,
  SortOption,
} from "interfaces/overview";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { getBrands } from "utilities/brands";
import { exist, getDurationRange } from "utilities/common";
import { mapChartData } from "utilities/charts";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";
import TableView from "../../common/table-view";
import CollectionCard from "../../common/collection-card";
import Pagination from "../../common/Pagination";
import SortSearch from "../../common/sort-search";

interface BrandsItemsSalesState {
  brands: BrandsValues[];
  comparedBrands?: BrandsValues[];
  comparedBrandsItems?: Record<string, BrandsItemsValues[]>;
  loading: boolean;
  comparedLoading: boolean;
  id: string;
  currentPage: number;
  comparedCurrentPage: number;
  totalPages: number;
  comparedTotalPages: number;
  searchQuery: string;
  sortBy?: SortOption;
  sortDirection?: Direction;
}

interface BrandsItemsSalesProps {
  exportRef: React.RefObject<HTMLDivElement>;
  updateSelectedBrand: (brandName: string) => void;
}
class BrandsItemsSales extends React.Component<
  BrandsItemsSalesProps & WithTranslation,
  BrandsItemsSalesState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: BrandsItemsSalesProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      comparedLoading: true,
      brands: [],
      id: Date.now().toString(),
      currentPage: 1,
      comparedCurrentPage: 1,
      totalPages: 1,
      comparedTotalPages: 1,
      searchQuery: "",
    };
    // TODO bind class methods
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.loadAllData = this.loadAllData.bind(this);
    this.updateCurrentPage = this.updateCurrentPage.bind(this);
    this.updateComparedCurrentPage = this.updateComparedCurrentPage.bind(this);
    this.loadBrands = this.loadBrands.bind(this);
    this.loadComparedBrands = this.loadComparedBrands.bind(this);
  }

  componentDidMount() {
    this.loadAllData();
    this.context.addUpdatesListener(this.state.id, this.loadAllData);
  }

  componentDidUpdate(_, prevState: Readonly<BrandsItemsSalesState>) {
    const sortChanged =
      prevState.sortBy !== this.state.sortBy ||
      prevState.sortDirection !== this.state.sortDirection;
    const searchChanged = prevState.searchQuery !== this.state.searchQuery;
    if (
      prevState.currentPage !== this.state.currentPage ||
      searchChanged ||
      sortChanged
    ) {
      this.loadBrands().catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
        this.setState({ loading: false });
      });
    }
    if (
      prevState.comparedCurrentPage !== this.state.comparedCurrentPage ||
      searchChanged ||
      sortChanged
    ) {
      this.loadComparedBrands().catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
        this.setState({ comparedLoading: false });
      });
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  updateSearchQuery(searchQuery: string) {
    this.setState({
      searchQuery,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateSort(sortBy?: SortOption, sortDirection?: Direction) {
    if (!sortBy || !sortDirection) {
      this.setState({
        sortBy: undefined,
        sortDirection: undefined,
        currentPage: 1,
        comparedCurrentPage: 1,
      });
      return;
    }
    this.setState({
      sortBy,
      sortDirection,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateCurrentPage(currentPage: number) {
    this.setState({
      currentPage,
    });
  }

  updateComparedCurrentPage(comparedCurrentPage: number) {
    this.setState({
      comparedCurrentPage,
    });
  }

  loadBrands() {
    this.setState({ loading: true });

    let brandsPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      brandsPromise = getBrands({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        currentPage: this.state.currentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          brands: res.data,
          totalPages: res.pagination.totalPageCount || 1,
          loading: false,
        });
      });
    }

    return brandsPromise;
  }

  loadComparedBrands() {
    this.setState({ comparedLoading: true });
    let comparedBrandsPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedBrandsPromise = getBrands({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        currentPage: this.state.comparedCurrentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          comparedBrands: res.data,
          comparedTotalPages: res.pagination.totalPageCount || 1,
          comparedLoading: false,
        });
      });
    }
    return comparedBrandsPromise;
  }

  loadAllData() {
    const brandsPromise = this.loadBrands();
    const comparedBrandsPromise = this.loadComparedBrands();
    Promise.all([brandsPromise, comparedBrandsPromise]).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      toast.error(err);
      this.setState({
        loading: false,
        comparedLoading: false,
      });
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const brandsCounts: Counts[] = mapChartData(this.state.brands);
    const comparedBrandsCounts: Counts[] = mapChartData(
      this.state.comparedBrands
    );

    const tabs = {
      [t("allBrands")]: {
        title: `${t("allBrands")}`,
        items: brandsCounts,
      },
    };
    const comparedTabs = {
      [t("allBrands")]: {
        title: `${t("allBrands")}`,
        items: comparedBrandsCounts,
      },
    };

    return (
      <>
        <div className="card-container">
          <div className="span-2">
            <SortSearch
              searchHandler={this.updateSearchQuery}
              sortHandler={this.updateSort}
              sortBy={this.state.sortBy}
              direction={this.state.sortDirection}
              mode="minimal"
            />
          </div>
          <div className={this.context.comparedPeriod ? "" : "span-2 centered"}>
            <div className="card">
              <h2 className="card--title">
                {t("brandsSales")}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.currentPeriod?.type,
                    this.context.currentPeriod?.from,
                    this.context.currentPeriod?.to
                  )}
                  )
                </span>
              </h2>
              <div className="sales-table categories-sales-table">
                <TableView
                  products={
                    this.state.brands.map((item) => ({
                      ...item,
                      name: item.brand_name,
                      image_url: item.brand_image_url,
                    })) || []
                  }
                  updateProductSku={this.props.updateSelectedBrand}
                  type="brands-sales-list"
                  loading={this.state.loading}
                  withNavigation
                />
              </div>
              {!this.state.loading &&
                this.state.searchQuery &&
                !this.state.brands.length && (
                  <p>{t("noSearchResult", { ns: "common" })}</p>
                )}
            </div>
            {this.state.totalPages > 1 && (
              <Pagination
                totalPageCount={this.state.totalPages}
                currentPage={this.state.currentPage}
                pageNeighbors={1}
                changePageHandler={this.updateCurrentPage}
              />
            )}
          </div>
          {this.context.comparedPeriod && (
            <div>
              <div className="card">
                <h2 className="card--title">
                  {t("brandsSales")}
                  <span className="duration">
                    (
                    {getDurationRange(
                      this.context.comparedPeriod?.type,
                      this.context.comparedPeriod?.from,
                      this.context.comparedPeriod?.to
                    )}
                    )
                  </span>
                </h2>
                <div className="sales-table categories-sales-table">
                  <TableView
                    products={
                      this.state.comparedBrands?.map((item) => ({
                        ...item,
                        name: item.brand_name,
                        image_url: item.brand_image_url,
                      })) || []
                    }
                    updateProductSku={this.props.updateSelectedBrand}
                    type="brands-sales-list"
                    loading={this.state.comparedLoading}
                    withNavigation
                  />
                </div>
                {!this.state.comparedLoading &&
                  this.state.searchQuery &&
                  !this.state.comparedBrands.length && (
                    <p>{t("noSearchResult", { ns: "common" })}</p>
                  )}
              </div>
              {this.state.comparedTotalPages > 1 && (
                <Pagination
                  totalPageCount={this.state.comparedTotalPages}
                  currentPage={this.state.comparedCurrentPage}
                  pageNeighbors={1}
                  changePageHandler={this.updateComparedCurrentPage}
                />
              )}
            </div>
          )}
          <div
            className={`card ${
              this.context.comparedPeriod ? "" : "span-2 centered"
            }`}
            ref={this.props.exportRef}
          >
            <CollectionCard
              title={t("brandsBreakdown")}
              duration
              currentPeriod={this.context.currentPeriod}
              tabs={tabs}
            />
          </div>
          {this.state.comparedBrands && this.context.comparedPeriod && (
            <div className="card" ref={this.props.exportRef}>
              <CollectionCard
                title={t("comparedBrandsBreakdown")}
                tabs={comparedTabs}
                duration
                currentPeriod={this.context.comparedPeriod}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}
BrandsItemsSales.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["brands", "common"])(BrandsItemsSales);
