/* eslint-disable class-methods-use-this */
/**
 * @author Ahmed Elshantaly
 * @date 2022-02-14
 * @description Payment method card
 * @filename payment-method.tsx
 */
import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { withTranslation, WithTranslation } from "react-i18next";
import Mastercard from "static/images/mastercard.svg";
import Visa from "static/images/visa.svg";
import { deleteCard, setDefaultCard } from "utilities/settings";
import { toast } from "react-toastify";

interface PaymentMethodProps {
  id: string;
  cardEndingWith: string;
  expiryDate: string;
  cardType: string;
  default?: boolean;
}

class PaymentMethod extends React.Component<
  PaymentMethodProps &
    WithTranslation & {
      onFinish: () => void;
    }
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(
    props: PaymentMethodProps & WithTranslation & { onFinish: () => void }
  ) {
    super(props);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleMakeDefault = this.handleMakeDefault.bind(this);
    this.formatExpiryDate = this.formatExpiryDate.bind(this);
  }

  handleDeleteCard() {
    deleteCard(this.props.id)
      .then(() => {
        toast.success("Card deleted successfully");
        this.props.onFinish();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  handleMakeDefault() {
    setDefaultCard(this.props.id)
      .then(() => {
        toast.success("Card is set as default");
        this.props.onFinish();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  // Format expiry date to MM/YY f
  formatExpiryDate(date: string) {
    const formatedDate = date.split("");

    return `${formatedDate[2]}${formatedDate[3]}/${formatedDate[0]}${formatedDate[1]}`;
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="payment-method">
        <div className="payment-method__info">
          {this.props.cardType.toLowerCase() === "mastercard" ? (
            <Mastercard />
          ) : (
            <Visa />
          )}
          <p>
            <span>
              {`${this.props.cardType.toLowerCase()} ${t(
                "endingWith"
              )} ${this.props.cardEndingWith.substr(-4)}`}
            </span>
            <span>
              {t("expiry")} {this.formatExpiryDate(this.props.expiryDate)}
            </span>
          </p>
        </div>

        <div className="payment-method__actions">
          {!this.props.default && (
            <button
              type="button"
              className="edit"
              onClick={this.handleMakeDefault}
            >
              {t("makeDefault")}
            </button>
          )}
          <button
            type="button"
            className="delete"
            onClick={this.handleDeleteCard}
          >
            {t("deleteCard")}
          </button>
        </div>
      </div>
    );
  }
}

PaymentMethod.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(PaymentMethod);
