import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { HexColor, SettingsInterface } from "interfaces/settings";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteLogo,
  updateSettings as updateSettingsUtilities,
} from "utilities/settings";
import { getBase64 } from "utilities/get-base64";
import CloseIcon from "static/images/close.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import { ColorSelector } from "../../../common/color-selector";
import Loader from "../../../common/Loader";

interface SettingsState {
  dirty: SettingsInterface;
  loading: boolean;
  id: string;
  logoPreview: boolean;
}

class AppearanceSettings extends React.Component<
  RouteComponentProps & WithTranslation,
  SettingsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.state = {
      dirty: {},
      loading: false,
      id: "settings-update",
      logoPreview: false,
    };

    this.updatePrimaryColor = this.updatePrimaryColor.bind(this);
    this.save = this.save.bind(this);
    this.reset = this.reset.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    if (this.context.settings) {
      this.setState({
        dirty: {
          ...this.context.settings,
        },
      });
    }
  }

  updatePrimaryColor(ev: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.dirty) {
      return;
    }
    this.setState({
      dirty: {
        ...this.state.dirty,
        primary_color: ev.target.value as HexColor,
      },
    });
  }

  save(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    if (
      JSON.stringify(this.context.settings) === JSON.stringify(this.state.dirty)
    ) {
      return;
    }

    if (!this.state.dirty.logo) {
      deleteLogo(this.context.project)
        .then(() => {
          toast.success("logo deleted successfully");
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    updateSettingsUtilities(this.context.project, this.state.dirty)
      .then((settings) => {
        toast.success("Settings updated successfully");
        this.context.updateAppearanceSettings(settings);
      })
      .catch((error) => {
        this.setState({ loading: false });
        // eslint-disable-next-line no-console
        console.error(error);
        toast.error("Failed to load project settings");
      });
  }

  reset() {
    this.setState({
      dirty: {
        ...this.context.settings,
      },
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const dirty =
      JSON.stringify(this.context.settings) !==
      JSON.stringify(this.state.dirty);

    const logoInput = document.querySelector("#logo") as HTMLInputElement;

    const backendUrl = process.env.API_ENDPOINT.split("/admin")[0];

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="settings">
        <div className="card">
          <form onSubmit={this.save}>
            <h2 className="card--title">{t("appearanceSettings")}</h2>
            <p className="note">{t("appearanceSettingsDescription")}</p>
            <div className="settings__image-container">
              <label className="label">{t("logo")}</label>
              <div className="settings__image">
                {this.state.dirty.logo || this.state.logoPreview ? (
                  <div className="settings__image-preview">
                    <button
                      type="button"
                      className="settings__image-preview--delete"
                      onClick={() => {
                        logoInput.value = "";
                        this.setState({
                          dirty: {
                            ...this.state.dirty,
                            logo: "",
                          },
                          logoPreview: false,
                        });
                      }}
                    >
                      <CloseIcon />
                    </button>
                    <img
                      src={
                        this.state.dirty.logo && !this.state.logoPreview
                          ? `${backendUrl}/${this.state.dirty.logo}`
                          : this.state.dirty.logo
                      }
                      alt={t("logo")}
                    />
                  </div>
                ) : (
                  <label className="settings__image-text">
                    {t("uploadImage")}
                    <small>{t("maxMB")}</small>
                  </label>
                )}
                <input
                  name="logo"
                  type="file"
                  id="logo"
                  className="settings__image-input"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={(e) => {
                    if (e.target.files) {
                      getBase64(e.target.files[0]).then((file: string) => {
                        this.setState({
                          dirty: {
                            ...this.state.dirty,
                            logo: file,
                          },
                          logoPreview: true,
                        });
                      });
                    }
                  }}
                />
              </div>
            </div>
            <label className="label" htmlFor="color">
              {t("primaryColor")}
            </label>

            <ColorSelector
              id="color"
              onChange={this.updatePrimaryColor}
              value={this.state?.dirty?.primary_color || "#0137A0"}
            />

            <div className="action-buttons">
              <button type="submit" className="button save" disabled={!dirty}>
                {t("saveChanges")}
              </button>
              <button
                type="button"
                className="button cancel"
                onClick={this.reset}
                disabled={!dirty}
              >
                {t("reset")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AppearanceSettings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(AppearanceSettings);
