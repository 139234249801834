/**
 * @author Ahmed Serag
 * @date 2022-05-15
 * @description Implementation of Firebase related utilities.
 * @filename brands.ts
 */
import { FirebaseApp, initializeApp } from "firebase/app";
import { FirebasePerformance, getPerformance } from "firebase/performance";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDL8cLY67Kzq1Nnd3t13e4rKgK8T0q24fc",
  authDomain: "analytics-dashboard-318810.firebaseapp.com",
  databaseURL:
    "https://analytics-dashboard-318810-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "analytics-dashboard-318810",
  storageBucket: "analytics-dashboard-318810.appspot.com",
  messagingSenderId: "775666510711",
  appId: "1:775666510711:web:6aa0b8aaa62f38ca150f72",
};

/**
 * Firebase app utilities class.
 *
 * @export
 * @class Firebase
 */
export class Firebase {
  // The Firebase App instance
  static app: FirebaseApp;

  // The Performance Monitoring service
  static performance: FirebasePerformance;

  /**
   * Initialize the Firebase App.
   */
  public static initApp(): void {
    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
  }

  /**
   * Get the Performance Monitoring service.
   */
  public static initPerformance(): void {
    if (!this.app) {
      this.initApp();
    }
    // Initialize Performance Monitoring and get a reference to the service
    this.performance = getPerformance(this.app);
  }
}
