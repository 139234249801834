import { SearchDropdownOption } from "interfaces/advanced-filters";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MenuListProps, components } from "react-select";
import { FixedSizeList } from "react-window";

const LIST_ITEM_HEIGHT = 35; // px

class MenuList extends React.Component<
  MenuListProps<SearchDropdownOption, true> & WithTranslation
> {
  render() {
    const { children, maxHeight, t } = this.props;
    const count = Array.isArray(children) ? children.length : 0;

    return (
      <>
        {!count && (
          <components.NoOptionsMessage {...this.props}>
            {t("noOptions")}
          </components.NoOptionsMessage>
        )}
        <FixedSizeList
          height={Math.min(maxHeight, count * LIST_ITEM_HEIGHT)}
          itemCount={count}
          itemSize={LIST_ITEM_HEIGHT}
          width="100%"
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </FixedSizeList>
      </>
    );
  }
}

export default withTranslation(["filters"])(MenuList);
