/**
 * @author Salma Hefnawy
 * @date 2022-04-13
 * @description implementation of section loader component
 * to be used while loading.
 * @filename section-loader.tsx
 */
import React from "react";
import ThreeDots from "react-loader-spinner";

interface SectionLoaderProps {
  color?: string;
  height?: number;
  width?: number;
}

export default class SectionLoader extends React.Component<SectionLoaderProps> {
  static defaultProps = {
    color: "var(--color-primary)",
    height: 80,
    width: 80,
  };

  render() {
    return (
      <div className="section-loader-wrapper">
        <ThreeDots
          type="ThreeDots"
          color={this.props.color}
          height={this.props.height}
          width={this.props.width}
        />
      </div>
    );
  }
}
