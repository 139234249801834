/**
 * @author Ahmed Elshantaly
 * @date 2022-01-17
 * @description customers spending tab.
 * @filename customers-spending.tsx
 */

import React from "react";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";

import {
  getCustomersOrderedMore,
  getCustomersSpending,
  getCustomersSpentMore,
} from "utilities/customers";
import { exist, getNumberReadableValue } from "utilities/common";
import { Formik, Form, FormikHelpers, Field } from "formik";
import { number as YUPNumber, object as YUPObject } from "yup";
import { toast } from "react-toastify";
import {
  CustomerDetails,
  CustomersSpending as CustomersSpendingInterface,
  Quartiles,
} from "interfaces/customers-spending";
import { CSVLink } from "react-csv";
import { withTranslation, WithTranslation } from "react-i18next";
import Loader from "../../common/Loader";
import CustomersIcon from "../../../../static/images/total-customers.svg";
import ArrowUpIcon from "../../../../static/images/arrow-up.svg";
import ArrowDownIcon from "../../../../static/images/arrow-down.svg";
import SectionLoader from "../../common/section-loader";

type CustomersSpendingState = {
  quartiles?: CustomersSpendingInterface[];
  maxSpending?: number;
  minSpending?: number;
  spentMoreInitialNumber?: number;
  orderedMoreInitialNumber?: number;
  spentMoreMax?: number;
  spentMoreTotal?: number;
  spentMoreList?: CustomerDetails[];
  orderedMoreMax?: number;
  orderedMoreTotal?: number;
  orderedMoreList?: CustomerDetails[];
  loading: boolean;
  id: string;
  sectionLoader: boolean;
};

interface CustomersSpendingProps {
  exportRef: React.RefObject<HTMLDivElement>;
}
class CustomersSpending extends React.Component<
  CustomersSpendingProps & WithTranslation,
  CustomersSpendingState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: CustomersSpendingProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      id: Date.now().toString(),
      orderedMoreInitialNumber: 2,
      sectionLoader: false,
    };
    this.loadData = this.loadData.bind(this);
    this.getCustomerSpendingList = this.getCustomerSpendingList.bind(this);
    this.updateSpentMore = this.updateSpentMore.bind(this);
    this.updateOrderedMore = this.updateOrderedMore.bind(this);
    this.getSpentMore = this.getSpentMore.bind(this);
    this.getOrderedMore = this.getOrderedMore.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Customers Spending";
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  getCustomerSpendingList() {
    this.setState({ sectionLoader: true });
    let customersSpendingListPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      customersSpendingListPromise = getCustomersSpending({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
        with_list: 1,
      })
        .then((quartiles) => {
          this.setState({
            quartiles: [
              quartiles[Quartiles.Quartile1],
              quartiles[Quartiles.Quartile2],
              quartiles[Quartiles.Quartile3],
              quartiles[Quartiles.Quartile4],
            ],
          });

          this.setState({
            maxSpending: this.state.quartiles.reduce((prev, current) => {
              return prev.average > current.average ? prev : current;
            }).average,
            minSpending: this.state.quartiles.reduce((prev, current) => {
              return prev.average < current.average ? prev : current;
            }).average,
            spentMoreInitialNumber: Math.ceil(this.state.quartiles[1].average),
          });

          this.getSpentMore(String(this.state.spentMoreInitialNumber));
          this.getOrderedMore(String(this.state.orderedMoreInitialNumber));
        })

        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      Promise.all([customersSpendingListPromise]).then(() => {
        this.setState({
          sectionLoader: false,
        });
      });
    }
  }

  getSpentMore(money: string) {
    if (exist(this.context, ["project", "currentPeriod"])) {
      getCustomersSpentMore({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        order_value: String(money),
        filters: this.context.appliedFilters,
      })
        .then((value) => {
          this.setState({
            spentMoreTotal: value.count,
            spentMoreMax: value.maximum,
            spentMoreList: value.list,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  getOrderedMore(orders: string) {
    if (exist(this.context, ["project", "currentPeriod"])) {
      getCustomersOrderedMore({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        frequency: String(orders),
        filters: this.context.appliedFilters,
      })
        .then((value) => {
          this.setState({
            orderedMoreTotal: value.count,
            orderedMoreMax: value.maximum,
            orderedMoreList: value.list,
          });
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  loadData() {
    this.setState({ loading: true });
    let customersSpendingPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      customersSpendingPromise = getCustomersSpending({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        filters: this.context.appliedFilters,
      })
        .then((quartiles) => {
          this.setState({
            quartiles: [
              quartiles[Quartiles.Quartile1],
              quartiles[Quartiles.Quartile2],
              quartiles[Quartiles.Quartile3],
              quartiles[Quartiles.Quartile4],
            ],
          });

          this.setState({
            maxSpending: this.state.quartiles.reduce((prev, current) => {
              return prev.average > current.average ? prev : current;
            }).average,
            minSpending: this.state.quartiles.reduce((prev, current) => {
              return prev.average < current.average ? prev : current;
            }).average,
            spentMoreInitialNumber: Math.ceil(this.state.quartiles[1].average),
          });

          this.getSpentMore(String(this.state.spentMoreInitialNumber));
          this.getOrderedMore(String(this.state.orderedMoreInitialNumber));
        })

        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      Promise.all([customersSpendingPromise]).then(() => {
        this.setState({
          loading: false,
        });
      });
    }
    this.getCustomerSpendingList();
  }

  updateSpentMore(
    values: {
      amountOfMoney: number;
    },
    formikHelpers: FormikHelpers<{
      amountOfMoney: number;
    }>
  ) {
    this.setState({ spentMoreInitialNumber: values.amountOfMoney });
    this.getSpentMore(String(values.amountOfMoney));

    formikHelpers.setSubmitting(false);
  }

  updateOrderedMore(
    values: {
      numberOfOrders: number;
    },
    formikHelpers: FormikHelpers<{
      numberOfOrders: number;
    }>
  ) {
    this.setState({ orderedMoreInitialNumber: values.numberOfOrders });
    this.getOrderedMore(String(values.numberOfOrders));
    formikHelpers.setSubmitting(false);
  }

  render(): React.ReactNode {
    const { t } = this.props;
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div ref={this.props.exportRef}>
        <div className="customers-spending">
          <div className="customers-spending__quartiles">
            {this.state.quartiles.map((quartile, index) => {
              return (
                <div key={index} className="card quartile-card ">
                  {this.state.minSpending === quartile.average && (
                    <p className="quartile-card__ribbon">
                      {t("lowestSpending")}
                    </p>
                  )}
                  {this.state.maxSpending === quartile.average && (
                    <p className="quartile-card__ribbon">
                      {t("highestSpending")}
                    </p>
                  )}
                  <h2 className="card--title">
                    {t("quartile")} {index + 1}
                  </h2>
                  <p className="quartile-card__average">
                    <span>{getNumberReadableValue(quartile.average)}</span>
                    <span className="quartile-card__average-currency">
                      {this.context.settings.currency}
                    </span>
                    <span className="quartile-card__average-text">
                      {t("avgSpending")}
                    </span>
                  </p>

                  <dl className="quartile-card__list">
                    <dt>
                      <CustomersIcon /> {t("totalCustomers")}
                    </dt>
                    <dd>{getNumberReadableValue(quartile.number)}</dd>

                    <dt>
                      <ArrowUpIcon /> {t("maxOrderValue")}
                    </dt>
                    <dd>
                      {`${getNumberReadableValue(quartile.maximum)}
                      ${this.context.settings.currency}`}
                    </dd>

                    <dt>
                      <ArrowDownIcon /> {t("minOrderValue")}
                    </dt>
                    <dd>
                      {`${getNumberReadableValue(quartile.minimum)}
                      ${this.context.settings.currency}`}
                    </dd>
                  </dl>

                  {this.state.sectionLoader ? (
                    <SectionLoader width={40} height={40} />
                  ) : (
                    <CSVLink
                      data={this.state.quartiles[index].list}
                      filename={`quartile-${index + 1}.csv`}
                      className="export-list-button"
                      target="_blank"
                    >
                      {t("exportList")}
                    </CSVLink>
                  )}
                </div>
              );
            })}
          </div>
          <div className="customers-spending__calculators">
            <div className="card">
              <Formik
                onSubmit={this.updateSpentMore}
                initialValues={{
                  amountOfMoney: this.state.spentMoreInitialNumber,
                }}
                validationSchema={YUPObject().shape({
                  amountOfMoney: YUPNumber()
                    .positive()
                    .integer()
                    .required("Number is required"),
                })}
              >
                {(formikBag) => (
                  <Form className="card--section">
                    <Field name="amountOfMoney">
                      {({ field, meta }) => (
                        <div className="form-field">
                          <label htmlFor="amountOfMoney">
                            {t("customersWhoSpentMoreThan")}
                          </label>
                          <div>
                            <input
                              id="amount-of-money"
                              name="amountOfMoney"
                              type="number"
                              {...field}
                              value={field.value ?? ""}
                            />

                            <span>
                              {`${this.context.settings.currency} ${t(
                                this.context.currentPeriod.alias
                              )}`}
                            </span>
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <button
                      type="submit"
                      className="button save"
                      disabled={formikBag.isSubmitting}
                    >
                      {t("apply")}
                    </button>
                  </Form>
                )}
              </Formik>

              <dl className="quartile-card__list">
                <dt>
                  <CustomersIcon /> {t("totalCustomers")}
                </dt>
                <dd>{getNumberReadableValue(this.state.spentMoreTotal)}</dd>

                <dt>
                  <ArrowDownIcon />
                  {t("maxAmountSpentByCustomer")}
                </dt>
                <dd>
                  {`${getNumberReadableValue(this.state.spentMoreMax)}
                  ${this.context.settings.currency}`}
                </dd>
              </dl>

              {this.state.spentMoreList && (
                <CSVLink
                  data={this.state.spentMoreList}
                  filename={`customers-spent-more-than-${this.state.spentMoreInitialNumber}.csv`}
                  className="export-list-button"
                  target="_blank"
                >
                  {t("exportList")}
                </CSVLink>
              )}
            </div>

            <div className="card">
              <Formik
                onSubmit={this.updateOrderedMore}
                initialValues={{
                  numberOfOrders: this.state.orderedMoreInitialNumber,
                }}
                validationSchema={YUPObject().shape({
                  numberOfOrders: YUPNumber()
                    .positive()
                    .integer()
                    .required("Number is required"),
                })}
              >
                {(formikBag) => (
                  <Form className="card--section">
                    <Field name="numberOfOrders">
                      {({ field, meta }) => (
                        <div className="form-field">
                          <label htmlFor="numberOfOrders">
                            {t("customersWhoOrderedMoreThan")}
                          </label>
                          <div>
                            <input
                              id="number-of-orders"
                              name="numberOfOrders"
                              type="number"
                              {...field}
                              value={field.value ?? ""}
                            />

                            <span>
                              {" "}
                              {`${t("ordersIn")} ${t(
                                this.context.currentPeriod.alias
                              )}`}
                            </span>
                          </div>
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <button
                      type="submit"
                      className="button save"
                      disabled={formikBag.isSubmitting}
                    >
                      {t("apply")}
                    </button>
                  </Form>
                )}
              </Formik>

              <dl className="quartile-card__list">
                <dt>
                  <CustomersIcon /> {t("totalCustomers")}
                </dt>
                <dd>{getNumberReadableValue(this.state.orderedMoreTotal)}</dd>

                <dt>
                  <ArrowDownIcon />
                  {t("maxNumberOfOrdersMadeByCustomer")}
                </dt>
                <dd>
                  {getNumberReadableValue(this.state.orderedMoreMax)} Orders
                </dd>
              </dl>
              {this.state.orderedMoreList && (
                <CSVLink
                  data={this.state.orderedMoreList}
                  filename={`customers-ordered-more-than-${this.state.orderedMoreInitialNumber}.csv`}
                  className="export-list-button"
                  target="_blank"
                >
                  {t("exportList")}
                </CSVLink>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomersSpending.contextType = ANALYTICS_CONTEXT;
export default withTranslation("customers")(CustomersSpending);
