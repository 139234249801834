/**
 * @author Ahmed Serag
 * @date 2019-07-13
 * @description user authentication utilities
 * @filename authenticator.ts
 */
import * as Yup from "yup";
import { string as YUPString } from "yup";
import { Authentication } from "api/authentication";
import { LoginResponse } from "interfaces/payload";
import { PASSWORD_REGEX, URL_REGEX } from "consts/constants";
import { User as UserInterface } from "interfaces/user";
import { SignupPayload } from "interfaces/auth";
import { getPayloadData, handleError } from "./common";

export const YUPPasswordValidation = YUPString().matches(
  PASSWORD_REGEX,
  "Must contain at least one uppercase letter, one lowercase letter, one number and one special character and must be at least 8 characters long"
);

export const SET_PASSWORD_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      "Must contain at least one uppercase letter, one lowercase letter, one number and one special character and must be at least 8 characters long"
    )
    .required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "password and confirm password do not match"),
});
export class Authenticator {
  static requestCSRF() {
    return Authentication.requestCSRF();
  }

  /**
   * @description login user
   * @param email email of the user
   * @param password password of the user
   * @param otp otp sent if user has enabled 2fa
   */
  static login(
    email: string,
    password: string,
    otp?: string
  ): Promise<LoginResponse> {
    return Authentication.login(email, password, otp)
      .then((response) => {
        return getPayloadData(response);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  static logout() {
    return Authentication.logout().finally(() => {
      localStorage.removeItem(process.env.ACCESS_TOKEN_KEY);
      return Promise.resolve();
    });
  }

  static getCurrentUser(): Promise<UserInterface> {
    return Authentication.currentUser()
      .then((overviewPayload) => {
        return overviewPayload;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *
   * @param email email of the user to send the otp to.
   * @returns
   */
  static requestResetPassword(email: string) {
    return Authentication.requestResetPassword(email)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  static sendOtp(email: string, otp: string) {
    return Authentication.validateOTP(email, otp)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *
   * @param email email of the user to reset their password
   * @param newPassword
   * @param otp otp sent previously
   * @returns
   */
  static resetPassword(email: string, newPassword: string, otp: string) {
    return Authentication.resetPassword(email, newPassword, otp)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  static setPassword(password: string, registration_key: string) {
    return Authentication.setPassword(password, registration_key).catch(
      (error) => {
        return Promise.reject(handleError(error));
      }
    );
  }

  static signup(payload: SignupPayload) {
    const URLRegex = RegExp(URL_REGEX);
    const storeUrl = `https://${URLRegex.exec(payload.storeUrl)[4]}`;
    const updatedPayload = {
      ...payload,
      storeUrl,
    };

    return Authentication.signup(updatedPayload)
      .then((response) => {
        return getPayloadData(response);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  static confirmRegistration(token: string) {
    return Authentication.confirmRegistration(token)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
