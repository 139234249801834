/**
 * @author Karim Shalapy
 * @date 2022-09-29
 * @description implementation of a percentage change UI component
 * @filename percentage-change.tsx
 */

import React from "react";
import IncreaseIcon from "static/images/increase.svg";
import DecreaseIcon from "static/images/decrease.svg";
import { getPercentageChange } from "utilities/common";

interface PercentageChangeProps {
  current: number | undefined;
  previous: number | undefined;
}

export default class PercentageChange extends React.Component<PercentageChangeProps> {
  isTherePercentageChange() {
    return (
      this.props.current != null &&
      this.props.previous != null &&
      !Number.isNaN(
        getPercentageChange(this.props.current, this.props.previous).percentage
      ) &&
      getPercentageChange(this.props.current, this.props.previous).percentage
    );
  }

  render() {
    const percentageChange = getPercentageChange(
      this.props.current,
      this.props.previous
    );

    return (
      <>
        {this.isTherePercentageChange() ? (
          <span className={`percentage-change ${percentageChange.sign}`}>
            {`${Math.abs(percentageChange.percentage)}%`}
            {percentageChange.sign === "decrease" ? (
              <DecreaseIcon />
            ) : (
              <IncreaseIcon />
            )}
          </span>
        ) : (
          ""
        )}
      </>
    );
  }
}
