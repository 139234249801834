/**
 * @author Youssef Tarek
 * @date 2022-01-09
 * @description Brands tab in the app
 */

import { BrandsValues } from "interfaces/overview";
import React from "react";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import { CSVLink } from "react-csv";
import { withTranslation, WithTranslation } from "react-i18next";
import { getBrands } from "utilities/brands";
import { exist, getDurationRange } from "utilities/common";
import BackArrowIcon from "static/images/left-arrow.svg";
import { ANALYTICS_CONTEXT } from "../../../contexts/analytics-context";
import Loader from "../../common/Loader";
import Header from "../../common/header";
import { TableContent } from "../../common/table-content";
import BrandsItemsSales from "./brands-sales";
import BrandDetails from "./brand-details";

interface BrandsState {
  activeTab: "top_selling_brands" | "brands_sales" | string;
  brands: BrandsValues[];
  comparedBrands?: BrandsValues[];
  totalSales: number;
  comparedTotalSales?: number;
  loading: boolean;
  id: string;
  selectedBrand: string;
}

class Brands extends React.Component<
  RouteComponentProps & WithTranslation,
  BrandsState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  exportTopBrandsRef: React.RefObject<HTMLDivElement>;

  exportBrandsSalesRef: React.RefObject<HTMLDivElement>;

  exportBrandDetailsSalesRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);

    this.exportTopBrandsRef = React.createRef();
    this.exportBrandsSalesRef = React.createRef();
    this.exportBrandDetailsSalesRef = React.createRef();

    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.state = {
      activeTab: params.get("activeTab") ?? "top_selling_brands",
      loading: true,
      totalSales: 0,
      brands: [],
      id: Date.now().toString(),
      selectedBrand: "",
    };
    this.loadData = this.loadData.bind(this);
    this.updateSelectedBrand = this.updateSelectedBrand.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.updateUrlQueryParams(
      this.state.activeTab,
      this.context.listBy
    );
    this.context.addUpdatesListener(this.state.id, this.loadData);
    // set page title
    document.title = "Brands";
  }

  componentDidUpdate(_prevProps, prevState: BrandsState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.context.updateUrlQueryParams(
        this.state.activeTab,
        this.context.listBy
      );
      // set page title according to the active tab
      switch (this.state.activeTab) {
        case "top_selling_brands":
          document.title = "Brands";
          break;
        case "brands_sales":
          document.title = "Brands Sales";
          break;
        default:
          document.title = "Brands";
          break;
      }
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({ loading: true });

    let brandsPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      brandsPromise = getBrands({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sort: this.context.listBy,
        currentPage: 1,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          brands: res.data,
          totalSales: res.data.reduce(
            (total, sale) => total + sale.sales_value,
            0
          ),
        });
      });
    }

    let comparedBrandsPromise = Promise.resolve();

    if (this.context.comparedPeriod) {
      comparedBrandsPromise = getBrands({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        sort: this.context.listBy,
        currentPage: 1,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          comparedBrands: res.data,
          comparedTotalSales: res.data.reduce(
            (total, sale) => total + sale.sales_value,
            0
          ),
        });
      });
    }

    Promise.all([brandsPromise, comparedBrandsPromise])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);

        this.setState({
          loading: false,
        });
      });
  }

  updateSelectedBrand(selectedBrand: string) {
    this.setState({ selectedBrand });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="brands">
        <Header
          title={t("brands")}
          exportRef={
            this.state.activeTab === "top_selling_brands"
              ? this.exportTopBrandsRef
              : this.exportBrandsSalesRef
          }
        />
        <div className="tabs">
          <div
            className={`tab ${
              this.state.activeTab === "top_selling_brands" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "top_selling_brands") {
                this.setState({ activeTab: "top_selling_brands" });
              }
            }}
          >
            {t("topSellingBrands")}
          </div>
          <div
            className={`tab ${
              this.state.activeTab === "brands_sales" ? " active" : ""
            }`}
            onClick={() => {
              if (this.state.activeTab !== "brands_sales") {
                this.setState({ activeTab: "brands_sales" });
              }
            }}
          >
            {t("brandsSales")}
          </div>
        </div>
        {this.state.activeTab === "top_selling_brands" && (
          <>
            <div className="toggler">
              <div
                className={`toggle ${
                  this.context.listBy === "sales_value" ? "active" : ""
                }`}
                onClick={() => {
                  if (this.context.listBy !== "sales_value") {
                    this.context.updateListingType(
                      this.state.activeTab,
                      "sales_value"
                    );
                  }
                }}
              >
                {t("salesValue")}
              </div>
              <div
                className={`toggle ${
                  this.context.listBy === "number_sold" ? "active" : ""
                }`}
                onClick={() => {
                  if (this.context.listBy !== "number_sold") {
                    this.context.updateListingType(
                      this.state.activeTab,
                      "number_sold"
                    );
                  }
                }}
              >
                {t("numberSold")}
              </div>
            </div>
            <div ref={this.exportTopBrandsRef}>
              <div
                className={
                  this.context.comparedPeriod
                    ? "card-container"
                    : "flex justify-center"
                }
              >
                <div className="card brands--table">
                  <div className="brands--body">
                    <h2 className="card--title">
                      <div>
                        {t("topSellingBrands")}
                        <span className="duration">
                          (
                          {getDurationRange(
                            this.context.currentPeriod.type,
                            this.context.currentPeriod?.from,
                            this.context.currentPeriod?.to
                          )}
                          )
                        </span>
                      </div>
                      {this.state.brands.length ? (
                        <CSVLink
                          data={this.state.brands}
                          filename={`brands_sales breakdown from:${this.context.currentPeriod?.from}-to:${this.context.currentPeriod?.to}.csv`}
                          className="export-list-button"
                          target="_blank"
                        >
                          {t("exportList")}
                        </CSVLink>
                      ) : null}
                    </h2>
                    {this.state.brands?.map((brand, index) => {
                      if (index < 10) {
                        return (
                          <TableContent
                            key={index}
                            rowItem={{
                              image_url: brand.brand_image_url,
                              number_of_items: brand.sales_value,
                              name: brand.brand_name || t("unnamed"),
                            }}
                            itemIndex={index}
                            percentageValue={Math.ceil(
                              (brand.sales_value / this.state.totalSales) * 100
                            )}
                            isCompare={!!this.context.comparedPeriod}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                {this.context.comparedPeriod && (
                  <div className="card brands--table">
                    <div className="brands--body">
                      <h2 className="card--title">
                        <div>
                          {t("topSellingBrands")}
                          <span className="duration">
                            (
                            {getDurationRange(
                              this.context.comparedPeriod.type,
                              this.context.comparedPeriod?.from,
                              this.context.comparedPeriod?.to
                            )}
                            )
                          </span>
                        </div>
                        {this.state.comparedBrands?.length ? (
                          <CSVLink
                            data={this.state.comparedBrands}
                            filename={`brands_sales breakdown from:${this.context.comparedPeriod?.from}-to:${this.context.comparedPeriod?.to}.csv`}
                            className="export-list-button"
                            target="_blank"
                          >
                            {t("exportList")}
                          </CSVLink>
                        ) : null}
                      </h2>
                      {this.state.comparedBrands?.map((brand, index) => {
                        if (index < 10) {
                          return (
                            <TableContent
                              key={index}
                              rowItem={{
                                image_url: brand.brand_image_url,
                                number_of_items: brand.sales_value,
                                name: brand.brand_name || t("unnamed"),
                              }}
                              itemIndex={index}
                              percentageValue={Math.ceil(
                                (brand.sales_value /
                                  this.state.comparedTotalSales) *
                                  100
                              )}
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {this.state.activeTab === "brands_sales" &&
          (this.state.selectedBrand ? (
            <>
              <button
                className="products-button--back"
                type="button"
                onClick={() => {
                  this.setState({
                    selectedBrand: "",
                  });
                }}
              >
                <BackArrowIcon />
                {t("backToList")}
              </button>
              <BrandDetails
                brandName={this.state.selectedBrand}
                updateBrandName={this.updateSelectedBrand}
                exportRef={this.exportBrandDetailsSalesRef}
              />
            </>
          ) : (
            <BrandsItemsSales
              exportRef={this.exportBrandsSalesRef}
              updateSelectedBrand={this.updateSelectedBrand}
            />
          ))}
      </div>
    );
  }
}
Brands.contextType = ANALYTICS_CONTEXT;
export default withTranslation("brands")(Brands);
