/**
 * @author Ahmed Serag
 * @date 2022-07-17
 * @description page views tab in user behavior.
 * @filename page-views.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BackArrowIcon from "../../../../../static/images/left-arrow.svg";
import PageReport from "./page-report";
import PageViewsOverview from "./overview";

interface PageViewsState {
  activePageTitle?: string;
}

class PageViews extends React.Component<WithTranslation, PageViewsState> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: WithTranslation) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <>
        {this.state.activePageTitle ? (
          <>
            <button
              className="products-button--back"
              type="button"
              onClick={() => {
                this.setState({
                  activePageTitle: null,
                });
              }}
            >
              <BackArrowIcon />
              {t("backToPagesList")}
            </button>
            <PageReport pageTitle={this.state.activePageTitle} />
          </>
        ) : (
          <PageViewsOverview
            loadPage={(pageTitle) => {
              this.setState({ activePageTitle: pageTitle });
            }}
          />
        )}
      </>
    );
  }
}
export default withTranslation("user-behavior")(PageViews);
