/**
 * @author Youssef Tarek
 * @date 2023-03-01
 * @description signup form component
 * @filename signup-form.tsx
 */

import React from "react";
import { SignupPayload } from "interfaces/auth";
import Select, { ThemeConfig, StylesConfig } from "react-select";
import SectionLoader from "common/section-loader";
import { URL_REGEX } from "consts/constants";
import { ROUTES } from "consts/routes";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { string as YUPString, object as YUPObject } from "yup";
import { Link } from "react-router-dom";
import Input from "../../common/Input";

const STORE_CATEGORIES = [
  { label: "Electronics", value: "Electronics" },
  { label: "Fashion & Apparel", value: "Fashion & Apparel" },
  { label: "Household Goods", value: "Household Goods" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Sports", value: "Sports" },
  { label: "Food & Beverage", value: "Food & Beverage" },
  { label: "Grocery", value: "Grocery" },
  { label: "Other", value: "Other" },
] as const;

const PLATFORM_PROVIDERS = [
  { label: "Magento", value: "magento" },
  { label: "WooCommerce", value: "woocommerce" },
  { label: "Shopify - coming soon", value: "shopify" },
] as const;

const customSelectMenuTheme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "var(--black)",
    primary50: "var(--grey-150)",
    primary25: "var(--grey-100)",
    neutral20: "var(--color-not-focused)",
  },
});

const customSelectMenuStyles: (
  isInvalid?: boolean
) => StylesConfig<
  (typeof STORE_CATEGORIES)[number] | (typeof PLATFORM_PROVIDERS)[number]
> = (isInvalid) => ({
  control(provided, state) {
    return {
      ...provided,
      borderRadius: "6px",
      maxWidth: "500px",
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        borderColor: "var(--color-not-focused)",
      },
      ...(isInvalid
        ? {
            borderColor: "var(--color-not-focused-error)",
            "&:hover": { borderColor: "var(--color-not-focused-error)" },
            ...(state.isFocused
              ? {
                  boxShadow: `0 0 0 1px var(--color-focused-error)`,
                }
              : {}),
          }
        : {}),

      ...(state.isDisabled
        ? {
            backgroundColor: "transparent",
            borderColor: "var(--color-not-focused)",
            opacity: "0.4",
            pointerEvents: "all",
            cursor: "not-allowed",
            "&:hover": {},
          }
        : {}),
    };
  },
  valueContainer(provided) {
    return {
      ...provided,
      paddingInlineStart: "10px",
      paddingBlock: "0",
      fontSize: "14px",
    };
  },
  input(provided) {
    return {
      ...provided,
      margin: 0,
    };
  },
  menu(provided) {
    return {
      ...provided,
      zIndex: 10,
    };
  },
  option(provided, state) {
    return {
      ...provided,
      color: state.isDisabled
        ? "var(--disabled-button-font-color)"
        : state.isSelected
        ? "var(--white)"
        : "var(--color-focused)",
      cursor: state.isDisabled ? "not-allowed" : "default",
    };
  },
});

interface SignupFormProps {
  submitHandler: (values: SignupPayload) => Promise<void>;
  isSubmit: boolean;
}

interface SignupFormState {
  providersFocused: boolean;
  categoriesFocused: boolean;
}

class SignupForm extends React.Component<SignupFormProps, SignupFormState> {
  constructor(props) {
    super(props);
    this.state = {
      providersFocused: false,
      categoriesFocused: false,
    };
  }

  render() {
    return (
      <Formik
        onSubmit={this.props.submitHandler}
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          businessName: "",
          storeUrl: "",
          platformProvider: "",
          storeCategory: "",
        }}
        validationSchema={YUPObject().shape({
          name: YUPString()
            .max(
              200,
              ({ max }) => `Maximum allowed number of characters is ${max}`
            )
            .required("Name is required"),
          email: YUPString()
            .email("Please Input a proper Email")
            .required("Email is required"),
          password: YUPString()
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .max(
              255,
              ({ max }) => `Password can't be more than ${max} characters`
            )
            .matches(
              /^(?=.*[A-Z])(?=.*[!@#$%^&*+=-])(?=.*[0-9])(?=.*[a-z]).+$/g,
              "Must contain number, lowercase, uppercase, special character"
            )
            .required("Password is required"),
          confirmPassword: YUPString()
            .required("Confirm password is required")
            .oneOf(
              [Yup.ref("password")],
              "Password and Confirm Password do not match"
            ),
          businessName: YUPString().required("Business name is required"),
          storeUrl: YUPString()
            .max(255, ({ max }) => `URL can't be more than ${max} characters`)
            .matches(URL_REGEX, "Please input a valid URL")
            .required("URL is required"),
          platformProvider: YUPString()
            .equals(
              PLATFORM_PROVIDERS.slice(0, 2).map((item) => item.value),
              "Not supported yet."
            )
            .required("Platform Provider is required"),
          storeCategory: YUPString().equals(
            STORE_CATEGORIES.map((item) => item.value),
            "Please choose a proper category."
          ),
        })}
        validateOnMount
      >
        {({ isValid, isSubmitting }) => (
          <Form noValidate>
            <h1 className="auth__title">Dashboard Sign Up</h1>
            <Field name="name">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="text"
                    id="name-signup"
                    label="Full Name"
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    autoComplete="name"
                    disabled={isSubmitting}
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="email">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="email"
                    id="email-signup"
                    label="Email"
                    autoComplete="email"
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    disabled={isSubmitting}
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="businessName">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="text"
                    id="business-name-signup"
                    label="Business Name"
                    autoComplete="organization"
                    disabled={isSubmitting}
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="storeUrl">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="text"
                    id="store-url-signup"
                    label="Store Admin Base Domain URL"
                    autoComplete="url"
                    disabled={isSubmitting}
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    tooltip="This is the base url for your store's admin dashboard that you use to manage your store"
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="platformProvider">
              {({ field, meta, form }) => {
                return (
                  <div
                    className="custom-input-container is-selected form-field"
                    aria-invalid={!!(meta.touched && meta.error)}
                  >
                    <label
                      htmlFor="provider-signup"
                      className={
                        this.state.providersFocused ? "is-focused" : ""
                      }
                    >
                      <span className={isSubmitting ? "disabled" : ""}>
                        Ecommerce Platform Provider*
                      </span>
                    </label>
                    <Select
                      id="provider-signup"
                      name={field.name}
                      placeholder="Select a platform provider..."
                      isDisabled={isSubmitting}
                      theme={customSelectMenuTheme}
                      styles={customSelectMenuStyles(
                        !!(meta.touched && meta.error)
                      )}
                      options={PLATFORM_PROVIDERS}
                      isOptionDisabled={(
                        option: (typeof PLATFORM_PROVIDERS)[number]
                      ) => option.value === PLATFORM_PROVIDERS[2].value}
                      onFocus={() => {
                        this.setState({ providersFocused: true });
                      }}
                      onBlur={() => {
                        this.setState({ providersFocused: false });
                        form.setTouched({
                          ...form.touched,
                          [field.name]: true,
                        });
                      }}
                      value={PLATFORM_PROVIDERS.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(
                        option: (typeof PLATFORM_PROVIDERS)[number]
                      ) => {
                        form.setFieldValue(field.name, option?.value || "");
                      }}
                      isClearable
                    />
                    {meta.touched && meta.error ? (
                      <p
                        className={`error ${
                          this.state.providersFocused ? "is-focused" : ""
                        }`}
                      >
                        {meta.error}
                      </p>
                    ) : null}
                  </div>
                );
              }}
            </Field>
            <Field name="storeCategory">
              {({ field, meta, form }) => (
                <div
                  className="custom-input-container form-field is-selected"
                  aria-invalid={!!(meta.touched && meta.error)}
                >
                  <label
                    htmlFor="category-signup"
                    className={this.state.categoriesFocused ? "is-focused" : ""}
                  >
                    <span className={isSubmitting ? "disabled" : ""}>
                      Store Category
                    </span>
                  </label>
                  <Select
                    id="category-signup"
                    name={field.name}
                    placeholder="Select your store category..."
                    isDisabled={isSubmitting}
                    theme={customSelectMenuTheme}
                    styles={customSelectMenuStyles(
                      !!(meta.touched && meta.error)
                    )}
                    options={STORE_CATEGORIES}
                    onFocus={() => {
                      this.setState({ categoriesFocused: true });
                    }}
                    onBlur={() => {
                      this.setState({ categoriesFocused: false });
                      form.setTouched({
                        ...form.touched,
                        [field.name]: true,
                      });
                    }}
                    value={STORE_CATEGORIES.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option: (typeof STORE_CATEGORIES)[number]) => {
                      form.setFieldValue(field.name, option?.value || "");
                    }}
                    isClearable
                  />
                  {meta.touched && meta.error ? (
                    <p
                      className={`error ${
                        this.state.categoriesFocused ? "is-focused" : ""
                      }`}
                    >
                      {meta.error}
                    </p>
                  ) : null}
                </div>
              )}
            </Field>
            <Field name="password">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="password"
                    id="password-signup"
                    label="Password"
                    autoComplete="new-password"
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    disabled={isSubmitting}
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, meta }) => (
                <div className="form-field">
                  <Input
                    type="password"
                    id="confirm-password-signup"
                    label="Confirm Password"
                    errorMsg={meta.touched && meta.error ? meta.error : ""}
                    disabled={isSubmitting}
                    required
                    {...field}
                  />
                </div>
              )}
            </Field>
            <button
              type="submit"
              className="auth-button"
              aria-label="Sign up"
              disabled={!isValid || isSubmitting || this.props.isSubmit}
              aria-disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? (
                <SectionLoader
                  width={30}
                  height={20}
                  color="var(--disabled-button-font-color)"
                />
              ) : (
                <span>Sign up</span>
              )}
            </button>

            <p className="auth__info-text">
              <>
                Already have an account?{" "}
                <Link to={ROUTES.Login.path}>Sign in</Link>
              </>
            </p>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SignupForm;
