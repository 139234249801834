export interface User {
  created_at: string;
  email: string;
  email_verified_at: string;
  id: number;
  is_admin: number;
  name: string;
  project: string;
  subscription_tier: string;
  language: string;
  roles?: UserRole[];
  phone_number?: string;
  phone_number_ext?: string;
  tfa_enabled?: 1 | 0;
}

export enum UserRole {
  admin = "admin",
  management = "management",
  marketing = "marketing",
  operations = "operations",
}

export enum UserPlatform {
  magento = "magento",
  woocommerce = "woocommerce",
}
