import { Direction, SalesValues, SortOption } from "interfaces/overview";
import React from "react";
import { toast } from "react-toastify";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import { getCategoriesSales } from "utilities/categories";
import { exist, getDurationRange } from "utilities/common";
import { mapChartData } from "utilities/charts";
import { withTranslation, WithTranslation } from "react-i18next";
import TableView from "../../common/table-view";
import CollectionCard from "../../common/collection-card";
import Pagination from "../../common/Pagination";
import SortSearch from "../../common/sort-search";

type CategoriesSalesState = {
  categoriesSales: SalesValues[];
  comparedCategoriesSales?: SalesValues[];
  loading: boolean;
  comparedLoading: boolean;
  id: string;
  currentPage: number;
  comparedCurrentPage: number;
  totalPages: number;
  comparedTotalPages: number;
  searchQuery: string;
  sortBy?: SortOption;
  sortDirection?: Direction;
};

interface CategoriesSalesProps {
  updateCategoryName: (categoryName: string) => void;
  exportRef: React.RefObject<HTMLDivElement>;
}

class CategoriesSales extends React.Component<
  CategoriesSalesProps & WithTranslation,
  CategoriesSalesState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: CategoriesSalesProps & WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      comparedLoading: true,
      categoriesSales: [],
      id: Date.now().toString(),
      currentPage: 1,
      comparedCurrentPage: 1,
      totalPages: 1,
      comparedTotalPages: 1,
      searchQuery: "",
    };
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.loadAllData = this.loadAllData.bind(this);
    this.updateCurrentPage = this.updateCurrentPage.bind(this);
    this.updateComparedCurrentPage = this.updateComparedCurrentPage.bind(this);
    this.loadCategoriesSales = this.loadCategoriesSales.bind(this);
    this.loadComparedCategoriesSales =
      this.loadComparedCategoriesSales.bind(this);
  }

  componentDidMount() {
    this.loadAllData();
    this.context.addUpdatesListener(this.state.id, this.loadAllData);
  }

  componentDidUpdate(_, prevState: Readonly<CategoriesSalesState>) {
    const sortChanged =
      prevState.sortBy !== this.state.sortBy ||
      prevState.sortDirection !== this.state.sortDirection;
    const searchChanged = prevState.searchQuery !== this.state.searchQuery;
    if (
      prevState.currentPage !== this.state.currentPage ||
      searchChanged ||
      sortChanged
    ) {
      this.loadCategoriesSales()
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err);
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
    if (
      prevState.comparedCurrentPage !== this.state.comparedCurrentPage ||
      searchChanged ||
      sortChanged
    ) {
      this.loadComparedCategoriesSales()
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          toast.error(err);
        })
        .finally(() => {
          this.setState({
            comparedLoading: false,
          });
        });
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  updateSearchQuery(searchQuery: string) {
    this.setState({
      searchQuery,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateSort(sortBy?: SortOption, sortDirection?: Direction) {
    if (!sortBy || !sortDirection) {
      this.setState({
        sortBy: undefined,
        sortDirection: undefined,
        currentPage: 1,
        comparedCurrentPage: 1,
      });
      return;
    }
    this.setState({
      sortBy,
      sortDirection,
      currentPage: 1,
      comparedCurrentPage: 1,
    });
  }

  updateCurrentPage(currentPage: number) {
    this.setState({
      currentPage,
    });
  }

  updateComparedCurrentPage(comparedCurrentPage: number) {
    this.setState({
      comparedCurrentPage,
    });
  }

  loadCategoriesSales() {
    this.setState({ loading: true });

    let categoriesSalesPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      categoriesSalesPromise = getCategoriesSales({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        currentPage: this.state.currentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          categoriesSales: res.data,
          loading: false,
          totalPages: res.pagination.totalPageCount,
        });
      });
    }

    return categoriesSalesPromise;
  }

  loadComparedCategoriesSales() {
    this.setState({ comparedLoading: true });

    let comparedCategoriesSalesPromise = Promise.resolve();
    if (this.context.comparedPeriod) {
      comparedCategoriesSalesPromise = getCategoriesSales({
        project: this.context.project,
        from: this.context.comparedPeriod.from,
        to: this.context.comparedPeriod.to,
        sort: this.state.sortBy,
        direction: this.state.sortDirection,
        search: this.state.searchQuery,
        currentPage: this.state.comparedCurrentPage,
        filters: this.context.appliedFilters,
      }).then((res) => {
        this.setState({
          comparedCategoriesSales: res.data,
          comparedLoading: false,
          comparedTotalPages: res.pagination.totalPageCount || 1,
        });
      });
    } else {
      this.setState({
        comparedCategoriesSales: null,
        comparedLoading: false,
      });
    }
    return comparedCategoriesSalesPromise;
  }

  loadAllData() {
    const categoriesSalesPromise = this.loadCategoriesSales();
    const comparedCategoriesSalesPromise = this.loadComparedCategoriesSales();
    Promise.all([categoriesSalesPromise, comparedCategoriesSalesPromise])
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
          comparedLoading: false,
        });
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    const allCategoriesData = mapChartData(this.state.categoriesSales);
    const tabs = {
      [t("allCategories")]: {
        title: `${t("allCategories")}`,
        items: allCategoriesData,
      },
    };
    const allComparedCategoriesData = mapChartData(
      this.state.comparedCategoriesSales
    );
    const comparedTabs = {
      [t("allCategories")]: {
        title: `${t("allCategories")}`,
        items: allComparedCategoriesData,
      },
    };

    return (
      <div className="card-container">
        <div className="span-2">
          <SortSearch
            searchHandler={this.updateSearchQuery}
            sortHandler={this.updateSort}
            sortBy={this.state.sortBy}
            direction={this.state.sortDirection}
            mode="minimal"
          />
        </div>
        <div className={this.context.comparedPeriod ? "" : "span-2 centered"}>
          <div className="card">
            <h2 className="card--title">
              {t("categoriesSales")}
              <span className="duration">
                (
                {getDurationRange(
                  this.context.currentPeriod?.type,
                  this.context.currentPeriod?.from,
                  this.context.currentPeriod?.to
                )}
                )
              </span>
            </h2>
            <div className="sales-table categories-sales-table">
              <TableView
                products={this.state.categoriesSales || []}
                updateProductSku={this.props.updateCategoryName}
                type="categories-sales-list"
                loading={this.state.loading}
                withNavigation
              />
            </div>
            {!this.state.loading &&
              this.state.searchQuery &&
              !this.state.categoriesSales.length && (
                <p>{t("noSearchResult", { ns: "common" })}</p>
              )}
          </div>
          {this.state.totalPages > 1 && (
            <Pagination
              totalPageCount={this.state.totalPages}
              currentPage={this.state.currentPage}
              pageNeighbors={1}
              changePageHandler={this.updateCurrentPage}
            />
          )}
        </div>
        {this.context.comparedPeriod && (
          <div>
            <div className="card">
              <h2 className="card--title">
                {t("categoriesSales")}
                <span className="duration">
                  (
                  {getDurationRange(
                    this.context.comparedPeriod?.type,
                    this.context.comparedPeriod?.from,
                    this.context.comparedPeriod?.to
                  )}
                  )
                </span>
              </h2>
              <div className="sales-table categories-sales-table">
                <TableView
                  products={this.state.comparedCategoriesSales || []}
                  updateProductSku={this.props.updateCategoryName}
                  type="categories-sales-list"
                  loading={this.state.comparedLoading}
                  withNavigation
                />
              </div>
              <p>
                {!this.state.comparedLoading &&
                  this.state.searchQuery &&
                  !this.state.comparedCategoriesSales.length && (
                    <p>{t("noSearchResult", { ns: "common" })}</p>
                  )}
              </p>
            </div>
            {this.state.comparedTotalPages > 1 && (
              <Pagination
                totalPageCount={this.state.comparedTotalPages}
                currentPage={this.state.comparedCurrentPage}
                pageNeighbors={1}
                changePageHandler={this.updateComparedCurrentPage}
              />
            )}
          </div>
        )}
        <div
          ref={this.props.exportRef}
          className={`card ${
            this.context.comparedPeriod ? "" : "span-2 centered"
          }`}
        >
          <CollectionCard
            title={t("categorySales")}
            tabs={tabs}
            duration
            currentPeriod={this.context.currentPeriod}
          />
        </div>
        {this.context.comparedPeriod && (
          <div className="card" ref={this.props.exportRef}>
            <CollectionCard
              title={t("comparedCategoryBreakdown")}
              tabs={comparedTabs}
              duration
              currentPeriod={this.context.comparedPeriod}
            />
          </div>
        )}
      </div>
    );
  }
}

CategoriesSales.contextType = ANALYTICS_CONTEXT;
export default withTranslation(["categories", "common"])(CategoriesSales);
