/**
 * @author Ahmed Serag
 * @date 2020-07-02
 * @description implementation of Stock related API calls.
 * @filename stock.ts
 */
import { PaginationPayload, Payload } from "interfaces/payload";
import {
  GenericRequestPayload,
  ItemsSales,
  StockResponse,
} from "interfaces/overview";
import {
  ProductRecommendations,
  ProductRequestPayload,
  ProductSummary,
  RelatedProducts,
  StockRequestPayload,
} from "interfaces/stock";
import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class Stock {
  public static itemsSales({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<PaginationPayload<ItemsSales[]>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.items.path}`);

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.items.method,
    });
  }

  public static itemsStock({
    project,
    from,
    to,
    threshold = `10`,
    filters = {},
  }: StockRequestPayload): Promise<PaginationPayload<StockResponse>> {
    const url = new URL(`${process.env.API_ENDPOINT}${ENDPOINTS.stock.path}`);
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    // defaults to 10 in case threshold was provided as an empty string
    url.searchParams.append("threshold", threshold || "10");
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.stock.method,
    });
  }

  public static productSummary({
    project,
    from,
    to,
    sku,
    filters = {},
  }: ProductRequestPayload): Promise<Payload<ProductSummary>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.productSummary.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sku", sku);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.productSummary.method,
    });
  }

  public static productRecommendations({
    project,
    from,
    to,
    sku,
    filters = {},
  }: ProductRequestPayload): Promise<Payload<ProductRecommendations>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.productRecommendations.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sku", sku);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.productRecommendations.method,
    });
  }

  public static relatedProducts({
    project,
    from,
    to,
    sku,
    filters = {},
  }: ProductRequestPayload): Promise<PaginationPayload<RelatedProducts[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.relatedProducts.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("sku", sku);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.relatedProducts.method,
    });
  }

  public static searchItem({
    project,
    from,
    to,
    search,
    currentPage = 1,
    sort,
    direction,
    filters = {},
  }: GenericRequestPayload): Promise<PaginationPayload<ItemsSales[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.searchItem.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    // defaults to 10 in case threshold was provided as an empty string
    url.searchParams.append("currentPage", currentPage.toString() || "1");
    url.searchParams.append("search", search);
    if (sort) {
      url.searchParams.append("sort", sort);
    }
    if (direction) {
      url.searchParams.append("direction", direction);
    }
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.searchItem.method,
    });
  }
}
