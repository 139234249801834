/**
 * @author Youssef Tarek
 * @date 2022-07-04
 * @description Otp box component
 * @filename otp-box.tsx
 */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js/mobile";

interface OtpBoxProps {
  title: string;
  toggleOtpBox: () => void;
  phone: string;
  country?: string;
  countryCallingCode?: string;
  email: string;
  password?: string;
  validate2FA?: (email: string, otp: string) => void;
  enable2FA?: (phoneNumberExt, phoneNumber, toggle) => void;
  loginWithOtp?: (email: string, password: string, otp: string) => void;
  errorMessage?: string;
}

interface OtpBoxState {
  otp: string[];
  activeIndex: number;
  timer: number;
  resend: boolean;
}
class OtpBox extends React.Component<
  OtpBoxProps & WithTranslation,
  OtpBoxState
> {
  // a ref for the input element to be active
  inputRef: React.RefObject<HTMLInputElement>;

  currentOtPIndex = 0;

  timer = null;

  // to reset the state when resend is clicked
  initialState = {
    otp: new Array(6).fill(""),
    activeIndex: 0,
    timer: 59,
    resend: false,
  };

  constructor(props: OtpBoxProps & WithTranslation) {
    super(props);
    this.state = {
      otp: new Array(6).fill(""),
      activeIndex: 0,
      timer: 59,
      resend: false,
    };
    this.inputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.resendOTP = this.resendOTP.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    if (this.state.activeIndex <= 5) {
      this.inputRef.current?.focus();
    }
    if (this.state.timer === 0) {
      this.setState({
        resend: true,
        timer: 59,
      });
      clearInterval(this.timer);
    }
  }

  /**
   * validates that the user input is a number,
   * if it is updates the otp state with the current value
   * and the active index
   * @param element the input element
   * @returns
   */
  handleChange(element: HTMLInputElement) {
    if (Number.isNaN(Number(element.value))) {
      return false;
    }
    this.setState({
      otp: [
        ...this.state.otp.map((otpValue, otpIndex) => {
          if (otpIndex === this.currentOtPIndex) {
            return element.value;
          }
          return otpValue;
        }),
      ],
    });
    // this means that a backspace was entered
    if (!element.value) {
      this.setState({
        activeIndex: this.currentOtPIndex - 1,
      });
      // a new number was entered
    } else {
      this.setState({
        activeIndex: this.currentOtPIndex + 1,
      });
    }
    return 1;
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    this.currentOtPIndex = index;
    if (e.key === "Backspace") {
      this.setState({
        activeIndex: index - 1,
      });
    }
  };

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.props.validate2FA) {
      this.props.validate2FA(this.props.email, this.state.otp.join(""));
    }
    if (this.props.loginWithOtp) {
      this.props.loginWithOtp(
        this.props.email,
        this.props.password,
        this.state.otp.join("")
      );
    }
  }

  startTimer() {
    this.setState({
      timer: 59,
    });
    this.timer = setInterval(() => {
      this.setState({
        timer: this.state.timer - 1,
      });
    }, 1000);
  }

  resendOTP() {
    if (this.props.enable2FA) {
      this.props.enable2FA(
        this.props.countryCallingCode,
        this.props.phone,
        false
      );
    }
    if (this.props.loginWithOtp) {
      this.props.loginWithOtp(this.props.email, this.props.password, "");
    }
    this.startTimer();
    this.setState({
      ...this.initialState,
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div className="otp-box">
        <div className="otp-box__card">
          <div className="otp-box__header">
            <div className="otp-box__title">{this.props.title}</div>
            <button
              type="button"
              className="otp-box__close-button"
              onClick={this.props.toggleOtpBox}
            >
              +
            </button>
          </div>
          <div className="otp-box__content">
            <div className="otp-box__text">
              <span>{`${t("aCodeWasSentToTheNumber")} `}</span>
              <span>
                {` ${parsePhoneNumber(
                  `+${this.props.phone}`
                ).formatInternational()}`}
              </span>
              <span>{`, ${t("enterTheCodeBelow")}`}</span>
            </div>
            {this.props.enable2FA && (
              <div className="opt-box__wrong-number">
                <span>{`${t("notYourNumber")}?`}</span>
                <button
                  type="button"
                  className="otp-box__change-button"
                  onClick={this.props.toggleOtpBox}
                >
                  {`${t("change")}`}
                </button>
              </div>
            )}
          </div>
          <form
            className="otp-box__form-box"
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            <div className="otp-box__inputs-container">
              {this.state.otp.map((otp, index) => {
                return (
                  <input
                    key={index}
                    ref={
                      index === this.state.activeIndex ? this.inputRef : null
                    }
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    className={`otp-box__input ${
                      this.props.errorMessage && "otp-box__error"
                    } `}
                    maxLength={1}
                    value={otp}
                    onChange={(e) => {
                      this.handleChange(e.target);
                    }}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    onKeyDown={(e) => {
                      this.handleKeyDown(e, index);
                    }}
                  />
                );
              })}
            </div>
            {this.props.errorMessage && (
              <div className="otp-box__error-message">
                {this.props.errorMessage}
              </div>
            )}
            <button
              type="submit"
              className="button submit-btn"
              disabled={this.state.otp.includes("")}
            >
              {`${t("confirm")}`}
            </button>
          </form>
          <div className="otp-box__resend">
            <button
              type="button"
              className="otp-box__resend-button"
              disabled={!this.state.resend}
              onClick={this.resendOTP}
            >
              {this.state.resend
                ? t("resend")
                : `${t("resendIn")} 00:${this.state.timer.toLocaleString(
                    "en-US",
                    {
                      minimumIntegerDigits: 2,
                    }
                  )}`}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("common")(OtpBox);
