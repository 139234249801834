/**
 * @author Salma Hefnawy
 * @date 2022-03-28
 * @description implementation of User behavior related API calls.
 * @filename user-behavior.ts
 */
import { PaginationPayload, Payload } from "interfaces/payload";
import {
  MostSearchedTerms,
  LoyaltyPoints,
  ExportedLoyaltyPoints,
  PromoCodes,
  ExportedPromoCodesList,
  GA4PagesSummary,
  SpecificPageSummary,
  PageSummaryRequestPayload,
} from "interfaces/user-behavior";
import { GenericRequestPayload } from "interfaces/overview";
import { appendFiltersToSearchParam } from "utilities/urls";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

export class UserBehavior {
  public static mostSearchedTerms(
    project: string
  ): Promise<Payload<MostSearchedTerms>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.mostSearchedTerms.path}`
    );
    url.searchParams.append("project", project);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.searchItem.method,
    });
  }

  public static getLoyaltyPoints({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<LoyaltyPoints>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getLoyaltyPoints.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);

    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.getLoyaltyPoints.method,
    });
  }

  public static getLoyaltyPointsPerDay({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<number[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getLoyaltyPointsPerDay.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.getLoyaltyPointsPerDay.method,
    });
  }

  public static exportLoyalPoints(
    project: string,
    percentage: string
  ): Promise<Payload<ExportedLoyaltyPoints>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.exportLoyalPoints.path}`
    );

    url.searchParams.append("project", project);
    url.searchParams.append("percentage", percentage);
    return Network.fetch(url.toString(), {
      method: ENDPOINTS.exportLoyalPoints.method,
    });
  }

  public static getPromoCodes({
    project,
    from,
    to,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<PromoCodes>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getPromoCodes.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.getPromoCodes.method,
    });
  }

  public static exportPromoCodes({
    project,
    from,
    to,
    currentPage,
    pageSize,
    filters = {},
  }: GenericRequestPayload): Promise<Payload<ExportedPromoCodesList[]>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.exportPromoCodes.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    if (currentPage && pageSize) {
      url.searchParams.append("currentPage", currentPage.toString());
      url.searchParams.append("pageSize", pageSize.toString());
    }
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.exportPromoCodes.method,
    });
  }

  public static exportPromoCodesPaginated({
    project,
    from,
    to,
    currentPage = 1,
    pageSize = 10,
    filters = {},
  }: GenericRequestPayload): Promise<
    PaginationPayload<ExportedPromoCodesList[]>
  > {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.exportPromoCodes.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("currentPage", currentPage.toString());
    url.searchParams.append("pageSize", pageSize.toString());

    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.exportPromoCodes.method,
    });
  }

  public static getAllGA4PagesSummary({
    project,
    from,
    to,
    currentPage,
    pageSize,
    sort,
    direction,
    filters = {},
  }: GenericRequestPayload): Promise<PaginationPayload<GA4PagesSummary>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getAllGA4PagesSummary.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);

    if (currentPage && pageSize) {
      url.searchParams.append("currentPage", currentPage.toString());
      url.searchParams.append("pageSize", pageSize.toString());
    }
    if (sort && direction) {
      url.searchParams.append("sort", sort);
      url.searchParams.append("direction", direction);
    }
    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.getAllGA4PagesSummary.method,
    });
  }

  public static getSpecificPageSummary({
    project,
    from,
    to,
    pageTitle,
    filters = {},
  }: PageSummaryRequestPayload): Promise<Payload<SpecificPageSummary>> {
    const url = new URL(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getSpecificPageSummary.path}`
    );
    url.searchParams.append("project", project);
    url.searchParams.append("from", from);
    url.searchParams.append("to", to);
    url.searchParams.append("pageTitle", pageTitle);

    return Network.fetch(appendFiltersToSearchParam(url, filters).toString(), {
      method: ENDPOINTS.getSpecificPageSummary.method,
    });
  }
}
