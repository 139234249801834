/**
 * @author Youssef Tarek
 * @date 2022-03-08
 * @description page for a specific product
 * @filename product.tsx
 */

import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import {
  getNumberReadableValue,
  exist,
  getPerviousDate,
  showDefaultImage,
} from "utilities/common";
import {
  getProductRecommendations,
  getProductSummary,
  getRelatedProducts,
} from "utilities/stock";
import defaultImg from "static/images/default.png";
import BundleIcon from "static/images/bundle.svg";
import DiscountIcon from "static/images/discount.svg";
import StockIcon from "static/images/stock.svg";
import {
  ProductRecommendations,
  ProductSummary,
  RelatedProducts,
} from "interfaces/stock";

import SectionLoader from "../../common/section-loader";
import PercentageChange from "../../common/percentage-change";

interface ProductProps {
  productSku: string;
  updateProductSku: (productSku: string) => void;
  exportRef?: React.RefObject<HTMLDivElement>;
}
interface ProductState {
  loadingProductSummary: boolean;
  loadingRelatedProducts: boolean;
  loadingProductRecommendations: boolean;
  id: string;
  productSummary: ProductSummary;
  previousProductSummary: ProductSummary;
  relatedProducts: RelatedProducts[];
  productRecommendations: ProductRecommendations;
}

class Product extends React.Component<
  ProductProps & WithTranslation,
  ProductState
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: ProductProps & WithTranslation) {
    super(props);

    this.state = {
      loadingProductSummary: true,
      loadingRelatedProducts: true,
      loadingProductRecommendations: true,
      id: Date.now().toString(),
      productSummary: null,
      previousProductSummary: null,
      relatedProducts: [],
      productRecommendations: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.context.addUpdatesListener(this.state.id, this.loadData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.productSku !== prevProps.productSku) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.context.removeUpdatesListener(this.state.id);
  }

  loadData() {
    this.setState({
      loadingProductSummary: true,
      loadingRelatedProducts: true,
      loadingProductRecommendations: true,
    });

    let productSummaryPromise = Promise.resolve();
    let relatedProductsPromise = Promise.resolve();
    let productRecommendationsPromise = Promise.resolve();
    let previousProductSummaryPromise = Promise.resolve();

    if (exist(this.context, ["project", "currentPeriod"])) {
      productSummaryPromise = getProductSummary({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sku: this.props.productSku,
        filters: this.context.appliedFilters,
      })
        .then((productSummary) => {
          // Todo: this is returned in an array, should be spread
          this.setState({
            productSummary: productSummary[0],
          });
        })
        .finally(() => {
          this.setState({
            loadingProductSummary: false,
          });
        });

      previousProductSummaryPromise = getProductSummary({
        project: this.context.project,
        from: getPerviousDate(this.context.currentPeriod.type).from,
        to: getPerviousDate(this.context.currentPeriod.type).to,
        sku: this.props.productSku,
        filters: this.context.appliedFilters,
      }).then((productSummary) => {
        this.setState({ previousProductSummary: productSummary[0] });
      });

      relatedProductsPromise = getRelatedProducts({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sku: this.props.productSku,
        filters: this.context.appliedFilters,
      })
        .then((relatedProducts) => {
          this.setState({
            relatedProducts,
          });
        })
        .finally(() => {
          this.setState({
            loadingRelatedProducts: false,
          });
        });

      productRecommendationsPromise = getProductRecommendations({
        project: this.context.project,
        from: this.context.currentPeriod.from,
        to: this.context.currentPeriod.to,
        sku: this.props.productSku,
        filters: this.context.appliedFilters,
      })
        .then((productRecommendations) => {
          this.setState({
            productRecommendations,
          });
        })
        .finally(() => {
          this.setState({
            loadingProductRecommendations: false,
          });
        });
    }

    Promise.all([
      productSummaryPromise,
      relatedProductsPromise,
      productRecommendationsPromise,
      previousProductSummaryPromise,
    ]).catch((error) => {
      toast.error(error);
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div
        className="product-analytics"
        key={this.state.id}
        ref={this.props.exportRef}
      >
        <div className="card-container">
          {this.state.loadingProductSummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="card product-data">
              <div className="information-card">
                <div className="product-info">
                  {this.state.productSummary?.image_url ? (
                    <img
                      src={this.state.productSummary.image_url}
                      alt={this.state.productSummary.name}
                      onError={(e) => {
                        showDefaultImage(e);
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImg}
                      alt={this.state.productSummary.name}
                    />
                  )}
                  <div className="product-name">
                    <span className="title">
                      {this.state.productSummary.name}
                    </span>
                    <span className="tooltip">
                      {this.state.productSummary.name}
                    </span>
                  </div>
                </div>
                <div className="product-price">
                  <span className="title ">
                    {`${getNumberReadableValue(
                      this.state.productSummary.price
                    )} ${this.context.settings.currency}`}
                  </span>
                </div>
              </div>
            </div>
          )}
          {this.state.loadingProductSummary ? (
            <div className="card product-data">
              <SectionLoader />
            </div>
          ) : (
            <div className="card product-sales transparent-card">
              <div className="stats-card">
                <div className="stat">
                  <div className="label">{t("unitsSold")}</div>
                  <div className="title">
                    {getNumberReadableValue(
                      this.state.productSummary.number_sold
                    )}
                  </div>
                  <PercentageChange
                    current={this.state.productSummary?.number_sold}
                    previous={this.state.previousProductSummary?.number_sold}
                  />
                </div>
                <div className="stat">
                  <div className="label">{t("totalSalesValue")}</div>
                  <div className="title">
                    {`${getNumberReadableValue(
                      this.state.productSummary.sales_value
                    )} ${this.context.settings.currency}`}
                  </div>
                  <PercentageChange
                    current={this.state.productSummary?.sales_value}
                    previous={this.state.previousProductSummary?.sales_value}
                  />
                </div>
                <div className="stat">
                  <div className="label">{t("inStock")}</div>
                  <div className="title">
                    {getNumberReadableValue(this.state.productSummary.stock)}
                  </div>
                </div>
                {this.state.productSummary.productConversionRate && (
                  <div className="stat">
                    <div className="label">{t("productConversionRate")}</div>
                    <div className="title">
                      {`${this.state.productSummary.productConversionRate}%`}
                    </div>
                    <PercentageChange
                      current={this.state.productSummary?.productConversionRate}
                      previous={
                        this.state.previousProductSummary?.productConversionRate
                      }
                    />
                  </div>
                )}
                {this.state.productSummary.pageViews && (
                  <div className="stat">
                    <div className="label">{t("pageViews")}</div>
                    <div className="title">
                      {`${getNumberReadableValue(
                        this.state.productSummary.pageViews
                      )}`}
                    </div>
                    <PercentageChange
                      current={this.state.productSummary?.pageViews}
                      previous={this.state.previousProductSummary?.pageViews}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="card-container product-lower-cards">
          {this.state.loadingRelatedProducts ? (
            <div className="card paired-products">
              <SectionLoader />
            </div>
          ) : (
            <div className="card paired-products">
              <h2 className="card--title">{t("productsFrequentlyPaired")}</h2>
              {this.state.relatedProducts.length
                ? this.state.relatedProducts.map((relatedProduct) => (
                    // eslint-disable-next-line react/jsx-indent
                    <div className="paired-product" key={relatedProduct.sku}>
                      <div className="product-info">
                        {relatedProduct.image_url ? (
                          <img
                            src={relatedProduct.image_url}
                            alt={relatedProduct.name}
                            onError={(e) => {
                              showDefaultImage(e);
                            }}
                          />
                        ) : (
                          <img src={defaultImg} alt={relatedProduct.name} />
                        )}
                        <div className="product-name">
                          <span className="label">{t("productName")}</span>
                          <span className="name">{relatedProduct.name}</span>
                          <span className="tooltip">{relatedProduct.name}</span>
                        </div>
                      </div>
                      <div className="product-frequency">
                        <span className="label">{t("timesPaired")}</span>
                        <span className="frequency">
                          {relatedProduct.frequency}
                        </span>
                      </div>
                      <div className="product-details">
                        <button
                          type="submit"
                          onClick={() => {
                            this.props.updateProductSku(relatedProduct.sku);
                          }}
                        >
                          {t("viewProduct")}
                        </button>
                      </div>
                    </div>
                  ))
                : t("noRelatedProducts")}
            </div>
          )}
          {this.state.loadingProductRecommendations ? (
            <div className="card recommendations">
              <SectionLoader />
            </div>
          ) : (
            <div className="card recommendations transparent-card">
              {this.state.productRecommendations.should_be_promoted && (
                <div className="discount-banner banner">
                  <div className="banner-icon">
                    <DiscountIcon />
                  </div>
                  <div className="banner-text">
                    <span className="label">{t("discountedPrice")}</span>
                    <span className="description">{t("applyAnOffer")}</span>
                  </div>
                </div>
              )}
              {this.state.relatedProducts.length ? (
                <div className="bundle-banner banner">
                  <div className="banner-icon">
                    <BundleIcon />
                  </div>
                  <div className="banner-text">
                    <span className="label">{t("bundle")}</span>
                    <span className="description">
                      {t("addThisProductToBundle")}

                      <button type="button" className="banner-button">
                        {this.state.relatedProducts[0].name}
                      </button>
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="stock-banner banner">
                <div className="banner-icon">
                  <StockIcon />
                </div>
                <div className="banner-text">
                  <span className="label">{t("stock")}</span>
                  <span className="description">
                    {t("productIsSellingFast")}
                    <span>
                      {" "}
                      {new Date(
                        this.state.productRecommendations.run_out_date
                      ).toLocaleDateString(
                        i18next.language === "en" ? "en-US" : "ar-EG",
                        {
                          weekday: "long",
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Product.contextType = ANALYTICS_CONTEXT;
export default withTranslation("product")(Product);
