/**
 * @author Ahmed Serag
 * @date 2021-12-04
 * @description Account information of the user
 * @filename account-information.tsx
 */
import { number as YUPNumber, object as YUPObject } from "yup";
import { toast } from "react-toastify";
import { Formik, Form, FormikHelpers, Field } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import {
  updateSettings as updateSettingsUtility,
  updateGoals as updateGoalsUtility,
} from "utilities/settings";
import { withTranslation, WithTranslation } from "react-i18next";

class DataSettings extends React.Component<
  RouteComponentProps & WithTranslation
> {
  declare context: React.ContextType<typeof ANALYTICS_CONTEXT>;

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {};
    this.updateDataSettings = this.updateDataSettings.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  updateDataSettings(
    values: {
      threshold: string;
      dailyGoal: number;
      weeklyGoal: number;
      monthlyGoal: number;
      yearlyGoal: number;
    },
    formikHelpers: FormikHelpers<{
      threshold: string;
      dailyGoal: number;
      weeklyGoal: number;
      monthlyGoal: number;
      yearlyGoal: number;
    }>
  ) {
    let updateThresholdPromise: Promise<unknown> = Promise.resolve();
    let updateGoalsPromise: Promise<unknown> = Promise.resolve();

    if (this.context.settings?.threshold !== values.threshold) {
      updateThresholdPromise = updateSettingsUtility(this.context.project, {
        threshold: values.threshold,
      });
    }

    if (
      this.context.settings?.daily_goal !== values.dailyGoal ||
      this.context.settings?.weekly_goal !== values.weeklyGoal ||
      this.context.settings?.monthly_goal !== values.monthlyGoal ||
      this.context.settings?.yearly_goal !== values.yearlyGoal
    ) {
      updateGoalsPromise = updateGoalsUtility(
        this.context.project,
        values.dailyGoal || 0,
        values.weeklyGoal || 0,
        values.monthlyGoal || 0,
        values.yearlyGoal || 0
      );
    }

    Promise.all([updateThresholdPromise, updateGoalsPromise])
      .then(() => {
        toast.success("Data settings updated successfully");
        this.context.updateSettings({
          ...this.context.settings,
          threshold: values.threshold,
          daily_goal: values.dailyGoal,
          weekly_goal: values.weeklyGoal,
          monthly_goal: values.monthlyGoal,
          yearly_goal: values.yearlyGoal,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast.error("Failed to update data settings");
        // eslint-disable-next-line no-console
        console.error(error);
        formikHelpers.setSubmitting(false);
      });
  }

  render(): React.ReactNode {
    const { t } = this.props;

    return (
      <div className="data-settings">
        <div className="card">
          <h2 className="card--title">{t("dataSettings")}</h2>
          <Formik
            onSubmit={this.updateDataSettings}
            enableReinitialize
            initialValues={{
              threshold: this.context.settings?.threshold,
              dailyGoal: this.context.settings?.daily_goal,
              weeklyGoal: this.context.settings?.weekly_goal,
              monthlyGoal: this.context.settings?.monthly_goal,
              yearlyGoal: this.context.settings?.yearly_goal,
            }}
            validationSchema={YUPObject().shape({
              threshold: YUPNumber().min(1, `${t("lowInStockWarning")}`),
              dailyGoal: YUPNumber().min(1, `${t("dailyGoalWarning")}`),
              weeklyGoal: YUPNumber().min(1, `${t("weeklyGoalWarning")}`),
              monthlyGoal: YUPNumber().min(1, `${t("monthlyGoalWarning")}`),
              yearlyGoal: YUPNumber().min(1, `${t("yearlyGoalWarning")}`),
            })}
          >
            {(formikBag) => (
              <Form>
                <div className="card--section">
                  <Field name="threshold">
                    {({ field, meta }) => (
                      <div className="form-field">
                        <label htmlFor="threshold">{t("lowInStock")}</label>
                        <input
                          placeholder="0.0"
                          name="threshold"
                          type="text"
                          {...field}
                          value={field.value ?? "0"}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="card--section">
                  <Field name="dailyGoal">
                    {({ field, meta }) => (
                      <div className="form-field">
                        <label htmlFor="dailyGoal">
                          {t("dailyOrdersValueGoal")}
                        </label>
                        <input
                          placeholder="0.0"
                          name="dailyGoal"
                          type="text"
                          {...field}
                          value={field.value ?? 0}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="weeklyGoal">
                    {({ field, meta }) => (
                      <div className="form-field">
                        <label htmlFor="weeklyGoal">
                          {t("weeklyOrdersValueGoal")}
                        </label>
                        <input
                          placeholder="0.0"
                          name="weeklyGoal"
                          type="text"
                          {...field}
                          value={field.value ?? 0}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="monthlyGoal">
                    {({ field, meta }) => (
                      <div className="form-field">
                        <label htmlFor="monthlyGoal">
                          {t("monthlyOrdersValueGoal")}
                        </label>
                        <input
                          placeholder="0.0"
                          name="monthlyGoal"
                          type="text"
                          {...field}
                          value={field.value ?? 0}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="yearlyGoal">
                    {({ field, meta }) => (
                      <div className="form-field">
                        <label htmlFor="name">
                          {t("annualOrdersValueGoal")}
                        </label>
                        <input
                          placeholder="0.0"
                          name="yearlyGoal"
                          type="text"
                          {...field}
                          value={field.value ?? 0}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <div className="action-buttons">
                    <button
                      type="submit"
                      className="button save"
                      disabled={formikBag.isSubmitting}
                    >
                      {t("saveChanges")}
                    </button>
                    <button
                      className="button-outline"
                      type="button"
                      onClick={() => formikBag.resetForm()}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

DataSettings.contextType = ANALYTICS_CONTEXT;
export default withTranslation("settings")(DataSettings);
