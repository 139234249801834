/**
 * @author Salma Hefnawy
 * @date 2020-06-07
 * @description implementation of scroll down indicator component
 * @filename scroll-down.tsx
 */
import { ANALYTICS_CONTEXT } from "contexts/analytics-context";
import React from "react";

class ScrollDownIndicator extends React.Component {
  componentDidMount() {
    /**
     * add and remove scroll down indicator according to scroll position
     */

    const scrollDown = document.getElementById("scroll-down");
    // const root = document.getElementById("main-section");
    if (scrollDown && scrollDown.nextElementSibling) {
      console.log(
        scrollDown?.nextElementSibling,
        scrollDown?.nextElementSibling?.clientHeight,
        scrollDown?.nextElementSibling?.scrollHeight
      );
    }

    window.onscroll = () => {
      const currentScrollPos = window.scrollY;

      if (scrollDown) {
        if (currentScrollPos >= 50) {
          scrollDown.classList.add("scroll-down--hide");
        } else {
          scrollDown.classList.remove("scroll-down--hide");
        }
      }
    };
  }

  render() {
    return <div className="scroll-down" id="scroll-down" />;
  }
}

ScrollDownIndicator.contextType = ANALYTICS_CONTEXT;

export default ScrollDownIndicator;
